<div class="casoFondo">
    <mat-toolbar class="header">
        <span class="example-spacer"> </span>
        <span>{{tituloHeader}}</span>
    </mat-toolbar>

    <div class="barraDeProgreso">
        <mat-toolbar class="barraDeProgreso">
            <span class="example-spacer"></span>
            <span>{{titulo}}</span>
        </mat-toolbar>
        <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
    </div>

    <div class="servicio-responsivo">
        <mat-card class="cardColumna">
            <div class="centrado">
                <img class="logo" src="../../../../../../../assets/confirmacion.jpg">
                <h1 *ngIf=mostrarCita>Tu cita se agendó con éxito</h1>
                <div *ngIf="mostrarSeleccion">
                    <h3>Has seleccionado a {{nombre}} {{apellido}} para tu caso</h3>
                </div>
            </div>
            <div *ngIf=mostrarCita>
                <div class="fecha-hora">
                    <mat-card-title class="desktop">Fecha: {{this.fecha}}</mat-card-title>
                    <mat-card-title class="desktop">Hora: {{this.hora}}</mat-card-title>
                    <h2 class="mobile">Fecha: {{this.fecha}}</h2>
                    <h2 class="mobile">Hora: {{this.hora}}</h2>
                </div>
                <mat-label class="especialista">{{nombre}} {{apellido}} se pondrá en contacto contigo para acordar el
                    medio por el cual se realizará la consulta.</mat-label>
            </div>


            <button class="boton-continuar" [routerLink]="['/casos-activos/3']" mat-button color="primary">VOLVER A
                MIS
                CASOS</button>
            <router-outlet #outlet="outlet"></router-outlet>

        </mat-card>
    </div>
    <br />
</div>