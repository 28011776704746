<div class="casoFondo">
  <mat-toolbar class="header">
    <button mat-button (click)="atras()">
      <mat-icon class="icono">chevron_left</mat-icon>
    </button>
    <span class="example-spacer"></span>
    <span>{{ "cita.agendar" | translate }}</span>
  </mat-toolbar>
  <div class="barraDeProgreso">
    <mat-toolbar class="barraDeProgreso">
      <span class="example-spacer"></span>
      <span>{{ "cita.dia" | translate }}</span>
    </mat-toolbar>
    <mat-progress-bar mode="determinate" value="80"></mat-progress-bar>
  </div>

  <div class="casoFondo">
    <br />
    <!-- <mat-spinner *ngIf="!terminoCarga"></mat-spinner> -->
    <div *ngIf="!valido">
      <p class="titulo" *ngIf="!valido && terminoCarga">
        {{ "cita.no" | translate }}
      </p>
    </div>
    <ng-container *ngIf="valido">
      <div class="servicio-responsivo">
        <mat-card class="cardColumna">
          <br /><br />
          <mat-card-title>{{ "cita.si" | translate }} </mat-card-title>
          <div class="row">
            <div class="column-6">
              <div [formGroup]="diaEscogido">
                <input
                  class="oculto"
                  disabled
                  formControlName="diaEscogido2"
                  matInput
                  [matDatepicker]="picker2"
                />
                <mat-calendar
                  class="calendar"
                  [selected]="selected"
                  (selectedChange)="selected = $event"
                  (click)="verificarHorarioDia($event)"
                  [dateClass]="dateClass"
                  #picker2
                >
                </mat-calendar>
              </div>
              <br />
              <div style="text-align: center">
                <p>{{ textoRespuesta }}</p>
              </div>
            </div>
            <div class="column-4">
              <mat-card class="cardColumnahour" *ngIf="cantidad > 0">
                <mat-card-title>{{
                  "cita.horario" | translate
                }}</mat-card-title>
                <div *ngIf="horarios" class="matradio">
                  <mat-radio-group
                    (change)="verificarAbogadosHorario()"
                    aria-labelledby="radio-group-label"
                    class="radio-group"
                    [(ngModel)]="horarioSeleccionado"
                  >
                    <mat-radio-button
                      labelPosition="before"
                      class="radio-button"
                      *ngFor="let horario of horarios"
                      [value]="horario"
                      [checked]="horario.checked"
                    >
                      {{ horario.horaString }}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <br />
                <div class="abogadosDisponiblesRango">
                  <div *ngIf="seleccionado">
                    <span *ngIf="cantidad > 0"
                      >{{ "cita.abogados" | translate }} {{ cantidad }}</span
                    >
                    <br /><br />
                    <button
                      *ngIf="cantidad > 0"
                      (click)="verAbogados()"
                      class="boton-primario"
                      mat-raised-button
                      color="primary"
                    >
                      {{ "cita.ver" | translate }}
                    </button>
                  </div>
                  <div *ngIf="seleccionado">
                    <span
                      >{{ seleccion.nombre }} {{ seleccion.apellido }}
                    </span>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </mat-card>
      </div>
    </ng-container>
    <br /><br /><br />
  </div>
</div>
