import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Especialidad } from '../../../../../api/user';
import { ApiService } from '../../../../../api/api.service';
import { Categoria } from '../../../../../api/user';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'dialog-content-example-dialog.html',
  styleUrls: ['./dialog.component.css'],
})
export class DialogComponent {
  categorias: Categoria[];
  constructor(
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: Especialidad
  ) {
    this.matIconRegistry.addSvgIcon(
      `Laboral`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/Laboral.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Individuos y familia`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/individuos.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Comercial y negocios`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/comercial.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Contratos`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/contratos.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Defensa del Consumidor`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/defensa.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Impuestos`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/impuestos.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Inmobiliaria y Propiedades`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/inmobiliaria.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Otros`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/otros.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Penal`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/penal.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Sucesiones y herencias`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/sucesiones.svg'
      )
    );

    this.apiService.getCategorias(data.id_especialidad).subscribe(
      (categorias) => {
        this.categorias = categorias;
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
