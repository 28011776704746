import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from './../../api/api.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AfterViewInit } from '@angular/core';
import { Puntuacion } from 'src/app/interfaces/Puntuacion';
import { NuevaPuntuacion } from 'src/app/interfaces/NuevaPuntuacion';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EspecialidadDelCasoService } from '../../services/especialidad/especialidad-del-caso.service';
import { CasoDetallado } from '../../api/user';
import { MatDialog } from '@angular/material/dialog';
import { DialogCalificarComponent } from './dialog/dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-calificar',
  templateUrl: './calificar.component.html',
  styleUrls: ['./calificar.component.css'],
})
export class CalificarComponent implements OnInit {
  titulo: string;

  calificando = {
    nombre: '',
    apellido: '',
    categoriaAbogado: '',
    titulo: '',
    categoriaCaso: '',
  };

  caso: CasoDetallado;
  especialidadCaso = '';

  calificado = false;
  clickeada = false;
  estrella = [
    '',
    'star_border',
    'star_border',
    'star_border',
    'star_border',
    'star_border',
  ];
  ayuda = '';
  datos;
  clienteAbogado = 'cliente';
  puntuacion: NuevaPuntuacion = new NuevaPuntuacion();
  isAbogado: boolean = false;
  avatarUrl: string;

  opinion = {
    nombre: '',
    apellido: '',
    puntuacion: 0,
    estrellas: '',
    comentario: '',
  };

  constructor(
    private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private _location: Location,
    private especialidadService: EspecialidadDelCasoService,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
    this.matIconRegistry.addSvgIcon(
      `perfil`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/perfil.svg'
      )
    );
    this.caso = JSON.parse(localStorage.getItem('caso'));
    this.datos = JSON.parse(localStorage.getItem('datos'));
    console.log(this.caso, this.datos);
    this.isAbogado = this.datos.rolActual == '2';
    if (
      !this.isAbogado &&
      this.datos &&
      this.datos.abogado &&
      this.datos.abogado.avatarUrl
    ) {
      this.avatarUrl = environment.apiUrl + this.datos.abogado.avatarUrl;
      this.clienteAbogado = 'abogado';
    }
    if (
      this.isAbogado &&
      this.datos &&
      this.datos.cliente &&
      this.datos.cliente.avatarUrl
    ) {
      this.avatarUrl = environment.apiUrl + this.datos.cliente.avatarUrl;
      this.clienteAbogado = 'cliente';
    }
  }

  opinionForm = new UntypedFormGroup({
    nombre: new UntypedFormControl(),
    apellido: new UntypedFormControl(),
    comentario: new UntypedFormControl(),
  });

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  onSubmit() {
    if (this.opinion.puntuacion < 1 || this.opinion.puntuacion > 5) {
      this.ayuda = 'Califica y luego presiona continuar';
    } else {
      this.ayuda = '';
      this.opinion.comentario = this.opinionForm.value.comentario;
      this.openDialog();
    }
  }

  openDialog() {
    const { comentario } = this.opinionForm.value;
    this.puntuacion.caso = this.datos.id_caso;
    this.puntuacion.comentario = comentario;
    this.puntuacion.usuario = this.isAbogado
      ? this.datos.cliente.id_usuario
      : this.datos.abogado.id_usuario;
    this.puntuacion.puntuacion = this.opinion.puntuacion;
    const dialogRef = this.dialog.open(DialogCalificarComponent, {
      backdropClass: 'backdropBackground',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.publicar();
      }
    });
  }

  puntuar(puntos) {
    this.clickeada = true;
    switch (puntos) {
      case 1:
        this.opinion.puntuacion = 1;
        break;
      case 2:
        this.opinion.puntuacion = 2;
        break;
      case 3:
        this.opinion.puntuacion = 3;
        break;
      case 4:
        this.opinion.puntuacion = 4;
        break;
      case 5:
        this.opinion.puntuacion = 5;
        break;
      default:
        break;
    }
    for (let i = 0; i < puntos + 1; i++) {
      this.estrella[i] = 'star';
    }
    for (let i = puntos + 1; i < this.estrella.length; i++) {
      this.estrella[i] = 'star_border';
    }
  }

  ngOnInit(): void {
    this.mapCalificando();
  }

  private mapCalificando() {
    if (!this.isAbogado) {
      this.calificando.nombre = this.datos.abogado.nombre;
      this.calificando.apellido = this.datos.abogado.apellido;
      this.calificando.categoriaAbogado = this.especialidadesToString(
        this.datos.abogado.datosProfesionales[0].especialidadesAbogados
      );
      this.especialidadCaso = this.especialidadService.match(
        this.caso.especialidad.nombre,
        this.datos.abogado
      );
    } else {
      this.calificando.nombre = this.datos.cliente.nombre;
      this.calificando.apellido = this.datos.cliente.apellido;
    }
    this.calificando.titulo = this.datos.tituloDelCaso;
    this.calificando.categoriaCaso = this.datos.categoriaCaso;
  }

  private especialidadesToString(especialidades: Array<any>) {
    return especialidades.map((e) => e.nombre).join();
  }

  publicar() {
    const { comentario } = this.opinionForm.value;
    this.puntuacion.caso = this.datos.id_caso;
    this.puntuacion.comentario = comentario;
    this.puntuacion.usuario = this.isAbogado
      ? this.datos.cliente.id_usuario
      : this.datos.abogado.id_usuario;
    this.puntuacion.puntuacion = this.opinion.puntuacion;
    this.apiService.createPuntuacion(this.puntuacion).subscribe(
      () => {
        if (this.isAbogado) {
          this.calificado = true;
        } else {
          this.calificado = true;
        }
        localStorage.removeItem('datos');
      },
      (error) => {
        console.error(error);
        this.openSnackBar(
          'No es posible publicar tu calificación, por favor intentá nuevamente más tarde'
        );
      }
    );
    this.calificado = true;
  }

  navegar() {
    this.router.navigate(['casos-activos/', this.isAbogado ? '2' : '3']);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 5000 });
  }
}
