import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LogOutService } from 'src/app/services/logOut/log-out.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private logOut: LogOutService) { }

  ngOnInit(): void {
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  cerrarSesion() {
    this.logOut.cerrarSesion();
  }

}
