import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api/api.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cuenta-validada-cliente',
  templateUrl: './cuenta-validada-cliente.component.html',
  styleUrls: ['./cuenta-validada-cliente.component.css']
})
export class CuentaValidadaClienteComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute,
    public translate: TranslateService) {

      translate.addLangs(['es-AR', 'it-IT','en-US']);
      if(sessionStorage.getItem('language')==null){
        translate.setDefaultLang('es-AR');
      }
      else{
        translate.setDefaultLang(sessionStorage.getItem('language'));
      }
     }
  id = "";
  language = "";
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params['params'].id;
      this.language = params['params'].lang;
      this.translate.use(this.language);

      sessionStorage.setItem('language', this.language);
    });

    this.apiService.confirmarUsuario(this.id)
      .subscribe(respuesta => {
      },
        error => {
          console.error(error);
        });
  }

}