<div class="contenidoModal">
  <mat-card>
    <form [formGroup]="formLogo">
      <div class="row">
        <div class="col-md-12">
          <div class="tituloCentrado">
            <h3> Cargar Logo </h3>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="inputs">
            <mat-label>Logo</mat-label>

            <button mat-icon-button matPrefix (click)="f_input.click()">
              <mat-icon>attach_file</mat-icon>
            </button>
            <input type="text" readonly matInput formControlName="display"
              (click)="f_input.click()"
            />
            <input
              type="file"
              hidden
              accept="image/jpeg,image/png"
              #f_input
              (change)="doSelFile(f_input.files)"
            />
          </mat-form-field>
        </div>
      </div>

      <button class="boton-secundario" type="button" mat-button color="primary" (click)="doSubirLogo()" 
        style="margin-top: 2rem;"
        [disabled]="!selFile"
      > Cargar Logo </button>
    </form>
  </mat-card>
</div>
