import { Component, OnInit, Input, Inject } from '@angular/core';
import { Planes } from '../../../../api/planes';
import {
  BajaInput,
  Especialidad,
  Jurisdiccion,
  JurisdiccionCheckBox,
  MotivoBaja,
  Perfil,
} from '../../../../api/user';
import { PlanService } from '../../../../services/plan/plan.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ApiService } from '../../../../api/api.service';
import { RoleNumber } from '../../../../enums/role';
import { LogOutService } from 'src/app/services/logOut/log-out.service';

@Component({
  selector: 'app-popup-baja',
  templateUrl: './popup-baja.component.html',
  styleUrls: ['./popup-baja.component.css'],
})
export class PopupBajaComponent implements OnInit {

    perfil: Perfil;
    plan: Planes;
    labelPosition = 'before';
    motivosBaja:MotivoBaja[];
    motivo:MotivoBaja;
    cantidadMotivosSeleccionadas=0;
    desactivarBoton=false;
    clase:String;
    constructor(private logOut: LogOutService,
        private planService: PlanService,
        public dialog: MatDialog,
        private apiService: ApiService,
        @Inject(MAT_DIALOG_DATA) public data: any
      ) {}
    
      ngOnInit(): void {
        this.getMiPerfil();
        this.getMotivos();
        this.plan = this.data.plan;
      }

      getMiPerfil() {
        this.apiService.getPerfil().subscribe(
          (perfil) => {
            this.perfil = perfil;

          },
          (error) => {
            console.error(error);
          }
        );
      }


      getMotivos() {
        this.apiService.getMotivosBaja().subscribe(
          (motivos) => {
            this.motivosBaja = motivos;
          },
          (error) => {}
        );
      }

      cambiarMotivo(motivo: MotivoBaja) {
        this.motivo =motivo;
        this.motivosBaja.forEach((element) => {
          if (element.id === motivo.id) {
            element.selected = !element.selected;
          }
        });
        this.cantidadMotivosSeleccionadas = 0;
        this.motivosBaja.forEach((element) => {
          if (element.selected == true) {
            this.cantidadMotivosSeleccionadas++;
          }
        });
        this.botonDisabled();
      }

      botonDisabled() {

        let motivosValidas: boolean =
          this.cantidadMotivosSeleccionadas>= 1 ;

        this.desactivarBoton = !motivosValidas;
      }

      onSubmitBaja(){

        let baja:BajaInput ={id:this.perfil.id_usuario,estado:"B",motivoBaja:this.motivo.id} 
        this.apiService.baja(baja).subscribe(
          (response) => {
            
            this.cerrarSesion();
            
          },
          (error) => {}
        );
      }

      changeClass(change: boolean) {
        if (change) {
          this.clase = 'scrooleable';
        } else {
          this.clase = 'scrooleableHidden';
        }
      }

      cerrarSesion() {
        this.logOut.BajaCuenta();
      }
    
    

}