import { Component, OnInit, Inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ApiService } from '../../../../../api/api.service';
import { Router } from '@angular/router';
import { Jurisdiccion, Localidad } from '../../../../../api/user';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { EnterClickService } from '../../../../../utils/enter-click/enter-click.service';
import { TranslateService } from '@ngx-translate/core';
import { Pais } from 'src/app/api/pais';
import { Observable } from 'rxjs';
import { RoleNumber } from '../../../../../enums/role';
import { Especialidad } from '../../../../../api/user';
import { Categoria } from '../../../../../api/user';
import { MatDialog } from '@angular/material/dialog';

import { Planes } from '../../../../../api/planes';
import { PlanMultipagoService } from 'src/app/services/plan/plan-multipago.service';
import { IPlanCicloPlanAmount, PlanDefaultAmount } from 'src/app/components/admin/multipago/modules/multipago.model';
import { PopupDialogComponent } from './popup-dialog/popup-dialog.component';
import { DOCUMENT } from '@angular/common';

enum PlanType {
  Mail = 'Mail',
  Schedule = 'Agenda',
  CallExpress = 'Llamada Express',
}

class CostPlan {
  Mail: number;
  Schedule: number;
  CallExpress: number;
  ScheduleMoneda: string;
  CallExpressMoneda: string;
}

@Component({
  selector: 'app-crear',
  templateUrl: './crear.component.html',
  styleUrls: ['./crear.component.css'],
})
export class CrearComponent implements OnInit {
  titulo = '';
  detalles = '';
  flag: boolean = false;
  edit: boolean = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApiService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private enterClick: EnterClickService,
    public translate: TranslateService,
    private servicePlanMultipago: PlanMultipagoService,
    public dialog: MatDialog
  ) {
    translate.addLangs(['es-AR', 'it-IT', 'en-US']);
    this.language = sessionStorage.getItem('language');
    translate.setDefaultLang(sessionStorage.getItem('language'));
    this.matIconRegistry.addSvgIcon(
      `borrar`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/garbage.svg'
      )
    );
  }

  planCiclo: IPlanCicloPlanAmount = {};

  paises: Pais[];
  jurisdicciones: Jurisdiccion[];
  localidades: Localidad[];
  especialidades: Especialidad[];
  categorias: Categoria[];
  archivos: File[] = [];
  language: String = '';
  contadorTitulo;
  contadorDetalles;
  claseTitulo = 'contador';
  claseDetalles = 'contador';
  jurisdiccionSeleccionada = 'Provincia';
  localidadSeleccionada = 'Localidad';
  cambiar = false;
  casoForm = new UntypedFormGroup({
    titulo: new UntypedFormControl('', [Validators.maxLength(255)]),
    detalles: new UntypedFormControl('', [Validators.maxLength(255)]),
    pais: new UntypedFormControl(''),
    jurisdiccion: new UntypedFormControl('', [Validators.required]),
    localidad: new UntypedFormControl('', [Validators.required]),
    archivosSeleccionados: new UntypedFormControl(),
    especialidad: new UntypedFormControl('', [Validators.required]),
    categoria: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
  });
  caso_edit;

  caso = {
    titulo: '',
    detalles: '',
    jurisdiccion: {
      nombre: '',
      id_jurisdiccion: '',
      countryId: 54,
    },
    localidad: {
      nombre: '',
      id_localidad: '',
    },
    especialidad: {
      nombre: '',
      id_especialidad: '',
    },
    categoria: {
      nombre: '',
      id_categoria: '',
    },
    archivosSeleccionados: [],
  };
  datos = {
    titulo: 'Llamada express',
    abogados: [],
    caso: this.caso,
  };
  costPlan: CostPlan;

  isFormValid() {
    // Comprueba si cada campo requerido está completo
    const isTituloValid = this.casoForm.get('titulo').value !== '';
    const isDetallesValid = this.casoForm.get('detalles').value !== '';
    const isPaisValid = this.casoForm.get('pais').value !== '';
    const isJurisdiccionValid = this.casoForm.get('jurisdiccion').value !== '';
    const isLocalidadValid = this.casoForm.get('localidad').value !== '';
    const isEspecialidadValid = this.casoForm.get('especialidad').value !== '';

    // Devuelve true si todos los campos requeridos están completos
    return (
      isTituloValid &&
      isDetallesValid &&
      isPaisValid &&
      isJurisdiccionValid &&
      isLocalidadValid &&
      isEspecialidadValid
    );
  }

  sincronizarCasoConFormulario() {
    const valoresForm = this.casoForm.value;
    this.caso.titulo = valoresForm.titulo;
    this.caso.detalles = valoresForm.detalles;

    this.caso.jurisdiccion.nombre = valoresForm.jurisdiccion.nombre;
    this.caso.jurisdiccion.id_jurisdiccion =
      valoresForm.jurisdiccion.id_jurisdiccion;
    this.caso.jurisdiccion.countryId = valoresForm.jurisdiccion.countryId;

    this.caso.localidad.nombre = valoresForm.localidad.nombre;
    this.caso.localidad.id_localidad = valoresForm.localidad.id_localidad;

    const especialidadSeleccionada = this.especialidades.find(
      (e) => e.id_especialidad === valoresForm.especialidad
    );
    const categoriaSeleccionada = this.categorias.find(
      (c) => c.id_categoria === valoresForm.categoria
    );

    this.caso.especialidad.nombre = especialidadSeleccionada.nombre;
    this.caso.especialidad.id_especialidad = valoresForm.especialidad;
    this.caso.categoria.nombre = categoriaSeleccionada.titulo;
    this.caso.categoria.id_categoria = valoresForm.categoria;
  }

  ngOnInit(): void {
    var plans: Planes[];
    this.costPlan = new CostPlan();

    this.apiService.getPlanes().subscribe(
      (planes) => {
        plans = planes;
        this.setCost(plans);

        this.servicePlanMultipago
          .getPlanDefaultAmounts(
            0,
            this.caso.jurisdiccion.id_jurisdiccion,
            [2, 3]
          )
          .subscribe({
            next: (result: PlanDefaultAmount[]) => {
              this.setDefaultCost(result);
              console.log(result.length);
            },
          });
      },
      (error) => {
        console.error(error);
      }
    );
    this.traerAbogados();

    let caso = JSON.parse(localStorage.getItem('caso'));
    this.caso_edit = JSON.parse(localStorage.getItem('caso-edit'));
    if (this.caso_edit != null) {
      this.edit = true;
    }
    let paisValue = JSON.parse(localStorage.user).pais;
    if (paisValue == 'ARG') paisValue = '54';
    this.casoForm.get('pais').setValue(paisValue * 1);

    let observable = new Observable<number>((observer) => {
      if (caso != null) {
        this.titulo = caso.titulo;
        this.detalles = caso.detalles;
        this.casoForm.patchValue({
          titulo: caso.titulo,
          detalles: caso.detalles,
        });
        this.conteoTitulo();
        this.conteoDetalles();
        this.cargarJurisdicciones(paisValue);

        let archivos = JSON.parse(localStorage.getItem('archivos'));
        this.archivos = [];
        if (archivos) {
          this.archivos = archivos;
        }
      } else {
        observer.next(paisValue);
      }
    });

    this.apiService.getPaisesClean().subscribe({
      next: (resp: Pais[]) => {
        this.paises = resp;
      },
      error: (error) => {
        console.error(error);
      },
    });

    if (
      this.caso &&
      this.caso.jurisdiccion &&
      this.caso.jurisdiccion.countryId !== undefined
    ) {
      console.log('countryId:', this.caso.jurisdiccion.countryId);
      this.apiService
        .getCategoriasByPais(
          +this.caso.especialidad.id_especialidad,
          this.caso.jurisdiccion.countryId
        )
        .subscribe(
          (categorias) => {
            this.categorias = categorias;
          },
          (error) => {
            console.error(error);
          }
        );
    } else {
      console.log('caso or jurisdiccion or countryId is undefined');
    }

    observable.subscribe({
      next: (paisSelected) => {
        this.cargarJurisdicciones(paisSelected, caso);
        this.cargarEspecialidades(paisSelected);
      },
    });
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    this.language = lang;
    sessionStorage.setItem('language', lang);
    sessionStorage.language = lang;
    this.flag = true;
  }

  conteoTitulo() {
    this.contadorTitulo = this.casoForm.value.titulo.length;
    if (this.contadorTitulo === 55) {
      this.claseTitulo = 'textoRojo';
    } else {
      this.claseTitulo = 'contador';
    }
  }

  conteoDetalles() {
    let detalles = this.casoForm.getRawValue().detalles;
    this.contadorDetalles = detalles ? detalles.length : 0;
    if (this.contadorDetalles === 255) {
      this.claseDetalles = 'textoRojo';
    } else {
      this.claseDetalles = 'contador';
    }
  }

  atras($event) {
    if (this.enterClick.isClick($event)) {
      this.router.navigate(['/']);
    }
  }

  selectFiles(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.archivos.push(event.target.files[i]);
    }
    this.casoForm.get('archivosSeleccionados').patchValue(this.archivos);
  }

  eliminar(archivo, index) {
    let newFileList = Array.from(this.archivos);
    let borrar = newFileList.filter(
      (archivoParaBorrar) => archivoParaBorrar.name === archivo.name
    );
    this.archivos.splice(index, 1);
  }

  getValueFromCicloPlanAmountUtfCode(planCiclo: IPlanCicloPlanAmount, planId: number, field: string, defValue:any) {
    let value = this.getValueFromCicloPlanAmount(planCiclo, planId, field, defValue);
    value = `&#${value};`;
    value = this.domSanitizer.bypassSecurityTrustHtml(value);
    return value;
  }

  getValueFromCicloPlanAmount(planCiclo: IPlanCicloPlanAmount, planId: number, field: string, defValue:any) {
    if (planCiclo && planCiclo[planId] && planCiclo[planId][0])
      return planCiclo[planId][0][field];
    return defValue;
  }

  cargarJurisdicciones(paisId: number, caso: any = null): void {
    let localidadId: number = null;
    this.apiService.getJurisdiccionPaisesBy(paisId).subscribe({
      next: (jurisdiccion) => {
        this.jurisdicciones = jurisdiccion;
        if (jurisdiccion && jurisdiccion.length > 0) {
          this.apiService.getPlanInfos(jurisdiccion[0].id_jurisdiccion, [2, 3]).subscribe({
            next: (result) => {
              this.planCiclo = result;
            }
          });
        }
        if (caso) {
          if (caso != null) {
            this.casoForm.get('jurisdiccion').setValue(caso.jurisdiccion);
            this.casoForm.value.jurisdiccion = caso.jurisdiccion;
            jurisdiccion.some((respJurisdiccion) => {
              if (
                respJurisdiccion.id_jurisdiccion ==
                caso.jurisdiccion.id_jurisdiccion
              ) {
                this.casoForm.patchValue({
                  jurisdiccion: respJurisdiccion,
                });
                return true;
              }
              return false;
            });
          } else {
          }
          this.cargarLocalidades();
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  cargarEspecialidades(paisId: number): void {
    this.apiService.getEspecialidadesByPais(
      RoleNumber.Cliente,
      paisId
    )
    .subscribe(
      (especialidades) => {
        this.especialidades = especialidades.map((especialidad) => {
          const nombreReemplazado = especialidad.nombre.replace('/', ' ');
          return { ...especialidad, nombre: nombreReemplazado };
        });

        if (this.caso.especialidad.id_especialidad) {
          this.onEspecialidadChange(+this.caso.especialidad.id_especialidad);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  updateJurisdiccion(event: any) {
    const selectedIndex = event.target.options.selectedIndex;
    this.caso.jurisdiccion.id_jurisdiccion =
      event.target.options[selectedIndex].getAttribute('id');
    this.caso.jurisdiccion.countryId =
      event.target.options[selectedIndex].getAttribute('countryId');
  }

  cargarLocalidades() {
    this.jurisdiccionSeleccionada = 'Provincia';
    this.casoForm.get('localidad').setValue('');
    this.localidadSeleccionada = 'Localidad';
    this.apiService
      .getLocalidades(this.casoForm.value.jurisdiccion.id_jurisdiccion)
      .subscribe(
        (localidad) => {
          this.localidades = localidad;

          if (this.localidades && this.localidades.length > 0) {
            this.casoForm.patchValue({ localidad: this.localidades[0] });
          }

          let localidadValue = this.casoForm.getRawValue().localidad;
          if (typeof localidadValue === 'number') {
            localidad.some((respLocalidad) => {
              if (localidadValue == respLocalidad.id_localidad) {
                this.casoForm.patchValue({ localidad: respLocalidad });
                return true;
              }
              return false;
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  onEspecialidadChange(especialidadId: number) {
    this.apiService.getCategorias(especialidadId).subscribe((categorias) => {
      this.categorias = categorias;

      if (this.categorias && this.categorias.length > 0) {
        this.casoForm
          .get('categoria')
          .setValue(this.categorias[0].id_categoria);
      }

      this.casoForm.get('categoria').enable();
      if (this.casoForm.valid) {
        this.caso.titulo = this.casoForm.get('titulo').value;
        this.caso.detalles = this.casoForm.get('detalles').value;
      }
    });
  }

  setCost(plans: Planes[]) {
    this.costPlan.Mail = plans.find((x) => x.nombre == PlanType.Mail).costo;
    this.costPlan.Schedule = plans.find(
      (x) => x.nombre == PlanType.Schedule
    ).costo;
    this.costPlan.CallExpress = plans.find(
      (x) => x.nombre == PlanType.CallExpress
    ).costo;
  }

  setDefaultCost(planAmounts: PlanDefaultAmount[]): void {
    planAmounts.forEach((planAmount: PlanDefaultAmount) => {
      switch (planAmount.planId) {
        case 2:
          this.costPlan.Schedule = planAmount.monto;
          this.costPlan.ScheduleMoneda = planAmount.monedaId;
          break;
        case 3:
          this.costPlan.CallExpress = planAmount.monto;
          this.costPlan.CallExpressMoneda = planAmount.monedaId;
          break;
      }
    });
  }

  OpenDialog() {
    this.document.body.classList.add('custom-glassmorphism');
    const dialogRef = this.dialog.open(PopupDialogComponent, {
      data: { caso: this.caso },
      width: '80%',
      height: '90%',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.document.body.classList.remove('custom-glassmorphism');
    });
  }

  navegar(destino) {
    switch (destino) {
      case 1:
        this.cargarArchivo();
        this.OpenDialog();
        break;
      case 2:
        this.router.navigateByUrl('/rango', { state: this.datos });
        this.sincronizarCasoConFormulario();
        break;
      case 3:
        this.router.navigateByUrl('/seleccion/previa', { state: this.datos });
        this.sincronizarCasoConFormulario();
        break;
      default:
        break;
    }
  }

  cargarArchivo() {
    this.sincronizarCasoConFormulario();
    console.log(this.caso);
    let archivos: File[] =
      this.casoForm.get('archivosSeleccionados').value || [];

    this.apiService.postCasoTmpUpload(archivos).subscribe({
      next: (resp: any[]) => {
        if (resp) {
          let toSave: any[] = [...archivos, ...resp];
          localStorage.setItem('archivos', JSON.stringify(toSave));
          this.caso.archivosSeleccionados = toSave;
        } else {
          localStorage.removeItem('archivos');
        }
      },
      error: (err) => {
        console.error('Hubo un error al cargar los archivos', err);
      },
    });
    this.sincronizarCasoConFormulario();
    console.log(this.caso);
  }

  traerAbogados() {
    let hora = Date.now();
    this.apiService
      .getAbogadosDisponiblesLlamada(
        +this.caso.especialidad.id_especialidad,
        hora,
        +this.caso.localidad.id_localidad
      )
      .subscribe(
        (respuesta) => {
          this.datos.abogados = respuesta;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  async onSubmit() {
    this.sincronizarCasoConFormulario();
    console.log(this.caso);
    let archivos: File[] =
      this.casoForm.get('archivosSeleccionados').value || [];

    this.apiService.postCasoTmpUpload(archivos).subscribe({
      next: (resp: any[]) => {
        if (resp) {
          let toSave: any[] = [...archivos, ...resp];
          localStorage.setItem('archivos', JSON.stringify(toSave));
          this.caso.archivosSeleccionados = toSave;
        } else {
          localStorage.removeItem('archivos');
        }
      },
      error: (err) => {
        console.error('Hubo un error al cargar los archivos', err);
      },
    });
    this.sincronizarCasoConFormulario();
    console.log(this.caso);
  }
}
