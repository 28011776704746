import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EspecialidadDelCasoService {

  constructor() { }

  match(especialidad, abogado) {
    let especialidadesAbogados = abogado.datosProfesionales[0].especialidadesAbogados;
    let especialidadParaMostrar = "";
    let contador = 0;
    especialidadesAbogados.forEach(especialidadAbogado => {
      especialidadAbogado.nombreCliente.forEach(nombreParaCliente => {
        if(nombreParaCliente == especialidad) {
          contador++;
          if(nombreParaCliente != "Otros") {
            especialidadParaMostrar = especialidadParaMostrar + " " + especialidadAbogado.nombre;
          } else {
            especialidadParaMostrar = especialidadesAbogados[0].nombre;
          }
        }
      });
    });
    return especialidadParaMostrar;
  }
}
