<form [formGroup]="formMail" class="dialog-mp-main">
    <mat-form-field style="width: 100%;">
        <mat-label>Correo electronico</mat-label>
        <input matInput #input formControlName="mail"/>
        <mat-hint align="end">Correo electronico con la se asociara a la cuenta MercadoPago</mat-hint>
    </mat-form-field>
    <div class="dialog-mp-buttons">
        <button mat-flat-button color="primary"
            [disabled]="!formMail.valid"
            (click)="onSelTarjeta()"
        >
            Pagar por Tarjeta
        </button>
        <button mat-flat-button color="primary"
            [disabled]="!formMail.valid"
            (click)="onSelPlataforma()"
        >
            Ir a Plataforma
        </button>
        <button mat-flat-button color="primary"
            [disabled]="!formMail.valid"
            (click)="onSelByUrl()"
        >
            Ir a Pagina Pago
        </button>
    </div>
</form>
