<div class="casoFondo">
  <mat-toolbar class="header">
    <button mat-button (click)="atras()">
      <mat-icon class="icono">chevron_left</mat-icon>
    </button>
    <span class="example-spacer"></span>
    <span>{{ "seleccion.agendar" | translate }}</span>
  </mat-toolbar>
  <div class="barraDeProgreso" *ngIf="seleccionAbogado">
    <mat-toolbar class="barraDeProgreso">
      <span class="example-spacer"></span>
      <span>{{ "seleccion.info" | translate }}</span>
    </mat-toolbar>
    <mat-progress-bar mode="determinate" value="30"></mat-progress-bar>
  </div>

  <div class="barraDeProgreso" *ngIf="mostrarTelefono">
    <mat-toolbar class="barraDeProgreso">
      <span class="example-spacer"></span>
      <span>{{ "seleccion.pago" | translate }}</span>
    </mat-toolbar>
    <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
  </div>
  <br />
  <mat-card class="cardPerfil" *ngIf="mostrarTelefono">
    <div class="separadorCentrado">
      <img class="logo" src="../../../../assets/exito.svg" />
      <h3>{{ "seleccion.pago1" | translate }}</h3>
      <mat-label>{{ "seleccion.tele" | translate }}</mat-label>
    </div>
  </mat-card>
  <mat-card class="cardPerfil">
    <div class="imagenCuadrada">
      <img class="sello_sign" src="assets/sello_sign.png" *ngIf="abogado.datosProfesionales[0].certificacion!=null && abogado.datosProfesionales[0].certificacion.status_sign=='CO'">

      <img
        *ngIf="tieneFoto"
        class="rounded"
        [src]="avatarUrl | secure | async"
      />
      <mat-icon
        *ngIf="!tieneFoto"
        svgIcon="perfil"
        class="imagenDetalles"
      ></mat-icon>
      <p *ngIf="!tieneFoto">asd</p>
    </div>
    <div class="nombrePerfil">
      <mat-card-title>{{ nombre }} {{ apellido }}</mat-card-title>
      <h2 *ngIf="!mostrar">{{ nombreEspecialidad }}</h2>
      <h2 *ngIf="mostrar">{{ especialidadParaMostrar }}</h2>
      <ng-template [ngIf]="countryId != 52 || rolId != 3">
        <mat-card-title>{{ localidad }}</mat-card-title>
      
        <h2>{{ abogado.datosProfesionales[0].localidad.jurisdiccion.nombre }}</h2>
      </ng-template>
    </div>
    <mat-card-content>
      <mat-card-title>{{ "seleccion.bio" | translate }}</mat-card-title>
      <h2>{{ abogado.descripcion }}</h2>
    </mat-card-content>
  </mat-card>
  
  <mat-card *ngIf="mostrarDatos" class="cardPerfil">
    <mat-card-title>{{ "seleccion.contacto" | translate }}</mat-card-title>
    <div>
      <div *ngIf="mostrarCorreo && !mostrarTelefono">
        <br>
        <mat-card-subtitle>{{pais
        }}</mat-card-subtitle>
        <mat-card-subtitle>{{mail}}</mat-card-subtitle>
        <mat-card-subtitle>{{
          abogado.datosProfesionales[0].telefono
        }}&nbsp;&nbsp;<a href="https://wa.me/+{{pais}}{{abogado.datosProfesionales[0].telefono}}">
          {{ "detalles.whatsapp" | translate }}
         </a></mat-card-subtitle>
      </div>
      <div *ngIf="mostrarExplicacionLlamada">
        {{ "seleccion.mostrar" | translate }}
        <button
          class="boton-llamar"
          mat-button
          color="primary"
          disabled
          (click)="llamar(telefono)"
        >
          {{ "seleccion.llamar" | translate }}
        </button>
      </div>
      <div *ngIf="mostrarTelefono">
        <p>{{ telefono }}</p>
        <button
          class="boton-llamar"
          mat-button
          color="primary"
          (click)="llamar(telefono)"
        >
          {{ "seleccion.llamar" | translate }}
        </button>
      </div>
    </div>
  </mat-card>
  <app-opiniones
    [datosPerfil]="abogado"
    [opiniones]="opiniones"
  ></app-opiniones>
  <mat-card *ngIf="mostrarVolver" class="cardPerfil">
    <button
      class="boton-continuar"
      [routerLink]="['/casos-activos/3']"
      mat-button
      color="primary"
    >
      {{ "seleccion.volver" | translate }}
    </button>
  </mat-card>
  <mat-card *ngIf="!mostrarTelefono" class="cardPerfil">
    <button
      class="boton-primario"
      mat-raised-button
      color="primary"
      (click)="navegar(2)"
    >
      {{ "seleccion.confirmar" | translate }}
    </button>
  </mat-card>
  <br />
</div>
