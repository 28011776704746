import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class UpdateRangesService {
  constructor(private apiService: ApiService) {}

  public updateTags(
    userId: number,
    newTags: string[],
    isEspecialidad: boolean
  ) {
    let response: boolean = true;
    response = this.getTags(userId, newTags, isEspecialidad);
    return response;
  }

  private getTags(userId: number, newTags, isEmpty: boolean) {
    let done: boolean = false;
    this.apiService.getTags(userId).subscribe(
      (oldTags) => {
        let allTags: string;
        if (!isEmpty) {
          allTags = this.appendTags(oldTags, newTags);
        } else {
          let empty = [];
          allTags = this.appendTags(empty, newTags);
        }
        done = this.updateRanges(userId, allTags);
        return done;
      },
      (error) => {
        console.error(error);
        done = false;
        return done;
      }
    );
    return done;
  }

  private appendTags(oldTags: string[], newTags: string[]) {
    let tags = '';
    oldTags.forEach((tag) => {
      tags += 'tags=' + tag + '&';
    });
    newTags.forEach((tag) => {
      tags += 'tags=' + tag + '&';
    });
    return tags;
  }

  private updateRanges(userId, allTags) {
    let done: boolean = false;
    this.apiService.patchRangos(userId, allTags).subscribe(
      (respuesta) => {
        if (respuesta == null) {
          done = true;
          return done;
        }
      },
      (error) => {
        console.error(error);
        done = false;
        return done;
      }
    );
    return done;
  }

  public updateWithEmptyTags(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let tags = [];
      this.apiService.getPerfil().subscribe(
        (perfil) => {
          perfil.datosProfesionales.forEach((dato) => {
            if (dato.estado != 'D')
              tags.push(dato.localidad.jurisdiccion.nombre);
          });
          perfil.datosProfesionales[0].especialidadesAbogados.forEach(
            (especialidad) => {
              tags = tags.concat(especialidad.nombreCliente);
              tags = [...new Set([...tags, ...especialidad.nombreCliente])];
            }
          );
          this.updateTags(perfil.id_usuario, tags, true);
          resolve(tags);
        },
        (error) => {
          reject();
        }
      );
    });
  }
}
