import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
})
export class DialogCalificarComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogCalificarComponent>,
    public dialog: MatDialog,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }

  ngOnInit(): void {}

  cerrar(): void {
    this.dialogRef.close();
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }
}
