
<div class="contenidoModal">
    <mat-card > 
      <form [formGroup]="certForm">

        <div class="row">

          <div class="col-md-12">
            <div class="tituloCentrado">
              <h3>{{ "certificacion.cert_title" | translate }}</h3>
          </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-md-6">
              <mat-form-field appearance="outline" class="inputs">
                  <mat-label>{{ "certificacion.question" | translate }}</mat-label>
                  <input required matInput placeholder="{{ 'certificacion.question' | translate }}"
                  formControlName="question" >
              </mat-form-field>
          </div>

          <div class="col-md-6">
            <mat-form-field appearance="outline" class="inputs">
                <mat-label>{{ "certificacion.weight" | translate }}</mat-label>
                <input required matInput type="number"  placeholder="{{ 'certificacion.weight' | translate }}"
                formControlName="weight" >
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
                <mat-label>Valido</mat-label><br>
                <mat-radio-group aria-label="" formControlName="answer">
                  <mat-radio-button value="0">No</mat-radio-button>
                  <mat-radio-button value="1">Si</mat-radio-button>
                </mat-radio-group>
          </div>

        </div>
        <br>

        <button class="boton-secundario" type="button" mat-button color="primary" (click)="onSubmit()" >{{ "registroEntidad.ingresar" | translate }}</button>
      
        
      </form>
    </mat-card>

    
  </div>

