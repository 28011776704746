import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TiposCasoCode } from 'src/app/enums/TiposCasoCode';
import { FilesService } from 'src/app/utils/files.service';

import { ApiService } from '../../../../../../api/api.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { CurrentTimeService } from '../../../../../../utils/currentTime/current-time.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.css'],
})
export class PopupDialogComponent implements OnInit {
  caso: any;

  edit: boolean = false;
  desactivarSubmit = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
    private apiService: ApiService,
    private router: Router,
    private fileSrv: FilesService,
    private currentTimeSrv: CurrentTimeService,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<PopupDialogComponent>
  ) {
    this.caso = data.caso;
    console.log('Caso:', this.caso);

    translate.addLangs(['es-AR', 'it-IT', 'en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }

  cerrar(): void {
    this.dialogRef.close();
  }

  guardar(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    let caso = JSON.parse(localStorage.getItem('caso-edit'));
    if (caso != null) {
      this.edit = true;
    }
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  atras() {
    window.history.back();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      panelClass: ['snackbar-custom', 'snackbar-custom-action'],
      duration: 5000,
    });
  }

  onSubmit() {
    let timeStamp = this.currentTimeSrv.getTimeStamp();
    let timeZone = '';
    this.apiService.getCountry().subscribe(
      (res) => {
        timeZone = res.countryCode;
        this.postCaso(timeStamp, timeZone);
      },
      (error) => {
        console.error(error);
        timeZone = this.currentTimeSrv.getTimezoneHours();
        this.postCaso(timeStamp, timeZone);
      }
    );
  }

  postCaso(timeStamp, timeZone) {
    let caso = JSON.parse(localStorage.getItem('caso-edit'));
    if (caso != null) {
      this.apiService
        .putCasoMail(
          caso.id_caso,
          this.caso.categoria.id_categoria,
          this.caso.detalles,
          this.caso.archivosSeleccionados,
          this.caso.localidad.id_localidad,
          this.caso.titulo,
          TiposCasoCode.EMAIL,
          timeStamp,
          timeZone
        )
        .subscribe(
          () => {
            this.router.navigate(['/casos-activos/3']);
            this.desactivarSubmit = false;
            localStorage.removeItem('caso-edit');
            this.openSnackBar('Su caso se creó con éxito', 'Ok');
            this.dialogRef.close();
          },
          (error) => {
            this.desactivarSubmit = false;
            console.error(error);
            this.openSnackBar('No fue posible publicar tu caso', 'Fracaso');
          }
        );
    } else {
      this.apiService
        .postCasoMail(
          this.caso.categoria.id_categoria,
          this.caso.detalles,
          this.caso.archivosSeleccionados,
          this.caso.localidad.id_localidad,
          this.caso.titulo,
          TiposCasoCode.EMAIL,
          timeStamp,
          timeZone
        )
        .subscribe(
          () => {
            this.router.navigate(['/casos-activos/3']);
            this.desactivarSubmit = false;
            this.openSnackBar('Su caso se creó con éxito', 'Ok');
            this.dialogRef.close();
          },
          (error) => {
            this.desactivarSubmit = false;
            console.error(error);
            this.openSnackBar('No fue posible publicar tu caso', 'Fracaso');
          }
        );
    }
  }
}
