<div class="casoFondo">
    <mat-toolbar class="header">
      <span class="example-spacer"></span>
      <span>
        {{ "admin.administrador" | translate }}
      </span>
  
      <br />
      <!-- <label class="selectLenguage">
        {{ "admin.selectLenguage" | translate }}
      </label>
      <select
        class="lenguage"
        #selectedLang
        (change)="switchLang(selectedLang.value)"
      >
        <option
          *ngFor="let language of translate.getLangs()"
          [value]="language"
          [selected]="language === translate.currentLang"
        >
          {{ language }}
        </option>
      </select> -->
      <!-- <a>
        <img
          class="españa"
          src="../../../../assets/españa.png.png"
          width="25"
          height="15"
          id="es"
          #selectedLang
          (click)="switchLang(id)"
        />
      </a> -->
  
      <button (click)="switchLang('es-AR')" class="españaBtn"></button>
  
      <button (click)="switchLang('it-IT')" class="italiaBtn"></button>
  
      <!-- <a>
        <img
          class="italia"
          id="it"
          src="../../../../assets/italia.png.png"
          width="25"
          height="15"
          #selectedLang
        />
      </a> -->
      <!-- onclick="switchLang(selectedLang.value)"
      /> -->
  
      <!-- <script>
        document.getElementById("españaLogo").onclick = function () {
          switchLang(selectedLang.value);
        };
      </script> -->
  
      <button class="cerrarSesionAdmin" mat-button (click)="cerrarSesion()">
        <mat-icon class="icono">logout</mat-icon>
      </button>
    </mat-toolbar>
    <br />
  
    <app-navbar-admin></app-navbar-admin>
  
    <mat-card class="admin">
        <router-outlet></router-outlet>
    </mat-card>
</div>
