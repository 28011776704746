import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../../../modules/material.module';
import { LoginRoutingModule } from '../../login/login-routing.module';
import { ClienteRegistroModule } from '../registroYMensajesAsociados/modules/clienteRegistro.modules';
import { ClienteCasosModule } from '../casos/modules/clienteCasosNuevos.modules';

@NgModule({
  declarations: [
  ],
  imports: [
    BrowserModule,
    LoginRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    ClienteRegistroModule,
    ClienteCasosModule
  ],
  providers: [],
  bootstrap: []
})
export class ClienteModule { }
