import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/api/api.service';

@Component({
  selector: 'app-mensaje-reenvio',
  templateUrl: './mensaje-reenvio.component.html',
  styleUrls: ['./mensaje-reenvio.component.css']
})
export class MensajeReenvioComponent implements OnInit {
    language:String="";
    constructor(public dialog: MatDialog,private apiService: ApiService, private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute, public translate: TranslateService) {
      translate.addLangs(['es-AR', 'it-IT','en-US']);
      this.language = sessionStorage.getItem('language');
      translate.setDefaultLang(sessionStorage.getItem('language'));
     }
    userForm = new UntypedFormGroup({
      mail: new UntypedFormControl('', [
        Validators.required
      ]),
    });
    idCambioClave: number;

  ngOnInit(): void {
  }

  resend(){
    
    let mail = this.userForm.get('mail').value;
    this.apiService.solicitarReenvioConfirmacion(mail).subscribe(
      (response) => {
        
        this._snackBar.open("Se solicito el reenvio", "", { duration: 5000 });
        this.dialog.closeAll();
        
      },
      (error) => {this.dialog.closeAll();}
    );
  }

}
