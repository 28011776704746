import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';

import { NavbarAdminComponent } from './navbar-admin.component';
import { CommonModule } from '@angular/common';
import { navbarAdminRouting } from './navbar-admin-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [NavbarAdminComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    CommonModule,
    MatMenuModule,
    navbarAdminRouting,
    TranslateModule,
  ],
  exports: [NavbarAdminComponent],
  providers: [],
  bootstrap: [],
})
export class NavbarAdminmodule {}
