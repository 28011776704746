export enum Role {
  Cliente = 'ROLE_CLIENTE',
  Abogado = 'ROLE_ABOGADO',
  Admin = 'ROLE_ADMIN',
}

export enum RoleNumber {
  Abogado = 2,
  Cliente = 3,
}
