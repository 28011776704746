import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PuntuacionesService {

  constructor() { }

  avatar(opinion) {
      if (opinion.usuarioCreador.avatarUrl != null) {
        opinion.tieneFoto = true;
        opinion.avatarUrl = environment.apiUrl + opinion.usuarioCreador.avatarUrl;
      } else {
        opinion.tieneFoto = false;
      }
    return opinion;
  }

  crearEstrellasMapeando(opiniones) {
    opiniones = opiniones.map(opinion => {
      this.crearEstrellasIndividualEmojis(opinion);
      this.avatar(opinion);
    })
  }

  crearEstrellasIndividualEmojis(opinion) {
    let parteEntera = Math.trunc(opinion.puntuacion);
    let parteDecimal = opinion.puntuacion % 1;

    switch (parteEntera) {
      case 1:
        opinion.estrellas = "★ ";
        break;
      case 2:
        opinion.estrellas = "★ ★ ";
        break;
      case 3:
        opinion.estrellas = "★ ★ ★ ";
        break;
      case 4:
        opinion.estrellas = "★ ★ ★ ★ ";
        break;
      case 5:
        opinion.estrellas = "★ ★ ★ ★ ★ ";
        break;
      default:
        opinion.estrellas = "★ ★ ★ ★ ★ ";
        break;
    }

    switch (parteEntera) {
      case 1:
        opinion.estrellas = opinion.estrellas + "☆ ☆ ☆ ☆";
        break;
      case 2:
        opinion.estrellas = opinion.estrellas + "☆ ☆ ☆";
        break;
      case 3:
        opinion.estrellas = opinion.estrellas + "☆ ☆";
        break;
      case 4:
        opinion.estrellas = opinion.estrellas + "☆";
        break;
    }

    return opinion;

  }

  crearEstrellasIndividual(opinion) {
    let parteEntera = Math.trunc(opinion.puntuacion);
    let parteDecimal = opinion.puntuacion % 1;

    switch (parteEntera) {
      case 1:
        opinion.estrellas = "star";
        break;
      case 2:
        opinion.estrellas = "star star";
        break;
      case 3:
        opinion.estrellas = "star star star";
        break;
      case 4:
        opinion.estrellas = "star star star star";
        break;
      case 5:
        opinion.estrellas = "star star star star star";
        break;
      default:
        opinion.estrellas = "star_border";
        break;
    }

    if (parteDecimal < 0.5 && parteEntera != 5) {
      opinion.estrellas = opinion.estrellas + " star_border ";
    }
    if (parteDecimal >= 0.5) {
      opinion.estrellas = opinion.estrellas + " star_half ";
    }

    switch (parteEntera) {
      case 1:
        opinion.estrellas = opinion.estrellas + "star_border star_border star_border";
        break;
      case 2:
        opinion.estrellas = opinion.estrellas + "star_border star_border";
        break;
      case 3:
        opinion.estrellas = opinion.estrellas + "star_border";
        break;
      case 4:
        opinion.estrellas = opinion.estrellas;
        break;
    }
  }
}
