<div class="terms">
    <header class="termsHeader">
    </header>
    <main class="termsMain">
        <section class="termsContainer">
            <article class="termsArticle">
                <h2 class="termFirstTitle">POLITICAS DE PRIVACIDAD<br />
                DEL SITIO WWW.LEGALIFY.AR, WWW.LEGALIFY.COM.AR y WWW.LEGALIFY.APP</h2>
<p>
(a) El Sitio ha sido desarrollado con respeto por la privacidad del Usuario en Internet y considerando el deber del Administrador de proteger, conforme a la Ley 25326, cualesquiera Datos Personales que el Usuario transmitiere al Sitio en relación con el Acceso del Sitio, la Permanencia en el Sitio, la Actividad en el Sitio y la Salida del Sitio.<br />
(b) Cada Acceso del Sitio automáticamente representa para el Usuario su consentimiento sin condiciones para que el Administrador pueda recolectar, en el Sitio y/o por correo electrónico recibido del Usuario, uno o más Datos Personales con las siguientes finalidades salvo indicación expresa en contrario del Administrador en el Sitio y/o por correo electrónico enviado al Usuario:<br />
(I) mejorar la interacción entre el Usuario y el Sitio durante la Permanencia en el Sitio; y<br />
(II) elaborar estadísticas anónimas (es decir, no susceptibles de posibilitar la identificación del Usuario) del Sitio; y<br />
(III) cumplir una orden de una Autoridad Pública recibida por el Administrador.<br />
(c) El Administrador deberá obtener del Usuario, en el Sitio y/o por correo electrónico recibido del Usuario, el consentimiento sin condiciones del Usuario para guardar una o más Cookies del Sitio en el dispositivo utilizado por el Usuario para Acceder al Sitio y utilizar dichas Cookies del Sitio antes de guardar éstas en dicho dispositivo.<br />
(d) El Usuario:<br />
(I) podrá en cualquier momento durante la Permanencia en el Sitio comunicar al Administrador en el Sitio su negativa sin condiciones a:<br />
(1) suministrar uno o más Datos Personales al Administrador conforme al apartado (b); y/o<br />
(2) permitir el almacenamiento y la utilización de una o más Cookies del Sitio conforme al apartado (c); y<br />
(II) reconoce y acepta que la interacción entre el Usuario y el Sitio durante la Permanencia en el Sitio podría ser menos satisfactoria (cuantitativa y/o cualitativamente) que lo sería por el contrario si:<br />
(1) comunicare una negativa conforme al sub-apartado (I); o<br />
(2) suministrare Datos Personales incompletos, inexactos o falsos a los fines del apartado (b).<br />
(e) El Administrador almacenará los Datos Personales en una base de datos cuya administración será responsabilidad exclusiva del Administrador en el domicilio indicado en la Cláusula Domicilio Especial.<br />
(f) Los Datos Personales que el Administrador recolectare conforme al apartado (b) podrán ser almacenados, procesados y transferidos exclusivamente por:<br />
(I) el Administrador; y<br />
(II) cada persona humana o jurídica con la que el Administrador celebrare un contrato de transferencia o cesión de uno o más Datos Personales; y<br />
(III) cada Autoridad Pública que requiriere al Administrador la transferencia o cesión de uno o más Datos Personales por resolución judicial y cuando medien razones fundadas relativas a la seguridad pública, la defensa nacional o la salud pública.<br />
(g) Conforme a la Ley 25326 el Usuario podrá solicitar al Administrador:<br />
(I) el acceso a uno o más Datos Personales; y<br />
(II) la actualización de uno o más Datos Personales que hubieran perdido vigencia por haber cambiado las circunstancias del Usuario; y<br />
(III) la rectificación de uno o más Datos Personales inexactos o incompletos; y<br />
(IV) el bloqueo de uno o más Datos Personales; y<br />
(V) la supresión de uno o más Datos Personales.<br />
(h) Cada solicitud del Usuario conforme al apartado (g) deberá ser efectuada mediante:<br />
(I) una carta documento si el Usuario solicitare acceder a uno o más Datos Personales; o<br />
(II) una carta simple, acompañada de una fotocopia simple de su Documento Nacional de Identidad o Pasaporte vigente, si el Usuario solicitare la actualización, la rectificación, el bloqueo o la supresión de uno o más Datos Personales.<br />
(i) El Usuario deberá entregar o causar que se entregue la comunicación correspondiente según el apartado (h) en el domicilio del Administrador indicado en la Cláusula Domicilio Especial.<br />
(j) El Administrador:<br />
(I) no estará obligado a responder favorablemente:<br />
(1) una solicitud de acceso, rectificación y/o supresión de uno o más Datos Personales recibida del Usuario conforme al apartado (g) si el cumplimiento con la solicitud de que se trate afectare la protección de la defensa de la Nación, del orden y la seguridad públicos o de los derechos e intereses de terceros; o<br />
(2) una solicitud de acceso de uno o más Datos Personales recibida del Usuario conforme al apartado (g) sub-apartado (I) si dicha solicitud no estuviere fechada por lo menos 6 (seis) meses después de la fecha de la última solicitud de acceso de Datos Personales que el Administrador hubiera recibido del Usuario conforme al apartado (g) sub-apartado (I) salvo que el Usuario demostrare razonablemente al Administrador (a criterio exclusivo del Administrador) que tiene un interés legítimo para acceder a los Datos Personales de que se trate antes de transcurrir 6 (seis) meses desde de la fecha de aquella última solicitud de acceso; o<br />
(3) una solicitud de supresión de uno o más Datos Personales recibida del Usuario conforme al apartado (g) sub-apartado (V) si la supresión solicitada pudiere causar perjuicios a derechos o intereses legítimos de terceros o impidiere al Administrador cumplir una obligación legal de conservar los Datos Personales de que se trate; y<br />
(II) informará los fundamentos de cada negativa conforme al sub-apartado (I) mediante una comunicación escrita dirigida al domicilio que el Usuario hubiera informado en la solicitud rechazada de que se trate.<br />
(k) La AAIP, en su carácter de Órgano de Control de la Ley 25326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de Datos Personales.<br />
</p>

            </article>
        </section>
    </main>
</div>
