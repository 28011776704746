import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReturnUser } from '../../api/user';
import { TokenDecoderService } from '../tokenDecoder/token-decoder.service';
import { LogOutService } from '../logOut/log-out.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private userSubject: BehaviorSubject<ReturnUser>;
    public user: Observable<ReturnUser>;

    constructor(private tokenDecoderService: TokenDecoderService,
        private router: Router,
        private http: HttpClient,
        private logOut: LogOutService
    ) {
        this.userSubject = new BehaviorSubject<ReturnUser>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    public get getRole(): ReturnUser {
        return this.tokenDecoderService.getRole();
    }


    logout() {
        this.logOut.limpiarStorage();
        this.userSubject.next(null);
        this.router.navigate(['/login']);
    }
}