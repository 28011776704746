import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TI_MP } from 'src/app/api/user';
import { PlanPlataforma } from 'src/app/components/admin/multipago/modules/multipago.model';
import { environment } from 'src/environments/environment';

declare var MercadoPago: any;    

@Component({
  selector: 'app-popup-mercadopago-tarjeta',

  templateUrl: './popup-mercadopago-tarjeta.component.html',
  styleUrls: ['./popup-mercadopago-tarjeta.component.css']
})
export class PopupMercadopagoTarjetaComponent implements OnInit {

  mpPublickToken = environment.mercadopago_token;

  planPlataforma: PlanPlataforma;

  cardFormdata: any;

  constructor(
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<PopupMercadopagoTarjetaComponent>,
  ) { 
    if (data['planPlataforma'])
      this.planPlataforma = data.planPlataforma;
  }

  ngOnInit(): void {
    let script = this._renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://sdk.mercadopago.com/js/v2';
    script.onload = () => {
      console.log("MercadoPago");  
      console.log(MercadoPago);
      this.doLoadMercadoPago();
    };
    this._renderer2.appendChild(this._document.body, script);
  }

  ngAfterViewInit(): void {
  }

  doLoadMercadoPago(): void {
    let usuario: any = JSON.parse(localStorage.getItem('user'));
    const mp = new MercadoPago(this.mpPublickToken, {
      locale: 'es-AR'
    });
    const bricksBuilder = mp.bricks();
        const renderCardPaymentBrick = async (bricksBuilder) => {
          const settings = {
            initialization: {
              amount: this.planPlataforma.monto, // monto a ser pago
              payer: {
                email: `${usuario.mail}`,
                identification: {
                  type: `${TI_MP[usuario.datosProfesionales[0].tipoDniId]}`,
                  number: `${usuario.datosProfesionales[0].numeroDni}`
                }
              }
            },
            customization: {
              visual: {
                style: {
                  theme: 'default', // | 'dark' | 'bootstrap' | 'flat'
                  texts: {
                    cardholderName: {
                      placeholder: "_"
                    }
                  }
                }
              },
              paymentMethods: {
                minInstallments: 1,
                maxInstallments: 1
              },
            },
            callbacks: {
              onReady: () => {
                // callback llamado cuando Brick esté listo
              },
              onSubmit: (cardFormData) => {
                //  callback llamado cuando el usuario haga clic en el botón enviar los datos
                //  ejemplo de envío de los datos recolectados por el Brick a su servidor
                console.log(cardFormData);
                this.cardFormdata = cardFormData;
                this.dialogRef.close(cardFormData);
                return;
              },
              onError: (error) => {
                // callback llamado para todos los casos de error de Brick
              },
            },
          };
          await bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings);
        };
        renderCardPaymentBrick(bricksBuilder);
  }

  doLoadMercadoPagoAPI(): void {
    let usuario: any = JSON.parse(localStorage.getItem('user'));
    const mp = new MercadoPago(this.mpPublickToken);

    const cardForm = mp.cardForm({
      amount: `${this.planPlataforma.monto}`,
      iframe: true,
      form: {
        id: "form-checkout",
        cardNumber: {
          id: "form-checkout__cardNumber",
          placeholder: "Numero de tarjeta",
        },
        expirationDate: {
          id: "form-checkout__expirationDate",
          placeholder: "MM/YY",
        },
        securityCode: {
          id: "form-checkout__securityCode",
          placeholder: "Código de seguridad",
        },
        cardholderName: {
          id: "form-checkout__cardholderName",
          placeholder: "Titular de la tarjeta",
        },
        issuer: {
          id: "form-checkout__issuer",
          placeholder: "Banco emisor",
        },
        installments: {
          id: "form-checkout__installments",
          placeholder: "Cuotas",
        },        
        identificationType: {
          id: "form-checkout__identificationType",
          placeholder: "Tipo de documento",
        },
        identificationNumber: {
          id: "form-checkout__identificationNumber",
          placeholder: "Número del documento",
        },
        cardholderEmail: {
          id: "form-checkout__cardholderEmail",
          placeholder: "E-mail",
        },
      },
      callbacks: {
        onFormMounted: error => {
          if (error) return console.warn("Form Mounted handling error: ", error);
          console.log("Form mounted");
        },
        onSubmit: event => {
          event.preventDefault();

          const {
            paymentMethodId: payment_method_id,
            issuerId: issuer_id,
            cardholderEmail: email,
            amount,
            token,
            installments,
            identificationNumber,
            identificationType,
          } = cardForm.getCardFormData();

          fetch("/process_payment", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token,
              issuer_id,
              payment_method_id,
              transaction_amount: Number(amount),
              installments: Number(installments),
              description: "Descripción del producto",
              payer: {
                email,
                identification: {
                  type: identificationType,
                  number: identificationNumber,
                },
              },
            }),
          });
        },
        onFetching: (resource) => {
          console.log("Fetching resource: ", resource);

          // Animate progress bar
          const progressBar = document.querySelector(".progress-bar");
          progressBar.removeAttribute("value");

          return () => {
            progressBar.setAttribute("value", "0");
          };
        }
      },
    });
  }
}
