import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnterClickService {

  constructor() { }

  public isClick($event) {
    let x = $event.offsetX;
    let y = $event.offsetY;
    let result: boolean = false;
    if(x >0 && y > 0) {
      result = true;
    }
    return result;
  }
}
