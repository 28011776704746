// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://login.legalify-app.com/service/legalify',
  angularUrl: 'https://login.legalify-app.com',
  //measurement_id:'G-S8ZS180DR0',
  measurement_id:'G-S8ZS180DR0',
  //apiUrl: 'https://login.legalify-app.com/service/legalify',
  //angularUrl: 'https://login.legalify-app.com',
  //apiUrl: 'https://www.legalify.app/service/legalify',
  //angularUrl: 'https://www.legalify.app',
  mercadopago_token: 'APP_USR-1da78844-1736-4f2d-8db4-b46a0281f72f',
  paypal_clientid: 'ARi0qYGlkaxHXWFAIrfjioDpbSEbF3oYQmOA_xDuwpRSXECuRRiRVTOTpSeGzcwfz5nqEjoeOhS-r5Bl',
  paypal_partner_url: 'https://www.sandbox.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js',

  webPushPublicKey: 'BENNN_N8nGxPkOno0xb41RGfD5NZeNJF46vnuK1MNbZ4WvMMdk9XCJLaEmK7jOcBtLQuoEh6qEirO8nAlgNJBAQ'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

