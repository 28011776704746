import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OportunidadesComponent } from '../casos/oportunidades/oportunidades.component';
import { PreviaComponent } from '../seleccion/previa/previa.component';
import { DatosComponent } from '../seleccion/datos/datos.component';
import { AuthGuard } from '../../../auth.guard';
import { Role } from '../../../enums/role';
import { MensajeEsperaComponent } from '../agregar/mensaje-espera/mensaje-espera.component';
import { AbogadoComponent } from '../abogado.component';
import { VinculacionesComponent } from '../vinculaciones/vinculaciones.component';


const routes: Routes = [
  {
      path: 'oportunidades',
      component: OportunidadesComponent,
      canActivate: [AuthGuard],
      data: { roles: [Role.Abogado] }
    },
    {
      path: 'seleccion/previa',
      component: PreviaComponent,
      canActivate: [AuthGuard],
      data: { roles: [Role.Cliente] }
    },
    {
      path: 'seleccion/datos',
      component: DatosComponent,
      canActivate: [AuthGuard],
      data: { roles: [Role.Cliente] }
    },
    {
      path: 'llamada',
      component: DatosComponent,
      canActivate: [AuthGuard],
      data: { roles: [Role.Cliente] }
    },
    {
      path: 'agregar-jurisdiccion',
      component: MensajeEsperaComponent,
    }, {
      path: 'abogado',
      component: AbogadoComponent,
      children: [{
        path: 'vinculaciones',
        component: VinculacionesComponent,
        canActivate: [AuthGuard],
        data: { roles: [Role.Abogado] },
      }]
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AbogadoRoutingModule { }