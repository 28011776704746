import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor() { }

  fromFileToBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  fromBase64ToFile(file: string, fileName: string) {
    const type = this.getTypeFromBase64(file);
    const toReplace = `data:${type};base64,`;
    const test = file.replace(toReplace, '');
    const byteString = atob(test);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: type });
    const imageFile = new File([blob], fileName, { type: type });
    return imageFile;
  }

  getTypeFromBase64(file: string) {
    return file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
  }

}
