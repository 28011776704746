import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../../api/api.service';
import { environment } from '../../../../../../environments/environment';
import { StorageService } from '../../../../../utils/storage/storage.service';
import { Planes } from '../../../../../api/planes';
import { FormatDateService } from 'src/app/utils/formatDate/formatDate.service.';
import { EspecialidadDelCasoService } from '../../../../../services/especialidad/especialidad-del-caso.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MultipagoService } from 'src/app/components/admin/multipago/modules/multipago.service';
import { MPVinculacion, PlanDefaultAmount, PlanPlataforma } from 'src/app/components/admin/multipago/modules/multipago.model';
import { PlanMultipagoService } from 'src/app/services/plan/plan-multipago.service';
import { ThisReceiver } from '@angular/compiler';
import { forkJoin, Observable, PartialObserver } from 'rxjs';

enum PlanType {
  Mail = 'Mail',
  Schedule = 'Agenda',
  CallExpress = 'Llamada Express',
}

class CostPlan {
  Mail: number;
  Schedule: number;
  CallExpress: number;
  ScheduleMoneda: string;
  CallExpressMoneda: string;
}

@Component({
  selector: 'app-resumen',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.css'],
})
export class ResumenComponent implements OnInit {
  abogado: any;
  nombre: string;
  apellido: string;
  nombreEspecialidad: string;
  tieneFoto = false;
  avatarUrl = '';
  seleccion = {
    fecha: '',
    hora: '',
    abogado: '',
    titulo: '',
    caso: '',
  };

  caso: any;
  importe = '';
  importeMoneda = '';
  mostrarFecha = false;

  id_caso: number;
  invitadoId: number;
  fecha: any;
  fechaCompletaString: string;
  fechaCompletaDate: Date;
  hora: any;

  contratarAbogado = {
    abogadoId: 0,
    mpCallbackFailure: environment.angularUrl + '/pago/fallo',
    mpCallbackPending: environment.angularUrl + '/pago/pendiente',
    mpCallbackSuccess: environment.angularUrl + '/llamada',
    planId: 0,
  };

  costPlan: CostPlan;

  formatCulture: string = 'full';
  culture: string = 'es-AR';
  formatDateBackend: string = 'yyyy-MM-ddTHH:mm:ss';

  especialidadParaMostrar = '';
  mostrar = false;

  cacheAgendaPlataformas: PlanPlataforma[] = [];
  cacheLlamadaPlataformas: PlanPlataforma[] = [];

  constructor(
    private formatDateService: FormatDateService,
    private storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private serviceMultipago: MultipagoService, 
    private servicePlanMultipago: PlanMultipagoService, 
    private _location: Location,
    private especialidadService: EspecialidadDelCasoService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _snackBar: MatSnackBar,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
    this.matIconRegistry.addSvgIcon(
      `perfil`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/perfil.svg'
      )
    );
    if (this.router.getCurrentNavigation().extras.state) {
      this.caso = this.router.getCurrentNavigation().extras.state.caso;
      this.seleccion.fecha =
        this.router.getCurrentNavigation().extras.state.fecha;
      this.seleccion.hora =
        this.router.getCurrentNavigation().extras.state.horarioSeleccionado;
      this.abogado = this.router.getCurrentNavigation().extras.state.abogado;
      this.seleccion.abogado = this.abogado;
      this.nombre =
        this.router.getCurrentNavigation().extras.state.abogado.nombre;
      this.apellido =
        this.router.getCurrentNavigation().extras.state.abogado.apellido;
      this.nombreEspecialidad =
        this.router.getCurrentNavigation().extras.state.abogado.datosProfesionales[0].especialidadesAbogados[0].nombre;
      this.seleccion.titulo =
        this.router.getCurrentNavigation().extras.state.titulo;

      if (
        this.caso &&
        this.caso.especialidad &&
        this.caso.especialidad.nombre
      ) {
        this.especialidadParaMostrar = this.especialidadService.match(
          this.caso.especialidad.nombre,
          this.abogado
        );
        if (this.especialidadParaMostrar.length > 2) {
          this.mostrar = true;
        }
      }
    }
  }

  ngOnInit(): void {
    let planId: number;
    var plans: Planes[];
    let observable: Observable<any>[] = [];
    this.costPlan = new CostPlan();

    observable.push(this.apiService.getPlanes());
    observable.push(this.servicePlanMultipago.getPlanDefaultAmounts(0, this.caso.jurisdiccion.id_jurisdiccion, [2, 3]));

    forkJoin(observable).subscribe({
      next: (result: any[]) => {
        plans = result[0];
        this.setCost(plans);

        this.setDefaultCost(result[1]);

        if (this.seleccion.titulo === 'Agendar cita') {
          let aux = JSON.parse(localStorage.getItem('respuestaConvertida'));
          this.fechaCompletaDate = new Date(aux);
          this.fechaCompletaString =
            this.formatDateService.convertDateToFormatSpecifc(
              this.formatCulture,
              this.fechaCompletaDate,
              this.culture
            );
          this.hora = this.formatDateService.convertDateToFormatSpecifc(
            'HH:mm',
            this.fechaCompletaDate,
            this.culture
          );
          this.fecha =
            this.fechaCompletaDate.getDate() +
            '/' +
            (this.fechaCompletaDate.getMonth() + 1);
          this.importe = this.costPlan.Schedule.toString();
          this.importeMoneda = this.costPlan.ScheduleMoneda;
          this.mostrarFecha = true;
          planId = 2;
        } else {
          planId = 3;
          this.importe = this.costPlan.CallExpress.toString();
          this.importeMoneda = this.costPlan.CallExpressMoneda;
        }
        this.cacheAgendaPlataformas = [];
        this.cacheLlamadaPlataformas = [];

        let obsPlataformas = this.serviceMultipago.getPlanPlataformasEnabled(planId);
        let obsVinculaciones = this.servicePlanMultipago.getAbogadoVinculacionesById(this.seleccion.abogado["id_usuario"]*1);

        forkJoin([obsPlataformas, obsVinculaciones]).subscribe({
          next: (observer: any[]) => {
            let result: PlanPlataforma[] = [];
            let planPlataformas: PlanPlataforma[] = observer[0];
            let vinculaciones: MPVinculacion[] = observer[1];
            let paisId: number = this.apiService.getSessionCountryIdByLocalStorage();

            planPlataformas.forEach((planPlataforma: PlanPlataforma) => {
              if (planPlataforma.countryId != paisId)
                return;
              vinculaciones.some((vinculacion: MPVinculacion) => {
                if ((!vinculacion.needLinked || vinculacion.linked) && vinculacion.plataformaId == planPlataforma.plataformaId) {
                  result.push(planPlataforma);
                  return true;
                }
              });
            });

            this.cacheAgendaPlataformas = result;
          }
        });
      },
      error: (error) => {
        console.error(error);
      }
    })

    this.getMiPerfil();
    if (
      this.abogado.avatarUrl != null &&
      !this.abogado.avatarUrl.includes(null)
    ) {
      this.tieneFoto = true;

      this.avatarUrl = this.abogado.avatarUrl;
    } else {
      this.tieneFoto = false;
    }
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  setDefaultCost(planAmounts: PlanDefaultAmount[]): void {
    planAmounts.forEach((planAmount: PlanDefaultAmount) => {
      switch (planAmount.planId) {
        case 2:
          this.costPlan.Schedule = planAmount.monto;
          this.costPlan.ScheduleMoneda = planAmount.monedaId;
          break;
        case 3:
          this.costPlan.CallExpress = planAmount.monto;
          this.costPlan.CallExpressMoneda = planAmount.monedaId;
          break;
      }
    });
  }

  setCost(plans: Planes[]) {
    this.costPlan.Mail = plans.find((x) => x.nombre == PlanType.Mail).costo;
    this.costPlan.Schedule = plans.find(
      (x) => x.nombre == PlanType.Schedule
    ).costo;
    this.costPlan.CallExpress = plans.find(
      (x) => x.nombre == PlanType.CallExpress
    ).costo;
  }

  completarDatos() {
    if (!this.abogado) {
      this.abogado = JSON.parse(localStorage.getItem('abogado'));
    }
    if (!this.caso) {
      this.caso = JSON.parse(localStorage.getItem('caso'));
    }
    this.caso.archivosSeleccionados = JSON.parse(
      localStorage.getItem('archivos')
    );
    if (this.abogado) {
      this.caso.profesionalAsignadoId = this.abogado.id_usuario;
    }
  }

  getMiPerfil() {
    this.apiService.getPerfil().subscribe(
      (perfil) => {
        this.invitadoId = perfil.id_usuario;
        this.completarDatos();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  agendarCita() {
    let datos = {
      fecha: this.formatDateService.convertDateToFormatSpecifc(
        this.formatDateBackend,
        this.fechaCompletaDate,
        this.culture
      ),
      invitadoId: this.invitadoId,
      usuarioId: this.caso.profesionalAsignadoId,
    };
    this.apiService.agendarCita(datos).subscribe(
      (respuesta) => {},
      (error) => {
        console.error(error);
      }
    );
  }

  aceptarAbogado() {
    this.apiService
      .aceptarAbogado(this.abogado.id_usuario, this.id_caso)
      .subscribe(
        (respuesta) => {},
        (error) => {
          console.error(error);
        }
      );
  }

  navegar(destino) {
    this.seleccion.caso = this.caso;
    this.abogado = history.state.abogado;
    this.seleccion.abogado = this.abogado;
    switch (destino) {
      case 1:
        window.history.back();
        break;
      case 2:
        // deprecado
        let abogadoId = this.abogado.id_abogado;
        this.contratarAbogado.abogadoId = abogadoId;
        this.contratarAbogado.abogadoId = this.abogado.id_usuario;
        this.contratarAbogado.mpCallbackFailure =
          environment.angularUrl + '/pago/fallo';
        this.contratarAbogado.mpCallbackPending =
          environment.angularUrl + '/pago/pendiente';
        this.contratarAbogado.mpCallbackSuccess =
          environment.angularUrl + '/llamada';
        if (this.mostrarFecha == true) {
          this.contratarAbogado.mpCallbackSuccess =
            environment.angularUrl + '/agendada';
          this.contratarAbogado.planId = 2;
        } else {
          this.contratarAbogado.mpCallbackSuccess =
            environment.angularUrl + '/llamada';
          this.contratarAbogado.planId = 3;
        }

        //COMENTAR ESTA LLAMADA PARA HACER EL BYPASS A MERCADO PAGO

        this.apiService.postPago(this.contratarAbogado).subscribe(
          (respuesta) => {
            localStorage.setItem('abogado', JSON.stringify(this.abogado));
            localStorage.setItem(
              'archivos',
              JSON.stringify(this.caso.archivosSeleccionados)
            );
            localStorage.setItem('caso', JSON.stringify(this.caso));
            window.location.href = respuesta.url;
          },
          (error) => {
            console.error(error);
            this.openSnackBar(
              'Falló la redirección a Mercado Pago. Por favor intente nuevamente más tarde.'
            );
          }
        );

        //DESCOMENTAR LO DE ABAJO PARA HACER EL BYPASS A MERCADO PAGO

        // localStorage.setItem('abogado', JSON.stringify(this.abogado));
        // if(this.mostrarFecha == true) {
        //   this.router.navigateByUrl("/agendada", { state: this.seleccion});
        // } else {
        //   this.router.navigateByUrl("/llamada", { state: this.seleccion});
        // }

        break;
      default:
        break;
    }
  }

  doMultipago(planPlataforma: PlanPlataforma): void {
    let abogadoId = this.abogado.id_abogado;
    this.contratarAbogado.abogadoId = abogadoId;
    this.contratarAbogado.abogadoId = this.abogado.id_usuario;
    this.contratarAbogado.mpCallbackFailure =
      environment.angularUrl + '/pago/fallo';
    this.contratarAbogado.mpCallbackPending =
      environment.angularUrl + '/pago/pendiente';
    this.contratarAbogado.mpCallbackSuccess =
      environment.angularUrl + '/llamada';
    if (this.mostrarFecha == true) {
      this.contratarAbogado.mpCallbackSuccess =
        environment.angularUrl + '/agendada';
      this.contratarAbogado.planId = 2;
    } else {
      this.contratarAbogado.mpCallbackSuccess =
        environment.angularUrl + '/llamada';
      this.contratarAbogado.planId = 3;
    }
    let caso = JSON.parse(localStorage.getItem('caso-edit'));
    if(caso!=null && caso!=undefined){
      localStorage.setItem('abogado', JSON.stringify(this.abogado));
      localStorage.setItem(
        'archivos',
        JSON.stringify(this.caso.archivosSeleccionados)
      );
      localStorage.setItem('caso', JSON.stringify(this.caso));
      this.router.navigateByUrl("/agendadamod", { state: this.seleccion});
    }
    else{
      this.servicePlanMultipago.doClientePostPago(planPlataforma, this.contratarAbogado).subscribe(
        (respuesta) => {
          let url: URLSearchParams = new URLSearchParams(respuesta.url);
          let externalReference: string = url.get('external_reference');
  
          localStorage.setItem('externalReference', externalReference);
          localStorage.setItem('abogado', JSON.stringify(this.abogado));
          localStorage.setItem(
            'archivos',
            JSON.stringify(this.caso.archivosSeleccionados)
          );
          localStorage.setItem('caso', JSON.stringify(this.caso));
          window.location.href = respuesta.url;
        },
        (error) => {
          console.error(error);
          this.openSnackBar(
            'Falló la redirección a Mercado Pago. Por favor intente nuevamente más tarde.'
          );
        }
      );
    }
    //COMENTAR ESTA LLAMADA PARA HACER EL BYPASS A MERCADO PAGO
    
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 5000 });
  }
}
