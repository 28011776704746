<div class="contenidoModal">
  <div class="separadorCentrado">
    <h1 class="titulo600">{{ data.titulo }}</h1>
    <mat-card-subtitle>{{ data.texto }}</mat-card-subtitle>
    <button
      *ngIf="data.mostrarCancelar"
      class="boton-secundario"
      mat-button
      color="primary"
      [mat-dialog-close]="true"
      (click)="cancelar()"
    >
      {{ data.textoBotonConfirmar }}
    </button>
    <button
      class="boton-primario"
      mat-dialog-close
      mat-raised-button
      color="primary"
    >
      {{ data.textoBotonCerrar }}
    </button>
  </div>
</div>
