import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SenderService {
  public nippy: boolean;

  constructor() {}
}
