export class MatriculaConfig {

    constructor(){

    }
    id_matriculaconfig: number;
    descripcion: string;
    nombre:string;
    countryId:number;
    tamanio:number;
    visible:boolean;
    solicitarCampo:boolean;
    admEmailCaso: string;
  }
  
  
  