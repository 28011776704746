<div class="dialog-content">
    <div class="dialog-header">
        <h2>{{title}}</h2>
        
    </div>
    <div class="dialog-body">
        {{message}}
    </div>
    <div class="dialog-footer">
        <button mat-flat-button type="button" (click)="dialogRef.close()">
            Cancelar
        </button>
        <button mat-flat-button color="primary" type="button" style="background-color: #005185;" 
            (click)="dialogRef.close(true)"
        >
            {{ "dialog.acept.button" | translate }}
        </button>
    </div>
</div>
