<mat-card-subtitle class="detallePlanTitulo" [ngStyle]="{'color': (plan && plan.status == 'canceled') ? 'red' : '#005185' }">{{
   planNombre
}}</mat-card-subtitle>
<div class="lineaImporte" *ngIf="plan">
  <mat-card-title *ngIf="!anual" class="detallePlan"
    >{{plan.moneda ? plan.moneda : '$'}} {{ planPrecio }}
  </mat-card-title>
  <mat-card-subtitle *ngIf="!anual">
    / {{ "planDescripcion.mes" | translate }}</mat-card-subtitle
  >
  <mat-card-title *ngIf="anual" class="detallePlan"
    >{{plan.monedaAnual ? plan.monedaAnual : '$'}} {{ planPrecio }}
  </mat-card-title>
  <mat-card-subtitle *ngIf="anual">
    / {{ "planDescripcion.año" | translate }}</mat-card-subtitle
  >
</div>
<mat-card-subtitle class="detallePlan" *ngIf="planContratado && (planContratado.status == 'trial')">{{
  "planDescripcion.prueba" | translate
}}</mat-card-subtitle>
<hr color="#D8D8D8" />
<mat-card-subtitle class="incluye">{{
  "planDescripcion.incluye" | translate
}}</mat-card-subtitle>
<div class="detallesPlan">
  <mat-card-subtitle class="detallePlanTexto">
    <mat-icon class="verde">done</mat-icon>
    {{ "planDescripcion.match" | translate }}
    <button class="botonAyudaPlan" (click)="ayuda(1)" mat-icon-button>
      <mat-icon *ngIf="mostrarAyuda" class="ayudaAzul">help</mat-icon>
    </button>
  </mat-card-subtitle>
  <mat-card-subtitle class="detallePlanTexto">
    <mat-icon class="verde">done</mat-icon>
    <span *ngIf="plan.especialidades>0 && plan.especialidades<9998">
      {{ plan.especialidades }} &nbsp;{{ "plan.especialidades" | translate }}
    </span>
    <span *ngIf="plan.especialidades>9998">
      {{ "plan.especialidadesilim" | translate }}
    </span>
  
  
  </mat-card-subtitle>
  <mat-card-subtitle class="detallePlanTexto">
    <mat-icon class="verde">done</mat-icon>
    <span *ngIf="plan.jurisdicciones>0 && plan.jurisdicciones<9998">
      {{ plan.jurisdicciones }} &nbsp;{{ "plan.jurisdicciones" | translate }}
    </span>
    <span *ngIf="plan.jurisdicciones>9998">
      {{ "plan.jurisdiccionessilim" | translate }}
    </span>
  </mat-card-subtitle>
  <mat-card-subtitle *ngIf="plan.agenda" class="detallePlanTexto">
    <mat-icon class="verde">done</mat-icon>
    {{ "planDescripcion.agenda" | translate }}
    <button class="botonAyudaPlan" (click)="ayuda(2)" mat-icon-button>
      <mat-icon *ngIf="mostrarAyuda" class="ayudaAzul">help</mat-icon>
    </button>
  </mat-card-subtitle>
  <mat-card-subtitle *ngIf="plan.llamada" class="detallePlanTexto">
    <mat-icon class="verde">done</mat-icon>
    {{ "planDescripcion.llamada" | translate }}
    <button class="botonAyudaPlan" (click)="ayuda(3)" mat-icon-button>
      <mat-icon *ngIf="mostrarAyuda" class="ayudaAzul">help</mat-icon>
    </button>
  </mat-card-subtitle>
</div>
