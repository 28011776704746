 <body>
  <div class="contenedor-cards-mensaje">
  <mat-card class="mensaje">
    <img class="logo" src="../../../../../assets/logo.jpg">
    <div class="registrarse">
      <img class="logo" src="../../../../../assets/confirmacion.jpg">
      <div class="separadorCentrado">
        <h1>Tu cuenta se creó con éxito</h1>
        <mat-card-subtitle color="primary">Te enviamos un mail a tu correo para poder validar tu identidad. Si no lo encuentras en tu bandeja principal, revisa en spam.</mat-card-subtitle><br />
      </div>
    </div>
    <footer>
      <button class="boton-footer" mat-button color="primary" [routerLink]="['']">VOLVER A LA PÁGINA PRINCIPAL</button>
    </footer>
    <router-outlet #outlet="outlet"></router-outlet>
  </mat-card>
  </div>
</body> 