<div class="casoFondo">
  <form [formGroup]="casoForm">
    <mat-toolbar class="header">
      <button mat-button (click)="atras()">
        <mat-icon class="icono">chevron_left</mat-icon>
      </button>
      <span class="example-spacer"></span>
      <span>{{ "problematica.crear" | translate }}</span>
    </mat-toolbar>
    <div class="barraDeProgreso">
      <mat-toolbar class="barraDeProgreso">
        <span class="example-spacer"></span>
        <span>{{ "problematica.problematica" | translate }}</span>
      </mat-toolbar>
      <mat-progress-bar mode="determinate" value="60"></mat-progress-bar>
    </div>

    <div class="casoCuerpo">
      <div *ngIf="masDeUno">
        <div class="mobile">
          <mat-grid-list cols="2" rowHeight="2:1" [gutterSize]="'30px'">
            <mat-grid-tile class="especialidadSeleccionada">
              <div class="textoEnGridSeleccionada">
                {{ caso.especialidad.nombre }}
              </div>
              <mat-icon
                svgIcon="{{ caso.especialidad.nombre }}"
                class="icono-derecha-abajo-blanco"
              ></mat-icon>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
        <div class="desktop">
          <mat-grid-list cols="5" rowHeight="2:1" [gutterSize]="'30px'">
            <mat-grid-tile class="especialidadSeleccionada">
              <div class="textoEnGridSeleccionada">
                {{ caso.especialidad.nombre }}
              </div>
              <mat-icon
                svgIcon="{{ caso.especialidad.nombre }}"
                class="icono-derecha-abajo-blanco"
              ></mat-icon>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
        <div class="separador30"></div>
        <div class="mobile">
          <mat-grid-list
            cols="1"
            rowHeight="4:1"
            [gutterSize]="'30px'"
            (touchmove)="disableScroll()"
          >
            <mat-grid-tile
              *ngFor="let categoria of categorias"
              [class]="class"
              [ngClass]="{ selected: categoria.selected }"
              [value]="categoria"
              (click)="guardar(categoria)"
              (mousedown)="down(categoria)"
            >
              <div class="textoEnGrid">{{ categoria.titulo }}</div>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
        <div class="desktop">
          <mat-grid-list cols="5" rowHeight="2:1" [gutterSize]="'30px'">
            <mat-grid-tile
              tabindex="0"
              *ngFor="let categoria of categorias"
              class="casoImpar"
              [value]="categoria"
              (click)="guardar(categoria)"
            >
              <div class="textoEnGrid">{{ categoria.titulo }}</div>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </div>

      <div *ngIf="!masDeUno">
        <mat-card class="especialidadSeleccionadaUnica">
          <mat-card-title class="textoEnGridSeleccionadaUnica">{{
            caso.especialidad.nombre
          }}</mat-card-title>
          <mat-icon
            svgIcon="{{ caso.especialidad.nombre }}"
            class="icono-derecha-abajo-blanco"
          ></mat-icon>
        </mat-card>

        <div class="separador30"></div>
        <div *ngFor="let categoria of categorias">
          <mat-card
            [value]="categoria"
            (click)="guardar(categoria)"
            [class]="categoriaUnico"
          >
            <mat-card-title [class]="textoEnGridUnica">{{
              categoria.titulo
            }}</mat-card-title>
          </mat-card>
        </div>
      </div>
      <button
        *ngIf="!permitirContinuar"
        class="boton-continuar"
        [disabled]="!permitirContinuar"
        mat-stroked-button
        color="primary"
        (click)="onSubmit()"
      >
        {{ "problematica.continuar" | translate }}
      </button>
      <button
        *ngIf="permitirContinuar"
        class="boton-primario"
        [disabled]="!permitirContinuar"
        mat-raised-button
        color="primary"
        (click)="onSubmit()"
      >
        {{ "problematica.continuar" | translate }}
      </button>
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </form>
</div>
