<mat-card class="cardPerfil">
  <h2>{{ "opinion.puntuacion" | translate }}</h2>
  <div class="puntuacionAbogado">
    <mat-card-title class="puntuacion">{{
      puntuacionPropia.puntuacion
    }}</mat-card-title>
    <div class="estrellasCantidadOpiniones">
      <mat-icon class="estrellas">{{ puntuacionPropia.estrellas }}</mat-icon>
      <mat-card-subtitle
        >{{ cantOpiniones }}
        {{ "opinion.opiniones" | translate }}</mat-card-subtitle
      >
    </div>
  </div>
  <h2>{{ "opinion.opiClientes" | translate }}</h2>
  <div *ngFor="let opinion of opiniones">
    <div class="comentario">
      <img
        mat-card-image
        class="tieneFotoUsuario"
        *ngIf="!opinion.tieneFoto"
        src="../../../../assets/perfil.svg"
      />
      <img
        mat-card-image
        class="tieneFotoUsuario"
        *ngIf="opinion.tieneFoto"
        [src]="opinion.avatarUrl | secure | async"
      />
      <div class="comentarioCuerpo">
        <div class="tituloPuntuacion">
          <mat-card-title
            >{{ opinion.usuarioCreador.nombre }}
            {{ opinion.usuarioCreador.apellido }}
          </mat-card-title>
          <mat-card-title class="estrellas">
            {{ opinion.estrellas }}
          </mat-card-title>
        </div>
        <mat-card-subtitle>{{ opinion.comentario }}</mat-card-subtitle>
      </div>
    </div>
  </div>
</mat-card>
