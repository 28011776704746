import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../../../modules/material.module';
import { LoginRoutingModule } from '../../login/login-routing.module';
import { AbogadoRegistroModule } from '../registroYMensajesAsociados/modules/abogadoRegistro.modules';
import { AbogadoRoutingModule } from '../modules/abogado-routing.module';
//import { OportunidadesComponent } from '../casos/oportunidades/oportunidades.component';
import { DialogComponent } from '../plan/modal/dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { PopupMercadopagoTarjetaComponent } from '../plan/popup-mercadopago-tarjeta/popup-mercadopago-tarjeta.component';
import { CommonsModule } from '../../commons/modules/commons.module';
import { PopupMercadopagoOpcionesComponent } from '../plan/popup-mercadopago-opciones/popup-mercadopago-opciones.component';
@NgModule({
  declarations: [
    DialogComponent,
    PopupMercadopagoTarjetaComponent,
    PopupMercadopagoOpcionesComponent,
    // OportunidadesComponent
  ],
  imports: [
    BrowserModule,
    LoginRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    AbogadoRegistroModule,
    AbogadoRoutingModule,
    TranslateModule,
    CommonsModule
  ],
  providers: [],
  bootstrap: []
})
export class AbogadoModule { }
