import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pago-fallo',
  templateUrl: './pago-fallo.component.html',
  styleUrls: ['./pago-fallo.component.css'],
})
export class PagoFalloComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }

  ngOnInit(): void {}

  navegar() {
    let soyCliente: boolean;
    this.apiService.getPerfil().subscribe(
      (perfil) => {
        if (perfil.datosProfesionales.length > 0) {
          soyCliente = false;
        } else {
          soyCliente = true;
        }
        if (soyCliente) {
          this.router.navigate(['/casos-activos/3']);
        } else {
          this.router.navigate(['/perfil-abogado']);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }
}
