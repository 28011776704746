import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { Planes } from '../../../api/planes';
import { Perfil } from '../../../api/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'dialog-content-example-dialog.html',
  styleUrls: ['./dialog.component.css'],
})
export class DialogComponent {
  constructor(public dialog: MatDialog, public translate: TranslateService) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }
}
