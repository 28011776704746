<div class="contenidoModal">
  <form class="separadorCentrado" [formGroup]="facturacionForm">
    <h1 class="titulo600">
      Por favor, completa los siguientes datos para continuar
    </h1>
    <!-- <p class="subtitle">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, placeat.</p> -->
    <mat-form-field appearance="outline" class="inputs-registro facturacionInp">
      <mat-label>{{ paisConfigCuit.sdesc }}</mat-label>
      <input
        required
        matInput
        type="text"
        [(ngModel)]="cuit"
        placeholder="{{ paisConfigCuit.sdesc }}"
        formControlName="cuit"
        (input)="doCuitFix($event)"
      />
    </mat-form-field>
    <br />

    <mat-form-field appearance="outline" class="inputs-registro facturacionInp">
      <mat-label>Domicilio de facturación de su medio de pago</mat-label>
      <input
        required
        matInput
        type="text"
        [(ngModel)]="direccion"
        placeholder="Dirección de facturación"
        formControlName="direccion"
      />
    </mat-form-field>
    <br />

    <mat-form-field appearance="outline" class="inputs-registro facturacionInp">
      <mat-label>Código postal</mat-label>
      <input
        type="number"
        maxlength="8"
        min="0"
        required
        matInput
        type="number"
        [(ngModel)]="codigopostal"
        placeholder="Código postal"
        formControlName="codigoPostal"
      />
    </mat-form-field>

    <br />

    <button
      [disabled]="!facturacionForm.valid"
      [mat-dialog-close]="true"
      class="boton-primario"
      mat-raised-button
      color="primary"
      (click)="onSubmit()"
    >
      CONTINUAR
    </button>

    <!-- <p class="textoBajoBoton">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorem, fuga.
      </p> -->
  </form>
</div>
