import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContratarPlanYAbogado, Link } from 'src/app/api/mercado';
import { DatosFacturacion, Planes } from 'src/app/api/planes';
import { MPVinculacion, PlanDefaultAmount, PlanPlataforma, Suscripcion } from 'src/app/components/admin/multipago/modules/multipago.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlanMultipagoService {

  private url = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private http: HttpClient
  ) { }

  doAbogadoFacturaionUpdate(datos: DatosFacturacion): Observable<void>{
    return this.http.patch<any>(
      `${this.url}/api/usuario/edit/facturacion`,
      datos,
      this.httpOptions
    )
  }

  getPlanMonedaPlanesDips(): Observable<Planes[]> {
    return this.http.get<Planes[]>(
      `${this.url}/api/multipago/plan/disponibles`,
      this.httpOptions
    );
  }

  doAbogadoSuscribir(planPlataforma: PlanPlataforma): Observable<string[]> {
    let url = `${this.url}/api/multipago/abogado/suscripcion`;
    return this.http.post<string[]>(url, planPlataforma, this.httpOptions);
  }

  getSubscripcionLast(): Observable<Suscripcion> {
    return this.http.get<Suscripcion>(
      `${this.url}/api/multipago/abogado/suscripcion`,
      this.httpOptions
    );
  }

  doAbogadoSuscripcionCancelar(suscripcion: Suscripcion): Observable<void> {
    let url = `${this.url}/api/multipago/abogado/suscripcion/cancelar`;
    return this.http.post<void>(url, suscripcion, this.httpOptions);
  }

  isSuscriptionActive(suscripcion: Suscripcion): boolean {
    let result: boolean = suscripcion && suscripcion.estado == "active";
    return result;
  }

  isPlanCorpOrPrem(suscripcion: Suscripcion): boolean {
    let result: boolean;

    result = this.isSuscriptionActive(suscripcion);
    if (result) {
      result = suscripcion.planNombre.toLocaleUpperCase().indexOf("PREMIUM") >= 0 || suscripcion.planNombre.toLocaleUpperCase().indexOf("CORPORATIVO") >= 0;
    }
    return result;
  }

  getAbogadoVinculaciones(): Observable<MPVinculacion[]> {
    let url = `${this.url}/api/multipago/abogado/vinculaciones`;
    return this.http.get<MPVinculacion[]>(url, this.httpOptions);
  }

  getAbogadoVinculacionLink(plataformaId: number): Observable<any> {
    let url = `${this.url}/api/multipago/abogado/vinculacion/${plataformaId}/init`;
    return this.http.get<any>(url, this.httpOptions);
  }

  doAbogadoVinculacionPaypal(plataformaId: number, authCode: string, sharedId: string): Observable<void> {
    let url = `${this.url}/api/multipago/abogado/vinculacion/${plataformaId}`;
    let data = { authCode, sharedId };
    
    return this.http.post<void>(url, data, this.httpOptions);
  }

  getAbogadoVinculacionesById(abogadoId: number): Observable<MPVinculacion[]> {
    let url = `${this.url}/api/multipago/cliente/vinculaciones/${abogadoId}`;
    return this.http.get<MPVinculacion[]>(url, this.httpOptions);
  }

  getPlanDefaultAmounts(ciclo: number, jurisdiccionId: any, planIds: number[]): Observable<PlanDefaultAmount[]> {
    let params: HttpParams;
    let url = `${this.url}/api/multipago/cliente/plan/defaultamounts`;

    params = new HttpParams();
    params = params.append('ciclo', ciclo);
    params = params.append('jurisdiccionId', jurisdiccionId);
    planIds.forEach((planId: number) => {
      params = params.append('planIds', planId);
    });

    return this.http.get<PlanDefaultAmount[]>(url, {
      headers: this.httpOptions.headers,
      params: params
    });
  }

  doAbogadoSuscripcionCheckAndUpdate(suscripcionUuid: string): Observable<void> {
    let url = `${this.url}/api/multipago/abogado/suscripcion/${suscripcionUuid}`;
    return this.http.post<void>(url, this.httpOptions);
  }

  doAbogadoValidarIncrease(): Observable<void> {
    let url = `${this.url}/api/multipago/abogado/validar/increase`;
    return this.http.post<void>(url, this.httpOptions);
  }

  doClientePostPago(planPlataforma: PlanPlataforma, linkPago: ContratarPlanYAbogado): Observable<Link> {
    let url = `${this.url}/api/multipago/cliente/linkDePago`;
    let param = {
      'planPlataforma': planPlataforma,
      'linkPago': linkPago
    }
    return this.http.post<Link>(url, param, this.httpOptions);
  }

  doClienteValidarPago(externalReference: string): Observable<void> {
    let url = `${this.url}/api/multipago/cliente/validarPago`;
    return this.http.post<void>(url, externalReference, this.httpOptions);
  }

  doClienteCasoPago(externalReference: string, casoId: number): Observable<void> {
    let url = `${this.url}/api/multipago/cliente/linkDePago/${externalReference}/${casoId}`;
    return this.http.post<void>(url, externalReference, this.httpOptions);
  }

}
