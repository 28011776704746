<div class="casoFondo">
  <mat-toolbar class="header">
    <span class="example-spacer"></span>
    <span>Planes</span>
  </mat-toolbar>

  <mat-card class="mensaje">
    <div *ngIf="cargando">
      <mat-spinner></mat-spinner>
      <div class="separadorCentrado">
        <p class="title600">Por favor, espera un momento</p>
        <mat-card-subtitle color="primary"
          >Estamos procesando tu pago</mat-card-subtitle
        >
      </div>
    </div>
    <div *ngIf="!cargando">
      <img class="logo" src="../../../../../assets/confirmacion.jpg" />
      <div class="separadorCentrado">
        <h1>{{ mensaje }}</h1>
        <mat-card-subtitle color="primary">
          {{ mensajeSub }}</mat-card-subtitle
        ><br />
      </div>
      <!-- <button class="boton-footer" mat-button mat-dialog-close color="primary">ENTENDIDO</button> -->
      <button
        class="boton-footer"
        mat-button
        [routerLink]="['/plan']"
        color="primary"
      >
        ENTENDIDO
      </button>
    </div>
  </mat-card>
</div>
