<div class="casoFondo">
    <mat-toolbar class="header">
        <button mat-button [routerLink]="['/perfil-abogado']">
            <mat-icon class="icono">chevron_left</mat-icon>
        </button>
      <span>Agregar jurisdicción</span>
    </mat-toolbar>
    <mat-card class="mensaje">
      <div class="separadorCentrado">
        <app-mensaje-validar [mostrarLogo]="true" textoBoton='VOLVER AL PERFIL'></app-mensaje-validar>
      </div>
    </mat-card>
    <br/>
  </div>