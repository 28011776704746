import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-abogado',
  templateUrl: './abogado.component.html',
  styleUrls: ['./abogado.component.css'],
})
export class AbogadoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
