import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckPasswordService {

  constructor() { }

  checkPass(blur: boolean, password: string, repeatPassword: string) {
    let response = true;
    if(blur) {
      if (password != repeatPassword) {
        response = false;
      }
      else {
        response = true;
      }
    }
    return response;
  }

}
