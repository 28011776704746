import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ApiService } from '../../api/api.service';


@Injectable({
  providedIn: 'root'
})
export class CurrentTimeService {

  constructor(
    private apiService: ApiService
  ) { }

  public getTimezoneMinutes() {
    return new Date().getTimezoneOffset();
  }

  public getTimeStamp() {
    return Date.now();
  }

  public getTimezoneHours() {
      let aux = (-1)*this.getTimezoneMinutes()/60
      return "GMT"+aux;
  }
}
