<body>
  <div class="language-container">
    <button class="button-idioma">
      <img [src]="imgSelected" alt="" class="language-img" />
      <select
        [ngModel]="language"
        (ngModelChange)="switchLang($event)"
        class="language-select"
      >
        <option value="es-AR" [selected]="'es-AR' === language">
          {{ "admin.Button.es" | translate }}
        </option>
        <option value="it-IT" [selected]="'it-IT' === language">
          {{ "admin.Button.it" | translate }}
        </option>
        <option value="en-US" [selected]="'en-US' === language">
          {{ "admin.Button.en" | translate }}
        </option>
      </select>
    </button>
  </div>
  <div class="contenedor-cards">
    <mat-card class="registro">
      <form [formGroup]="newUser">
        <img class="logo" src="../../../../assets/logo.jpg" />
        <div class="separador">
          <button mat-button color="primary" [routerLink]="['/redes']">
            <mat-icon class="icono">chevron_left</mat-icon>
            {{ "registroCliente.volver" | translate }}
          </button>
          <h5>{{ "registroCliente.ingresar" | translate }}</h5>
          <h4> Crear Caso </h4>
        </div>

        <div class="inputs-registro inline-block">
          <mat-form-field appearance="outline" class="inputs">
            <mat-label>{{ "casoNuevo.nombre" | translate }}</mat-label>
            <input
              matInput
              type="text"
              placeholder="{{ 'casoNuevo.nombre' | translate }}"
              formControlName="casoTitulo"
            />
          </mat-form-field>
          <mat-form-field appearance="outline" class="inputs">
            <mat-label>{{ "casoNuevo.descripcion" | translate }}</mat-label>
            <textarea
              style="overflow: auto; resize: none; height: 200px"
              maxlength="255"
              matInput
              formControlName="casoDesc"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="inputs-registro inline-block">
          <mat-form-field appearance="outline" class="inputs">
            <mat-label>{{ "registroCliente.pais" | translate }}</mat-label>
            <mat-select
              [disabled]="!coinciden"
              required
              formControlName="pais"
              (selectionChange)="cargarProvincias()"
            >
              <mat-option *ngFor="let pais of cacheCountries" [value]="pais">
                {{ pais.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="inputs">
            <mat-label>{{ "registroCliente.provincia" | translate }}</mat-label>
            <mat-select
              [disabled]="!coinciden"
              required
              formControlName="provinciaResidencia"
              (selectionChange)="cargarLocalidadesResidencia()"
            >
              <mat-option
                *ngFor="let provincia of provinciasResidencias"
                [value]="provincia"
                >{{ provincia.nombre }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="inputs">
            <mat-label>{{ "registroAbogado.localidad" | translate }}</mat-label>
            <mat-select required formControlName="localidadResidencia">
              <mat-option
                *ngFor="let localidad of localidadesResidencias"
                [value]="localidad"
                >{{ localidad.nombre }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <div class="separador">
          <h4> Especifique la especialidad y categoria de su caso </h4>
        </div>

        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label> Especialidad </mat-label>
          <mat-select
            [disabled]="!coinciden"
            formControlName="especialidadId"
            (selectionChange)="doEspecialidadSel()"
          >
            <mat-option *ngFor="let especialidad of cacheEspecialidades"
              [value]="especialidad.id_especialidad"
            >
              {{ especialidad.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label> Categoria </mat-label>
          <mat-select [disabled]="!coinciden"
            formControlName="categoriaId"
          >
            <mat-option *ngFor="let categoria of cacheCategorias"
              [value]="categoria.id_categoria"
            >
              {{ categoria.titulo }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="separador">
          <h4>{{ "casoNuevo.adjuntar" | translate }}</h4>
        </div>

        <div class="separadorAdjuntar">
          <label for="file-upload">
            <p class="boton-adjuntar">
              {{ "casoNuevo.adjuntarBtn" | translate }}
            </p>
          </label>
          <input
            id="file-upload"
            type="file"
            multiple
            (change)="doSelectFiles($event)"
          />
        </div>
        <mat-hint class="separadorAdjuntar">{{
          "casoNuevo.adjuntarDesc" | translate
        }}</mat-hint>
        <div *ngFor="let archivo of selArchivos; let index = index">
          <div class="archivo">
            <mat-icon>description</mat-icon>
            <div>
              <h3 class="izq">
                {{
                  archivo.nombreoriginal
                    ? archivo.nombreoriginal
                    : archivo.name
                }}
              </h3>
              <p class="izq" style="text-wrap: nowrap;">
                {{ (archivo.size ? archivo.size : archivo.tamanio) / 100000 }}
                MB
              </p>
            </div>
            <div style="flex-grow: 1;"></div>
            <button class="" mat-icon-button>
              <mat-icon
                class="icono-derecha"
                svgIcon="borrar"
                (click)="doEliminarFiles(archivo, index)"
              ></mat-icon>
            </button>
          </div>
          <hr color="#D8D8D8" />
        </div>

        <div class="separador">
          <h4>{{ "registroCliente.datos" | translate }}</h4>
        </div>

        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroCliente.nombre" | translate }}</mat-label>
          <input
            required
            matInput
            type="text"
            placeholder="{{ 'registroCliente.nombre' | translate }}"
            formControlName="nombre"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroCliente.apellido" | translate }}</mat-label>
          <input
            required
            matInput
            type="text"
            placeholder="{{ 'registroCliente.apellido' | translate }}"
            formControlName="apellido"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroCliente.correo" | translate }}</mat-label>
          <input
            required
            matInput
            type="email"
            ngModel
            email
            placeholder="{{ 'registroCliente.correo' | translate }}"
            formControlName="email"
          />
          <mat-hint>{{ "registroCliente.correoDescrip" | translate }}</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroAbogado.celular" | translate }}</mat-label>
          <input
            [disabled]="!coinciden"
            matInput
            type="text"
            formControlName="telefono"
          />
        </mat-form-field>

        <br />
        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroCliente.contraseña" | translate }}</mat-label>
          <input
            required
            matInput
            placeholder="{{ 'registroCliente.contraseña' | translate }}"
            (keyup)="verificarPass()"
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
          />
          <button
            mat-icon-button
            matSuffix
            [disabled]="registroRedes"
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-hint>{{ "registroCliente.contraDescrip" | translate }}</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroCliente.repCon" | translate }}</mat-label>
          <input
            (blur)="verificarPassSalir()"
            (keyup)="verificarPass()"
            required
            matInput
            placeholder="{{ 'registroCliente.repCon' | translate }}"
            [type]="hide ? 'password' : 'text'"
            formControlName="repeatPassword"
          />
          <button
            mat-icon-button
            matSuffix
            [disabled]="registroRedes"
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-hint *ngIf="!coinciden" class="textoRojo">{{
            "registroCliente.contraCoin" | translate
          }}</mat-hint>
        </mat-form-field>

        <div class="separadorCentrado">
          <br /><br />
          <hr color="#D8D8D8" />
          <br />
          <mat-label color="primary"
            >{{ "registroCliente.final1" | translate }}
            <a target="_blank" href="/terminos-y-condiciones/">{{
              "registroCliente.final2" | translate
            }}</a>
            {{ "registroCliente.final3" | translate }}
            <a target="_blank" href="/politica-de-privacidad/">{{
              "registroCliente.final4" | translate
            }}</a></mat-label
          >
        </div>

        <button
          class="boton-primario"
          mat-raised-button
          color="primary"
          (click)="onSubmit()"
          [disabled]="!newUser.valid"
        >
          {{ "registroCliente.continuar" | translate }}
        </button>
      </form>
    </mat-card>
  </div>
</body>
