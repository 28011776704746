import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../../modules/material.module';

import { AbogadoRegistroRoutingModule } from './abogadoRegistro-routing.module';

import { RegistroAbogadoComponent } from '../registro/registro.component';
import { CreacionExitosaAbogadoComponent } from '../creacion-exitosa-abogado/creacion-exitosa-abogado.component';
// import { CuentaValidadaAbogadoComponent } from '../cuenta-validada-abogado/cuenta-validada-abogado.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    RegistroAbogadoComponent,
    CreacionExitosaAbogadoComponent,
    
    // CuentaValidadaAbogadoComponent
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    ReactiveFormsModule,
    AbogadoRegistroRoutingModule,
    TranslateModule,
    MatTooltipModule,
    FormsModule
  ],
  providers: [],
  bootstrap: []
})
export class AbogadoRegistroModule { }
