export enum EstadoCaso {
    FINALIZADO = "Finalizado",
    PENDIENTE_PUNTUACION = "Pendiente de Puntuación",
    CITA_AGENDADA = "Cita Agendada",
    LLAMADA_EXPRESS = "Llamada Express",
    TOMADO = "Tomado",
    PENDIENTE_ACEPTACION = "Pendiente de Aceptación",
    PERDIDO = "Perdido",
    PENDIENTE_SELECCION = "Pendiente de selección",
    GANADO = "Ganado"
}
export namespace EstadoCaso {
    export function getEstadoFromString(str: string): string {
        let out = null;
        Object.keys(EstadoCaso).forEach(key => {
            if (EstadoCaso[key] == str) {
                out = key;
            }
        });
        return out;
    }
}