<div class="centrado" *ngIf="suscripcion && suscripcion.estado == 'active'">
  <div class="tiempoSubscripcion">
    <span>{{ textoSubscripcion }}</span>
  </div>
  <mat-card class="cardSeleccionada">
    <br />
    <div>
      <app-detalle
        [anual]="suscripcion.planPlataformaCiclo == 2 ? true : false"
        [plan]="plan"
        [suscripcion]="suscripcion"
        [planPrecio]="suscripcion.monto"
        [planNombre]="suscripcion.planNombre"
        [mostrarAyuda]="false"
      ></app-detalle>
    </div>
    <br /><br />
    <button
      class="boton-secundario"
      mat-button
      color="primary"
      [routerLink]="['/plan']"
    >
    {{ 'perfilAbogado.camPlan' | translate }}
    </button>
    <br>
    <button
    *ngIf="suscripcion.estadoRaw !== 'pending_canceled'"
    class="boton-secundario"
    mat-button
    color="primary"
    [routerLink]="['/plan']"
  >
  {{ 'perfilAbogado.canSusc' | translate }}
  </button>
    


  </mat-card>
</div>

<div class="centrado" *ngIf="suscripcion && suscripcion.estado !== 'active' " style="color: red;">
  <div class="tiempoSubscripcion">
    <span *ngIf="suscripcion && (suscripcion.estado == 'canceled' || suscripcion.estado == 'pending')">Su Plan se encuentra inactivo por falta de pago,debe regularizar el proceso.</span>
  </div>
  <mat-card class="cardSeleccionada">
    <br />
    <div>
      <app-detalle
        [anual]="suscripcion.planPlataformaCiclo == 2 ? true : false"
        [plan]="plan"
        [planNombre]="suscripcion.planNombre"
        [planPrecio]="suscripcion.monto"
        [mostrarAyuda]="false"
      ></app-detalle>
    </div>
    <br /><br />
    <div class="container-boton">
      <button
        class="boton-primario"
        mat-raised-button
        color="primary"
        [routerLink]="['/plan']"
      >
        CONTRATAR PLAN
      </button>
    </div>

  </mat-card>
</div>




<div *ngIf="suscripcion == null" style="color: red;">
  <div class="separadorCentrado">
    <mat-icon class="iconoSinPlan">error_outlined</mat-icon>
    <h1>{{ 'perfilAbogado.sinplantitulo' | translate }}</h1>
    <p class="subtitle">
      {{ 'perfilAbogado.sinplanmsg' | translate }}
    </p>
  </div>
  <div class="container-boton">
    <button
      class="boton-primario"
      mat-raised-button
      color="primary"
      [routerLink]="['/plan']"
    >
    {{ 'perfilAbogado.sinplanbutc' | translate }}
    </button>
  </div>
</div>

<button
    class="boton-secundario"
    mat-button
    color="primary"
    (click)="baja()"
  >
  {{ 'perfilAbogado.sinplanbutb' | translate }}
  </button>
