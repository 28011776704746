<div class="casoFondo">
  <mat-toolbar class="header">
    <span class="example-spacer"></span>
    <span>{{ "paises.admin" | translate }}</span>

    <br />
    <button class="cerrarSesionAdmin" mat-button (click)="cerrarSesion()">
        <mat-icon class="icono">logout</mat-icon>
    </button>
</mat-toolbar>

<br />

<app-navbar-admin></app-navbar-admin>
  
    <mat-card class="admin">
      <img class="logo" src="../../../assets/logo.jpg" />
      <br />

      <button mat-icon-button matSuffix (click)="addEntity()" >
                                  <mat-icon>add_box</mat-icon>
                              </button>

      <br />
      <div class="table-container" *ngIf="!sinEntidades">
        <table mat-table [dataSource]="companies" style="width: 80%;margin: auto;">
          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef>
              {{ "registroEntidad.nombre" | translate }}
            </th>
            <td mat-cell *matCellDef="let company">{{ company.nombre }}</td>
          </ng-container>
  
          <ng-container matColumnDef="descripcion">
            <th mat-header-cell *matHeaderCellDef>
              {{ "registroEntidad.descripcion" | translate }}
            </th>
            <td mat-cell *matCellDef="let company">{{ company.descripcion }}</td>
          </ng-container>
  
  
          <ng-container matColumnDef="hash">
            <th mat-header-cell *matHeaderCellDef>
              {{ "registroEntidad.codigo" | translate }}
            </th>
            <td mat-cell *matCellDef="let company">
              {{ company.hash }}
            </td>
          </ng-container>

          <ng-container matColumnDef="logo">
            <th mat-header-cell *matHeaderCellDef>
              Logo
            </th>
            <td mat-cell *matCellDef="let company" style="background-color: #0A3F73; text-align: center;">
              <img 
                *ngIf="company?.logoId > 0"
                style="max-height: 40px;"
                [src]="getLogoUrl(company)"
              />
            </td>
          </ng-container>
  
          <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef>
              Pais
            </th>
            <td mat-cell *matCellDef="let company">
              {{ company.countryName }}
            </td>
          </ng-container>
        
          <ng-container matColumnDef="idCompany" >
            <th mat-header-cell *matHeaderCellDef>
              {{ "registroEntidad.acciones" | translate }}
            </th>
           
            <td mat-cell *matCellDef="let company">
              <button mat-icon-button matSuffix (click)="edit(company)" >
                <mat-icon>settings</mat-icon>
              </button>
              <button mat-icon-button matSuffix (click)="doLogoUpload(company)" >
                <mat-icon>picture_in_picture</mat-icon>
              </button>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </mat-card>
  </div>
  