import { Component, OnInit, Input } from '@angular/core';
import { Archivo } from '../../api/user';
import { ApiService } from '../../api/api.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogoPrevisualizarComponent } from '../commons/dialogo/dialogo-previsualizar/dialogo-previsualizar.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-archivos',
  templateUrl: './archivos.component.html',
  styleUrls: ['./archivos.component.css']
})
export class ArchivosComponent implements OnInit {

  @Input('archivos') archivos: FileList;
  @Input('casoId') casoId: number;
  @Input('isDownloadable') isDownloadable: boolean;
  archivo: Archivo;
  constructor(private apiService: ApiService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  descargarArchivo(archivoLocal) {    
    let extensions = [".pdf", ".jpg", ".jpeg", ".png", ".svg"];
    let isPreview = extensions.some(ext => archivoLocal.tipo == ext);

    this.apiService.downloadDoc(archivoLocal.id_adjunto_caso, this.casoId)
    .subscribe(archivo => {
      if (isPreview) {
        let reader = new FileReader();

        reader.onloadend = () => {
          this.dialog.open(DialogoPrevisualizarComponent, {
            data: {
              data: reader.result,
              mime: this.getMimeType(archivoLocal.tipo)
            },
            width: '80vw',
            height: '90vh'
          });
        };
        reader.readAsDataURL(archivo);
      } else {
        let dataType = archivo.type;
        let binaryData = [];
        binaryData.push(archivo);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', archivoLocal.nombre);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.archivo = archivo;
      }
    }, error => {
        console.error(error);
    });
  }

  getMimeType(extension: string): string {
    if (extension == ".pdf")
      return "application/pdf";
    else if (extension == ".jpg")
      return "image/jpg";
    else if (extension == ".png" || extension == ".png")
      return "image/png";
    else if (extension == ".svg")
      return "image/svg+xml";
    else
      return "application/octet-stream";
  }

}
