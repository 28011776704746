<body>

  <div>
    <app-language-nav></app-language-nav>
  </div>
  <div class="contenedor-cards-mensaje">
    <mat-card class="mensaje">
      <app-mensaje-validar [mostrarLogo]="true" textoBoton='VOLVER A LA PAGINA PRINCIPAL'></app-mensaje-validar>
    </mat-card>
  </div>
</body>