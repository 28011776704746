<body>
  <div class="contenedor-cards">
    <mat-card class="login">
      <form [formGroup]="userForm">
        <img class="logo" src="../../../assets/logo.jpg" />
        <mat-form-field appearance="outline" class="inputs">
          <mat-label>{{ "reestablecer.correo" | translate }}</mat-label>
          <input
            required
            matInput
            type="email"
            ngModel
            [email]="true"
            placeholder="{{ 'reestablecer.correo' | translate }}"
            formControlName="email"
          />
        </mat-form-field>
        <button
          (click)="onSubmit()"
          class="boton-primario"
          mat-raised-button
          color="primary"
        >
          {{ "reestablecer.reestablecer" | translate }}
        </button>
        <h6></h6>
        <button
          class="boton-secundario"
          mat-button
          color="primary"
          [routerLink]="['']"
        >
          {{ "reestablecer.volver" | translate }}
        </button>
        <router-outlet #outlet="outlet"></router-outlet>
      </form>
    </mat-card>
  </div>
</body>
