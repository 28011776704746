import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { loadScript } from '@paypal/paypal-js';
import { PlanPlataforma } from 'src/app/components/admin/multipago/modules/multipago.model';
import { environment } from 'src/environments/environment';

export enum PAYPAL_TIPO {
  SUSCRIPCION,
  PAGO
}

@Component({
  selector: 'app-popup-paypal-pago',
  templateUrl: './popup-paypal-pago.component.html',
  styleUrls: ['./popup-paypal-pago.component.css']
})
export class PopupPaypalPagoComponent implements OnInit {

  planUuid: string;
  usuarioId: number;

  typePago: PAYPAL_TIPO = PAYPAL_TIPO.SUSCRIPCION;

  paypalClientId: string = environment.paypal_clientid;

  planPlataforma: PlanPlataforma;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<PopupPaypalPagoComponent>
  ) { 
    if (data['planUuid'])
      this.planUuid = data.planUuid;
    if (data['usuarioId'])
      this.usuarioId = data.usuarioId;
    if (data['typePago'])
      this.typePago = data.typePago;
    if (data['clientId'])
      this.paypalClientId = data.clientId;
    if (data['planPlataforma'])
      this.planPlataforma = data.planPlataforma;
  }

  ngOnInit(): void {
    const planUuid = this.planUuid;
    const usuarioId = this.usuarioId;

    if (this.typePago == PAYPAL_TIPO.SUSCRIPCION)
      this.doLoadSuscripcion();
  }

  doLoadSuscripcion(): void {
    loadScript({
      "client-id": this.paypalClientId,
      "vault": true,
      "intent": "subscription"
    }).then((paypal) => {
      paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe'
        },
        createSubscription: (data, actions) => {
          return actions.subscription.create({
            plan_id: `${this.planUuid}`,
            custom_id: `${this.usuarioId}`
          });
        },
        onApprove: (data, actions) => {
          return new Promise<void>(() => {
            console.log("OnApprove.");
            console.log(data);
            console.log(actions);
            alert("Aprobado, en los proximos minutos se habilitara la suscripcion.");
            this.dialogRef.close(data);
          });
        }
      }).render("#paypal-buttons");
    });
  }

  doLoadCheckout(): void {
    loadScript({
      "client-id": this.paypalClientId
    }).then(paypal => {
      paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical'
        },
        createOrder(data, actions) {
            return actions.order.create({
              intent: 'CAPTURE',
              purchase_units: [{
                amount: {
                  value: this.planPlataforma.monto,
                  currency_code: this.planPlataforma.monedaId
                },
                payee: {
                  email_address: JSON.parse(localStorage.user).mail
                },
                payment_instruction: {
                  disbursement_mode: 'INSTANT',
                  platform_fees: [{
                    amount: {
                      currency_code: this.planPlataforma.monedaId,
                      value: "3"
                    }
                  }]
                }
              }],
              application_context: {
                return_url: `${environment.angularUrl}/pago`,
                cancel_url: `${environment.angularUrl}/cancel`
              }
            })
        },
      }).render("#paypal-buttons");
    });
  }

}
