<div class="header-modal">
  <mat-icon class="iconoBlanco" svgIcon={{data.nombre}}></mat-icon> <h2 mat-dialog-title class="titulo-modal"> {{data.nombre}}</h2>

  <div class="actionBtn" mat-dialog-actions>
    <button class="noneBtn" mat-button mat-dialog-close>x</button>
  </div>
  
</div>

<mat-dialog-content>
  
  <ul *ngFor="let categoria of categorias">
    <li>{{categoria.titulo}}</li>    
  </ul>

</mat-dialog-content>
