import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../../modules/material.module';

import { ClienteRegistroRoutingModule } from './clienteRegistro-routing.module';;

import { CreacionExitosaClienteComponent } from '../creacion-exitosa/creacion-exitosa-cliente.component';
import { RegistroClienteComponent } from '../registro-cliente/registro-cliente.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CreacionExitosaClienteComponent,
    RegistroClienteComponent,
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    ReactiveFormsModule,
    ClienteRegistroRoutingModule,
    TranslateModule,
    FormsModule
  ],
  providers: [],
  bootstrap: []
})
export class ClienteRegistroModule { }
