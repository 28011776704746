
    <img class="logo" src="../../../assets/logo.jpg" />
    <p class="tituloAdmin">
      {{ "admin.title" | translate }}
      <!-- Aquí podrás aceptar o rechazar las matrículas de los abogados que
      solicitaron ingresar a Legalify -->
    </p>
    <div *ngIf="sinAbogados">
      <p class="textoAdmin">
        {{ "admin.subTitle" | translate }}
        <!-- Actualmente no hay matrículas por validar. Te enviaremos un mail cuando
        lo haya. -->
      </p>
    </div>
    <br />
    <div class="table-container" *ngIf="cargoLaLista && !sinAbogados">
      <table mat-table [dataSource]="abogadosData">
        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef>
            {{ "admin.name.label" | translate }}
          </th>
          <td mat-cell *matCellDef="let abogado">{{ abogado.nombre }}</td>
        </ng-container>

        <ng-container matColumnDef="apellido">
          <th mat-header-cell *matHeaderCellDef>
            {{ "admin.lastname.label" | translate }}
          </th>
          <td mat-cell *matCellDef="let abogado">{{ abogado.apellido }}</td>
        </ng-container>

        <ng-container matColumnDef="tipo">
          <th mat-header-cell *matHeaderCellDef>
            {{ "admin.type.label" | translate }}
          </th>
          <td mat-cell *matCellDef="let abogado">
            {{ abogado.datosProfesionales.tipoDni }}
          </td>
        </ng-container>

        <ng-container matColumnDef="dni">
          <th mat-header-cell *matHeaderCellDef>
            {{ "admin.document.label" | translate }}
          </th>
          <td mat-cell *matCellDef="let abogado">
            {{ abogado.datosProfesionales.numeroDni }}
          </td>
        </ng-container>

        <ng-container matColumnDef="correo">
          <th mat-header-cell *matHeaderCellDef>
            {{ "admin.mail.label" | translate }}
          </th>
          <td mat-cell *matCellDef="let abogado">{{ abogado.mail }}</td>
        </ng-container>

        <ng-container matColumnDef="pais">
          <th mat-header-cell *matHeaderCellDef>
            {{ "admin.pais" | translate }}
          </th>
          <td mat-cell *matCellDef="let abogado">
            {{ abogado.paisNombre }}
          </td>
        </ng-container>

        <ng-container matColumnDef="jurisdiccion">
          <th mat-header-cell *matHeaderCellDef>
            {{ "admin.jur.label" | translate }}
          </th>
          <td mat-cell *matCellDef="let abogado">
            {{ abogado.datosProfesionales.localidad.jurisdiccion.nombre }}
          </td>
        </ng-container>
        
        <ng-container matColumnDef="provinciaResidencia">
          <th mat-header-cell *matHeaderCellDef>
            {{ "admin.state.label" | translate }}
          </th>
          <td mat-cell *matCellDef="let abogado">{{ abogado.provincia }}</td>
        </ng-container>

        <ng-container matColumnDef="ciudadResidencia">
          <th mat-header-cell *matHeaderCellDef>
            {{ "admin.city.label" | translate }}
          </th>
          <td mat-cell *matCellDef="let abogado">{{ abogado.ciudad }}</td>
        </ng-container>

        <ng-container matColumnDef="colegio">
          <th mat-header-cell *matHeaderCellDef>
            {{ "admin.college.label" | translate }}
          </th>
          <td mat-cell *matCellDef="let abogado">
            {{ abogado.datosProfesionales.colegio }}
          </td>
        </ng-container>

        <ng-container matColumnDef="tomo">
          <th mat-header-cell *matHeaderCellDef>
            {{ "admin.tomo.label" | translate }}
          </th>
          <td mat-cell *matCellDef="let abogado">
            <span *ngIf="abogado.pais!=595">{{ abogado.datosProfesionales.tomo }}</span> 
          </td>
        </ng-container>

        <ng-container matColumnDef="folio">
          <th mat-header-cell *matHeaderCellDef>
            {{ "admin.folio.label" | translate }}
          </th>
          <td mat-cell *matCellDef="let abogado">
            <span *ngIf="abogado.pais!=595">{{ abogado.datosProfesionales.folio }}</span> 
            
          </td>
        </ng-container>

        <ng-container matColumnDef="otro">
          <th mat-header-cell *matHeaderCellDef>
            {{ "admin.otro.label" | translate }}
          </th>
          <td mat-cell *matCellDef="let abogado">
            {{ abogado.datosProfesionales.otro }}
          </td>
        </ng-container>

        <ng-container matColumnDef="acciones" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>
            {{ "admin.actions.label" | translate }}
          </th>
          <td mat-cell *matCellDef="let abogado">
            <button class="iconoAdmin" mat-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu class="menuAdmin" #menu="matMenu">
              <button mat-menu-item color="warn" (click)="rechazar(abogado)">
                {{ "admin.refuse.button" | translate }}
              </button>
              <hr />
              <button
                mat-menu-item
                color="primary"
                (click)="aceptar(abogado)"
                cdkFocusInitial
              >
                {{ "admin.acept.button" | translate }}
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
