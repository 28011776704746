import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-dialogo-confirmar',
  templateUrl: './dialogo-confirmar.component.html',
  styleUrls: ['./dialogo-confirmar.component.css']
})
export class DialogoConfirmarComponent implements OnInit {

  result: boolean;

  subjectResult: Subject<boolean> = new Subject();

  title: string;

  message: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<DialogoConfirmarComponent>,
    public translate: TranslateService,
    private serviceUtil: UtilService
  ) {
    serviceUtil.doPreparePageTranslate(translate);

    this.title = data.title ?? '';
    this.message = data.message ?? '';
  }

  ngOnInit(): void {
  }

}
