import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ReturnUser } from '../api/user';
import { RedesCredenciales } from '../interfaces/redes-credenciales';
import { UrlDto } from '../interfaces/url';
import { TokenDecoderService } from './tokenDecoder/token-decoder.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../api/api.service';
import { LogOutService } from './logOut/log-out.service';
import { Source } from '../enums/redesSociales';
import { SwPush } from '@angular/service-worker';
declare var gtag;

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(
    private http: HttpClient,
    private tokenDecoderService: TokenDecoderService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private apiService: ApiService,
    private logOut: LogOutService,
    private swPush: SwPush
  ) {}

  public getRedPerfil(code, tipo, red) {
    return this.http.get<RedesCredenciales>(
      `${environment.apiUrl}/cuenta/getRedPerfil?code=${code}&tipo=${tipo}&red=${red}`
    );
  }
  
  public getPlataformaPerfil(plataforma: string, suscripcionUuid: string): Observable<RedesCredenciales> {
    let params = { plataforma, suscripcionUuid };
    let url = `${environment.apiUrl}/cuenta/getSuscripcionInfo`;

    return this.http.get<RedesCredenciales>(url, { params });
  }

  public getRedesCredentialsUrl(red, tipo?) {
    return this.http.get<UrlDto>(
      `${environment.apiUrl}/cuenta/getRedesCredentialsUrl?red=${red}${
        tipo ? `&tipo=${tipo}` : ''
      }`
    );
  }

  public loginRedes(tipo, code) {
    return this.http.get<ReturnUser>(
      `${environment.apiUrl}/cuenta/loginRedes?tipo=${tipo}&code=${code}`,
      this.httpOptions
    );
  }

  public verificoSesion() {
    if (!this.tokenDecoderService.isExpired()) {
      this.navegacion(JSON.parse(localStorage.getItem('user')), true);
    } else {
      this.logOut.limpiarStorage();
    }
  }

  public getCompanyId(companyNombre: string): Observable<number> {
    return this.http.get<number>(`${environment.apiUrl}/cuenta/company/${companyNombre}/id`);
  }

  public doUpdateUsuarioCompany(usuarioId: number, companyId: number): Observable<void> {
    let url = `${environment.apiUrl}/cuenta/company/${usuarioId}/${companyId}`;

    return this.http.post<void>(url, {});
  }

  public handleUserData(user) {
    user = user;
    console.log("1402 USER LOGGED "+JSON.stringify(user));
    localStorage.setItem('token', user.token);
    localStorage.setItem('usuarioActualId', user['id_usuario']);
    localStorage.setItem('descripcion', user['descripcion']);
    localStorage.setItem('user', JSON.stringify(user));
    if(user['lenguaje']=='T'){
      sessionStorage.setItem('language', "it-IT");
    }
    else if(user['lenguaje']=='N'){
      sessionStorage.setItem('language', "es-AR");
    }
    else{
      sessionStorage.setItem('language', "es-AR");
    }
    this.navegacion(user, false);
  }

  private navegacion(user, iniciado) {
    if (user) {
      if (user.rol.id === 1) {
        gtag('event', 'login', {
          'send_to':environment.measurement_id,
          'method': 'login_admin',
        });
        this.router.navigate(['/admin']);
        this.openSnackBar('Sesión de administrador iniciada exitosamente');
      } else {
        gtag('event', 'login', {
          'send_to':environment.measurement_id,
          'method': 'login_abogado',
        });
        if (!iniciado) {
          this.openSnackBar('Sesión iniciada exitosamente');
        }
        if (user.tutorialCompletado) {
          
          this.router.navigate(['/casos-activos/', user.rol.id]);
          
        } else {
          this.apiService.tutorialCompleto().subscribe(
            (respuesta) => {},
            (error) => {
              console.error(error);
            }
          );
          this.router.navigateByUrl('/bienvenida', { state: user.rol });
        }
      }
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 5000 });
  }

  public handleError(error, source: Source) {
    console.error(error);
    let text = '';
    switch (error.status) {
      case 409:
        if (source == Source.Login) {
          text = 'Esta cuenta no se encuentra registrada en Legalify';
        }
        if (source == Source.Register) {
          text =
            'Ya tienes una cuenta registrada con esta red social en Legalify';
        }
        break;
      default:
        text = 'Falló el ';
        if (source == Source.Login) {
          text += 'inicio de sesión ';
        }
        if (source == Source.Register) {
          text += 'registro ';
        }
        text += 'con esta red social, por favor intente nuevamente más tarde';
        break;
    }
    this.openSnackBar(text);
  }

  doLoadWebPush(): void {
    this.swPush.requestSubscription({
      serverPublicKey: environment.webPushPublicKey
    }).then((value: PushSubscription) => {
      console.log("Aceptando notificacion, se verifica y/o registra la suscripcion.");
      console.log(value.toJSON());
      this.apiService.doCuentaNotificacionSuscribir(value.toJSON()).subscribe();
    }).catch(reason => {
      console.log(reason);
    });
  }

}
