<mat-dialog-content>
  <h1>{{ "calificar.cerrar" | translate }}</h1>
  <div class="linea">
    <button
      mat-dialog-close="true"
      class="boton-primario"
      mat-raised-button
      color="primary"
    >
      {{ "calificar.continuar" | translate }}
    </button>
    <button
      (click)="cerrar()"
      class="boton-continuar"
      mat-stroked-button
      color="primary"
    >
      {{ "calificar.volverbtn" | translate }}
    </button>
  </div>
</mat-dialog-content>
