import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  getItem(variable:string){
    return localStorage.getItem(variable);
  }

  addItem(name:string, value:string){
    var exist = localStorage.getItem(name);
    const delimiter = ";";
    if(exist!=null){
      var items : Array<string> = [];
      exist.split(delimiter).forEach(function(item){
        items.push(item);
      });

      var duplicate = items.find(x=> x==value);
      if(duplicate==null){
        items.push(value);
      }
      localStorage.setItem(name,items.join(delimiter));
    }
    else{
      var items : Array<string> = [];
      items.push(value);
      localStorage.setItem(name, items.join(delimiter));
    }
  }
}
