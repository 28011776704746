import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  Moneda,
  PlanMoneda,
  PlanPais,
  PlanPlataforma,
  Plataforma,
  PlataformaMoneda,
  Suscripcion,
  EspecialidadesCliente,
} from './multipago.model';

@Injectable({
  providedIn: 'root',
})
export class MultipagoService {
  private url = environment.apiUrl + '/api/multipago';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  getPlanPaises(): Observable<PlanPais[]> {
    return this.http.get<PlanPais[]>(this.url + '/plan/pais', this.httpOptions);
  }

  getPlanPaisesEnabled(): Observable<PlanPais[]> {
    return this.http.get<PlanPais[]>(
      this.url + '/plan/pais/enabled',
      this.httpOptions
    );
  }

  doPlanPaisesSave(planPais: PlanPais): Observable<PlanPais> {
    return this.http.post<PlanPais>(
      `${this.url}/plan/pais`,
      planPais,
      this.httpOptions
    );
  }

  doPlanPaisesDel(planCountryId: number): Observable<PlanPais> {
    planCountryId = planCountryId ? planCountryId : 0;
    return this.http.delete<PlanPais>(
      `${this.url}/plan/pais/${planCountryId}`,
      this.httpOptions
    );
  }

  getPlanMonedas(): Observable<PlanMoneda[]> {
    return this.http
      .get<PlanMoneda[]>(this.url + '/plan/moneda', this.httpOptions)
      .pipe(
        map((planMonedas: PlanMoneda[]) => {
          planMonedas.forEach((planMoneda: PlanMoneda) => {
            if (!planMoneda.monto) planMoneda.monto = 0.0;
            if (!planMoneda.montoAnual) planMoneda.montoAnual = 0.0;
          });
          return planMonedas;
        })
      );
  }

  getPlanMonedasEnabled(): Observable<PlanMoneda[]> {
    return this.http.get<PlanMoneda[]>(
      this.url + '/plan/moneda/enabled',
      this.httpOptions
    );
  }

  getPlanMonedasByCountryUser(): Observable<PlanMoneda[]> {
    return this.http.get<PlanMoneda[]>(
      this.url + '/plan/moneda/byCountry',
      this.httpOptions
    );
  }

  getMonedasDisp(planId: number, countryId: number): Observable<Moneda[]> {
    countryId = countryId ? countryId : 0;
    planId = planId ? planId : 0;
    return this.http.get<Moneda[]>(
      `${this.url}/plan/moneda/monedasDisp?countryId=${countryId}&planId=${planId}`,
      this.httpOptions
    );
  }

  doPlanMonedaSave(planMoneda: PlanMoneda): Observable<PlanMoneda> {
    return this.http.post<PlanMoneda>(
      `${this.url}/plan/moneda`,
      planMoneda,
      this.httpOptions
    );
  }

  doPlanMonedaDel(planMonedaId: number): Observable<PlanMoneda> {
    planMonedaId = planMonedaId ? planMonedaId : 0;
    return this.http.delete<PlanMoneda>(
      `${this.url}/plan/moneda/${planMonedaId}`,
      this.httpOptions
    );
  }

  getPlataformas(): Observable<Plataforma[]> {
    return this.http.get<Plataforma[]>(
      this.url + '/plataforma',
      this.httpOptions
    );
  }

  getPlataformaMonedas(): Observable<PlataformaMoneda[]> {
    return this.http.get<PlataformaMoneda[]>(
      this.url + '/plataforma/moneda',
      this.httpOptions
    );
  }

  getSuscripcionEstados(): Observable<string[]> {
    let url = `${this.url}/suscripcion/estados`;
    return this.http.get<string[]>(url, this.httpOptions);
  }

  getSuscripciones(
    pageIndex?: number,
    pageSize?: number,
    countryId?: number,
    plataformaId?: number
  ): Observable<Suscripcion[]> {
    let url = `${this.url}/suscripcion/all?pageIndex=${pageIndex}&pageSize=${pageSize}`;
    if (countryId != null) {
      url += `&countryId=${countryId}`;
    }
    return this.http.get<Suscripcion[]>(url, this.httpOptions);
  }

  getSuscripcionesByUser(usuario: string): Observable<Suscripcion[]> {
    const url = `${this.url}/suscripcion/all?usuario=${usuario}`;
    return this.http.get<Suscripcion[]>(url);
  }

  getSuscripcionFilter(
    countryId: number,
    plataformaId: number = 0,
    filterUsuario: string,
    filterEstados: string[]
  ): Observable<Suscripcion[]> {
    let params: any = {};
    if (plataformaId) {
      params.plataformaId = plataformaId;
    }
    params.usuario = filterUsuario ?? '';
    params.estados = filterEstados;
    let httpOptions = Object.assign({}, this.httpOptions, { params });

    let url = `${this.url}/suscripcion/all?pageSize=100&countryId=${countryId}`;
    if (plataformaId) {
      url += `&platformId=${plataformaId}`;
    }

    return this.http.get<Suscripcion[]>(url, httpOptions);
  }

  getMonedas(): Observable<Moneda[]> {
    return this.http.get<Moneda[]>(
      this.url + '/plataforma/moneda/all',
      this.httpOptions
    );
  }

  getPlataformaDispMonedas(
    plataformaId: number,
    countryId: number
  ): Observable<Moneda[]> {
    return this.http.get<Moneda[]>(
      this.url +
        `/plataforma/moneda/monedasDisp?countryId=${countryId}&plataformaId=${plataformaId}`,
      this.httpOptions
    );
  }

  doPlataformaMonedaSave(
    plataformaMoneda: PlataformaMoneda
  ): Observable<PlataformaMoneda> {
    return this.http.post<PlataformaMoneda>(
      `${this.url}/plataforma/moneda`,
      plataformaMoneda,
      this.httpOptions
    );
  }

  doPlataformaMonedaDel(
    plataformaMonedaId: number
  ): Observable<PlataformaMoneda> {
    plataformaMonedaId = plataformaMonedaId ? plataformaMonedaId : 0;
    return this.http.delete<PlataformaMoneda>(
      `${this.url}/plataforma/moneda/${plataformaMonedaId}`,
      this.httpOptions
    );
  }

  getPlanPlataformas(): Observable<PlanPlataforma[]> {
    return this.http.get<PlanPlataforma[]>(
      this.url + '/plan/plataforma',
      this.httpOptions
    );
  }

  getPlanPlataformasFilter(
    countryId: number,
    planId: number,
    monedaId: string,
    plataformaId: number
  ): Observable<PlanPlataforma[]> {
    let params = { countryId, planId, monedaId, plataformaId };
    let httpOptions = Object.assign({}, this.httpOptions, { params });

    let url = `${this.url}/plan/plataforma`;
    return this.http.get<PlanPlataforma[]>(url, httpOptions);
  }

  getPlanPlataformasEnabled(planId: number): Observable<PlanPlataforma[]> {
    let url: string = `${this.url}/plan/plataforma/enabled?planId=${planId}`;

    return this.http.get<PlanPlataforma[]>(url, this.httpOptions);
  }

  getPlanPlatPlataformaDisp(
    countryId: number,
    monedaId: string
  ): Observable<Plataforma[]> {
    let url = `${this.url}/plan/plataforma/disponibles?countryId=${countryId}&monedaId=${monedaId}`;
    return this.http.get<Plataforma[]>(url, this.httpOptions);
  }

  doPlanPlataformaSave(
    planPlataforma: PlanPlataforma
  ): Observable<PlanPlataforma> {
    return this.http.post<PlanPlataforma>(
      `${this.url}/plan/plataforma`,
      planPlataforma,
      this.httpOptions
    );
  }

  doPlanPlataformaValidar(
    planPlataforma: PlanPlataforma
  ): Observable<PlanPlataforma> {
    return this.http.post<PlanPlataforma>(
      `${this.url}/plan/plataforma/validar`,
      planPlataforma,
      this.httpOptions
    );
  }

  doPlanPlataformaDel(planPlataformaId: number): Observable<PlanPlataforma> {
    planPlataformaId = planPlataformaId ? planPlataformaId : 0;
    return this.http.delete<PlanPlataforma>(
      `${this.url}/plan/plataforma/${planPlataformaId}`,
      this.httpOptions
    );
  }
}

export class BaseSnackBar {
  constructor(protected snackBar: MatSnackBar) {}

  openSnackBar(message: string): void {
    this.snackBar.open(message, '', { duration: 5000 });
  }

  doShowErrorSnackBar(err: any): void {
    if (err.error && err.error.message) {
      this.openSnackBar(err.error.message);
    } else if (err.message) {
      this.openSnackBar(err.message);
    }
  }
}
