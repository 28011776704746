<div class="casoFondo">
  <div class="contenedor-cards-mensaje">
    <mat-card class="mensaje">
      <img class="logo" src="../../../../assets/logo.jpg" />
      <img
        *ngIf="exito"
        class="logo"
        src="../../../../assets/confirmacion.jpg"
      />
      <div class="separadorCentrado">
        <h3>{{ texto }}</h3>
        <mat-card-subtitle *ngIf="!exito">{{
          "mercado.vinculacion" | translate
        }}</mat-card-subtitle>
      </div>
      <button
        class="boton-footer"
        mat-button
        color="primary"
        [routerLink]="['/perfil-abogado']"
      >
        VOLVER A MI PERFIL
      </button>
      <router-outlet #outlet="outlet"></router-outlet>
    </mat-card>
  </div>
</div>
