<div class="casoFondo">
  <div *ngIf="!nippy">
    <div class="header-iconos">
      <div *ngIf="!soyCliente">
        <app-navbar-abogado></app-navbar-abogado>
      </div>
      <div *ngIf="soyCliente">
        <app-navbar-cliente></app-navbar-cliente>
      </div>

      <img *ngIf="companyLogo"
        class="letrasNippyBlanca"
        [src]="companyLogo"
        style="max-height: 50px;"
      />

      <app-language-nav></app-language-nav>
    </div>
  </div>
  <div *ngIf="nippy">
    <div class="Nippy">
      <div *ngIf="!soyCliente">
        <app-navbar-abogado></app-navbar-abogado>
      </div>
      <div *ngIf="soyCliente">
        <app-navbar-cliente></app-navbar-cliente>
      </div>
      <img
        class="letrasNippyBlanca"
        src="../../../../../../assets/nippy_logo.png"
      />
      <app-language-nav></app-language-nav>
    </div>
  </div>

  <mat-tab-group
    dynamicHeight
    mat-align-tabs="center"
    class="tabs"
    (selectedTabChange)="clickTab($event)"
  >
    <mat-tab class="mont" label="{{ 'casosActivos.abierto' | translate }}">
      <div class="casoFondo">
        <div
          class="separadorCentradoFondoFull"
          *ngIf="!hayCasosAbiertos && soyCliente"
        >
          <br /><br />
          <div class="desktop">
            <h1>{{ "casosActivos.desc" | translate }}</h1>
          </div>
          <div class="mobile">
            <h2 class="weigth600">{{ "casosActivos.desc" | translate }}</h2>
          </div>
        </div>
        <div class="separadorCentrado" *ngIf="!hayCasosAbiertos && !soyCliente">
          <br /><br />
          <div class="desktop">
            <h1>{{ "casosActivos.desc" | translate }}</h1>
          </div>
          <div class="mobile">
            <h2 class="weigth600">{{ "casosActivos.desc" | translate }}</h2>
          </div>
        </div>
        <br />
        <!-- <mat-spinner *ngIf="!terminoCarga"></mat-spinner> -->
        <div class="separadorCentrado" *ngIf="hayCasosAbiertos">
          <br />
          <div *ngIf="casosAbiertos">
            <div class="casos-flexbox" *ngFor="let caso of casosAbiertos">
              <mat-card class="casoscard">
                <mat-card-header>
                  <div mat-card-avatar></div>
                  <p [class]="caso.clase">{{ caso.estado | translate }}</p>
                  <p class="fecha">
                    {{ caso.fechaCreacion | date: "dd/MM/yyy" }} 
                  </p>
                  <br />
                </mat-card-header>
                <mat-card-content>
                  <mat-card-title class="tituloCaso">{{
                    caso.tituloAlterado
                  }}</mat-card-title>
                  <mat-card-subtitle
                    >{{ "casosActivos.categoria" | translate }}:
                    {{ caso.categoria.tituloAlterado }}</mat-card-subtitle
                  >

                  <div *ngIf="caso.toggled">

                    <mat-card-title>
                      <mat-icon class="azul">place</mat-icon
                      >{{ caso.localidad.jurisdiccion.nombre }}
                    </mat-card-title>
                    <br />
                    <mat-card-subtitle> {{ caso.localidad.nombre }}</mat-card-subtitle>
                    <br />
                    <hr color="#D8D8D8" />
                    <br />
                    <h2>{{ "casosActivos.categoria" | translate }}</h2>
                    <mat-card-subtitle>{{ caso.categoria.titulo }}</mat-card-subtitle>
                    <br />
                    <hr color="#D8D8D8" />
                    <br />
                    <h2>{{ "casosActivos.especialidad" | translate }}</h2>
                    <mat-card-subtitle>{{ caso.especialidad.nombre }}</mat-card-subtitle>

                  </div>

                </mat-card-content>
                <br />
                <mat-card-actions class="detailCase">
                  <hr color="#D8D8D8" />
                  <button mat-button *ngIf="caso.toggled!=true" (click)="caso.toggled=true">{{ "casosActivos.detalles" | translate }}</button>
                  <button mat-button *ngIf="caso.toggled" (click)="caso.toggled=false">{{ "casosActivos.ocultar" | translate }}</button>
                  <button mat-button (click)="detalles(caso)">
                    {{ "casosActivos.ver" | translate }}
                  </button>

                  <button mat-icon-button *ngIf="rolId==3" style="visibility: hidden;">
                    <mat-icon  (click)="edit(caso)">edit</mat-icon>
                  </button>
                  <button mat-icon-button >
                    <mat-icon class="red-icon" *ngIf="getDaysDiff(caso.fechaCreacion,fecha.getTime(),caso.tipo,caso.estado)" (click)="doDeleteConfirm(caso)">delete</mat-icon>
                  </button>
                </mat-card-actions>
              </mat-card>
            </div>
          </div>
        </div>
        <br />
        <div *ngIf="soyCliente">
          <div class="desktop">
            <button
              *ngIf="terminoCarga"
              class="boton-primario"
              mat-raised-button
              color="primary"
              (click)="navegar()"
            >
              {{ "casosActivos.crear" | translate }}
            </button>
          </div>
          <div class="mobile">
            <button
              *ngIf="terminoCarga"
              class="boton-primario"
              mat-raised-button
              color="primary"
              (click)="navegar()"
            >
              {{ "casosActivos.crear" | translate }}
            </button>
          </div>
        </div>
        <div class="botonesPaginacion">
          <button
            *ngIf="paginateLeftOpen"
            mat-button
            (click)="cargarMasCasos('a', 0)"
            color="primary"
          >
            <img src="../../../../../../assets/chevronLeft.svg" />
          </button>
          <button
            *ngIf="paginateRightOpen"
            mat-button
            (click)="cargarMasCasos('a', 1)"
            color="primary"
          >
            <img src="../../../../../../assets/chevronRight.svg" />
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{ 'casosActivos.cerrado' | translate }}">
      <div class="casoFondo">
        <!-- <mat-spinner *ngIf="!terminoCarga"></mat-spinner> -->
        <div class="separadorCentrado" *ngIf="!hayCasosCerrados">
          <br /><br />
          <div class="desktop">
            <h1>{{ "casosActivos.descCerr" | translate }}</h1>
          </div>
          <div class="mobile">
            <h2 class="weigth600">{{ "casosActivos.descCerr" | translate }}</h2>
          </div>
        </div>
        <div class="separadorCentrado" *ngIf="hayCasosCerrados">
          <br />
          <div class="casos-flexbox" *ngFor="let caso of casosCerrados">
            <mat-card class="casoscard">
              <mat-card-header>
                <div mat-card-avatar></div>
                <p [class]="caso.clase">{{ caso.estado }}</p>
                <p class="fecha">
                  {{ caso.fechaCreacion | date: "dd/MM/yyy" }}
                </p>
                <br />
              </mat-card-header>
              <mat-card-content>
                <mat-card-title class="tituloCaso">{{
                  caso.tituloAlterado
                }}</mat-card-title>
                <mat-card-subtitle
                  >Categoría:
                  {{ caso.categoria.tituloAlterado }}</mat-card-subtitle
                >
              </mat-card-content>
              <br />
              <mat-card-actions class="detailCase">
                <hr color="#D8D8D8" />
                <button mat-button (click)="detalles(caso)">
                  {{ "casosActivos.detalles" | translate }}
                </button>
              </mat-card-actions>
            </mat-card>
          </div>
        </div>
        <div class="botonesPaginacion">
          <button
            *ngIf="paginateLeftClosed"
            mat-button
            (click)="cargarMasCasos('c', 0)"
            color="primary"
          >
            <img src="../../../../../../assets/chevronLeft.svg" />
          </button>
          <button
            *ngIf="paginateRightClosed"
            mat-button
            (click)="cargarMasCasos('c', 1)"
            color="primary"
          >
            <img src="../../../../../../assets/chevronRight.svg" />
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <br />
</div>
