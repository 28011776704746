<div class="contenidoModal">
  <div
    (mouseover)="changeClass(true)"
    (mouseout)="changeClass(false)"
    [class]="clase"
  >
    <button mat-button class="close" mat-dialog-close>X</button>
    <div *ngIf="data.downgrade">
      <h1>{{ "editarEsp.Atencion" | translate }}</h1>
      <p>
        {{ "editarEsp.planSel" | translate }} ({{ plan.nombre }})
        {{ "editarEsp.permite" | translate }}
        {{ plan.jurisdicciones }} {{ "editarEsp.jur" | translate }}
        {{ plan.especialidades }} {{ "editarEsp.tex" | translate }}
      </p>
      <div class="lineaLimites">
        <p class="subtitulo">{{ "editarEsp.jurisdiccion" | translate }}</p>
        <p class="limites">
          <mat-icon class="errorIcon">error_outline</mat-icon>
          {{ plan.jurisdicciones }} permitidas
        </p>
      </div>
      <mat-selection-list [disableRipple]="true" #item>
        <div>
          <mat-list-option
            *ngFor="let jurisdiccion of jurisdicciones"
            [selected]="jurisdiccion.selected"
            [disabled]="
              cantidadJurisdiccionesSeleccionadas >= plan.jurisdicciones &&
              !jurisdiccion.selected
            "
            (click)="cambiarJurisdicciones(jurisdiccion)"
          >
            <mat-icon class="azul" mat-list-icon>place</mat-icon>
            <div class="nombreJur" mat-line>{{ jurisdiccion.nombre }}</div>
            <div mat-line>
              Tomo: {{ jurisdiccion.tomo }} Folio: {{ jurisdiccion.folio }}
            </div>
          </mat-list-option>
        </div>
      </mat-selection-list>
    </div>

    <div *ngIf="!data.downgrade">
      <div class="linea">
        <h1>{{ "editarEsp.editar" | translate }}</h1>
        <p class="limites">
          <mat-icon class="errorIcon">error_outline</mat-icon>
          <span *ngIf="plan.especialidades < 9999">
            {{ plan.especialidades }} {{ "editarEsp.permi" | translate }}
          </span>
          <span *ngIf="!(plan.especialidades < 9999) && plan.id_plan != 7">
            {{ "editarEsp.ilim" | translate }}
          </span>
        </p>
      </div>
      <p>
        {{ "editarEsp.Agregar" | translate }}
      </p>
    </div>

    <div *ngIf="data.downgrade" class="lineaLimites2">
      <p class="subtitulo">{{ "editarEsp.especialidades" | translate }}</p>
      <p class="limites">
        <mat-icon class="errorIcon">error_outline</mat-icon
        >{{ plan.especialidades }} {{ "editarEsp.permi" | translate }}
      </p>
    </div>

    <mat-selection-list [disableRipple]="true" #item>
      <mat-list-option
        *ngFor="let especialidad of especialidades"
        [disabled]="
          this.cantidadEspecialidadesSeleccionadas >=
            this.plan.especialidades && !especialidad.selected
        "
        [selected]="especialidad.selected"
        (click)="cambiarEspecialidad(especialidad)"
      >
        <p class="nombreEsp">{{ especialidad.nombre }}</p>
      </mat-list-option>
    </mat-selection-list>
  </div>
  <div *ngIf="data.downgrade">
    <button
      [disabled]="desactivarBoton"
      [mat-dialog-close]="true"
      class="boton-primario"
      mat-raised-button
      color="primary"
      (click)="onSubmit()"
    >
      {{ "editarEsp.pago" | translate }}
    </button>
    <p class="textoBajoBoton">
      {{ "editarEsp.presionar" | translate }}
    </p>
  </div>
  <div>
    <button
      *ngIf="!data.downgrade"
      [disabled]="desactivarBotonEspecialidades"
      [mat-dialog-close]="true"
      class="boton-primario"
      mat-raised-button
      color="primary"
      (click)="onSubmitEspecialidades()"
    >
      {{ "editarEsp.confirmar" | translate }}
    </button>
  </div>
</div>
