import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormatDateService } from 'src/app/utils/formatDate/formatDate.service.';
import { ApiService } from '../../../../../api/api.service';
import { PlanService } from 'src/app/services/plan/plan.service';
import { CasoDetallado } from '../../../../../api/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SenderService } from 'src/app/services/sender.service';
import { PlanMultipagoService } from 'src/app/services/plan/plan-multipago.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogoConfirmarComponent } from 'src/app/components/commons/dialogo/dialogo-confirmar/dialogo-confirmar.component';
import { Observable, Subject, forkJoin, observable } from 'rxjs';

enum CaseType {
  Open = 'a',
  Close = 'c',
}

@Component({
  selector: 'app-activos',
  templateUrl: './activos.component.html',
  styleUrls: ['./activos.component.css'],
})
export class ActivosComponent implements OnInit {
  language:String="";
  constructor(
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private planService: PlanService,
    private servicePlanMP: PlanMultipagoService,
    private formatDateService: FormatDateService,
    public translate: TranslateService,
    private service: SenderService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    if(sessionStorage.getItem('language')==null){
      sessionStorage.setItem('language','es-AR');
      translate.setDefaultLang('es-AR');
      
    }
    else{
      translate.setDefaultLang(sessionStorage.getItem('language'));
    }
    console.log('ASD: ' + sessionStorage.getItem('language'));
  }

  casosAbiertos: any[];
  terminoCarga: boolean = false;
  casosCerrados: CasoDetallado[];
  hayCasosAbiertos = true;
  hayCasosCerrados = false;
  soyCliente = true;
  rolId = 0;
  crearOBuscar = '';
  LIMITE_CASOS = 6;
  LIMITE_TITULO = 38;
  LIMITE_CATEGORIA = 50;
  paginaCasos: number;
  mostrarMas: boolean = true;
  paginateLeftOpen = false;
  paginateRightOpen = false;
  paginateLeftClosed = false;
  paginateRightClosed = false;
  caseType: CaseType;
  nippy: boolean;
  companyLogo: string;
  fecha=new Date();

  cargarMasCasos(type: CaseType, destino: number) {
    const ATRAS = 0;
    const ADELANTE = 1;

    switch (destino) {
      case ATRAS: {
        if (this.paginaCasos > 0) {
          this.paginaCasos--;
        }
        break;
      }
      case ADELANTE: {
        this.paginaCasos++;
        break;
      }
    }
    this.loadCases(this.paginaCasos, type);
  }

  switchLang(lang: string) {
    sessionStorage.setItem('language',lang);
    this.language = lang;
    this.translate.use(lang);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 5000 });
  }

  clickTab(event) {
    this.paginaCasos = 0;
    this.paginateRightOpen = false;
    this.paginateLeftOpen = false;
    this.paginateRightClosed = false;
    this.paginateLeftClosed = false;

    if (event.tab.textLabel == 'ABIERTOS') {
      this.loadCases(this.paginaCasos, CaseType.Open);
    } else {
      this.loadCases(this.paginaCasos, CaseType.Close);
    }
  }

  asignarClasesEstados(caso) {
    let clase = '';
    switch (caso.estado) {
      case 'Pendiente de Puntuación':
        clase = 'pendientePuntuacion';
        break;
      case 'Pendiente de Aceptación':
        clase = 'aceptacion-seleccion';
        break;
      case 'Pendiente de selección':
        clase = 'aceptacion-seleccion';
        break;
      case 'Tomado':
        clase = 'ganado-tomado';
        break;
      case 'Seleccionado':
        clase = 'ganado-tomado';
        break;
      case 'Cita Agendada':
        clase = 'citaAgendada';
        break;
      case 'Llamada Express':
        clase = 'llamadaExpress';
        break;
      case 'No seleccionado':
        clase = 'noSeleccionado';
        break;
      case 'Finalizado':
        clase = 'finalizado';
        break;
    }

    if (caso.estado.includes('Abogados Disponibles')) {
      clase = 'abogadosDisponibles';
    }
    return clase;
  }

  getDaysDiff(now,fechacreacion,tipo,estado){
    let del = false;
    if(estado.includes("Pendiente de Aceptación") || estado.includes("Abogados Disponibles")){
      if(tipo=='Email'){
        let days =Math.floor((fechacreacion-now)/1000/60/60/24);
        del = days>30?true:false;
      }
      else{
        del=true;
      }
    }
    return del;
  }

  loadCases(page: number, type: CaseType) {
    this.fecha=new Date();
    this.fecha.getTime();
    this.terminoCarga = false;
    this.apiService.getCasos(page, type, this.LIMITE_CASOS).subscribe(
      (casos) => {
        if (casos.length == 0) {
          if (type == CaseType.Open) this.hayCasosAbiertos = false;
          else this.hayCasosCerrados = false;
        } else {
          let casesFormated = casos;
          // var casesFormated = this.formatDateService.convertDateToLocalByAttribute(casos, "fechaCreacion");
          // var casesFormated = this.formatDateService.convertDateToUTCByAttribute(casos, "fechaCreacion");
          if (type == CaseType.Open) {
            this.paginateRightOpen = casesFormated.length >= this.LIMITE_CASOS;
            if (this.paginateRightOpen) {
              this.apiService
                .getCasos(page + 1, type, this.LIMITE_CASOS)
                .subscribe(
                  (response) => {
                    if (response.length == 0) {
                      this.paginateRightOpen = false;
                    }
                  },
                  (error) => {
                    this.openSnackBar(
                      'No fue posible cargar la lista de casos'
                    );
                  }
                );
            }
            this.paginateLeftOpen = this.paginaCasos != 0;
            if (!this.paginateRightOpen) {
              casesFormated = casesFormated.slice(0, this.LIMITE_CASOS - 1);
            }
          } else {
            this.paginateRightClosed =
              casesFormated.length >= this.LIMITE_CASOS;
            this.paginateLeftClosed = this.paginaCasos != 0;
            if (this.paginateRightClosed) {
              this.apiService
                .getCasos(page + 1, type, this.LIMITE_CASOS)
                .subscribe(
                  (response) => {
                    if (response.length == 0) {
                      this.paginateRightClosed = false;
                    }
                  },
                  (error) => {
                    this.openSnackBar(
                      'No fue posible cargar la lista de casos'
                    );
                  }
                );
            }
            if (!this.paginateRightClosed) {
              casesFormated = casesFormated.slice(0, this.LIMITE_CASOS - 1);
            }
          }
          casesFormated.map((caso) => {
            caso['clase'] = this.asignarClasesEstados(caso);
            if (caso.titulo.length > this.LIMITE_TITULO) {
              caso.tituloAlterado =
                caso.titulo.substring(0, this.LIMITE_TITULO) + '...';
            } else {
              caso.tituloAlterado = caso.titulo;
            }
            if (caso.categoria.titulo.length > this.LIMITE_CATEGORIA) {
              caso.categoria.tituloAlterado =
                caso.categoria.titulo.substring(0, this.LIMITE_CATEGORIA) +
                '...';
            } else {
              caso.categoria.tituloAlterado = caso.categoria.titulo;
            }
          });
          if (type == CaseType.Open) {
            this.hayCasosAbiertos = true;
            this.casosAbiertos = casesFormated;
          } else {
            this.hayCasosCerrados = true;
            this.casosCerrados = casesFormated;
          }
        }
        this.terminoCarga = true;
      },
      (error) => {
        this.openSnackBar('No fue posible cargar la lista de casos');
        console.error(error);
        this.terminoCarga = true;
      }
    );
  }

  ngOnInit(): void {
    //this.nippy = this.service.nippy;
    this.nippy = sessionStorage.nippy;
    this.companyLogo = sessionStorage.companyLogo;
    console.log('NIPPY ' + this.nippy);
    this.paginaCasos = 0;
    localStorage.setItem('entro', 'si');
    this.route.paramMap.subscribe((params) => {
      this.rolId = params['params'].rolId;
      console.log(params);

      if (this.rolId == 2) {
        this.servicePlanMP.doAbogadoValidarIncrease().subscribe({
          next: () => {
            console.log("validacion de migracion a increase - final.");
          }
        });
      }
    });
    if (this.rolId == 3) {
      this.crearOBuscar = 'crear ';
      localStorage.removeItem('caso');
      this.soyCliente = true;
    } else {
      this.crearOBuscar = 'buscar ';
      this.soyCliente = false;
    }
    this.loadCases(this.paginaCasos, CaseType.Open);
    this.loadCases(this.paginaCasos, CaseType.Close);
  }

  detalles(caso) {
    const rol = this.rolId;
    this.router.navigateByUrl('/detalles', { state: { caso, rol } });
  }

  doDeleteConfirm(caso) {
    forkJoin(this.translate.get("dialog.confirm.delete.header"), this.translate.get("dialog.confirm.delete.message")).subscribe({
      next: (resp: string []) => {
        const dialogRef = this.dialog.open(DialogoConfirmarComponent, {
          data: {
            title: resp[0],
            message: resp[1]
          }
        });

        dialogRef.afterClosed().subscribe({
          next: data => {
            console.log("dialog close with: " + data);
            if (data) {
              this.delete(caso);
            }
          }
        });
      }
    })
  }

  delete(caso){
    this.apiService.borrarCaso(caso.id_caso).subscribe(
      (response) => {
        if(response.estado=="Ok"){
          this.casosAbiertos=[];
          this.casosCerrados=[];
  
          this.loadCases(this.paginaCasos, CaseType.Open);
          this.loadCases(this.paginaCasos, CaseType.Close);
        }
        else{
          this.openSnackBar('No se puede eliminar el caso');
        }
        
      },
      (error) => {
        this.openSnackBar('No fue posible cargar la lista de casos');
        console.error(error);
        this.terminoCarga = true;
      });
  }

  edit(caso){
    localStorage.setItem("caso",JSON.stringify(caso));
    localStorage.setItem("caso-edit",JSON.stringify(caso));
    this.router.navigate(['/edit-caso']);
  }

  navegar = () => {
    if (this.rolId == 3) {
      localStorage.removeItem('caso');
      localStorage.removeItem('caso-edit');
      this.router.navigate(['/crear-nuevo']);
    } else {
      this.router.navigate(['/oportunidades']);
    }
  };
}
