import { Component, OnInit } from '@angular/core';
import {
  Validators,
  UntypedFormControl,
  UntypedFormGroup,
  FormControl,
} from '@angular/forms';
import {
  Categoria,
  Especialidad,
  Identificacion,
  Jurisdiccion,
  Localidad,
  RegisterClient,
} from '../../../../api/user';
import { ApiService } from '../../../../api/api.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { CheckPasswordService } from '../../../../services/register/checkPassword/check-password.service';
import { TranslateService } from '@ngx-translate/core';
import { Pais } from 'src/app/api/pais';
import { environment } from 'src/environments/environment';
import { RoleNumber } from 'src/app/enums/role';
import { TiposCasoCode } from 'src/app/enums/TiposCasoCode';
import { CurrentTimeService } from 'src/app/utils/currentTime/current-time.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

declare var gtag;
declare let fbq: any;

@Component({
  selector: 'app-registro-cliente',
  templateUrl: './registro-cliente.component.html',
  styleUrls: ['./registro-cliente.component.css'],
})
export class RegistroClienteComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  coinciden = true;
  blur = false;
  language: String = '';
  localidadesResidencias: Localidad[];
  localidades: Localidad[];
  imgSelected;
  cacheCountries: Pais[];
  jurisdicciones: Jurisdiccion[];
  provinciasResidencias: Jurisdiccion[];
  nombreIdentificacion = '';

  selArchivos: File[] = [];

  cacheEspecialidades: Especialidad[] = [];
  cacheCategorias: Categoria[] = [];

  constructor(
    private apiService: ApiService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private loginSrv: LoginService,
    private route: ActivatedRoute,
    private currentTimeSrv: CurrentTimeService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private checkPasswordService: CheckPasswordService,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT', 'en-US']);
    this.language = sessionStorage.getItem('language');
    if (!this.language) {
      this.language = 'es-AR';
      sessionStorage.setItem('language', '' + this.language);
    }
    translate.setDefaultLang(sessionStorage.getItem('language'));
    if (this.language == 'it-IT')
      this.imgSelected = '../../../assets/italia.png';
    else if (this.language == 'en-US')
      this.imgSelected = '../../../assets/ingles.png';
    else
      this.imgSelected = '../../../assets/españa.png';

    this.matIconRegistry.addSvgIcon(
      `borrar`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/garbage.svg'
      )
    );
  }
  registerClients: RegisterClient[] = [];
  registroRedes: boolean = false;
  flag: boolean = false;
  identificaciones: Identificacion[];
  newUser = new UntypedFormGroup({
    nombre: new UntypedFormControl(),
    apellido: new UntypedFormControl(),
    email: new UntypedFormControl(),
    dni: new UntypedFormControl('0'),
    tipoIdentificacion: new UntypedFormControl(
      {
        id_tipos_identificacion: 2,
        nombre: 'Cédula de Identidad'
      }
    ),
    telefono: new UntypedFormControl(),
    password: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/),
    ]),
    repeatPassword: new UntypedFormControl('', [Validators.required]),
    pais: new UntypedFormControl(),
    localidad: new UntypedFormControl(),
    provinciaResidencia: new UntypedFormControl(),
    localidadResidencia: new UntypedFormControl(),
    casoTitulo: new FormControl<string>(null, [Validators.required]),
    casoDesc: new FormControl<string>(null, [Validators.required]),
    especialidadId: new FormControl<number>(null, [Validators.required]),
    categoriaId: new FormControl<number>(null, [Validators.required]),
    archivosSeleccionados: new FormControl(),
  });

  redes: string = '';

  ngOnInit() {
    this.registroRedes = false;
    this.route.paramMap.subscribe((params) => {
      const red = params['params'].tipo;
      this.redes = red;
      this.route.queryParams.subscribe((params) => {
        const code = params['code'];
        if (code) {
          this.registroRedes = true;
          this.loginSrv.getRedPerfil(code, 'C', red).subscribe((res) => {
            this.newUser.patchValue({
              nombre: res.nombre,
              apellido: res.apellido,
              email: res.mail,
            });
            this.newUser.controls['password'].disable();
            this.newUser.controls['repeatPassword'].disable();
            this.guardarUsuario();
          });
        }
      });
    });
    this.trackRegister();

    this.apiService.getPaisesClean().subscribe({
      next: (resp: Pais[]) => {
        this.cacheCountries = resp;
        this.jurisdicciones = this.provinciasResidencias = [];

        resp.some((pais: Pais) => {
          pais.name = pais.name ?? '';
          if (pais.name.toUpperCase().startsWith('ARGENT')) {
            this.newUser.controls['pais'].setValue(pais);
            this.cargarProvincias();
            return true;
          }
          return false;
        });
      },
      error: (err: any) => {
        console.error(err);
      },
    });
    this.getIdentificaciones();
  }

  trackRegister() {
    gtag('event', 'registro_clie_visita', {
      send_to: environment.measurement_id,
      event_name: 'registro_clie_visita',
    });
  }

  getIdentificaciones() {
    this.apiService.getIdentificacion().subscribe(
      (identificaciones) => {
        this.identificaciones = identificaciones;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  cargarLocalidades() {
    this.apiService
      .getLocalidades(this.newUser.value.jurisdiccion.id_jurisdiccion)
      .subscribe(
        (localidad) => {
          this.localidades = localidad;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  cargarProvincias(): void {
    this.jurisdicciones = this.provinciasResidencias = [];
    this.localidadesResidencias = [];
    this.apiService
      .getJurisdiccionPaisesBy(this.newUser.value.pais.countryId)
      .subscribe({
        next: (resp: Jurisdiccion[]) => {
          if (resp.length > 0) {
            this.newUser.patchValue({
              provinciaResidencia: resp[0]
            });
            this.cargarLocalidadesResidencia();
          }
          this.jurisdicciones = this.provinciasResidencias = resp;
          this.localidadesResidencias = [];
        },
      });

    this.apiService.getEspecialidadesByPais(RoleNumber.Cliente, this.newUser.value.pais.countryId).subscribe({
      next: (especialidades: Especialidad[]) => {
        this.cacheEspecialidades = especialidades;
        if (especialidades && especialidades.length > 0) {
          this.newUser.patchValue({
            especialidadId: especialidades[0].id_especialidad
          });

          this.doEspecialidadSel();
        }
      }
    });
  }

  doEspecialidadSel(): void {
    let countryId = this.newUser.value.pais.countryId;
    let especialidadId = this.newUser.value.especialidadId;
    this.cacheCategorias = [];
    this.newUser.patchValue({
      categoriaId: null
    });

    this.apiService.getCategoriasByPais(especialidadId, countryId).subscribe({
      next: (categorias: Categoria[]) => {
        this.cacheCategorias = categorias;
        if (categorias && categorias.length > 0) {
          this.newUser.patchValue({
            categoriaId: categorias[0].id_categoria
          });
        }
      }
    });
  }

  cargarLocalidadesResidencia() {
    this.apiService
      .getLocalidades(this.newUser.value.provinciaResidencia.id_jurisdiccion)
      .subscribe(
        (localidad) => {
          if (localidad.length > 0) {
            this.newUser.patchValue({
              localidadResidencia: localidad[0]
            });
          }
          this.localidadesResidencias = localidad;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  switchLang(lang: string) {
    console.log(lang);
    this.translate.use(lang);
    this.language = lang;
    sessionStorage.setItem('language', lang);
    sessionStorage.language = lang;
    if (lang == 'es-AR') this.imgSelected = '../../../assets/españa.png';
    if (lang == 'it-IT') this.imgSelected = '../../../assets/italia.png';
    if (lang == 'en-US') this.imgSelected = '../../../assets/ingles.png';
  }

  onSubmit() {
    if (this.newUser.value.password != this.newUser.value.repeatPassword) {
      this.openSnackBar('Las contraseñas no coinciden');
    } else {
      this.guardarUsuario();
      fbq('track', 'CompleteRegistration');
    }
  }
  hide = true;

  guardarUsuario() {
    let clientRegister: RegisterClient;

    let lenguage = sessionStorage.getItem('language');
    let lchar = '';
    if (lenguage == 'es-AR') lchar = 'P';
    if (lenguage == 'it-IT') lchar = 'T';
    if (lenguage == 'en-US') lchar = 'N';

    clientRegister = {
      apellido: this.newUser.value.apellido,
      clave: this.newUser.value.password,
      datosUsuario: [
        {
          direccion: this.newUser.value.provinciaResidencia.nombre,
          localidadId: this.newUser.value.localidadResidencia.id_localidad,
          numeroDni: this.newUser.value.dni,
          telefono: this.newUser.value.telefono,
          tipoDni:
            this.newUser.value.tipoIdentificacion.id_tipos_identificacion,
        },
      ],
      lenguaje: lchar,
      mail: this.newUser.value.email,
      nombre: this.newUser.value.nombre,
      pais: this.newUser.value.pais.countryId,
      localidadId: this.newUser.value.localidadResidencia.id_localidad,
      rol: 3,
      redesSociales: this.registroRedes,
      mailConfirmUrl: '/validada-cliente',
    } as RegisterClient;
  
    let timeZone = '';
    this.apiService.getCountry().subscribe(
      (res) => {
        timeZone = res.countryCode;
        this.doGuardarUsuario(clientRegister, timeZone);
      },
      (error) => {
        console.error(error);
        timeZone = this.currentTimeSrv.getTimezoneHours();
        this.doGuardarUsuario(clientRegister, timeZone);
      }
    );
  }

  doGuardarUsuario(register: RegisterClient, timeZone: string) {
    register["caso"] = {
      casoId: 0,
      categoriaId: this.newUser.value.categoriaId,
      descripcion: this.newUser.value.casoDesc,
      localidadId: this.newUser.value.localidadResidencia.id_localidad,
      tipo: TiposCasoCode.EMAIL,
      titulo: this.newUser.value.casoTitulo,
      fechaCreacion: this.currentTimeSrv.getTimeStamp(),
      zonaHoraria: timeZone,
    };

    let nextFn = (res) => {
      if (sessionStorage["companyId"]) {
        this.loginSrv.doUpdateUsuarioCompany(res.id_usuario, sessionStorage["companyId"]).subscribe({
          next: () => {
            sessionStorage.removeItem("companyId");
          }
        });
      }

      if (this.registroRedes == true) {
        if (this.redes == 'L') {
          gtag('event', 'registro_clie_exitoso_linkedin', {
            send_to: environment.measurement_id,
            method: 'registro_clie_exitoso_linkedin',
          });
        } else if (this.redes == 'G') {
          gtag('event', 'registro_clie_exitoso_gmail', {
            send_to: environment.measurement_id,
            method: 'registro_clie_exitoso_gmail',
          });
        } else if (this.redes == 'F') {
          gtag('event', 'registro_clie_exitoso_facebook', {
            send_to: environment.measurement_id,
            method: 'registro_clie_exitoso_facebook',
          });
        }
      } else {
        gtag('event', 'registro_clie_exitoso_mail', {
          send_to: environment.measurement_id,
          method: 'registro_clie_exitoso_mail',
        });
      }

      this.openSnackBar(this.translate.instant('register.success'));
      if (this.registroRedes && res.token) {
        this.loginSrv.handleUserData(res);
      } else {
        this.router.navigate(['/exito-cliente']);
      }
    };
    let errFn = (error) => {
      console.error(error);
      if (error.status === 409) {
        this.openSnackBar(this.translate.instant('email.registered'));
      } else {
        this.openSnackBar(this.translate.instant('service.error'));
      }
    };

    if (this.selArchivos && this.selArchivos.length > 0) {
      this.apiService
        .registrarClienteWithFiles(register, this.selArchivos)
        .subscribe(nextFn, errFn);
    } else {
      this.apiService.registrarCliente(register).subscribe(nextFn, errFn);
    }
  }

  verificarPassSalir() {
    this.blur = true;
    this.verificarPass();
  }

  verificarPass() {
    this.coinciden = this.checkPasswordService.checkPass(
      this.blur,
      this.newUser.value.password,
      this.newUser.value.repeatPassword
    );
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  definirIdentificacion() {
    this.nombreIdentificacion = this.newUser.value.tipoIdentificacion.nombre;
  }

  doSelectFiles(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.selArchivos.push(event.target.files[i]);
    }
    this.newUser.patchValue({
      archivosSeleccionados: this.selArchivos
    });
  }

  doEliminarFiles(archivo, index) {
    let newFileList = Array.from(this.selArchivos);
    let borrar = newFileList.filter(
      (archivoParaBorrar) => archivoParaBorrar.name === archivo.name
    );
    this.selArchivos.splice(index, 1);
  }

}
