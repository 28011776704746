import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { PlanPlataforma } from 'src/app/components/admin/multipago/modules/multipago.model';
import { PopupMercadopagoTarjetaComponent } from '../popup-mercadopago-tarjeta/popup-mercadopago-tarjeta.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-popup-mercadopago-opciones',
  templateUrl: './popup-mercadopago-opciones.component.html',
  styleUrls: ['./popup-mercadopago-opciones.component.css']
})
export class PopupMercadopagoOpcionesComponent implements OnInit, AfterViewInit {

  planPlataforma: PlanPlataforma;

  onReturnURL: Subject<PlanPlataforma>;

  formMail: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
      private dialogRef: MatDialogRef<PopupMercadopagoOpcionesComponent>,
      private dialog: MatDialog
  ) {
    let mail: string;
    if (data['planPlataforma'])
      this.planPlataforma = data.planPlataforma;
    
    this.onReturnURL = new Subject();

    mail = JSON.parse(localStorage.user).mail;
    this.formMail = new FormGroup({
      mail: new FormControl<string>(mail, [Validators.required, Validators.email])
    });
  }

  ngOnInit(): void {
    console.log("PopupMercadopagoOpcionesComponent");
    this.formMail.valid
  }

  ngAfterViewInit(): void {
    console.log("PopupMercadopagoOpcionesComponent: ngAfterViewInit");
  }

  onSelTarjeta(): void {
    this.dialogRef.close();

    const dialogRef = this.dialog.open(PopupMercadopagoTarjetaComponent, {
      backdropClass: 'backdropBackground',
      data: {
        planPlataforma: this.planPlataforma
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      try {
        if (!response)
          return;
        
        this.doSelOption(response.token);
      } finally {
        this.onReturnURL.complete();
      }
    });
  }

  onSelPlataforma(): void {
    this.dialogRef.close();

    this.doSelOption("false");
    this.onReturnURL.complete();
  }

  onSelByUrl(): void {
    this.dialogRef.close();

    this.doSelOption("isPlanByUrl");
    this.onReturnURL.complete();
  }

  doSelOption(option: string): void {
    let send = Object.assign({}, this.planPlataforma);
    send.optional01 = option;
    send.optional02 = this.formMail.getRawValue().mail;

    this.onReturnURL.next(send);
  }

}
