<div class="separadorCentrado">
  <img *ngIf="mostrarLogo"class="logo" src="../../../../assets/logo.jpg">
  <img class="logo" src="../../../../assets/verificando.jpg">
  <h1>{{ "creaExitosaAbo.verificando" | translate }}</h1>
  <mat-label>{{ "creaExitosaAbo.verificabody1" | translate }} </mat-label>
  <mat-label class="azul">{{ "creaExitosaAbo.verificabody2" | translate }} </mat-label>
  <div *ngIf="mostrarLogo">
    <button class="boton-continuar" mat-button color="primary" [routerLink]="['/perfil-abogado']" [queryParams]="{ jurisdiccion: 1 }">{{textoBoton}}</button>
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>

