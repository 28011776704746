import { Component, OnInit} from '@angular/core';
import { Validators, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RegisterClient, ResetPasswordRepeat } from '../../../api/user';
import { ApiService } from '../../../api/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CheckPasswordService } from 'src/app/services/register/checkPassword/check-password.service';
import { Company } from 'src/app/api/company';
import { MatDialog } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Pregunta } from 'src/app/api/pregunta';
import { Formulario } from 'src/app/api/formulario';

@Component({
  selector: 'app-cert-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css']
})
export class CertFormularioComponent implements OnInit{
  language:String="";
  coinciden = true;
  imgSelected: String = "";
  blur = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialog,private apiService: ApiService, private checkPasswordService: CheckPasswordService,private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute,public translate: TranslateService) { 
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    this.language = sessionStorage.getItem('language');
    if(!sessionStorage.getItem("language")){
      translate.setDefaultLang("es-AR");
      this.language = "es-AR"
    }else
      translate.setDefaultLang(sessionStorage.getItem('language'));
    if(this.language == "es-AR")
      this.imgSelected = "../../../assets/españa.png"
    if(this.language == "it-IT")
      this.imgSelected = "../../../assets/italia.png"
    if(this.language == "en-US")
      this.imgSelected = "../../../assets/ingles.png"
  }
  certForm = new UntypedFormGroup({
    question: new UntypedFormControl('', [
      Validators.required,
    ]),
    weight: new UntypedFormControl('', [
      Validators.required,
    ]),
    answer: new UntypedFormControl('', [
      Validators.required,
    ])

  });
  idCambioClave: number;
  edit:Boolean=false;
  selectedPregunta:Pregunta;
  formulario:Formulario;

  ngOnInit() {
    this.edit=this.data.edit;
    this.formulario=this.data.formulario;
    if(this.edit==true){
      this.selectedPregunta=this.data.pregunta;
      this.feedForm();
    }
  }

  hide = true;

  feedForm(){
    this.certForm.get('question').setValue(this.selectedPregunta.pregunta);
    this.certForm.get('weight').setValue(this.selectedPregunta.peso);
    this.certForm.get('answer').setValue(this.selectedPregunta.respuesta);
  }
  
  switchLang(lang: string) {
    console.log(lang)
    this.translate.use(lang);
    this.language = lang;
    sessionStorage.setItem('language', lang);
    sessionStorage.language = lang;
    if(lang == "es-AR")
      this.imgSelected = "../../../assets/españa.png"
    if(lang == "it-IT")
      this.imgSelected = "../../../assets/italia.png"
    if(lang == "en-US")
      this.imgSelected = "../../../assets/ingles.png"
  }

  onSubmit() {

    console.log("Valid "+this.certForm.valid);
    if(this.certForm.valid){
        let question = this.certForm.value.question;
        let weight = this.certForm.value.weight;
        let answer = this.certForm.value.answer;
        
        if(this.edit==true){
          let pregunta:Pregunta={formulario:this.formulario,id_pregunta:this.selectedPregunta.id_pregunta,peso:weight,pregunta:question,respuesta:answer};
          this.apiService.updPregunta(pregunta).subscribe(preg => {
            if(preg!=null){
              this.dialog.closeAll();
            }
            else{
              this.openSnackBar("Ocurrio un error");
            }
          },
          error => {
            console.error(error);
            this.openSnackBar("Estamos trabajando para mejorar el servicio, por favor intente nuevamente más tarde");
            
          });
        }
        else{
          let pregunta:Pregunta={formulario:this.formulario,id_pregunta:null,peso:weight,pregunta:question,respuesta:answer};
          this.apiService.putPregunta(pregunta).subscribe(preg => {
            if(preg!=null){
              this.dialog.closeAll();
            }
            else{
              this.openSnackBar("Ocurrio un error");
            }
          },
          error => {
            console.error(error);
            this.openSnackBar("Estamos trabajando para mejorar el servicio, por favor intente nuevamente más tarde");
            
          });
        }
    }
    else{
        this.openSnackBar("Ingrese todos los campos");
    }
  }



  openSnackBar(message: string) {
    this._snackBar.open(message, "", { duration: 5000 });
  }

}