import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data/data.service';
import { LogOutService } from 'src/app/services/logOut/log-out.service';

@Component({
  selector: 'app-data',
  templateUrl: './datos.component.html',
  styleUrls: ['./datos.component.css']
})
export class DataComponent implements OnInit {

  constructor(private logOut: LogOutService, public translate: TranslateService, private dataService: DataService) { 
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }

  ngOnInit(): void {
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  cerrarSesion() {
    this.logOut.cerrarSesion();
  }

  public base64ToBlob(b64Data, sliceSize=512) {
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);
    
        let byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
  }

  generateExcel(){
    this.dataService.crearReporte().subscribe( (res) => {
      let buf = this.base64ToBlob(res.base64Excel);
      const url = window.URL.createObjectURL(buf);
      const link = document.createElement('a');
      link.href = url;
      let str = 'reporte' + new Date().getDate() + "/" + ( new Date().getMonth() + 1 ) + "/" + new Date().getFullYear() + ".xlsx" 
      link.setAttribute('download', str);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, err => {
      console.log("Error en el reporte", err)
    })
  }
}
