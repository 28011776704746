import { Pregunta } from "./pregunta";

export class Formulario {
    id_formulario: number;
    status: number;
    preguntas : Pregunta[];

    constructor(id_formulario,status){
        this.id_formulario=id_formulario;
        this.status=status;
        this.preguntas=[];
    }
    
}
  
  