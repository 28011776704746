import { Component, OnInit, Input } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DialogComponent } from '../modal/dialog.component';
import { Tipo } from '../../../../interfaces/modal';
import { PlanesConvertidos } from '../../../../api/planes';
import { TranslateService } from '@ngx-translate/core';
import { Suscripcion } from 'src/app/components/admin/multipago/modules/multipago.model';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.css'],
})
export class DetalleComponent implements OnInit {
  @Input('planPrecio') planPrecio: number;
  @Input('planNombre') planNombre: string;
  @Input('plan') plan: any;
  @Input('planContratado') planContratado: any;
  @Input('mostrarAyuda') mostrarAyuda: boolean;
  @Input('anual') anual: boolean;
  @Input('suscripcion') suscripcion:Suscripcion;

  datos = [
    {
      tipo: 1,
      titulo: 'Match con casos de tu interés',
      uno: 'Legalify detectará casos que hagan match con las especialidades y jurisdicciones que seleccionaste',
      dos: 'Cada vez que haya un match, Legalify te notificará sobre la oportunidad mediante e-mail ',
      tres: 'En la sección de “Oportunidades” podrás leer el caso y seleccionar si te interesa o no.',
      cuatro:
        'Si te interesa, el cliente podrá contactarte por e-mail. El cliente solo visualizará los 3 primeros abogados que hayan mostrado interés en su caso, así que si te interesa ¡no tardes en mostrar interés!',
      paga: 'En esta modalidad, tanto las tarifas como las condiciones de la consulta serán acordadas por el cliente y el abogado por fuera de Legalify. Para más información podes visitar nuestros',
    },
    {
      tipo: 2,
      titulo: 'Agenda para citas pagas',
      uno: 'Los abogados configurarán la disponibilidad de su agenda a través de rangos horarios.',
      dos: 'Cuando un cliente cree un caso y seleccione la opción de “Agendar Cita”, podrá visualizar tu disponibilidad',
      tres: 'Si el cliente decide agendar una cita con el abogado, primero deberá pagar la consulta dentro de Legalify. (Utilizando Mercado Pago) En esta modalidad, el precio lo pone Legalify',
      cuatro:
        'En ese momento, Legalify le notificará al abogado que tiene una cita agendada y le brindará los datos del cliente para que coordinen el medio por el cual tendrán la consulta',
      paga: 'A fin de mes, Legalify le paga al abogado $1400 por cada consulta realizada. Es por eso que para que los abogados puedan utilizar este servicio, deben vincular su cuenta de Legalify a una cuenta de Mercado Pago para poder recibir los pagos de Legalify. Esto deben hacerlo en la sección de Perfil. Para más información podes visitar nuestros',
    },
    {
      tipo: 3,
      titulo: 'Llamada express',
      uno: 'Los abogados configurarán su disponibilidad para atender llamados a través de rangos horarios.',
      dos: 'Cuando un cliente cree un caso y seleccione la opción de “Llamada Express”, podrá visualizar a los abogados que coincidan con el horarios en el que se está creando el caso',
      tres: 'Si el cliente decide contactar al abogado, primero deberá pagar la consulta dentro de Legalify. (Utilizando Mercado Pago) En esta modalidad, el precio lo pone Legalify',
      cuatro:
        'En ese momento, Legalify le notificará al abogado que tiene una consulta paga y que el cliente lo estára contactando.',
      paga: 'A fin de mes, Legalify le paga al abogado $3000 por cada consulta realizada. Es por eso que para que los abogados puedan utilizar este servicio, deben vincular su cuenta de Legalify a una cuenta de Mercado Pago para poder recibir los pagos de Legalify. Esto deben hacerlo en la sección de Perfil. Para más información podes visitar nuestros',
    },
  ];

  textoEspecialidades = '1 especialidad';
  textoJurisdicciones = '1 jurisdicción';
  constructor(public dialog: MatDialog, public translate: TranslateService) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
    
  }

  ngOnInit(): void {
    let especialidades: number = 0;
    let jurisdicciones: number = 0;
    switch(this.planNombre){
        case "Plan Basico":
            especialidades = 1;
            jurisdicciones = 1;
            break;
        case "Plan Premium":
            especialidades = 3;
            jurisdicciones = 2;
            break;
        case "Plan Corporativo":
            especialidades = 9999;
            jurisdicciones = 9999;
            break;
    }
    this.plan.planVencido = false
    if (especialidades > 1) {
      this.textoEspecialidades = especialidades + ' especialidades';
    }
    if (especialidades > 9998) {
      this.textoEspecialidades = 'Especialidades ilimitadas';
    }
    if (jurisdicciones > 1) {
      this.textoJurisdicciones = jurisdicciones + ' jurisdicciones';
    }
    if (jurisdicciones > 9998) {
      this.textoJurisdicciones = 'Jurisdicciones ilimitadas';
    }

  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  ayuda(tipo: number) {
    this.dialog.open(DialogComponent, {
      width: '600px',
      data: {
        tipo: tipo,
        titulo: this.datos[tipo - 1].titulo,
        uno: this.datos[tipo - 1].uno,
        dos: this.datos[tipo - 1].dos,
        tres: this.datos[tipo - 1].tres,
        cuatro: this.datos[tipo - 1].cuatro,
        paga: this.datos[tipo - 1].paga,
      },
      backdropClass: 'backdropBackground',
    });
  }
}
