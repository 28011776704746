import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PendienteAbogado } from '../../../api/user';
import { ApiService } from '../../../api/api.service';
import { LogOutService } from '../../../services/logOut/log-out.service';
import { UpdateRangesService } from '../../../services/agenda/update-ranges.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css'],
})
export class InicioComponent implements OnInit {
  sinAbogados = false;
  constructor(
    public translate: TranslateService,
    private logOut: LogOutService,
    private _snackBar: MatSnackBar,
    private apiService: ApiService,
    private updateRangesService: UpdateRangesService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang('es-AR');
  }
  displayedColumns: string[] = [
    'nombre',
    'apellido',
    'tipo',
    'dni',
    'correo',
    'pais',
    'jurisdiccion',
    'provinciaResidencia',
    'ciudadResidencia',
    'colegio',
    'tomo',
    'folio',
    'otro',
    'acciones',
  ];
  abogadosData: any;
  cargoLaLista = false;
  id: string;

  ngOnInit(): void {
    this.cargarAbogados();
  }
  abogados: PendienteAbogado[];

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  cargarAbogados() {
    this.abogadosData = [];
    this.apiService.getAbogados('P').subscribe(
      (abogados) => {
        this.abogados = abogados;
        abogados.forEach((abogado) => {
          abogado.datosProfesionales.forEach((dato) => {
            let aux = {
              nombre: '',
              apellido: '',
              mail: '',
              ciudad: '',
              provincia: '',
              paisNombre:'',
              datosProfesionales: {
                colegio: '',
                especialidades: [
                  {
                    id_especialidad: 0,
                    nombre: '',
                  },
                ],
                folio: '',
                localidad: {
                  id_localidad: 0,
                  jurisdiccion: {
                    id_jurisdiccion: 0,
                    nombre: '',
                  },
                  nombre: '',
                },
                numeroDni: '',
                tipoDni: '',
                tomo: '',
                telefono: '',
                estado: '',
              },
              id_usuario: 0,
            };
            aux.id_usuario = abogado.id_usuario;
            aux.mail = abogado.mail;
            aux.paisNombre = abogado.paisNombre;
            aux.nombre = abogado.nombre;
            aux.apellido = abogado.apellido;
            aux.ciudad = abogado.ciudad;
            aux.provincia = abogado.provincia;
            aux.datosProfesionales = dato;
            this.abogadosData.push(aux);
          });
        });
        this.cargoLaLista = true;
        if (abogados.length == 0) {
          this.sinAbogados = true;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  rechazar(dato): void {
    this.cargoLaLista = false;
    let id_datos_profesionales;
    id_datos_profesionales = dato.datosProfesionales.id_datos_profesionales;
    this.apiService.aceptarBajar('B', id_datos_profesionales).subscribe(
      (response) => {
        this.cargarAbogados();
        this.openSnackBar('Se rechazó el abogado.');
      },
      (error) => {
        console.error(error);
        this.openSnackBar(
          'No fue posible rechazar al abogado. Por favor, vuelva a intentarlo.'
        );
      }
    );
  }

  aceptar(dato): void {
    this.cargoLaLista = false;
    let id_datos_profesionales;
    id_datos_profesionales = dato.datosProfesionales.id_datos_profesionales;
    let tags = [dato.datosProfesionales.localidad.jurisdiccion.nombre];
    this.apiService.aceptarBajar('A', id_datos_profesionales).subscribe(
      (response) => {
        //this.updateRangesService.updateTags(dato.id_usuario, tags);
        this.cargarAbogados();
        this.openSnackBar('Se aceptó al abogado');
      },
      (error) => {
        console.error(error);
        this.openSnackBar(
          'No fue posible aceptar al abogado. Por favor, vuelva a intentarlo.'
        );
      }
    );
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 5000 });
  }

  cerrarSesion() {
    this.logOut.cerrarSesion();
  }
}
