<div class="casoFondo">
  <form [formGroup]="casoForm">
    <mat-toolbar class="header">
      <button mat-button (click)="atras()">
        <mat-icon class="icono">chevron_left</mat-icon>
      </button>
      <span class="example-spacer"></span>
      <span>{{ "especialidad.crear" | translate }}</span>
    </mat-toolbar>
    <div class="barraDeProgreso">
      <mat-toolbar class="barraDeProgreso">
        <span class="example-spacer"></span>
        <!-- <span>Selecciona la especialidad del caso</span> -->
        <span>{{ "especialidad.categorizar" | translate }}</span>
      </mat-toolbar>
      <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
    </div>

    <div class="casoCuerpo" (touchmove)="disableScroll()">
      <div class="mobile">
        <mat-grid-list cols="2" rowHeight="1:1" [gutterSize]="'30px'">
          <mat-grid-tile
            *ngFor="let especialidad of especialidades"
            [class]="class"
            [ngClass]="{ selected: especialidad.selected }"
            [value]="especialidad"
            (click)="guardar(especialidad)"
            (mousedown)="down(especialidad)"
          >
            <mat-icon
              svgIcon="ayuda"
              class="icono-ayuda"
              (click)="openDialog(especialidad)"
            ></mat-icon>
            <div class="textoEnGrid">{{ especialidad.nombre }}</div>
            <mat-icon
              svgIcon="{{ especialidad.nombre }}"
              class="icono-derecha-abajo"
            ></mat-icon>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <div class="desktop">
        <mat-grid-list cols="5" rowHeight="2:1" [gutterSize]="'30px'">
          <mat-grid-tile
            tabindex="0"
            *ngFor="let especialidad of especialidades"
            class="casoImpar"
            [value]="especialidad"
            (click)="guardar(especialidad)"
          >
            <mat-icon
              svgIcon="ayuda"
              class="icono-ayuda"
              (click)="openDialog(especialidad)"
            ></mat-icon>
            <span class="textoEnGrid">{{ especialidad.nombre }}</span>
            <mat-icon
              svgIcon="{{ especialidad.nombre }}"
              class="icono-derecha-abajo"
            ></mat-icon>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
      <button
        *ngIf="!permitirContinuar"
        class="boton-continuar"
        [disabled]="!permitirContinuar"
        mat-stroked-button
        color="primary"
        (click)="onSubmit()"
      >
        {{ "especialidad.continuar" | translate }}
      </button>
      <button
        *ngIf="permitirContinuar"
        class="boton-primario"
        [disabled]="!permitirContinuar"
        mat-raised-button
        color="primary"
        (click)="onSubmit()"
      >
        {{ "especialidad.continuar" | translate }}
      </button>
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </form>
</div>
