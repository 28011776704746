<div class="casoFondo">
  <form [formGroup]="casoForm">
    <mat-toolbar class="header">
      <button mat-button (click)="atras($event)">
        <mat-icon class="icono">chevron_left</mat-icon>
      </button>
      <span class="example-spacer"></span>
      <span *ngIf="edit == false">{{ "casoNuevo.nuevo" | translate }}</span>
      <span *ngIf="edit == true">{{ "casoNuevo.editar" | translate }}</span>
    </mat-toolbar>

    <div class="contenedor" style="margin-top: 2rem">
      <div class="mitad">
        <mat-card class="cardPerfil1">
          <mat-form-field appearance="outline" class="inputs">
            <mat-label>{{ "casoNuevo.nombre" | translate }}</mat-label>
            <input
              autofocus
              maxlength="55"
              (keyup)="conteoTitulo()"
              required
              matInput
              type="text"
              placeholder="{{ titulo }}"
              formControlName="titulo"
              [value]="titulo"
            />
            <mat-hint [class]="claseTitulo"
              >{{ "casoNuevo.nombreDesc" | translate }} {{ contadorTitulo }} /
              55</mat-hint
            >
          </mat-form-field>
          <br />
          <mat-form-field appearance="outline" class="inputs">
            <mat-label>{{ "casoNuevo.descripcion" | translate }}</mat-label>
            <textarea
              style="overflow: auto; resize: none; height: 200px"
              maxlength="255"
              (keyup)="conteoDetalles()"
              required
              matInput
              formControlName="detalles"
              placeholder="{{ detalles }}"
            ></textarea>
            <mat-hint [class]="claseDetalles"
              >{{ "casoNuevo.descripcionDesc" | translate }}
              {{ contadorDetalles }} / 255</mat-hint
            >
          </mat-form-field>
          <br /><br /><br />
        </mat-card>
      </div>

      <div class="mitad">
        <mat-card class="cardPerfil">
          <div class="servicio-responsivo">
            <div class="columna1">
              <h2>{{ "casoNuevo.pais" | translate }}*</h2>
              <mat-form-field appearance="outline" class="inputProvincia">
                <mat-select
                  [placeholder]="'casoNuevo.pais' | translate"
                  required
                  formControlName="pais"
                  (selectionChange)="cargarJurisdicciones($event.value)"
                  (selectionChange)="cargarEspecialidades($event.value)"
                >
                  <mat-option
                    *ngFor="let pais of paises"
                    [value]="pais.countryId"
                    >{{ pais.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="columna2"></div>
          </div>
          <div class="servicio-responsivo">
            <div class="columna1">
              <h2>{{ "casoNuevo.provincia" | translate }}*</h2>
              <mat-form-field appearance="outline" class="inputProvincia">
                <mat-select
                  [placeholder]="jurisdiccionSeleccionada"
                  required
                  formControlName="jurisdiccion"
                  (selectionChange)="cargarLocalidades()"
                >
                  <mat-option
                    *ngFor="let jurisdiccion of jurisdicciones"
                    [value]="jurisdiccion"
                    >{{ jurisdiccion.nombre }}
                  </mat-option>
                </mat-select>
                <mat-hint *ngIf="edit == true" style="color: red"
                  >{{ "casoNuevo.provincia" | translate }}
                </mat-hint>
              </mat-form-field>
            </div>

            <div class="columna2">
              <h2>{{ "casoNuevo.localidad" | translate }}*</h2>
              <mat-form-field appearance="outline" class="inputProvincia">
                <mat-select
                  [placeholder]="localidadSeleccionada"
                  required
                  formControlName="localidad"
                >
                  <mat-option
                    *ngFor="let localidad of localidades"
                    [value]="localidad"
                    >{{ localidad.nombre }}</mat-option
                  >
                </mat-select>
                <mat-hint *ngIf="edit == true" style="color: red"
                  >{{ "casoNuevo.localidad" | translate }}
                </mat-hint>
              </mat-form-field>
            </div>
          </div>
        </mat-card>

        <mat-card class="cardPerfil">
          <h3>{{ "casoNuevo.adjuntar" | translate }}</h3>
          <div class="separadorAdjuntar">
            <label for="file-upload">
              <p class="boton-adjuntar">
                {{ "casoNuevo.adjuntarBtn" | translate }}
              </p>
            </label>
            <input
              id="file-upload"
              type="file"
              multiple
              (change)="selectFiles($event)"
            />
          </div>
          <mat-hint class="separadorAdjuntar">{{
            "casoNuevo.adjuntarDesc" | translate
          }}</mat-hint>
          <div *ngFor="let archivo of archivos; let index = index">
            <div class="archivo">
              <mat-icon>description</mat-icon>
              <div>
                <h3 class="izq">
                  {{
                    archivo.nombreoriginal
                      ? archivo.nombreoriginal
                      : archivo.name
                  }}
                </h3>
                <p class="izq">
                  {{ (archivo.size ? archivo.size : archivo.tamanio) / 100000 }}
                  MB
                </p>
              </div>
              <button class="icono-derecha" mat-icon-button>
                <mat-icon
                  class="icono-derecha"
                  svgIcon="borrar"
                  (click)="eliminar(archivo, index)"
                ></mat-icon>
              </button>
            </div>
            <hr color="#D8D8D8" />
          </div>
        </mat-card>
      </div>
    </div>

    <div
      class="contenedor"
      style="padding: 0rem 1.7rem 3rem 1.7rem; gap: 3.1rem"
    >
      <mat-form-field appearance="outline" class="inputs-registro">
        <mat-label>Especialidad</mat-label>
        <mat-select
          formControlName="especialidad"
          (selectionChange)="onEspecialidadChange($event.value)"
        >
          <mat-option
            *ngFor="let especialidad of especialidades"
            [value]="especialidad.id_especialidad"
            >{{ especialidad.nombre }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="inputs-registro">
        <mat-label>Categoría</mat-label>
        <mat-select formControlName="categoria">
          <mat-option
            *ngFor="let categoria of categorias"
            [value]="categoria.id_categoria"
            >{{ categoria.titulo }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <h1 style="text-align: center">
      Selecciona el tipo de servicio que quieres
    </h1>
    <div class="servicio-responsivo">
      <mat-card class="correo">
        <mat-card-content>
          <img src="../../../../../../assets/message.svg" />
          <mat-card-title>{{ "servicio.correo" | translate }}</mat-card-title>
          <p>
            {{ "servicio.correoDescripcion" | translate }}<br />
            {{ "servicio.correoDescripcion1" | translate }}<br />
            {{ "servicio.correoDescripcion2" | translate }} <br />
            {{ "servicio.correoDescripcion3" | translate }}
          </p>
          <br />
          <h2>{{ "servicio.gratis" | translate }}</h2>
          <h6 style="margin-top: -27px; margin-bottom: -9px">
            {{ "servicio.probono" | translate }}
          </h6>
        </mat-card-content>
        <mat-card-actions>
          <button
            class="boton-secundario"
            mat-button
            (click)="navegar(1)"
            [disabled]="!isFormValid()"
          >
            {{ "servicio.revisar" | translate }}
          </button>
        </mat-card-actions>
      </mat-card>

      <mat-card class="cita">
        <mat-card-content>
          <img src="../../../../../../assets/send.svg" />
          <mat-card-title>{{ "servicio.cita" | translate }}</mat-card-title>
          <p>
            <br />
            {{ "servicio.citaDescripcion1" | translate }}<br />{{
              "servicio.citaDescripcion2" | translate
            }}
          </p>
          <br />
          <br />

          <h2>
            {{ "servicio.precioCita1" | translate }}
            <span [innerHtml]="getValueFromCicloPlanAmountUtfCode(planCiclo, 2, 'monedaSimbolo', '36')"></span>
            {{ getValueFromCicloPlanAmount(planCiclo, 2, "monto", costPlan.Schedule)   }}
            {{ "servicio.precioCita2" | translate }}
          </h2>
        </mat-card-content>
        <mat-card-actions>
          <button
            class="boton-secundario"
            mat-button
            (click)="navegar(2)"
            [disabled]="!isFormValid()"
          >
            {{ "servicio.citaAgen" | translate }}
          </button>
        </mat-card-actions>
      </mat-card>

      <mat-card class="llamada">
        <mat-card-content>
          <img src="../../../../../../assets/calling.svg" />
          <mat-card-title>{{ "servicio.llamada" | translate }}</mat-card-title>
          <br />
          <p>
            {{ "servicio.llamadaDescripcion1" | translate }} <br />
            {{ "servicio.llamadaDescripcion2" | translate }}
          </p>
          <br />
          <br />
          <h2>
            {{ "servicio.precioLlamada1" | translate }}
            <span [innerHtml]="getValueFromCicloPlanAmountUtfCode(planCiclo, 3, 'monedaSimbolo', '36')"></span>
            {{ getValueFromCicloPlanAmount(planCiclo, 3, "monto", costPlan.Schedule)   }}
            {{ "servicio.precioLlamada2" | translate }}
          </h2>
        </mat-card-content>
        <mat-card-actions>
          <button
            class="boton-secundario"
            mat-button
            (click)="navegar(3)"
            [disabled]="!isFormValid()"
          >
            {{ "servicio.llamadaAho" | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </form>

  <br /><br /><br />
</div>
