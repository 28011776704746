import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ApiService } from '../../../api/api.service';
import { Planes } from '../../../api/planes';
import { Perfil } from '../../../api/user';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dialogvincular-content-example-dialog',
  templateUrl: 'dialogvincular-content-example-dialog.html',
  styleUrls: ['./dialogvincular.component.css'],
})
export class DialogVincularComponent {
  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
    this.matIconRegistry.addSvgIcon(
      `mercadopago`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/mercadopago.svg'
      )
    );
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  vincular() {
    this.apiService.getLinkAsociacion().subscribe(
      (Response) => {
        window.open(Response.url);
        console.log(Response);
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
