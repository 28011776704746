<div *ngFor="let archivo of archivos">
    <div class="archivo">
        <mat-icon>description</mat-icon>
        <div class="datos">
            <div class="servicio-responsivo">

                <p class="name" *ngIf="archivo.nombreoriginal; else elseNombre" >{{archivo.nombreoriginal}}</p>
                <ng-template #elseNombre>
                    <p class="name" *ngIf="archivo.nombre; else elseName" >{{archivo.nombre}}</p>
                </ng-template>
                <ng-template #elseName>
                    <p class="name" *ngIf="archivo.name" >{{archivo.name}}</p>
                </ng-template>
                <button class="icono-derecha"  mat-icon-button *ngIf="isDownloadable" >
                    <p  class="estrellas" (click)="descargarArchivo(archivo)">VER</p>
                </button>
            </div>
        </div>
    </div>
    <br/>
    <hr color="#D8D8D8" />
</div>