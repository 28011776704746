import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-bienvenida',
  templateUrl: './bienvenida.component.html',
  styleUrls: ['./bienvenida.component.css']
})
export class BienvenidaComponent implements OnInit {

  soyCliente = false;
  posicionInicial: boolean = true;
  posicionFinal: boolean = false;
  textoCliente = [
    {titulo:"Crea un nuevo caso", logo:"cliente1",descripcion:"Escribe el detalle del mismo y elige el medio por el cual quieres contactar a nuestros abogados."},
    {titulo:"Abogados especializados", logo:"cliente2", descripcion:"Te mostraremos sólo aquellos abogados que estén disponibles y que coincidan con las características de tu caso."},
    {titulo:"Elige, contacta y resuelve tu caso",logo:"cliente3", descripcion:"Luego de la consulta, no olvides de calificar al abogado que haya atendido tu caso."},
  ];

  textoAbogado = [
    {titulo:"Atiende casos de tu interés", logo:"abogado1", descripcion:"Recibirás notificaciones por mail cuando haya casos que se relacionen con la especialidad y jurisdicción que hayas seleccionado."},
    {titulo:"Agenda citas con clientes", logo:"abogado2", descripcion:"Elige en que días y horarios estás disponible para que los clientes te agenden consultas pagas. El cliente pagará previamente esos honorarios."},
    {titulo:"Atiende consultas de emergencia", logo:"abogado3", descripcion:"Elige en que días y horarios estás disponible para recibir llamadas de emergencia. El cliente pagará previamente esos honorarios."},
    {titulo:"Edita tu perfil para que conozcan sobre ti", logo:"abogado4", descripcion:"Carga una foto y una breve descripción sobre tu trayectoria. De esta manera tendrás más chances de destacar sobre el resto."}
  ];

  rol:number;

  posicion: number;
  textoMuestra= {
    titulo: "",
    descripcion: "",
    logo:""
  }
  constructor(private router: Router,
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer) {
      this.matIconRegistry.addSvgIcon(
        `cliente1`,
        this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/cliente1.svg')
      );
      this.matIconRegistry.addSvgIcon(
        `cliente2`,
        this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/cliente2.svg')
      );
      this.matIconRegistry.addSvgIcon(
        `cliente3`,
        this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/cliente3.svg')
      );
      this.matIconRegistry.addSvgIcon(
        `abogado1`,
        this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/abogado1.svg')
      );
      this.matIconRegistry.addSvgIcon(
        `abogado2`,
        this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/abogado2.svg')
      );
      this.matIconRegistry.addSvgIcon(
        `abogado3`,
        this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/abogado3.svg')
      );
      this.matIconRegistry.addSvgIcon(
        `abogado4`,
        this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/abogado4.svg')
      );
     }

  navegar() {
    if(history.state.id == 2)
      this.router.navigate(['/oportunidades']);
    else
      this.router.navigate(['/casos-activos/', this.rol]);
  }
  ngOnInit(): void {
    this.rol = history.state.id;
    this.posicion = 0;
    switch(this.rol) {
      case 2:
        this.soyCliente = false;
      break;
      case 3:
        this.soyCliente = true;
      break;
    }
    if(this.soyCliente) {
      this.textoMuestra = this.textoCliente[0];
    } else {
      this.textoMuestra = this.textoAbogado[0];
    }
    
  }
  cambiar(destino: number) {
    const ATRAS = 1;
    const ADELANTE = 2;
    let LONGITUD;
    if(this.soyCliente) {
      LONGITUD = this.textoCliente.length -1;
    } else {
      LONGITUD = this.textoAbogado.length -1;
    }
    switch(destino) {
      case ATRAS: {
        if(this.posicion>0) {
          this.posicion--;
        } else {
          this.posicion = 0;
        }
        break;
      }
      case ADELANTE: {
        if(this.posicion<LONGITUD) {
          this.posicion++;
        } else {
          this.posicion= this.textoCliente.length -1;
        }
        break;
      }
    }
    if(this.soyCliente) {
      this.textoMuestra = this.textoCliente[this.posicion];
      if(this.posicion == 2) {
        this.posicionFinal = true;
      } else {
        this.posicionFinal = false;
      }
    } else {
      this.textoMuestra = this.textoAbogado[this.posicion];
      if(this.posicion == 3) {
        this.posicionFinal = true;
      } else {
        this.posicionFinal = false;
      }
    }
    if(this.posicion == 0) {
      this.posicionInicial = true;
    } else {
      this.posicionInicial = false;
    }
  }

}
