
<div class="contenidoModal">
      <mat-card > 
        <form [formGroup]="entForm">

          <div class="row">

            <div class="col-md-12">
              <div class="tituloCentrado">
                <h3>{{ "registroEntidad.register_title" | translate }}</h3>
            </div>
            </div>

          </div>
          
          <div class="row">

            <div class="col-md-6">
                <mat-form-field appearance="outline" class="inputs">
                    <mat-label>{{ "registroEntidad.nombre" | translate }}</mat-label>
                    <input required matInput placeholder="{{ 'registroEntidad.nombre' | translate }}"
                    formControlName="nombre" >
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="outline" class="inputs">
                    <mat-label>{{ "registroEntidad.descripcion" | translate }}</mat-label>
                    <input required matInput placeholder="{{ 'registroEntidad.descripcion' | translate }}"
                      formControlName="descripcion">
                </mat-form-field>

            </div>

            <div class="col-md-6">

                <mat-form-field appearance="outline" class="inputs">
                    <mat-label>{{ "registroEntidad.telefono" | translate }}</mat-label>
                    <input required matInput placeholder="{{ 'registroEntidad.telefono' | translate }}"
                      formControlName="telefono">
                </mat-form-field>

            </div>

            <div class="col-md-6">

                <mat-form-field appearance="outline" class="inputs">
                    <mat-label>{{ "registroEntidad.url" | translate }}</mat-label>
                    <input required matInput placeholder="{{ 'registroEntidad.url' | translate }}"
                      formControlName="url">
                </mat-form-field>

            </div>

            <div class="col-md-6">

                <mat-form-field appearance="outline" class="inputs">
                    <mat-label>{{ "registroEntidad.codigo" | translate }}</mat-label>
                    <input required matInput placeholder="{{ 'registroEntidad.codigo' | translate }}"
                      formControlName="codigo">
                </mat-form-field>

            </div>

            <div class="col-md-6">
              <mat-form-field appearance="outline" class="inputs">
                  <mat-label>Pais</mat-label>
                  <mat-select formControlName="countryId">
                    <mat-option [value]="null">
                      -- Seleccionar --
                    </mat-option>
                    <mat-option *ngFor="let pais of cacheCountries" [value]="pais.countryId">
                      {{ pais.name }}
                    </mat-option>
                  </mat-select>
              </mat-form-field>
            </div>

          </div>

          <button class="boton-secundario" type="button" mat-button color="primary" (click)="onSubmit()" >{{ "registroEntidad.ingresar" | translate }}</button>
        
          
        </form>
      </mat-card>
  
      
    </div>
  
 