import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CreacionExitosaClienteComponent } from '../creacion-exitosa/creacion-exitosa-cliente.component';
import { RegistroClienteComponent } from '../registro-cliente/registro-cliente.component';
import { CuentaValidadaClienteComponent } from '../cuenta-validada-cliente/cuenta-validada-cliente.component';
import { ExternalComponent } from '../external/external.component';

const routes: Routes = [
  {
    path: 'exito-cliente',
    component: CreacionExitosaClienteComponent,
  },
  {
    path: 'registro-cliente',
    component: RegistroClienteComponent,
  },
  {
    path: 'registro-cliente/:tipo',
    component: RegistroClienteComponent,
  },
  {
    path: 'validada-cliente/:id/:lang',
    component: CuentaValidadaClienteComponent,
  },
  {
    path: 'account',
    component: ExternalComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class ClienteRegistroRoutingModule {}
