import { Component, Inject, OnInit } from '@angular/core';
import { PlanService } from '../../../../services/plan/plan.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DatosFacturacion } from '../../../../api/planes';
import { ApiService } from '../../../../api/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PlanMultipagoService } from 'src/app/services/plan/plan-multipago.service';
import { PaisConfig, PAISCONFIG_KEY_CUIT } from 'src/app/api/pais';

@Component({
  selector: 'app-popup-facturacion',
  templateUrl: './popup-facturacion.component.html',
  styleUrls: ['./popup-facturacion.component.css'],
})
export class PopupFacturacionComponent implements OnInit {
  facturacionForm = new UntypedFormGroup({
    cuit: new UntypedFormControl('', [Validators.pattern(/^[1-9]\d{10}$/i)]),
    direccion: new UntypedFormControl('', [Validators.pattern(/^[a-z\d\_\s]+$/i)]),
    codigoPostal: new UntypedFormControl('', [Validators.max(99999999)]),
  });

  cuitOldVal: string = "";

  cuit: string = "";
  direccion: string = "";
  codigopostal: string = "";

  paisConfigCuit: PaisConfig;

  countryId: number;

  constructor(
    private planService: PlanService,
    public dialog: MatDialog,
    private apiService: ApiService,
    private servicePlanMultipago: PlanMultipagoService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private dialogRef: MatDialogRef<PopupFacturacionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.getSuscripcion()
    this.doPaisConfigCuitLoad();
  }

  getSuscripcion(){
    this.planService.getClientStatus().subscribe( res => {
      if(res.mensaje == "OK"){
        this.cuit = res.cuit;
        this.codigopostal = res.codigoPostal;
        this.direccion = res.direccion;
      }
    }, err => {
      console.log(err)
    })
  }

  doPaisConfigCuitLoad(): void {
    let countryId = JSON.parse(localStorage.getItem('user')).pais;

    this.apiService.getPaisConfig(countryId, PAISCONFIG_KEY_CUIT).subscribe({
      next: (paisConfigCuit: PaisConfig) => {
        console.log(`CUIT configuracion: ${countryId} - ${PAISCONFIG_KEY_CUIT}`);
        console.log(paisConfigCuit);
        this.setPaisConfigCuit(paisConfigCuit);
      }
    });
  }

  setPaisConfigCuit(cuit: PaisConfig): void {
    let validators = [];
    let controlCuit: AbstractControl;

    if (cuit && cuit.bvisible) {
      if (cuit.bobligatorio)
        validators.push(Validators.required);
      if (cuit.svalor01)
        validators.push(Validators.pattern(cuit.svalor01));
    }

    controlCuit = this.facturacionForm.get("cuit");
    controlCuit.setValidators(validators);
    controlCuit.updateValueAndValidity();
    this.paisConfigCuit = cuit;
  }

  async onSubmit() {
    const { cuit, direccion, codigoPostal } = this.facturacionForm.getRawValue();
    let datos: DatosFacturacion = {
      cuit,
      direccion,
      codigoPostal
    };
    this.servicePlanMultipago.doAbogadoFacturaionUpdate(datos).subscribe(
      (respuesta) => {
        /*if(respuesta.mensaje == "OK"){
          this.router.navigate(["/plan"])
        }*/

        this.router.navigate(["/plan"])
        this.openSnackBar('Los datos se actualizaron con éxito');
      },
      (error) => {
        this.openSnackBar(
          'Falló la actualización de tus datos de facturación. Por favor, intente nuevamente más tarde.'
        );
        console.error(error);
      }
    );
  }

  doCuitFix(event): void {
    if (this.paisConfigCuit) {
      let cuitValue = this.facturacionForm.getRawValue().cuit;
      if (cuitValue.match(this.paisConfigCuit.svalor02)) {
        this.cuitOldVal = cuitValue;
      } else {
        this.facturacionForm.patchValue({
          cuit: this.cuitOldVal
        });
      }
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 5000 });
  }
}
