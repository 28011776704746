import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api/api.service';
import { Planes } from 'src/app/api/planes';

const CMS_STATUS_SAVE: number = 1;
const CMS_STATUS_DELETE: number = 2;

@Component({
  selector: 'app-plan-list',
  templateUrl: './plan-list.component.html',
  styleUrls: ['./plan-list.component.css']
})
export class PlanListComponent implements OnInit {

  CMS_STATUS_SAVE = CMS_STATUS_SAVE;
  CMS_STATUS_DELETE = CMS_STATUS_DELETE;

  statusCommand: number = 0;
  statusPlanId: number = 0;

  plan: Planes;
  cachePlanes: Planes[];

  formPlan: FormGroup = new FormGroup({
    id_plan: new FormControl<number>(0, []),
    nombre: new FormControl<string>('', [Validators.required]),
    planTipoId: new FormControl<number>(1, [Validators.required]),
    jurisdicciones: new FormControl<number>(0, [Validators.required]),
    especialidades: new FormControl<number>(0, [Validators.required]),
    agenda: new FormControl<boolean>(false, [Validators.required]),
    llamada: new FormControl<boolean>(false, [Validators.required]),
    cantidadLegalify: new FormControl<number>(0, [Validators.required]),
    costo: new FormControl<number>(0, [Validators.required]),
    costoAnual: new FormControl<number>(0, [Validators.required]),
  });

  constructor(
    private serviceApi: ApiService, 
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.doPlanLoad();
  }

  getSubTitle(): string {
    return this.plan ? "Editar" : "Lista";
  }

  doPlanLoad(): void {
    this.statusCommand = 0;
    this.statusPlanId = null;
    this.plan = null;
    this.serviceApi.getPlanesAll().subscribe({
      next: (planes: Planes[]) => {
        this.cachePlanes = planes;
        console.log("Planes...");
        console.log(planes);
      }
    });
  }

  doPlanNew(): void {
    let plan: Planes = {
      id_plan: 0,
      nombre: "",
      descripcion: '',
      estado: '1',
      planTipoId: 1,
      jurisdicciones: 1,
      especialidades: 1,
      agenda: false,
      llamada: false,
      cantidadLegalify: 0,
      costo: 0,
      costoAnual: 0,
      cantidadEspecialidades: 0,
      cantidadJurisdicciones: 0,
      visible: true,
      planVencido: false,
      planActivo: true,
    };

    this.doPlanSelect(plan);
  }

  doPlanSelect(plan: Planes): void {
    this.statusCommand = 0;
    this.statusPlanId = null;
    this.plan = plan;
    if (plan) {
      this.formPlan.patchValue({
        id_plan: plan.id_plan,
        nombre: plan.nombre,
        planTipoId: plan.planTipoId,
        jurisdicciones: plan.jurisdicciones,
        especialidades: plan.especialidades,
        agenda: plan.agenda,
        llamada: plan.llamada,
        cantidadLegalify: plan.cantidadLegalify,
        costo: plan.costo,
        costoAnual: plan.costoAnual,
      });
    }
  }

  doSave(): void {
    let formPlan: Planes = this.formPlan.getRawValue();
    let plan: Planes = {} as Planes;
    plan = Object.assign(plan, {
      id_plan: formPlan.id_plan,
      nombre: formPlan.nombre,
      planTipoId: formPlan.planTipoId,
      jurisdicciones: formPlan.jurisdicciones,
      especialidades: formPlan.especialidades,
      agenda: formPlan.agenda,
      llamada: formPlan.llamada,
      cantidadLegalify: formPlan.cantidadLegalify,
      costo: formPlan.costo,
      costoAnual: formPlan.costoAnual,
    });

    this.serviceApi.doPlanesSave(plan).subscribe({
      next: (result: Planes) => {
        this.doPlanLoad();
      },
      error: (err: any) => {
        if (err.error && err.error.message) {
          this.openSnackBar(
            err.error.message
          );
        }
        console.log(err);
      }
    });
  }

  doDelete(planId: number): void {
    this.serviceApi.doPlanesDelete(planId).subscribe({
      next: (plan: Planes) => {
        this.doPlanLoad();
      },
      error: (err: any) => {
        if (err.error && err.error.message) {
          this.openSnackBar(
            err.error.message
          );
        }
        console.log(err);
      }
    })
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 5000 });
  }

}
