import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../../../api/api.service';
import { Perfil } from '../../../../../../api/user';
import { StorageService } from '../../../../../../utils/storage/storage.service';
// import { TiposCasoCode } from 'src/app/enums/TiposCasoCode';
import { TiposCasoCode } from '../../../../../../enums/TiposCasoCode';
// import { FormatDateService } from 'src/app/utils/formatDate/formatDate.service.';
import { FormatDateService } from '../../../../../../utils/formatDate/formatDate.service.';
import { FilesService } from '../../../../../../utils/files.service';
import { CurrentTimeService } from '../../../../../../utils/currentTime/current-time.service';
import { PlanMultipagoService } from 'src/app/services/plan/plan-multipago.service';
import { BaseSnackBar } from 'src/app/components/admin/multipago/modules/multipago.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-agendada',
  templateUrl: './agendada.component.html',
  styleUrls: ['./agendada.component.css'],
})
export class AgendadaComponent extends BaseSnackBar implements OnInit {
  url = '';
  titulo = '';
  tituloHeader = '';
  nombre = '';
  apellido = '';
  mostrarCita = false;
  mostrarSeleccion = false;
  abogado: any;
  caso: any;
  perfil: Perfil;
  id_caso: number;
  invitadoId: number;
  fecha: any;
  fechaCompletaString: string;
  fechaCompletaDate: Date;
  hora: any;
  citaId: number;

  formatCulture: string = 'full';
  culture: string = 'es-AR';
  formatDateBackend: string = 'yyyy-MM-ddTHH:mm:ss';

  externalReference: string;

  casoAceptado: boolean = false;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private servicePlanMultipago: PlanMultipagoService,
    private _location: Location,
    private formatDateService: FormatDateService,
    private fileSrv: FilesService,
    private currentTimeSrv: CurrentTimeService,
    private _snackBar: MatSnackBar
  ) {
    super(_snackBar);

    this.url =
      this.router.getCurrentNavigation().extractedUrl.root.children.primary.segments[0].path;
    if (
      this.router.getCurrentNavigation().extras.state &&
      this.router.getCurrentNavigation().extras.state.titulo
    ) {
      this.titulo = this.router.getCurrentNavigation().extras.state.titulo;
    }
    if (this.url === 'agendada') {
      this.titulo = 'Cita agendada con éxito';
      this.tituloHeader = 'Agendar cita';
      this.mostrarSeleccion = false;
      this.mostrarCita = true;
    }
    if (this.url === 'agendadamod') {
      this.titulo = 'Cita agendada con éxito';
      this.tituloHeader = 'Agendar cita';
      this.mostrarSeleccion = false;
      this.mostrarCita = true;
      this.casoAceptado = true;
    }
    if (this.url === 'seleccionado') {
      let id_usuario =
        this.router.getCurrentNavigation().extras.state.id_usuario;
      let id_caso = this.router.getCurrentNavigation().extras.state.id_caso;

      this.casoAceptado = true;
      this.apiService.aceptarAbogado(id_usuario, id_caso).subscribe(
        (respuesta) => {},
        (error) => {
          console.error(error);
        }
      );

      this.titulo = 'Selección de abogado';
      this.tituloHeader = 'Detalle del caso';
      this.nombre = this.router.getCurrentNavigation().extras.state.nombre;
      this.apellido = this.router.getCurrentNavigation().extras.state.apellido;
      this.mostrarSeleccion = true;
      this.mostrarCita = false;
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe({
      next: (params) => {
        let externalReference: string =
          localStorage.getItem('externalReference');
        if (!externalReference || externalReference == 'null') {
          if (params?.token && params?.PayerID)
            externalReference = params.token;
          else
            externalReference = params['external_reference'];
        }

        if (this.casoAceptado) {
          this.getMiPerfil();
        } else {
          this.servicePlanMultipago
            .doClienteValidarPago(externalReference)
            .subscribe({
              next: () => {
                this.externalReference = externalReference;
                this.getMiPerfil();
              },
              error: (err) => {
                this.doShowErrorSnackBar(err);
                this.router.navigate(['/pago/fallo']);
              },
            });
        }
      },
    });
  }

  completarDatos() {
    this.abogado = JSON.parse(localStorage.getItem('abogado'));
    this.caso = JSON.parse(localStorage.getItem('caso'));
    this.fechaCompletaDate = new Date(
      this.storageService.getItem('fechaCompleta')
    );
    this.fechaCompletaString = this.storageService.getItem('fechaCompleta');

    let aux = JSON.parse(localStorage.getItem('respuestaConvertida'));
    this.fechaCompletaDate = new Date(aux);
    this.fechaCompletaString =
      this.formatDateService.convertDateToFormatSpecifc(
        this.formatCulture,
        this.fechaCompletaDate,
        this.culture
      );
    this.hora = this.formatDateService.convertDateToFormatSpecifc(
      'HH:mm',
      this.fechaCompletaDate,
      this.culture
    );
    this.fecha =
      this.fechaCompletaDate.getDate() +
      '/' +
      (this.fechaCompletaDate.getMonth() + 1);

    this.caso.archivosSeleccionados = JSON.parse(
      localStorage.getItem('archivos')
    );
    this.caso.profesionalAsignadoId = this.abogado.id_usuario;
    this.nombre = this.abogado.nombre;
    this.apellido = this.abogado.apellido;
    this.hora = this.formatDateService.convertDateToFormatSpecifc(
      'HH:mm',
      this.fechaCompletaDate,
      this.culture
    );
    this.agendarCita();
  }

  getMiPerfil() {
    this.apiService.getPerfil().subscribe(
      (perfil) => {
        this.invitadoId = perfil.id_usuario;
        this.completarDatos();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  agendarCita() {
    let datos = {
      fecha: this.formatDateService.convertDateToFormatSpecifc(
        this.formatDateBackend,
        this.fechaCompletaDate,
        this.culture
      ),
      invitadoId: this.invitadoId,
      usuarioId: this.caso.profesionalAsignadoId,
    };
    let caso = localStorage.getItem("caso-edit");
    if(caso!=null && caso!=undefined){
      this.actualizarCaso();
    }
    else{
      this.apiService.agendarCita(datos).subscribe(
        (respuesta) => {
          this.citaId = respuesta.id;
          this.crearCaso();
          
        },
        (error) => {
          console.error(error);
        }
      );
      
    }

    
  
  }

  aceptarAbogado() {
    this.apiService
      .aceptarAbogado(this.abogado.id_usuario, this.id_caso)
      .subscribe(
        (respuesta) => {},
        (error) => {
          console.error(error);
        }
      );
  }

  crearCaso() {
    let timeStamp = this.currentTimeSrv.getTimeStamp();
    let timeZone = '';
    this.abogado = JSON.parse(localStorage.getItem('abogado'));
    this.caso = JSON.parse(localStorage.getItem('caso'));
    // let archivos = JSON.parse(localStorage.getItem('archivos'));
    //   if(archivos) {
    //     for (let a of archivos) {
    //       const { file, name } = a;
    //       const archivoFinal = this.fileSrv.fromBase64ToFile(file, name);
    //       this.caso.archivosSeleccionados.push(archivoFinal);
    //     }
    //   }
    this.caso.profesionalAsignadoId = this.abogado.id_usuario;
    this.apiService.getCountry().subscribe(
      (res) => {
        timeZone = res.countryCode;
        this.postCaso(timeStamp, timeZone);
      },
      (error) => {
        console.error(error);
        timeZone = this.currentTimeSrv.getTimezoneHours();
        this.postCaso(timeStamp, timeZone);
      }
    );
  }

  actualizarCaso() {
    let timeStamp = this.currentTimeSrv.getTimeStamp();
    let timeZone = '';
    this.abogado = JSON.parse(localStorage.getItem('abogado'));
    this.caso = JSON.parse(localStorage.getItem('caso'));
    let caso_edit= JSON.parse(localStorage.getItem('caso-edit'));
    // let archivos = JSON.parse(localStorage.getItem('archivos'));
    //   if(archivos) {
    //     for (let a of archivos) {
    //       const { file, name } = a;
    //       const archivoFinal = this.fileSrv.fromBase64ToFile(file, name);
    //       this.caso.archivosSeleccionados.push(archivoFinal);
    //     }
    //   }
    this.caso.profesionalAsignadoId = this.abogado.id_usuario;
    this.apiService.getCountry().subscribe(
      (res) => {
        timeZone = res.countryCode;
        this.putCaso(timeStamp, timeZone,caso_edit);
      },
      (error) => {
        console.error(error);
        timeZone = this.currentTimeSrv.getTimezoneHours();
        this.putCaso(timeStamp, timeZone,caso_edit);
      }
    );
  }

  putCaso(timeStamp, timeZone,caso) {
    this.apiService
      .putCasoAgenda(
        caso.id_caso,
        this.caso.categoria.id_categoria,
        this.caso.detalles,
        this.caso.archivosSeleccionados,
        this.caso.localidad.id_localidad,
        this.caso.titulo,
        this.caso.profesionalAsignadoId,
        TiposCasoCode.AGENDA,
        this.citaId,
        timeStamp,
        timeZone
      )
      .subscribe(
        (respuesta) => {
          if (this.externalReference) {
            this.servicePlanMultipago
              .doClienteCasoPago(this.externalReference, respuesta.id_caso)
              .subscribe({
                complete: () => {
                  console.log('servicePlanMultipago.doClienteCasoPago');
                },
              });
          }

          this.id_caso = respuesta.id_caso;
          this.aceptarAbogado;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  postCaso(timeStamp, timeZone) {
    this.apiService
      .postCasoAgenda(
        this.caso.categoria.id_categoria,
        this.caso.detalles,
        this.caso.archivosSeleccionados,
        this.caso.localidad.id_localidad,
        this.caso.titulo,
        this.caso.profesionalAsignadoId,
        TiposCasoCode.AGENDA,
        this.citaId,
        timeStamp,
        timeZone
      )
      .subscribe(
        (respuesta) => {
          if (this.externalReference) {
            this.servicePlanMultipago
              .doClienteCasoPago(this.externalReference, respuesta.id_caso)
              .subscribe({
                complete: () => {
                  console.log('servicePlanMultipago.doClienteCasoPago');
                },
              });
          }

          this.id_caso = respuesta.id_caso;
          this.aceptarAbogado;
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
