<div class="cargaPaises">
  <mat-toolbar class="header">
    <span class="example-spacer"></span>
    <span>{{ "paises.admin" | translate }}</span>

    <br />
    <button class="cerrarSesionAdmin" mat-button (click)="cerrarSesion()">
      <mat-icon class="icono">logout</mat-icon>
    </button>
  </mat-toolbar>

  <br />

  <app-navbar-admin></app-navbar-admin>
  <br />

  <br />
  <!-- <div>
    <label
      >Pais: <input #nombrePais />
      #nombrePais me permite enviar los escrito en la etiqueta input al componente
    </label>
     (click) passes input value to add() and then clears the input
    <button (click)="add(heroName.value); heroName.value = ''">add</button>
  </div> -->

  <!-- <div class="PaisesCont">
    <mat-card class="cardPaises">
      <select class="paises" (change)="onSelected($event.target.value)">
        <option *ngFor="let pais of paises" value="{{ pais.countryId }}">
          <span class="badge">{{ pais.countryId }}</span> {{ pais.name }}
        </option>
      </select>
    </mat-card>

    <mat-card class="cardProvincias">
      <select class="provincias">
        <option *ngFor="let provincia of provincias">
          <span class="badge">{{ provincia.name }}</span>
        </option>
      </select>
    </mat-card>
  </div> -->
  <br />
  <div class="example-action-buttons">
    <button mat-button (click)="accordion.openAll()">
      {{ "paises.abrir" | translate }}
    </button>
    <button mat-button (click)="accordion.closeAll()">
      {{ "paises.cerrar" | translate }}
    </button>
  </div>
  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> {{ "paises.paises" | translate }} </mat-panel-title>
        <mat-panel-description>
          {{ "paises.agPaTe" | translate }}
          <mat-icon>flag</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <table class="example-full-width" cellspacing="0">
        <tr>
          <td>
            <mat-form-field class="num" appearance="outline">
              <mat-label>Numero pais</mat-label>
              <input #codPais matInput placeholder="Numero pais" value="" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ "paises.agPa" | translate }}</mat-label>
              <input
                #nomPais
                matInput
                value=""
                placeholder="{{ 'paises.cod' | translate }}"
              />
            </mat-form-field>
            &nbsp;
            <button mat-raised-button (click)="onClick()">
              {{ "paises.agregar" | translate }}
            </button>
          </td>
          <td>
            <mat-form-field appearance="outline">
              <mat-label>{{ "paises.paises" | translate }}</mat-label>
              <mat-select
                class="paisesMat"
                (selectionChange)="onSelected($event.value)"
                (selectionChange)="selectCod($event.value)"
              >
                <mat-option
                  *ngFor="let pais of paises"
                  value="{{ pais.countryId }}"
                  ><span class="badge">{{ pais.countryId }}</span>
                  {{ pais.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td>
            <mat-checkbox class="example-margin" 
              *ngIf="numPaisSeleccionado > 0"
              color="primary"
              #adminPais
              (change)="onChangeAdminPais()"
            >Administrar</mat-checkbox>
          </td>
        </tr>
      </table>

      <br>
      <br>
      <div style="text-align: right;" *ngIf="matriculaConfig != null">
        <button mat-flat-button color="primary" type="button"
        (click)="saveMatriculaConfig()"
      >
      {{ "tooltip.guardar" | translate }}
      </button>
      </div>
      
      <table class="example-full-width" cellspacing="0" *ngIf="matriculaConfig != null">
        <tr>
          <th>
            {{ "paises.idMatricula" | translate }}
          </th>
          <th>
            {{ "paises.nombre" | translate }}
          </th>
          <th>
            {{ "paises.obligatorio" | translate }}
          </th>
          <th>
            {{ "paises.visible" | translate }}
          </th>
          <th>
            {{ "paises.tamanio" | translate }}
          </th>
          <th>
            Email CASO
          </th>
        </tr>
        <tr>
          <th>
           {{matriculaConfig.id_matriculaconfig}}
          </th>
          <th>
              <input
                matInput
                [(ngModel)]="matriculaConfig.nombre"
                value=""
                placeholder=""
                type="text"
              />
          </th>
          <th>
            <input
                [(ngModel)]="matriculaConfig.solicitarCampo"
                type="checkbox"
              />
          </th>
          <th>
            <input
                [(ngModel)]="matriculaConfig.visible"
                type="checkbox"
              />
          </th>
          <th>
            <input
                matInput
                [(ngModel)]="matriculaConfig.tamanio"
                value=""
                placeholder=""
                type="number"
              />
          </th>
          <th>
            <input
              matInput
              [(ngModel)]="matriculaConfig.admEmailCaso"
              placeholder=""
              type="email"
            />
          </th>
        </tr>
      </table>

      <br />
      <form [formGroup]="formCuit" *ngIf="isPaisConfig()">
        <table class="example-full-width" cellspacing="0" *ngIf="matriculaConfig != null">
          <thead>
            <tr>
              <th>
              </th>
              <th>
                Nombre
              </th>
              <th>
                {{ "paises.obligatorio" | translate }}
              </th>
              <th>
                {{ "paises.visible" | translate }}
              </th>
              <th>
                Nombre
              </th>
              <th>
                RegEx Validacion
              </th>
              <th>
                RegEx Entrada
              </th>
              <th>
                RegEx Descripcion
              </th>
              <th>
                Plantilla
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <button mat-flat-button color="primary" type="button"
                  (click)="doPaisConfigSave()"
                >
                  {{ "tooltip.guardar" | translate }}
                </button>
              </td>
              <td>
                CUIT Validacion
              </td>
              <td class="perfil">
                <input
                  formControlName="bobligatorio" type="checkbox"
                  />
                </td>
                <td class="perfil">
                  <input
                  formControlName="bvisible" type="checkbox"
                  (change)="doPaisConfigVisibleOnChange()"
                />
              </td>
              <td>
                <mat-form-field appearance="legacy" class="input-fullwidth">
                  <input
                    matInput
                    formControlName="sdesc"
                    placeholder=""
                  />
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="legacy" class="input-fullwidth">
                  <input
                    matInput
                    formControlName="svalor01"
                    placeholder=""
                  />
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="legacy" class="input-fullwidth">
                  <input
                    matInput
                    formControlName="svalor02"
                    placeholder=""
                  />
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="legacy" class="input-fullwidth">
                  <input
                    matInput
                    formControlName="svalordesc"
                    placeholder=""
                  />
                </mat-form-field>
              </td>
              <td>
                <mat-select
                  (selectionChange)="doPaisConfigRegEx($event.value)"
                >
                  <mat-option
                    *ngFor="let regEx of templateRegExs"
                    value="{{ regEx.desc }}"
                  >
                    {{ regEx.desc }}
                  </mat-option>
                </mat-select>
              </td>
            </tr>
          </tbody>
        </table>
      </form>

    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ "paises.jurisdiccion" | translate }}
        </mat-panel-title>
        <mat-panel-description>
          {{ "paises.agJurTe" | translate }}
          <mat-icon>map</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <table class="example-full-width" cellspacing="0">
        <tr>
          <td>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>{{ "paises.agJur" | translate }}</mat-label>
              <input matInput #nomJur value="" placeholder="localidad" />
            </mat-form-field>
            &nbsp;
            <button mat-raised-button (click)="onClickJur()">
              {{ "paises.agregar" | translate }}
            </button>
            <br />
            <input
              (change)="fileChangeListener($event)"
              #fileImportInput
              accept=".csv"
              id="file-upload"
              type="file"
              class="input-file"
              hidden
            />
            <label class="file-uploadlbl" for="file-upload"
              >Seleccionar un archivo</label
            >
            <span id="file-chosen">Archivo seleccionado</span>

            <!-- <div class="file-upload">
              <input
                class="file-upload__input"
                type="file"
                name="myFile"
                (change)="fileChangeListener($event)"
                #fileImportInput
                accept=".csv"
              />
              <button
                class="file-upload__button"
                type="button"
                (click)="onClickJurArc()"
              >
                Elegir Archivo
              </button>
              <span class="file-upload__label">Archivo seleccionado</span>
            </div> -->

            <!-- <div class="file-upload">
              {{ fileName || "No file uploaded yet." }}

              <button
                mat-mini-fab
                color="primary"
                class="upload-btn"
                (click)="fileUpload($event)"
              >
                <mat-icon>attach_file</mat-icon>
              </button>
            </div> -->
          </td>
          <td>
            <mat-form-field appearance="outline">
              <mat-label>{{ "paises.jurisdiccion" | translate }}</mat-label>
              <mat-select
                class="provinciaMat"
                (selectionChange)="onSelectedJur($event.value)"
                (selectionChange)="selectCodJur($event.value)"
              >
                <mat-option
                  *ngFor="let jurisdiccionInput of jurisdiccionesInput"
                  value="{{ jurisdiccionInput.id_jurisdiccion }}"
                  class="jurMat"
                  ><span class="badge"
                    >{{ jurisdiccionInput.id_jurisdiccion }}
                    {{ jurisdiccionInput.nombre }}</span
                  >
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
      </table>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> {{ "paises.localidad" | translate }}</mat-panel-title>
        <mat-panel-description>
          {{ "paises.agLocTe" | translate }}
          <mat-icon>language</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <table class="example-full-width" cellspacing="0">
        <tr>
          <td>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>{{ "paises.agPro" | translate }}</mat-label>
              <input matInput #nomLoc value="" placeholder="localidad" />
            </mat-form-field>
            &nbsp;
            <button mat-raised-button (click)="onClickLoc()">
              {{ "paises.agregar" | translate }}
            </button>
            <br />
            <input
              (change)="fileChangeListenerLoc($event)"
              #fileImportInput
              accept=".csv"
              id="file-uploadLoc"
              type="file"
              class="input-file"
              hidden
            />
            <label class="file-uploadlbl" for="file-uploadLoc"
              >Seleccionar un archivo</label
            >
            <span id="file-chosenLoc">Archivo seleccionado</span>
          </td>
          <td>
            <mat-form-field appearance="outline">
              <mat-label>{{ "paises.localidad" | translate }}</mat-label>
              <mat-select class="juridiccionesMat">
                <mat-option
                  *ngFor="let localidad of localidades"
                  value="{{ localidad.localidadId }}"
                  ><span class="badge">{{ localidad.nombre }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
      </table>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- <select class="localidades">
    <option *ngFor="let localidad of localidades">
      <span class="badge">{{ localidad.name }}</span>
    </option>
  </select> -->

  <!-- <ul class="paises">
    <li *ngFor="let pais of paises">
      <span class="badge">{{ pais.countryId }}</span> {{ pais.name }}
      <button class="delete" title="delete hero" (click)="delete(hero)">
        x
      </button>
    </li>
  </ul> -->
</div>
