import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SpinnerServiceService } from './services/spinner-service/spinner-service.service';
import { filter } from 'rxjs/operators'
import { environment } from 'src/environments/environment.prod'
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  cargando: boolean = false;
  title = 'legalifyFrontend';

  constructor(private spinnerServiceService: SpinnerServiceService,private routertrack:Router) {
    this.initGoogleAnalytics();
  }

  ngOnInit() {
    this.spinnerServiceService.showSpinner$.subscribe(display=> {
      setTimeout(() => {
        this.cargando = display; 
      });
    });
  }

  initGoogleAnalytics(){
    let navEvents = this.routertrack.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEvents.subscribe((event:NavigationEnd) => {
      gtag('config', environment.measurement_id,{
        'page_title' : event.urlAfterRedirects,
        'page_path':event.urlAfterRedirects
      });
    }
    );
  }
}
