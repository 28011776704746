<!-- <mat-sidenav-container fullscreen>
  <mat-sidenav #sidenav>
    <mat-nav-list (click)="sidenav.close()">
      <a mat-list-item routerLink="/">
        <mat-icon>library_books</mat-icon>
        <span>matricula</span>
      </a>

      <a mat-list-item routerLink="/paises">
        <mat-icon>library_books</mat-icon>
        <span>Paises</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-toolbar color="primary">
    <button mat-icon-button (Click)="sidenav.open()">
      <mat-icon>menu</mat-icon>
    </button>
  </mat-toolbar>

  <router-outlet></router-outlet>
</mat-sidenav-container> -->

<!-- <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Navbar</a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Inicio</a>
        </li>
        <li class="nav-item">
          <a routerLink="/paises">Paises</a>
        </li>
      </ul>
    </div>
  </div>
</nav> -->

<div class="topnav">
  <a class="active" routerLink="/admin">{{
    "navbarAdmin.inicio" | translate
  }}</a>
  <a routerLink="/paises">{{ "navbarAdmin.paises" | translate }}</a>
  <a routerLink="/data-admin">{{ "navbarAdmin.data" | translate }}</a>
  <a routerLink="/entidades">{{ "navbarAdmin.entidades" | translate }}</a>
  <a routerLink="/certificacion">Certificacion</a>
  <a mat-button [matMenuTriggerFor]="menu">Multipagos</a>
  <mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/admin/plan">Planes</button>
    <button mat-menu-item routerLink="/admin/multipago/plataforma/moneda">
      Plataforma x Moneda
    </button>
    <hr />
    <button
      mat-menu-item
      routerLink="/admin/multipago/plataforma/suscripciones"
    >
      Suscripciones
    </button>
    <button mat-menu-item routerLink="/admin/multipago/especialidades">
      Especialidades x Pais
    </button>
    <hr />
    <button mat-menu-item routerLink="/admin/multipago/plan/pais">
      Plan x Pais
    </button>
    <button mat-menu-item routerLink="/admin/multipago/plan/moneda">
      Plan x Moneda
    </button>
    <button mat-menu-item routerLink="/admin/multipago/plan/plataforma">
      Plan x Plataforma
    </button>
    <hr />
  </mat-menu>
  <!--
  -->
</div>

<br />
