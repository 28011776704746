<div class="separadorCentrado">
    <img class="logo" src="../../../../assets/logo.jpg">
    <img class="logo" src="../../../../assets/verificando.jpg">
    <h1>{{ 'recuperar.reenvio' | translate }}</h1>
    <mat-label>{{ 'recuperar.solicitar' | translate }}</mat-label>

    <form [formGroup]="userForm">

        <mat-form-field appearance="outline" class="inputs">
            <mat-label>{{ 'recuperar.correoRecup' | translate }}</mat-label>
            <input required matInput placeholder="Correo" formControlName="mail">

        </mat-form-field>

    </form>

    
    <div >
      <button class="boton-continuar" mat-button color="primary" (click)="resend()">{{ 'recuperar.btn' | translate }}</button>
    </div>
</div>
