import { Respuesta } from "./respuesta";

export class Certificacion {
    mail: string;
    title: string;
    doc: string;
    respuestas : Respuesta[];

    constructor(mail,title,doc,respuestas){
        this.mail=mail;
        this.title=title;
        this.doc=doc;
        this.respuestas=respuestas;
    }
    
}
  
  