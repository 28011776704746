import { FormControl } from "@angular/forms";

export interface Pais {
  countryId: number;
  name: string
}

export interface PaisConfig {
  countryConfigId: number;
  countryId: number;
  countryName: string;
	bobligatorio: boolean;
	bvisible: boolean;
  sclave: string;
  sdesc: string;
  svalor01: string;
  svalor02: string;
  svalordesc: string;
}

export interface IFormPaisConfig {
  countryConfigId: FormControl<number>;
  countryId?: FormControl<number>;
  countryName?: FormControl<string>;
	bobligatorio?: FormControl<boolean>;
	bvisible?: FormControl<boolean>;
  sclave?: FormControl<string>;
  sdesc?: FormControl<string>;
  svalor01?: FormControl<string>;
  svalor02?: FormControl<string>;
  svalordesc?: FormControl<string>;
}

export interface REGEX_TEMPLATE {
  desc: string,
  regexVal: string,
  regexInput: string
}

export const REGEX_PLANTILLA = [{
  desc: '5 Digitos',
  regexVal: '^[1-9]\\d{4}$',
  regexInput: '^([1-9]\\d{0,4})?$'
}, {
  desc: '8 Digitos',
  regexVal: '^[1-9]\\d{7}$',
  regexInput: '^([1-9]\\d{0,7})?$'
}, {
  desc: '8 Digitos + 1 letra',
  regexVal: '^[1-9]\\d{7}[A-Z]$',
  regexInput: '^([1-9]\\d{0,7}[A-Z]?)?$'
}, {
  desc: '9 Digitos',
  regexVal: '^[1-9]\\d{8}$',
  regexInput: '^([1-9]\\d{0,8})?$'
}, {
  desc: '11 Digitos',
  regexVal: '^[1-9]\\d{10}$',
  regexInput: '^([1-9]\\d{0,10})?$'
}, {
  desc: '13 Digitos',
  regexVal: '^[1-9]\\d{12}$',
  regexInput: '^([1-9]\\d{0,12})?$'
}];

export const PAISCONFIG_KEY_CUIT = "cuit";
