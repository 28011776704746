<body>
  <div>
    <app-language-nav></app-language-nav>
  </div>
    <div class="contenedor-cards-mensaje">
      <mat-card class="mensaje">
        <img class="logo" src="../../../../assets/logo.jpg">
        <img class="logo" src="../../../../assets/confirmacion.jpg">
        <div class="separadorCentrado">
          <h1>{{ "registroCliente.validatedTitle" | translate }}</h1>
          <br/>
          <mat-label color="primary">{{ "registroCliente.validatedBody" | translate }}</mat-label>
        </div>
        <button class="boton-footer" mat-button color="primary" [routerLink]="['']">{{ "registroCliente.validatedFoot" | translate }}</button>
        <router-outlet #outlet="outlet"></router-outlet>
      </mat-card>
    </div>
</body>