<div class="casoFondo">
  <mat-toolbar class="header">
    <span class="example-spacer"></span>
    <span>{{ "finalizada.crear" | translate }}</span>
  </mat-toolbar>
  <div class="barraDeProgreso">
    <mat-toolbar class="barraDeProgreso">
      <span class="example-spacer"></span>
      <span>{{ "finalizada.finalizada" | translate }}</span>
    </mat-toolbar>
    <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
  </div>
  <mat-card class="mensaje">
    <div class="separadorCentrado">
      <br />
      <img class="logo" src="../../../../../assets/confirmacion.jpg" />
      <h1>{{ "finalizada.exito" | translate }}</h1>
      <mat-card-subtitle>{{
        "finalizada.correo" | translate
      }}</mat-card-subtitle>
      <button
        class="boton-continuar"
        mat-button
        color="primary"
        [routerLink]="['/casos-activos/3']"
      >
        {{ "finalizada.casos" | translate }}
      </button>
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </mat-card>
</div>
