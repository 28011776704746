<div class="casoFondo">
  <div *ngIf="!nippy">
    <app-language-nav></app-language-nav>

    <div class="header-iconos">
      <app-navbar-cliente></app-navbar-cliente>
    </div>
  </div>
  <div *ngIf="nippy">
    <div class="Nippy">
      <app-navbar-cliente></app-navbar-cliente>
    </div>

    <img
      class="letrasNippyBlancaPerfil"
      src="../../../../../../assets/nippy_logo.png"
    />

    <app-language-nav></app-language-nav>
  </div>

  <form [formGroup]="cambiarDatos">
    <mat-card class="cardPerfil">
      <div class="tituloCentrado">
        <!-- <mat-icon *ngIf="!tieneFoto" svgIcon="perfil" class="imagenPerfil"></mat-icon>
                <div *ngIf="tieneFoto" class="imagenCuadrada">
                    <img  class="imagenDetalles" [src]="avatarUrl | secure | async">
                </div> -->
        <div class="imagenCuadrada">
          <mat-icon
            *ngIf="!tieneFoto"
            svgIcon="perfil"
            class="imagenDetalles"
          ></mat-icon>
          <img
            *ngIf="tieneFoto"
            class="rounded"
            [src]="avatarUrl | secure | async"
          />
        </div>
      </div>
      <button
        *ngIf="!editarPerfil"
        class="editar"
        mat-fab
        (click)="activarEditar()"
      >
        <mat-icon matTooltip=
        '{{
          "tooltip.editar" | translate
        }}'
            matTooltipPosition="below">edit</mat-icon>
      </button>

      <button *ngIf="editarPerfil" class="editar" mat-fab (click)="editar()">
        <mat-icon matTooltip=
        '{{
          "tooltip.guardar" | translate
        }}'
            matTooltipPosition="below">save</mat-icon>
      </button>
      <div *ngIf="editarPerfil" class="separadorAdjuntar">
        <label for="file-upload">
          <p class="boton-adjuntar">{{ "perfilCliente.foto" | translate }}</p>
        </label>
        <input
          id="file-upload"
          type="file"
          multiple
          (change)="selectFiles($event)"
        />
      </div>
      <div class="separadorCentrado">
        <mat-card-title *ngIf="!editarPerfil"
          >{{ nombre }} {{ apellido }}</mat-card-title
        >
      </div>
      <br />
      <mat-card-content>
        <div *ngIf="!editarPerfil && perfil!=undefined">
         

          

            <mat-form-field appearance="outline" class="inputs-registro">
              <mat-label>{{"perfilAbogado.nombre" | translate
              }}</mat-label>
              <input
                required
                matInput
                type="text"
                placeholder="{{ nombre }}"
                formControlName="nombre"
                disabled
                readonly
              />
            </mat-form-field>
            <mat-form-field appearance="outline" class="inputs-registro">
              <mat-label>{{
                "perfilAbogado.apellido" | translate
              }}</mat-label>
              <input
                required
                matInput
                type="text"
                placeholder="{{ apellido }}"
                formControlName="apellido"
                disabled
                readonly
              />
            </mat-form-field>
            <mat-form-field appearance="outline" class="inputs-registro">
              <mat-label>{{
                "perfilAbogado.correo" | translate
              }}</mat-label>
              <input
                required
                matInput
                type="mail"
                placeholder="{{ mail }}"
                formControlName="mail"
                disabled
                readonly
              />
            </mat-form-field>

            <br />
            <mat-form-field *ngIf="sessionUser.pais != 52" appearance="outline" class="inputs-registro">
              <mat-label>{{
                "registroAbogado.tipoIdenti" | translate
              }}</mat-label>
              <mat-select
                required
                formControlName="tipoDni"
              >
                <mat-option
                  *ngFor="let identificacion of identificaciones"
                  [value]="identificacion.id_tipos_identificacion"
                >
                  {{ identificacion.nombre }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="perfil!=undefined && sessionUser.pais != 52" appearance="outline" class="inputs-registro">
              <mat-label>{{
                "registroAbogado.nroDocumento" | translate
              }}</mat-label>
              <input
                required
                matInput
                type="number"
                placeholder="{{ dni }}"
                formControlName="dni"
                disabled
                readonly
              />
            </mat-form-field>
            <mat-form-field appearance="outline" class="inputs-registro">
              <mat-label>{{ "registroAbogado.celular" | translate }}</mat-label>
              <input
                required
                matInput
                type="number"
                formControlName="telefono"
                readonly
              />
            </mat-form-field>

            <br />
            <!--
              <mat-card-subtitle class="inputs-registro"
                >{{"perfilAbogado.apellido" | translate
              }}</mat-card-subtitle>
            -->
            <mat-form-field appearance="outline" class="inputs-registro">
              <mat-label>{{ perfil.provincia }}</mat-label>
              <mat-select disabled
                formControlName="provinciaResidencia"
                (selectionChange)="cargarLocalidadesResidencia()"
              >
                <mat-option
                  *ngFor="let provincia of provinciasResidencias"
                  [value]="provincia"
                >
                  {{ provincia.nombre }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="inputs-registro">
              <mat-label>{{ perfil.ciudad }}</mat-label>
              <mat-select formControlName="localidadResidencia" disabled>
                <mat-option
                  *ngFor="let localidad of localidadesResidencias"
                  [value]="localidad"
                  >{{ localidad.nombre }}</mat-option
                >
              </mat-select>
            </mat-form-field>


            <mat-form-field appearance="outline" class="inputs-registro">
              <mat-label>{{
                "perfilAbogado.codigo" | translate
              }}</mat-label>
              <input
                type="number"
                maxlength="8"
                required
                matInput
                type="number"
                placeholder="{{ codigoPostal }}"
                formControlName="codigoPostal"
                value="codigoPostal"
                disabled
                readonly
              />
            </mat-form-field>
            <mat-form-field *ngIf="sessionUser.pais != 52" appearance="outline" class="inputs-registro">
              <mat-label>
                Promocion
              </mat-label>
              <input
                type="text"
                matInput
                placeholder="Promocion"
                formControlName="promocion"
                disabled
                readonly
              />
            </mat-form-field>
        </div>

        <div *ngIf="editarPerfil && perfil!=undefined">


          

            <mat-form-field appearance="outline" class="inputs-registro">
              <mat-label>{{"perfilAbogado.name" | translate
              }}</mat-label>
              <input
                required
                matInput
                type="text"
                placeholder="{{ nombre }}"
                formControlName="nombre"
              />
            </mat-form-field>
            <mat-form-field appearance="outline" class="inputs-registro">
              <mat-label>{{
                "perfilAbogado.apellido" | translate
              }}</mat-label>
              <input
                required
                matInput
                type="text"
                placeholder="{{ apellido }}"
                formControlName="apellido"
              />
            </mat-form-field>
            <mat-form-field appearance="outline" class="inputs-registro">
              <mat-label>{{
                "perfilAbogado.correo" | translate
              }}</mat-label>
              <input
                required
                matInput
                type="mail"
                placeholder="{{ mail }}"
                formControlName="mail"
              />
            </mat-form-field>

            <br />
            <mat-form-field *ngIF="sessionUser.pais != 52" appearance="outline" class="inputs-registro">
              <mat-label>
                {{ "registroAbogado.tipoIdenti" | translate }}
              </mat-label>
              <mat-select
                formControlName="tipoDni"
                required="sessionUser.pais != 52"
              >
                <mat-option
                  *ngFor="let identificacion of identificaciones"
                  [value]="identificacion.id_tipos_identificacion"
                >
                  {{ identificacion.nombre }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="perfil!=undefined && sessionUser.pais != 52" appearance="outline" class="inputs-registro">
              <mat-label>{{
                "registroAbogado.nroDocumento" | translate
              }}</mat-label>
              <input
                required="sessionUser.pais != 52"
                matInput
                type="number"
                placeholder="{{ dni }}"
                formControlName="dni"
              />
            </mat-form-field>

            <mat-form-field appearance="outline" class="inputs-registro">
              <mat-label>{{ "registroAbogado.celular" | translate }}</mat-label>
              <input
                required
                matInput
                type="number"
                formControlName="telefono"
              />
            </mat-form-field>

            <br />

            <!--
              <mat-card-subtitle class="inputs-registro">
                {{"perfilAbogado.residencia" | translate}}</mat-card-subtitle>
            -->

            <mat-form-field appearance="outline" class="inputs-registro">
              <mat-label>{{ perfil.provincia }}</mat-label>
              <mat-select
                formControlName="provinciaResidencia"
                (selectionChange)="cargarLocalidadesResidencia()"
              >
                <mat-option
                  *ngFor="let provincia of provinciasResidencias"
                  [value]="provincia"
                >
                  {{ provincia.nombre }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="inputs-registro">
              <mat-label>{{ perfil.ciudad }}</mat-label>
              <mat-select formControlName="localidadResidencia">
                <mat-option
                  *ngFor="let localidad of localidadesResidencias"
                  [value]="localidad"
                  >{{ localidad.nombre }}</mat-option
                >
              </mat-select>
            </mat-form-field>


            <mat-form-field appearance="outline" class="inputs-registro">
              <mat-label>{{
                "perfilAbogado.codigo" | translate
              }}</mat-label>
              <input
                type="number"
                maxlength="8"
                required
                matInput
                type="number"
                placeholder="{{ codigoPostal }}"
                formControlName="codigoPostal"
                value="codigoPostal"
              />
            </mat-form-field>
            <mat-form-field *ngIf="sessionUser.pais != 52" appearance="outline" class="inputs-registro">
              <mat-label>
                Promocion
              </mat-label>
              <input
                matInput
                type="text"
                placeholder="{{ promocion }}"
                formControlName="promocion"
              />
            </mat-form-field>

            <br>
            <button
                class="boton-primario"
                mat-raised-button
                color="primary"
                (click)="cancelar()"
              >
                {{ "perfilAbogado.cancelar" | translate }}
              </button>
              <button
                class="boton-primario"
                mat-raised-button
                color="primary"
                (click)="editar()"
              >
                {{ "perfilAbogado.guardarDet" | translate }}
              </button>
            
         

          <!-- <mat-form-field appearance="outline" class="inputs-registro">
                        <input required matInput type="text" placeholder="Celular de contacto"
                            formControlName="telefono">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="inputs-registro">
                        <input required matInput type="number" placeholder="DNI" formControlName="numeroDni">
                    </mat-form-field> -->
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="cardPerfil">
      <button
        class="boton-secundario"
        mat-button
        color="primary"
        (click)="cerrarSesion()"
      >
        {{ "perfilCliente.cerrar" | translate }}
      </button>
    </mat-card>
  </form>
  <br />
</div>
