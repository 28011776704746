import { Component, OnInit } from '@angular/core';
import {
  MatDialog,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlanMultipagoService } from '../../../../services/plan/plan-multipago.service';
import { __await } from 'tslib';
import { PlanService } from '../../../../services/plan/plan.service';
import { SleepService } from '../../../../services/sleep/sleep.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-exito',
  templateUrl: './exito.component.html',
  styleUrls: ['./exito.component.css'],
})
export class ExitoComponent implements OnInit {
  mensaje: string = 'Ya puedes disfrutar de los beneficios de tu nuevo';
  mensajeSub: string = 'Durante 30 dias tendras tu plan gratuito, luego de eso se generara un cobro automático. Puedes darlo de baja en la seccion planes';
  cargando: boolean = true;
  constructor(
    public dialog: MatDialog,
    private sleepService: SleepService,
    private planService: PlanService,
    private servicePlanMP: PlanMultipagoService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    //this.doWaitSubscripcion();
    let isPlatform = false;
    let uuid = "";

    this.route.queryParams.subscribe(params => {
      if (params?.type && params?.uuid) {
        isPlatform = true;
        uuid = params?.uuid;
      }
    });

    if (isPlatform) {
      this.servicePlanMP.doAbogadoSuscripcionCheckAndUpdate(uuid).subscribe({
        next: () => {
          console.log("Finalizada la comprobacion.");
          this.router.navigate(['/perfil-abogado']);
        }
      })
    } else {
      this.planService.getClientStatus().subscribe( res => {
        if(res.mensaje === "OK"){
         if(res.esCliente && res.tieneSuscripcion){
           this.cargando = false;
           if(res.suscripcion.status == "trial")
            this.mensaje += " " + res.suscripcion.nombrePlan;
         }
          
        }
      }, err => {
        this.mensaje = "Se produjo un error al intentar conseguir los datos."
        this.mensajeSub = "Intenta de nuevo mas tarde."
      });
    }
  }

  doWaitSubscripcion(): void {
    let subscripcionId: string;
    subscripcionId = sessionStorage.getItem("plan-uuid");
    this.planService.getSubscriptionStatus(subscripcionId).subscribe({
      next: (respuesta: string) => {
        if (respuesta == "active") {
          this.cargando = false;
          localStorage.removeItem('planContratado');
        } else {
          this.cargando = true;
          setTimeout(() => {
            this.doWaitSubscripcion();
          }, 15000);
        }
      },
      error: (err) => {
        console.error(err);
        this.snackBar.open(
          'No fue posible obtener la información de tu cuenta, por favor intente nuevamente más tarde.',
          '',
          { duration: 5000 }
        );
      }
    });
  }

  async getCuenta() {
    this.planService.getCuenta().subscribe(
      async (respuesta) => {
        let planContratado = JSON.parse(localStorage.getItem('planContratado'));
        if (
          planContratado.id_plan == respuesta.plan.id_plan &&
          planContratado.anual == respuesta.plan.anual
        ) {
          this.cargando = true;
          await this.sleepService.sleep(15000);
          this.getCuenta();
        } else {
          this.cargando = false;
          localStorage.removeItem('planContratado');
        }
      },
      (error) => {
        console.error(error);
        this.snackBar.open(
          'No fue posible obtener la información de tu cuenta, por favor intente nuevamente más tarde.',
          '',
          { duration: 5000 }
        );
      }
    );
  }
}
