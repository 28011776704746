<div class="language-container">
  <button class="button-idioma">
    <img [src]="imgSelected" alt="" class="language-img" />
    <select
      [ngModel]="language"
      (ngModelChange)="switchLang($event)"
      class="language-select"
    >
      <option value="es-AR" [selected]="'es-AR' === language">
        {{ "admin.Button.es" | translate }}
      </option>
      <option value="it-IT" [selected]="'it-IT' === language">
        {{ "admin.Button.it" | translate }}
      </option>
      <option value="en-US" [selected]="'en-US' === language">
        {{ "admin.Button.en" | translate }}
      </option>
    </select>
  </button>
</div>
