import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlanMultipagoService } from 'src/app/services/plan/plan-multipago.service';
import { MPVinculacion } from '../../admin/multipago/modules/multipago.model';
import { DialogVincularComponent } from '../perfil-abogado/dialogvincular.component';
import { loadCustomScript } from "@paypal/paypal-js";
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-vinculaciones',
  templateUrl: './vinculaciones.component.html',
  styleUrls: ['./vinculaciones.component.css']
})
export class VinculacionesComponent implements OnInit {

  cacheVinculaciones: MPVinculacion[] = [];

  displayedColumns: string[] = [
    'plataformaNombre',
    'vincular',
    'vinculado',
    'acciones', 
  ];

  selVinculacion: MPVinculacion;
  swInit: boolean = false;

  constructor(
    private servicePlanMP: PlanMultipagoService,
    public dialog: MatDialog,
  ) { 
    (window as any).doPaypalVinculado = (authCode, sharedId) => {
      this.onPaypalConnection(authCode, sharedId);
    }
  }

  ngOnInit(): void {
    if (this.swInit)
      return;
    this.swInit = true;

    this.servicePlanMP.getAbogadoVinculaciones().subscribe({
      next: (vinculaciones: MPVinculacion[]) => {
        let vincPaypal: MPVinculacion[] = [];
        let existsPaypal: boolean = false;
        this.cacheVinculaciones = vinculaciones;

        vinculaciones.forEach((row) => {
          if (row.plataformaNombre == "Paypal") {
            vincPaypal.push(row);
          }
        });

        vincPaypal.forEach((row, index) => {
          this.servicePlanMP.getAbogadoVinculacionLink(row.plataformaId).subscribe({
            next: resp => {
              console.log("respuesta: ");
              console.log(resp);

              if (resp?.links) {
                resp.links.some(paypalLink => {
                  if (paypalLink?.rel == "action_url") {
                    row.urlLink = paypalLink.href + "&displayMode=minibrowser";
                    existsPaypal = true;
                  }
                })
              }
            },
            complete: () => {
              if (index +1 == vincPaypal.length) {
                if (existsPaypal) {
                  try {
                    loadCustomScript({
                      url: environment.paypal_partner_url
                    }).then(resp => {
                      console.log("cargado loadCustomScript: ", resp);
                    });
                  } catch (err) {
                    console.error("error loadCustomScript: ", err);
                  }
                }
              }
            }
          });
        });
      }
    });
  }

  openDialogVincular(vinculacion: MPVinculacion) {
    if (vinculacion.plataformaNombre.toLocaleLowerCase() == "mercadopago") {
      this.dialog.open(DialogVincularComponent, {
        backdropClass: 'backdropBackground',
      });
    }
  }

  onPaypalButton(vinculacion: MPVinculacion): void {
    this.selVinculacion = vinculacion;
  }

  onPaypalConnection(authCode, sharedId) {
    this.servicePlanMP.doAbogadoVinculacionPaypal(this.selVinculacion.plataformaId, authCode, sharedId).subscribe({
      next: resp => {
        window.location.reload();
      }
    });
    console.log(`onPaypalConnection: ${authCode} - ${sharedId}`);
  }

}
