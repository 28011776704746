import { Component, OnInit, Input } from '@angular/core';
import { Opiniones } from '../../../api/user';
import { PuntuacionesService } from '../../../services/puntuaciones.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-opiniones',
  templateUrl: './opiniones.component.html',
  styleUrls: ['./opiniones.component.css'],
})
export class OpinionesComponent implements OnInit {
  @Input() opiniones: Opiniones;
  @Input() datosPerfil: any;

  puntuacionPropia = {
    puntuacion: 4.5,
    estrellas: '',
  };
  cantOpiniones: number = 0;
  constructor(
    private puntuacionesService: PuntuacionesService,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }

  ngOnInit(): void {
    if (this.datosPerfil) {
      this.puntuacionPropia.puntuacion = this.datosPerfil.puntuacionFinal;
      this.cantOpiniones = this.datosPerfil.cantOpiniones;
    }
    this.puntuacionesService.crearEstrellasMapeando(this.opiniones);
    this.puntuacionesService.crearEstrellasIndividual(this.puntuacionPropia);
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }
}
