import { Component, OnInit} from '@angular/core';
import { Validators, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RegisterClient, ResetPasswordRepeat } from '../../../api/user';
import { ApiService } from '../../../api/api.service';
import { MatSnackBar, 
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CheckPasswordService } from 'src/app/services/register/checkPassword/check-password.service';

@Component({
  selector: 'app-nueva-formulario',
  templateUrl: './nueva-formulario.component.html',
  styleUrls: ['./nueva-formulario.component.css']
})
export class NuevaFormularioComponent implements OnInit{

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  language:String="";
  coinciden = true;
  imgSelected: String = "";
  blur = false;
  constructor(private apiService: ApiService, private checkPasswordService: CheckPasswordService,private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute,public translate: TranslateService) { 
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    this.language = sessionStorage.getItem('language');
    if(!sessionStorage.getItem("language")){
      translate.setDefaultLang("es-AR");
      this.language = "es-AR"
    }else
      translate.setDefaultLang(sessionStorage.getItem('language'));
    if(this.language == "es-AR")
      this.imgSelected = "../../../assets/españa.png"
    if(this.language == "it-IT")
      this.imgSelected = "../../../assets/italia.png"
    if(this.language == "en-US")
      this.imgSelected = "../../../assets/ingles.png"
  }
  registerClients: RegisterClient[] = [];
  userForm = new UntypedFormGroup({
    password: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/),
    ]),
    repeatPassword: new UntypedFormControl('', [
      Validators.required
    ])
  });
  idCambioClave: number;

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.idCambioClave = params['params'].id;
     });
  }

  onSubmit() {
    if (this.userForm.value.password != this.userForm.value.repeatPassword) {
      this.openSnackBar("Las contraseñas no coinciden");
    } else if(this.userForm.value.password.length < 8){
      this.openSnackBar(this.translate.instant("password.length"))
    }else{
      this.nueva();
    }
  }
  hide = true;

  
  switchLang(lang: string) {
    console.log(lang)
    this.translate.use(lang);
    this.language = lang;
    sessionStorage.setItem('language', lang);
    sessionStorage.language = lang;
    if(lang == "es-AR")
      this.imgSelected = "../../../assets/españa.png"
    if(lang == "it-IT")
      this.imgSelected = "../../../assets/italia.png"
    if(lang == "en-US")
      this.imgSelected = "../../../assets/ingles.png"
  }

  nueva() {
    this.apiService.setNewPass({
      clave: this.userForm.value.password,
      idCambioClave: this.idCambioClave,
    } as ResetPasswordRepeat)
      .subscribe( () => {      
        this.openSnackBar("Contraseña restablecida con éxito");
        this.router.navigate(['']);
      },
        error => {
          console.error(error);
          if(error.status === 409) {
            this.openSnackBar("Error de registro");
          } else {
            this.openSnackBar("Estamos trabajando para mejorar el servicio, por favor intente nuevamente más tarde");
          }
        });
  }

  verificarPassSalir() {
    this.blur = true;
    this.verificarPass();
  }

  verificarPass() {
    this.coinciden = this.checkPasswordService.checkPass(
      this.blur,
      this.userForm.value.password,
      this.userForm.value.repeatPassword
    );
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "", {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

}