import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cuenta-validada-abogado',
  templateUrl: './cuenta-validada-abogado.component.html',
  styleUrls: ['./cuenta-validada-abogado.component.css']
})
export class CuentaValidadaAbogadoComponent implements OnInit {

  id = "";
  lang = "";
  constructor(private router: Router,
    private apiService: ApiService,
    private route: ActivatedRoute,public translate: TranslateService) {

      translate.addLangs(['es-AR', 'it-IT','en-US']);
      if(sessionStorage.getItem('language')==null){
        translate.setDefaultLang('es-AR');
        sessionStorage.setItem('language','es-AR');
      }
      else{
        translate.setDefaultLang(sessionStorage.getItem('language'));
      }
     }
  
  textoTitulo = "Identidad validada. Ahora validaremos matrícula";
  textoNavegacion = "VOLVER A LA PÁGINA PRINCIPAL";
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params['params'].id;
      if (this.id === "agregar") {
        this.textoTitulo = "Validaremos matrícula.";
        this.textoNavegacion = "VOLVER AL PERFIL";
      }
      this.lang = params['params'].lang;
      this.translate.use(this.lang);
      sessionStorage.setItem('language', this.lang);
    });

    if (this.id != "agregar") {
      this.apiService.confirmarUsuario(this.id)
        .subscribe(respuesta => {
        },
          error => {
            console.error(error);
          });
    }
  }

  navegar() {
    if (this.id === "agregar") {
      this.router.navigate(['/perfil-abogado']);
    } else {
      this.router.navigate(['']);
    }
  }

}
