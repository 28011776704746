<div class="contenidoModal">
  <img class="logo" src="../../../assets/mercadopago.svg" />
  <div class="bienvenida">
    <h2>{{"mercadoPago.vincular" | translate}}</h2>
    <mat-card-subtitle
      >{{"mercadoPago.descripcion" | translate}}
    </mat-card-subtitle>
  </div>
  <button
    (click)="vincular()"
    class="boton-primario"
    mat-raised-button
    color="primary"
  >
    {{"mercadoPago.btn" | translate}}
  </button>
</div>
