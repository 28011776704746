import { Component, HostListener, OnInit } from '@angular/core';
import {
  DatosJurisdiccion,
  Especialidad,
  Jurisdiccion,
  JurisdiccionEditarVer2,
  JurisdiccionEditarVer3,
  Localidad,
  ReturnUser,
} from '../../../api/user';
// import { Rango, RangoConId } from '../../../api/horarios';
import { ApiService } from '../../../api/api.service';
import {
  FormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
// import { environment } from '../../../../environments/environment';
// import { MatIconRegistry } from "@angular/material/icon";
// import { DomSanitizer } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent as DialogComponentPlan } from '../perfil-abogado/dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { Perfil } from '../../../api/user';
// import { RoleNumber } from '../../../enums/role';
// import { UpdateRangesService } from '../../../services/agenda/update-ranges.service';
import { TranslateService } from '@ngx-translate/core';
import { browser } from 'protractor';
import { SpinnerServiceService } from 'src/app/services/spinner-service/spinner-service.service';

@Component({
  selector: 'app-agregar',
  templateUrl: './agregar.component.html',
  styleUrls: ['./agregar.component.css'],
})
export class AgregarComponent implements OnInit {
  telefono: string = '';
  numeroDni: string = '';
  tipoDni: string = '';
  titulo: string;
  planApto = false;
  mostrarEspecialidades: boolean = false;
  mostrarJurisdiccion: boolean = false;
  editarJurisdiccion: boolean = false;
  // todasEspecialidades: Especialidad[];
  // especialidadesSeleccionadas: Especialidad[] = [];
  jurisdicciones: Jurisdiccion[];
  jurisdiccionesExistentes: any;
  localidades: Localidad[];
  colegioValido: boolean = true;
  tomoValido: boolean = true;
  folioValido: boolean = true;
  userId: number;
  pais:string='';
  showTomo=true;
  showFolio=true;
  showMatricula=true;

  agregarJurisdiccion = new UntypedFormGroup({
    tomo: new UntypedFormControl('', [Validators.max(999), Validators.min(0)]),
    folio: new UntypedFormControl('', [Validators.max(9999), Validators.min(0)]),
    colegio: new UntypedFormControl('', [
      Validators.maxLength(255),
      Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ.\s]+$/),
    ]),
    otro: new UntypedFormControl(),
    jurisdiccion: new UntypedFormControl(),
    localidad: new UntypedFormControl(),
    id_datos_profesionales: new UntypedFormControl(),
  });
  cargando:boolean= false;
  constructor(
    private spinnerServiceService: SpinnerServiceService,
    private _snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    private apiService: ApiService,
    public translate: TranslateService // private matIconRegistry: MatIconRegistry, // private domSanitizer: DomSanitizer, // private updateRangesService: UpdateRangesService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));

    let mode = localStorage.getItem('mode');
    // if (mode == "especialidades") {
    //   this.titulo = "Agregar especialidad";
    //   this.mostrarEspecialidades = true;
    // }
    if (mode == 'jurisdiccion') {
      //this.titulo = 'Agregar jurisdicción';
      this.translate
        .get('agregar.agregarJur')
        .subscribe((translate: string) => {
          this.titulo = this.translate.instant('agregar.agregarJur');
        });
      this.mostrarJurisdiccion = true;
    }
    if (mode == 'editar') {
      //this.titulo = 'Editar jurisdicción';
      this.translate
        .get('agregar.agregarJur')
        .subscribe((translate: string) => {
          this.titulo = this.translate.instant('agregar.editarJur');
        });
      this.mostrarJurisdiccion = true;
      this.editarJurisdiccion = true;
    }

    // this.matIconRegistry.addSvgIcon(
    //   `Derecho Laboral`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/Laboral.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Derecho de Familia y Sucesiones`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/individuos.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Derecho Comercial`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/comercial.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Contratos`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/contratos.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Derecho del Consumidor`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/defensa.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Derecho Tributario`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/impuestos.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Inmobiliario y Propiedad Horizontal`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/inmobiliaria.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Otros`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/otros.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Derecho Penal`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/penal.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Sucesiones y herencias`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/sucesiones.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Derecho Previsional`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/previsional.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Derecho Digital Internet y Tecnología`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/digital.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Daños y Perjuicios`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/perjuicios.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Derecho de Sociedades y Corporativo`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/societario.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Accidentes de tránsito`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/accidentes.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Propiedad Industrial e Intelectual`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/propiedad.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Mala Praxis y Salud`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/mala.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Discriminación y violencia de género`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/discriminacion.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `ayuda`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/ayuda.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Derecho Aduanero y Comercio Exterior`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/aduanero.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Derecho Administrativo y Regulación Económica`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/administrativo.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Derecho Migratorio`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/derecho-migratorio.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Derecho Civil`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/derecho-civil.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Derecho Ambiental`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/derecho ambiental.svg')
    // );
    // this.matIconRegistry.addSvgIcon(
    //   `Concursos y Quiebras`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/quiebras.svg')
    // );
  }

  // guardar(especialidad) {
  //   especialidad.selected = !especialidad.selected;
  //   if (especialidad.selected) {
  //     console.log(especialidad)
  //     this.especialidadesSeleccionadas.push(especialidad);
  //     console.log(this.especialidadesSeleccionadas)
  //   } else {
  //     const i = this.especialidadesSeleccionadas.indexOf(especialidad);
  //     if (i > -1) this.especialidadesSeleccionadas.splice(i, 1);
  //   }

  // }

  cancelar() {
    this.mostrarEspecialidades = false;
    this.mostrarJurisdiccion = false;
    this.editarJurisdiccion = false;
    localStorage.removeItem('especialidades');
    localStorage.removeItem('mode');
    localStorage.removeItem('jurisdiccion');
    localStorage.removeItem('jurisdiccionesExistentes');
    this.router.navigate(['/perfil-abogado']);
  }

  openDialog() {
    this.dialog.open(DialogComponentPlan, {
      backdropClass: 'backdropBackground',
    });
  }

  onKey(event) {
    event.target.value = event.target.value.toUpperCase();
  }

  cargarLocalidades() {
    this.apiService
      .getLocalidades(
        this.agregarJurisdiccion.value.jurisdiccion.id_jurisdiccion
      )
      .subscribe(
        (localidad) => {
          this.localidades = localidad;
        },
        (error) => {
          this.openSnackBar(
            'No fue posible obtener el listado de localidades. Por favor, intente nuevamente más tarde.'
          );
        }
      );
  }

  editar() {
    
    let jurisdiccion: JurisdiccionEditarVer2 = JSON.parse(
      localStorage.getItem('jurisdiccion')
    );
    let datos: JurisdiccionEditarVer3 = {
      localidadId: this.localidades[0].id_localidad,
      colegio: this.agregarJurisdiccion.value.colegio.toUpperCase(),
      tomo: this.agregarJurisdiccion.value.tomo,
      folio: this.agregarJurisdiccion.value.folio,
      otro: this.agregarJurisdiccion.value.otro,
      datosProfesionalesId:
      jurisdiccion.id_datos_profesionales,
      jurisdiccionNombre: this.agregarJurisdiccion.value.nombre,
    };

    this.apiService.editarJurisdiccionv2(datos).subscribe(
      (respuesta) => {
        localStorage.removeItem('mode');
        localStorage.removeItem('jurisdiccion');
        // this.router.navigate(['/agregar-jurisdiccion']);
        this.router.navigate(['perfil-abogado'], { queryParams: { jurisdiccion: 1 } });
      },
      (error) => {
        console.error(error);
        this.openSnackBar(
          'No fue posible editar la jurisdicción, por favor intente nuevamente más tarde'
        );
        this.router.navigate(['perfil-abogado']);
      }
    );
  }

  intentoAgregar(campo: number) {

    console.log("INTENTO AGREGAR "+campo);
    const JURISDICCION = 1;
    // const ESPECIALIDAD = 2;
    if (this.planApto) {
      this.openDialog();
      this.router.navigate(['/plan']);
    } else {
      switch (campo) {
        case JURISDICCION: {
          this.mostrarJurisdiccion = false;
          
          let jurisdiccion: JurisdiccionEditarVer2 = JSON.parse(
            localStorage.getItem('jurisdiccion')
          );
          let datos: DatosJurisdiccion = {
            localidadId: this.localidades && this.localidades.length > 0 ? this.localidades[0].id_localidad : 0,
            colegio: this.agregarJurisdiccion.value.colegio.toUpperCase(),
            tomo: this.agregarJurisdiccion.value.tomo,
            folio: this.agregarJurisdiccion.value.folio,
            numeroDni: this.numeroDni,
            telefono: this.telefono,
            tipoDni: this.tipoDni,
            datosProfesionalesId: 0
          };
          if(this.editarJurisdiccion){
            console.log("JUR ED "+jurisdiccion.id_datos_profesionales);
            datos.datosProfesionalesId=jurisdiccion.id_datos_profesionales;
          }
          else{

            datos.datosProfesionalesId=0;

          }
          let yaExiste = false;
          /*this.jurisdiccionesExistentes.forEach((element) => {
            if (
              this.agregarJurisdiccion.value.jurisdiccion.nombre ==
              element.jurisdiccionNombre
            ) {
              yaExiste = true;
            }
          });
          */
          if (yaExiste) {
            this.openSnackBar(
              'Ya tienes esta jurisdicción, no es posible volver a agregarla'
            );
            this.mostrarJurisdiccion = true;
          } else {
            this.apiService.agregarJurisdiccion(datos).subscribe(
              (respuesta) => {
                localStorage.removeItem('jurisdiccionesExistentes');
                this.router.navigate(['/agregar-jurisdiccion']);
              },
              (error) => {

                console.log("Error "+JSON.stringify(error));
                if (error.status == 409) {
                  this.openDialog();
                  this.router.navigate(['/plan']);
                } else {
                  this.openSnackBar('No es posible agregar la jurisdicción');
                  this.router.navigate(['perfil-abogado']);
                }
              }
            );
          }
          break;
        }
        // case ESPECIALIDAD: {
        //   let especialidadesExistentes = JSON.parse(localStorage.getItem('especialidades'));
        //   let nuevasEspecialidades: number[] = this.especialidadesSeleccionadas.map(e => e.id_especialidad);
        //   if (this.checkEspecialidadsExistentes(this.especialidadesSeleccionadas, especialidadesExistentes).length > 0) {
        //     const result = this.checkEspecialidadsExistentes(this.especialidadesSeleccionadas, especialidadesExistentes);
        //     this.openSnackBar(result.length == 1 ? `Ya tienes la especialidad ${result[0]}, no es posible volver a agregarla` : `Ya tienes las especialidades: ${result.toString()}, no es posible volver a agregarla`);
        //   } else {
        //     localStorage.removeItem('especialidades');
        //     this.apiService.editarDatosProfesionales(nuevasEspecialidades)
        //       .subscribe(respuesta => {
        //         this.openSnackBar("Especialidad agregada exitosamente");
        //         let finalTags = [];
        //         this.especialidadesSeleccionadas.forEach(e => {
        //           finalTags = [...finalTags, ...e.nombreCliente];
        //         });
        //         let response: boolean = this.updateRangesService.updateTags(this.userId, finalTags, true);
        //         if (response) {
        //           this.openSnackBar("Se actualizó la agenda");
        //         }
        //         this.router.navigate(['perfil-abogado']);
        //       },
        //         error => {
        //           this.openSnackBar("No es posible agregar nueva especialidad");
        //           this.openDialog();
        //           this.router.navigate(['/plan']);
        //         });
        //     this.mostrarEspecialidades = false;
        //   }
        //   break;
        // }
      }
    }
  }

  // private checkEspecialidadsExistentes(aInsertar: Especialidad[], existentes: string[]) {
  //   let set = new Set(existentes);
  //   let res = [];
  //   aInsertar.forEach(a => {
  //     if (set.has(a.nombre)) {
  //       res.push(a.nombre);
  //     }
  //   });
  //   return res;
  // }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 5000 });
  }

  ngOnInit(): void {
    this.getMiPerfil();
    // this.getEspecialidades();
    this.getJurisdicciones();
  }

  getJurisdicciones() {
    this.apiService.getJurisdiccionPaisesAuto().subscribe(
      (jurisdiccion) => {
        this.jurisdiccionesExistentes = JSON.parse(
          localStorage.getItem('jurisdiccionesExistentes')
        );
        this.jurisdicciones = jurisdiccion;
        if (this.editarJurisdiccion) {
          this.patchForm();
        }
      },
      (error) => {}
    );
  }

  getEspecialidades() {
    // this.apiService.getEspecialidades(RoleNumber.Abogado)
    //   .subscribe(especialidades => {
    //     this.todasEspecialidades = especialidades.map(especialidad => {
    //       const nombreReemplazado = especialidad.nombre.replace('/', ' ');
    //       return { ...especialidad, nombre: nombreReemplazado, selected: false };
    //     });
    //   },
    //     error => {
    //     });
  }

  getMiPerfil() {
    this.cargando=true;
    this.apiService.getPerfil().subscribe(
      (perfil) => {
        
        this.tipoDni = perfil.datosProfesionales[0].tipoDni;
        this.numeroDni = perfil.datosProfesionales[0].numeroDni;
        this.telefono = perfil.datosProfesionales[0].telefono;
        this.userId = perfil.id_usuario;
        this.pais = perfil.pais;
        if(this.pais=='595'){
          this.showMatricula=true;
          this.showFolio=false;
          this.showTomo=false;
          this.agregarJurisdiccion.get('tomo').clearValidators();
          this.agregarJurisdiccion.get('folio').clearValidators();
          this.agregarJurisdiccion.get('tomo').updateValueAndValidity();
          this.agregarJurisdiccion.get('folio').updateValueAndValidity();
          this.agregarJurisdiccion.get('otro').setValidators([Validators.required]);
          this.agregarJurisdiccion.get('otro').updateValueAndValidity();;
        }
        else{
          this.showMatricula=false;
          this.showFolio=true;
          this.showTomo=true;
          
        }
        this.cargando=false;
      },
      (error) => {
        this.openSnackBar(
          'No fue posible obtener la información del perfil. Por favor, intente nuevamente más tarde.'
        );
      }
    );
  }

  patchForm() {
    let jurisdiccion: JurisdiccionEditarVer2 = JSON.parse(
      localStorage.getItem('jurisdiccion')
    );

    console.log("JURISDICCION "+JSON.stringify(jurisdiccion));
    this.agregarJurisdiccion.patchValue({ tomo: jurisdiccion.tomo });
    this.agregarJurisdiccion.patchValue({ otro: jurisdiccion.otro });
    this.agregarJurisdiccion.patchValue({ colegio: jurisdiccion.colegio });
    this.agregarJurisdiccion.patchValue({ folio: jurisdiccion.folio });
    console.log(this.jurisdicciones);
    let found = this.jurisdicciones.find(
      (aux) => aux.nombre == jurisdiccion.jurisdiccionNombre
    );
    this.agregarJurisdiccion.patchValue({ jurisdiccion: found });
    this.agregarJurisdiccion.patchValue({
      id_datos_profesionales: jurisdiccion.id_datos_profesionales,
    });
    this.cargarLocalidades();
  }

  onKeyUp(event) {
    if (
      !this.agregarJurisdiccion.value.colegio.match(
        /^[a-zA-ZñÑáéíóúÁÉÍÓÚ.\s]+$/
      )
    ) {
      this.colegioValido = false;
    } else {
      this.colegioValido = true;
    }
  }

  onKeyUpFolio(event) {
    let aux = event.target.value;
    if (aux > 9999) {
      this.folioValido = false;
    } else {
      this.folioValido = true;
    }
  }

  onKeyUpTomo(event) {
    let aux = event.target.value;
    if (aux > 999) {
      this.tomoValido = false;
    } else {
      this.tomoValido = true;
    }
  }
}
