import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dialogo-previsualizar',
  templateUrl: './dialogo-previsualizar.component.html',
  styleUrls: ['./dialogo-previsualizar.component.css']
})
export class DialogoPrevisualizarComponent implements OnInit {

  fileData;
  fileMime: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DialogoPrevisualizarComponent>
  ) {
    //this.fileData = this.sanitizer.bypassSecurityTrustResourceUrl(data['url']);
    this.fileData = this.sanitizer.bypassSecurityTrustResourceUrl(data['data']);
    this.fileMime = data['mime'];
  }

  ngOnInit(): void {
  }

}
