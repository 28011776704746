<div class="casoFondo">
  <div class="casoFondo">
    <mat-toolbar class="header">
      <button mat-button (click)="navegar()">
        <mat-icon class="icono">chevron_left</mat-icon>
      </button>
      <span class="example-spacer"></span>
      <span>{{ "previa.agendar" | translate }}</span>
    </mat-toolbar>

    <div class="barraDeProgreso">
      <mat-toolbar class="barraDeProgreso">
        <span class="example-spacer"></span>
        <p class="mobile">
          Abogados disponibles para <br />
          atender tu caso
        </p>
        <p class="desktop">{{ "previa.abogados" | translate }}</p>
      </mat-toolbar>
      <mat-progress-bar mode="determinate" value="20"></mat-progress-bar>
    </div>

    <div>
      <!-- <div *ngIf="isMobileDevice()"> -->
      <div class="mobile">
        <div *ngIf="abogado" class="casoFondo">
          <div class="cuadradoLimitado">
            <app-vista-previa
              *ngIf="abogado"
              [mostrarBoton]="true"
              [abogado]="abogado"
              [titulo]="titulo"
              [caso]="caso"
              [id_caso]="id_caso"
              [avatarUrl]="abogado.avatarUrl"
              [tieneFoto]="tieneFoto"
              [especialidad]="especialidad"
            >
            </app-vista-previa>
          </div>
        </div>
        <div class="botones-slider" *ngIf="abogados && abogados.length">
          <button
            class="boton-slider"
            mat-fab
            color="white"
            (click)="cambiar(1)"
          >
            <mat-icon class="sliderArrow">arrow_back</mat-icon>
          </button>
          <p>{{ posicion + 1 }}/{{ abogados.length }}</p>
          <button
            class="boton-sliderDerecha"
            mat-fab
            color="white"
            (click)="cambiar(2)"
          >
            <mat-icon class="sliderArrow">arrow_forward</mat-icon>
          </button>
        </div>
        <div
          class="no-hay-abogados"
          *ngIf="(abogados && abogados.length == 0) || !abogados"
        >
          <h1>
            {{ "previa.no" | translate }}
          </h1>
        </div>
      </div>

      <!-- <div *ngIf="!isMobileDevice()"> -->
      <div class="desktop">
        <div class="servicio-responsivo">
          <ng-container *ngIf="abogados && abogados.length">
            <div *ngFor="let abogado of abogados">
              <app-vista-previa
                *ngIf="abogado"
                [mostrarBoton]="true"
                [abogado]="abogado"
                [titulo]="titulo"
                [caso]="caso"
                [id_caso]="id_caso"
                [avatarUrl]="abogado.avatarUrl"
                [tieneFoto]="tieneFoto"
                [especialidad]="especialidad"
              >
              </app-vista-previa>
            </div>
          </ng-container>
          <div
            class="no-hay-abogados"
            *ngIf="(abogados && abogados.length == 0) || !abogados"
          >
            <h1>{{ "previa.no" | translate }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
</div>
