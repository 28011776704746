import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../../../api/api.service';
import { UsuariosHora } from '../../../../../../api/agenda';
import { StorageService } from '../../../../../../utils/storage/storage.service';
import { FormatDateService } from 'src/app/utils/formatDate/formatDate.service.';
import {
  MatCalendarHeader,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { MatCalendar } from '@angular/material/datepicker';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Horario } from '../../../../../../api/horarios';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rango',
  templateUrl: './rango.component.html',
  styleUrls: ['./rango.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class RangoComponent implements OnInit {
  @ViewChild('picker2') calendar: MatCalendar<Date>;
  @ViewChild('picker2') calendarHeader: MatCalendarHeader<Date>;

  mesPrevio = '';
  mesActual = '';
  primerClick = false;
  dias = [];
  meses = [];
  diaYMes = [
    {
      dia: -1,
      mes: -1,
    },
  ];

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    let mesActual = new Date().getMonth();
    let mesProximo = mesActual + 1;
    if (mesActual == 11) {
      mesProximo = 0;
    }
    if (view === 'month') {
      const date = cellDate.getDate();
      const month = cellDate.getMonth();
      const year = cellDate.getFullYear();
      this.dias = JSON.parse(localStorage.getItem('dias'));
      this.meses = JSON.parse(localStorage.getItem('meses'));
      let currentYear = new Date().getFullYear();
      let nextYear = new Date().getFullYear() + 1;
      for (var i = 0; i < this.dias.length; i++) {
        let unDia = this.dias[i];
        let unMes = this.meses[i];
        if (
          (date === unDia &&
            month === mesActual &&
            mesActual === unMes - 1 &&
            year === currentYear) ||
          (date === unDia &&
            month === mesProximo &&
            mesProximo === unMes - 1 &&
            year === currentYear) ||
          (date === unDia &&
            month === mesProximo &&
            mesProximo == 0 &&
            mesProximo === unMes - 1 &&
            year === nextYear)
        ) {
          return 'example-custom-date-class';
        }
      }
    }
  };

  horarioSeleccionado: Horario;
  cantidad = 0;
  textoRespuesta = '';
  valido: boolean = false;
  terminoCarga: boolean = false;
  usuariosDisponiblesPorHora: UsuariosHora[];
  diasDisponibles: string[] = [];
  events: string[] = [];
  datos = {
    caso: {
      titulo: '',
      detalles: '',
      jurisdiccion: {
        nombre: '',
        id_jurisdiccion: 0,
      },
      localidad: {
        nombre: '',
        id_localidad: 0,
      },
      especialidad: {
        nombre: '',
        id_especialidad: 0,
      },
      categoria: {
        nombre: '',
        id_categoria: 0,
        descripcion: '',
      },
      archivosSeleccionados: [],
    },
    titulo: 'Agendar cita',
    abogados: [],
    archivosSeleccionados: [],
  };
  seleccionado = false;
  selected: Date;
  respuestasHoras: any;

  todosLosHorarios: any;

  seleccion = {
    titulo: 'Agendar cita',
    nombre: '',
    apellido: '',
    estado: '',
    abogado: '',
    fechaIsoString: '',
    horarioSeleccionado: '',
    fecha: '',
    caso: {
      titulo: '',
      detalles: '',
      jurisdiccion: {
        nombre: '',
        id_jurisdiccion: 0,
      },
      localidad: {
        nombre: '',
        id_localidad: 0,
      },
      especialidad: {
        nombre: '',
        id_especialidad: 0,
      },
      categoria: {
        nombre: '',
        id_categoria: 0,
        descripcion: '',
      },
      archivosSeleccionados: [],
    },
  };

  horarios: Horario[];
  horariosCompletos = [
    {
      horaVisible: '',
      fechaOriginal: '',
    },
  ];
  abogadosIds: number[] = [];
  form = new UntypedFormGroup({
    horario: new UntypedFormControl(''),
  });

  formatCulture: string = 'yyyy-MM-dd';
  culture: string = 'es-AR';

  constructor(
    private _snackBar: MatSnackBar,
    private storageService: StorageService,
    private formatDateService: FormatDateService,
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT', 'en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));

    if (this.router.getCurrentNavigation().extras.state) {
      this.seleccion.caso =
        this.router.getCurrentNavigation().extras.state.caso;
      if (this.seleccion.caso.titulo != null) {
        localStorage.setItem('caso', JSON.stringify(this.seleccion.caso));
      } else {
        this.seleccion.caso = JSON.parse(localStorage.getItem('caso'));
        this.seleccion.fechaIsoString = JSON.parse(
          localStorage.getItem('fechaCompleta')
        );
      }
      this.seleccion.nombre =
        this.router.getCurrentNavigation().extras.state.nombre;
      this.seleccion.apellido =
        this.router.getCurrentNavigation().extras.state.apellido;
      this.seleccion.estado =
        this.router.getCurrentNavigation().extras.state.estado;
      if (this.router.getCurrentNavigation().extras.state.abogado) {
        this.seleccion.abogado =
          this.router.getCurrentNavigation().extras.state.abogado;
      }
      if (this.seleccion.estado == 's') {
        this.seleccionado = true;
      }
    }
  }

  ngOnInit(): void {
    this.valido = false;
    this.terminoCarga = false;
    if (this.seleccion.caso.titulo == '') {
      this.seleccion.caso = JSON.parse(localStorage.getItem('caso'));
      this.seleccion.fechaIsoString = JSON.parse(
        localStorage.getItem('fechaCompleta')
      );
    }
    this.verificarAbogados();
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  atras() {
    console.log(localStorage.getItem('caso'));
    window.history.back();
  }

  submit() {
    this.seleccion.horarioSeleccionado = this.horarioSeleccionado.horaString;
  }

  convertirHora(hora) {
    let horas = hora.getHours().toString();
    if (horas.length == 1) {
      horas = '0' + horas;
    }
    let minutos = hora.getMinutes().toString();
    if (minutos.length == 1) {
      minutos = '0' + minutos;
    }
    return horas + ':' + minutos;
  }

  navegar() {
    this.seleccion.horarioSeleccionado = this.horarioSeleccionado.horaString;
    let hora = new Date(this.horarioSeleccionado.horaString);
    let horarioEscogido;
    this.router.navigateByUrl('/resumen', { state: this.seleccion });
  }

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  diaEscogido = new UntypedFormGroup({
    diaEscogido2: new UntypedFormControl(),
  });

  range2 = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  verificarAbogados() {
    let hoy = new Date();
    let unMes = new Date();
    let mes = unMes.getMonth() + 1;
    unMes.setMonth(mes);
    let fechaInicio = this.formatDateService.convertDateToFormatSpecifc(
      this.formatCulture,
      hoy,
      this.culture
    );
    let fechaFinal = this.formatDateService.convertDateToFormatSpecifc(
      this.formatCulture,
      unMes,
      this.culture
    );
    localStorage.setItem('fechaInicio', fechaInicio);
    localStorage.setItem('fechaFinal', fechaFinal);
    if (this.seleccion.caso.titulo != undefined) {
      let tagEspecialidad = this.seleccion.caso.especialidad.id_especialidad;
      let tagLocalidad = this.seleccion.caso.jurisdiccion.nombre;
      let horaAConvertir;
      let agregarHorario = '';

      this.apiService
        .getAgendaDias(fechaInicio, fechaFinal, tagEspecialidad, tagLocalidad)
        .subscribe(
          (respuesta) => {
            this.terminoCarga = true;
            if (respuesta.length == 0) {
              this.valido = false;
            } else {
              this.valido = true;
            }
            if (respuesta === null) {
              this.textoRespuesta = 'No se encontraron abogados disponibles';
            } else {
              this.textoRespuesta = '';
              this.seleccionado = true;
            }
            this.diasDisponibles = respuesta;
            this.dias = [];
            respuesta.forEach((dia) => {
              if (dia.charAt(8) === '0') {
                let aux = parseInt(dia.charAt(9), 10);
                this.dias.push(aux);
              } else {
                let aux = dia.charAt(8).toString() + dia.charAt(9).toString();
                let aux2 = parseInt(aux, 10);
                this.dias.push(aux2);
              }

              if (dia.charAt(5) === '0') {
                //hasta septiembre inclusive
                let aux = parseInt(dia.charAt(6), 10);
                this.meses.push(aux);
              } else {
                //de octubre en adelante
                let aux = dia.charAt(5).toString() + dia.charAt(6).toString();
                let aux2 = parseInt(aux, 10);
                this.meses.push(aux2);
              }
            });
            localStorage.setItem('dias', JSON.stringify(this.dias));
            localStorage.setItem('meses', JSON.stringify(this.meses));
          },
          (error) => {
            console.error(error);
            this.terminoCarga = true;
            this.valido = false;
          }
        );
    } else {
      this.terminoCarga = true;
      this.valido = false;
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
    let fechaInicio = this.formatDateService.convertDateToFormatSpecifc(
      'yyyy-MM-dd',
      event.value,
      this.culture
    );
    let fechaFinal = fechaInicio;
    let tagEspecialidad = this.seleccion.caso.especialidad.nombre;
    let tagLocalidad = this.seleccion.caso.jurisdiccion.nombre;
    let horaAConvertir;
    let horaString = '';
    this.horarios = [];
    this.horariosCompletos = [];
    this.todosLosHorarios = [];
    this.apiService
      .getAgendaDisponibles(
        fechaInicio,
        fechaFinal,
        tagEspecialidad,
        tagLocalidad
      )
      .subscribe(
        (respuesta) => {
          if (respuesta === null || respuesta.length == 0) {
            this.textoRespuesta =
              'No se encontraron abogados disponibles en el día seleccionado';
          } else {
            this.textoRespuesta = '';
            this.todosLosHorarios = respuesta;
          }
          this.usuariosDisponiblesPorHora = respuesta;

          this.usuariosDisponiblesPorHora.forEach((usuario) => {
            horaAConvertir = new Date(usuario.hora);
            horaString =
              horaAConvertir.getHours().toString() +
              ':' +
              horaAConvertir.getMinutes().toString();
            horaString = this.convertirHora(horaAConvertir);
            let horarioAux = {
              horaString,
              checked: false,
            };
            this.horarios.push(horarioAux);
          });
        },
        (error) => {
          console.error(error);
        }
      );
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 5000 });
  }
  verificarHorarioDia($event) {
    this.seleccionado = false;
    if (
      this.calendar.monthView ||
      this.calendar.currentView == 'multi-year' ||
      this.calendar.currentView == 'year'
    ) {
      if (
        this.calendar.currentView == 'multi-year' ||
        this.calendar.currentView == 'year' ||
        (this.calendar.monthView._monthLabel != this.mesPrevio &&
          this.primerClick)
      ) {
        this.horarios = [];
        this.selected = undefined;
        this.mesPrevio = this.calendar.monthView._monthLabel;
      } else {
        this.primerClick = true;
        let hoy = new Date();
        if (this.selected < hoy) {
          this.openSnackBar('No es posible elegir días previos a hoy');
          this.horarios = [];
        } else {
          if (this.selected != null) {
            let aux = this.formatDateService.convertDateToFormatSpecifc(
              this.formatCulture,
              this.selected,
              this.culture
            );
            let fechaInicio = aux;
            let fechaFinal = fechaInicio;
            let tagEspecialidad = this.seleccion.caso.especialidad.nombre;
            let tagLocalidad = this.seleccion.caso.jurisdiccion.nombre;
            let horaAConvertir;
            let horaString = '';
            this.horarios = [];
            this.horariosCompletos = [];
            this.apiService
              .getAgendaDisponibles(
                fechaInicio,
                fechaFinal,
                tagEspecialidad,
                tagLocalidad
              )
              .subscribe(
                (respuesta) => {
                  this.mesPrevio = this.calendar.monthView._monthLabel;
                  if (respuesta === null || respuesta.length == 0) {
                    this.textoRespuesta =
                      'No se encontraron abogados disponibles';
                    this.cantidad = 0;
                  } else {
                    this.textoRespuesta = '';
                    this.todosLosHorarios = respuesta;
                  }
                  this.usuariosDisponiblesPorHora = respuesta;
                  this.cantidad = this.usuariosDisponiblesPorHora.length;
                  this.usuariosDisponiblesPorHora.map((usuario) => {
                    horaAConvertir = new Date(usuario.hora);
                    horaString =
                      horaAConvertir.getHours().toString() +
                      ':' +
                      horaAConvertir.getMinutes().toString();
                    horaString = this.convertirHora(horaAConvertir);
                    let horarioAux = {
                      horaString,
                      checked: false,
                    };
                    this.horarios.push(horarioAux);
                  });
                },
                (error) => {
                  this.mesPrevio = this.calendar.monthView._monthLabel;
                  console.error(error);
                }
              );
          } else {
            this.mesPrevio = this.calendar.monthView._monthLabel;
          }
        }
      }
    }
  }

  verificarAbogadosHorario() {
    this.seleccionado = true;
    let respuestaConvertida;
    let aux = 0;
    let posicionEncontrada = 0;
    this.horarios.forEach((horario) => {
      if (
        horario.horaString.toString() ==
        this.horarioSeleccionado.horaString.toString()
      ) {
        posicionEncontrada = aux;
      } else {
        aux++;
      }
    });
    respuestaConvertida = new Date(
      this.usuariosDisponiblesPorHora[posicionEncontrada].hora
    );
    localStorage.setItem(
      'respuestaConvertida',
      JSON.stringify(respuestaConvertida)
    );
    localStorage.setItem('caso', JSON.stringify(this.seleccion.caso));
    this.cantidad =
      this.usuariosDisponiblesPorHora[posicionEncontrada].usuarios.length;
    this.abogadosIds = [];
    this.usuariosDisponiblesPorHora[posicionEncontrada].usuarios.forEach(
      (elemento) => {
        this.abogadosIds.push(parseInt(elemento));
      }
    );
  }

  verAbogados() {
    let listaIds = '';
    this.abogadosIds.forEach((id) => {
      listaIds = listaIds + 'ids=' + id + '&';
    });

    listaIds = listaIds.slice(0, -1);

    this.apiService.getAbogadosDisponiblesAgenda(listaIds).subscribe(
      (abogados) => {
        this.datos.abogados = abogados;
        this.datos.caso = this.seleccion.caso;
        this.datos.titulo = 'Agendar cita';
        this.router.navigateByUrl('/seleccion/previa', { state: this.datos });
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
