import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IphoneScrollService {

  ORIGINAL_CLASS = "casoImpar";
  WITHOUT_HOVER = "casoSinHover";
  TIMER = 500;
  constructor() { }

  public changeClass() {
    let aux = this.ORIGINAL_CLASS;
    if (this.isIos()) {
      aux = this.WITHOUT_HOVER;
    }
    return aux;
  }

  public waitingScrollToFinish = async () => {
    if (this.isIos()) {
      await this.delay(this.TIMER);
    }
    return this.ORIGINAL_CLASS;
  }

private delay = ms => new Promise(res => setTimeout(res, ms));

public isIos() {
    var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    return isIOS;
  }

}
