
<mat-card-header>
  <mat-card-title>Planes</mat-card-title>
  <mat-card-subtitle>{{ getSubTitle() }}</mat-card-subtitle>
</mat-card-header>

<table class="example-full-width" cellspacing="0" *ngIf="!plan">
  <thead>
    <tr>
      <th class="perfil">
        <button mat-raised-button type="button"
          *ngIf="statusCommand==0"
          (click)="doPlanNew()"
        >
          {{ "tooltip.nuevo" | translate }}
        </button>
      </th>
      <th>Id</th>
      <th>Nombre</th>
      <th>Costo</th>
      <th>Costo Anual</th>
      <th>Tipo</th>
      <th>Jurisdicciones</th>
      <th>Especialidades</th>
      <th>Agenda</th>
      <th>Llamada</th>
      <th>Comision</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let plan of cachePlanes;">
      <td>
        <ng-container *ngIf="statusCommand==0">
          <button mat-flat-button color="primary" type="button"
            (click)="doPlanSelect(plan)"
          >
            {{ "tooltip.editar" | translate }}
          </button>
          &nbsp;
          <button mat-flat-button color="warn" type="button"
            (click)="statusCommand = CMS_STATUS_DELETE; statusPlanId = plan.id_plan;"
          >
            {{ "tooltip.eliminar" | translate }}
          </button>  
        </ng-container>
        <ng-container *ngIf="statusCommand==CMS_STATUS_DELETE && statusPlanId == plan.id_plan">
          <button mat-raised-button color="warn"
            class="custom-flex-g8"
            (click)="doDelete(plan.id_plan)"
          > 
            Confirmar
          </button>
          &nbsp;
          <button mat-raised-button type="button" (click)="statusCommand = 0;">Cancelar</button>
        </ng-container>
      </td>
      <td class="text-right">
        {{ plan.id_plan }}
      </td>
      <td>
        {{ plan.nombre }}
      </td>
      <td class="text-right">
        {{ plan.costo }}
      </td>
      <td class="text-right">
        {{ plan.costoAnual }}
      </td>
      <td>
        {{ plan.planTipoId == 1 ? 'Cliente' : 'Abogado' }}
      </td>
      <td class="text-right">
        {{ plan.jurisdicciones }}
      </td>
      <td class="text-right">
        {{ plan.especialidades }}
      </td>
      <td class="perfil">
        <mat-checkbox color="primary" [checked]="plan.agenda" 
          [disabled]="true"
        ></mat-checkbox>
      </td>
      <td class="perfil">
        <mat-checkbox color="primary" [checked]="plan.llamada" 
          [disabled]="true"
        ></mat-checkbox>
      </td>
      <td class="text-right">
        {{ plan.cantidadLegalify }}
      </td>
    </tr>
  </tbody>
</table>

<br />
<form [formGroup]="formPlan" class="input-fullwidth" *ngIf="plan">
  <div class="custom-row custom-pb-2r">
    <div class="custom-col-sm-02">
      <mat-form-field appearance="outline" class="input-fullwidth">
        <mat-label>
          Id
        </mat-label>
        <input
          formControlName="id_plan"
          readonly
          matInput
          placeholder="Id"
          class="text-right"
        />
      </mat-form-field>
    </div>
    <div class="custom-col-sm-05">
      <mat-form-field appearance="outline" class="input-fullwidth">
        <mat-label>
          Nombre
        </mat-label>
        <input
          matInput
          formControlName="nombre"
          placeholder="Nombre"
        />
      </mat-form-field>
    </div>
    <div class="custom-col-sm-03">
      <mat-form-field appearance="outline" class="input-fullwidth">
        <mat-label>
          Tipo
        </mat-label>
        <mat-select
          formControlName="planTipoId"
          required
          disableRipple
        >
          <mat-option [value]="2">Abogado</mat-option>
          <mat-option [value]="1">Cliente</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="custom-col-sm-02">
      <mat-form-field appearance="outline" class="input-fullwidth">
        <mat-label>
          Jurisdicciones
        </mat-label>
        <input
          matInput
          formControlName="jurisdicciones"
          type="number"
          min="0" step="1"
          placeholder="Jurisdicciones"
          class="text-right"
        />
      </mat-form-field>
    </div>
    <div class="custom-col-sm-02">
      <mat-form-field appearance="outline" class="input-fullwidth">
        <mat-label>
          Especialidades
        </mat-label>
        <input
          matInput
          formControlName="especialidades"
          type="number"
          min="0" step="1"
          placeholder="Especialidades"
          class="text-right"
        />
      </mat-form-field>
    </div>
    <div class="custom-col-sm-02 custom-box-center">
      <mat-checkbox color="primary"
        formControlName="agenda"
      >
        Agenda
      </mat-checkbox>
    </div>
    <div class="custom-col-sm-02 custom-box-center">
      <mat-checkbox color="primary"
        formControlName="llamada"
      >
        Llamada
      </mat-checkbox>
    </div>
    <div class="custom-col-sm-02">
      <mat-form-field appearance="outline" class="input-fullwidth">
        <mat-label>
          Comision
        </mat-label>
        <input
          matInput
          formControlName="cantidadLegalify"
          type="number"
          min="0"
          step="0.01"
          placeholder="Comision"
          class="text-right"
        />
      </mat-form-field>
    </div>
    <div class="custom-col-sm-02">
      <mat-form-field appearance="outline" class="input-fullwidth">
        <mat-label>
          Costo
        </mat-label>
        <input
          matInput
          formControlName="costo"
          type="number"
          min="0"
          step="0.01"
          placeholder="Costo"
          class="text-right"
        />
      </mat-form-field>
    </div>
    <div class="custom-col-sm-02">
      <mat-form-field appearance="outline" class="input-fullwidth">
        <mat-label>
          Costo Anual
        </mat-label>
        <input
          matInput
          formControlName="costoAnual"
          type="number"
          min="0"
          step="0.01"
          placeholder="Costo Anual"
          class="text-right"
        />
      </mat-form-field>
    </div>
  </div>
  <mat-card-actions align="end">
    <ng-container *ngIf="statusCommand==0">
      <button mat-raised-button type="button" (click)="doPlanSelect(null)">Cancelar</button>
      <button mat-flat-button color="primary" 
        (click)="statusCommand = CMS_STATUS_SAVE"
      >Guardar</button>
    </ng-container>
    <ng-container *ngIf="statusCommand==CMS_STATUS_SAVE">
      <button mat-raised-button color="warn"
        class="custom-flex-g8"
        (click)="doSave()"
      > 
        Confirmar
      </button>
      <button mat-raised-button type="button" (click)="statusCommand = 0;">Cancelar</button>
    </ng-container>
    
  </mat-card-actions>
</form>
