<div class="casoFondo">
  <div class="header-iconos">
    <app-navbar-abogado></app-navbar-abogado>
    <app-language-nav></app-language-nav>
  </div>
  <mat-tab-group mat-align-tabs="center" *ngIf="perfil">
    <mat-tab label="{{ 'perfilAbogado.perfil' | translate }}">
      <div class="casoFondo">
        <form [formGroup]="cambiarDatos">
          <br />
          <!-- <mat-spinner *ngIf="!terminoCarga"></mat-spinner> -->
          <mat-card class="cardPerfil">
            <div class="imagenCuadrada">
              <img class="sello_sign" matTooltip="Se ha firmado el Manifiesto Legalify" src="assets/sello_sign.png" *ngIf="certified==true">
              <mat-icon
                *ngIf="!tieneFoto && terminoCarga"
                svgIcon="perfil"
                class="imagenDetalles"
              >
              </mat-icon>
              <img
                *ngIf="tieneFoto && terminoCarga"
                class="rounded"
                [src]="avatarUrl | secure | async"
              />
            </div>
            <div class="separadorAdjuntar">
              <label for="file-upload">
                <p class="boton-adjuntar-perfil">
                  {{ "perfilAbogado.foto" | translate }}
                </p>
              </label>
              <input
                id="file-upload"
                type="file"
                (change)="selectFiles($event)"
              />
            </div>
            <br />
            
            <mat-card-title>{{ nombre }} {{ apellido }}</mat-card-title>
            <br />
            <h2>{{ "perfilAbogado.bio" | translate }}</h2>
            <div *ngIf="!editarBio">
              <p>{{ descripcion }}</p><br>
               <button mat-raised-button color="accent" *ngIf="cert_requested==false" (click)="certify()">
                <mat-icon>book</mat-icon>
                {{ "registroAbogado.finalsign" | translate }}
               </button>&nbsp;
                <mat-icon *ngIf="cert_requested==false" style="position: absolute;" matTooltip="{{ 'registroAbogado.finalsignm' | translate }}">info</mat-icon>
               
                <div class="row">
                  <div class="col-6">

                  </div>
                  <div class="col-6">
                    <mat-card  *ngIf="cert_requested==true && certified==false" style="max-width: 300px;background: #d3e2ed;" class="example-card">
                      <mat-card-subtitle></mat-card-subtitle>
                      <mat-card-title>{{ "registroAbogado.sign_remember_title" | translate }}</mat-card-title>
                      <mat-card-content>
                        <p>{{ "registroAbogado.sign_remember_content" | translate }}</p>
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
                
                
               
              <button
                class="boton-primario"
                mat-raised-button
                color="primary"
                [disabled]="!suscripcionSel || suscripcionSel.estado != 'active'"
                (click)="editar(11)"
              >
                {{ "perfilAbogado.editarBio" | translate }}
              </button>
            </div>
            <div *ngIf="editarBio">
              <mat-form-field class="inputs-registro">
                <textarea
                  matInput
                  placeholder="{{ descripcion }}"
                  value="{{ descripcion }}"
                  formControlName="descripcion"
                ></textarea>
              </mat-form-field>
              <button
                class="boton-secundario"
                mat-button
                color="primary"
                (click)="editar(1)"
              >
                {{ "perfilAbogado.guardarbio" | translate }}
              </button>
            </div>
          </mat-card>
          <div class="perfilResponsivo">
            <mat-card class="cardPerfil2">
              <h2>{{ "agregar.jurisdiccion" | translate }}</h2>
              <div *ngFor="let dato of datosJurisdiccion">
                <mat-card-subtitle
                  *ngIf="!dato.aceptada"
                  class="cartelPendienteValidacion"
                  >{{
                    "perfilAbogado.pendienteV" | translate
                  }}</mat-card-subtitle
                >
                <div class="linea">
                  <div *ngIf="dato.aceptada">
                    <mat-card-title>
                      <mat-icon class="azul">place</mat-icon
                      >{{ dato.jurisdiccionNombre }} 
                    </mat-card-title>
                    <br />
                    <mat-card-subtitle
                    *ngIf="perfil.pais!=595">
                      Tomo: {{ dato.tomo }} Folio:
                      {{ dato.folio }} </mat-card-subtitle>
                    <mat-card-subtitle
                    *ngIf="perfil.pais==595">
                      Colegio:{{dato.colegio}} Matricula:{{ dato.otro }}</mat-card-subtitle>
                  </div>
                  <div *ngIf="!dato.aceptada">
                    <mat-card-title class="textoPendienteValidacion">
                      <mat-icon class="textoPendienteValidacion"
                        >place</mat-icon
                      >
                      {{ dato.jurisdiccionNombre }}
                    </mat-card-title>
                    <br />

                    <mat-card-subtitle
                    *ngIf="perfil.pais!=595" class="textoPendienteValidacion">
                      Tomo: {{ dato.tomo }} Folio:
                      {{ dato.folio }} </mat-card-subtitle>
                    <mat-card-subtitle
                    *ngIf="perfil.pais==595" class="textoPendienteValidacion">
                      Colegio:{{dato.colegio}} Matricula:{{ dato.otro }}</mat-card-subtitle>
                  </div>
                  <div>
                    <button
                      
                      (click)="editarJurisdiccion(dato)"
                      mat-icon-button
                    >
                      <mat-icon> edit_outline </mat-icon>
                    </button>
                    <button
                      *ngIf="datosJurisdiccion.length != 1"
                      mat-icon-button
                    >
                      <mat-icon
                      
                        svgIcon="borrar"
                        (click)="borrarJurisdiccion(dato)"
                      >
                      </mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="container-boton">
                <button
                  class="boton-primario"
                  mat-raised-button
                  color="primary"
                  [disabled]="!suscripcionSel || suscripcionSel.estado != 'active'"
                  (click)="verificoPlan(1)"
                >
                  {{ "perfilAbogado.agregarJur" | translate }}
                </button>
              </div>
            </mat-card>

            <mat-card class="cardPerfil3">
              <h2>{{ "perfilAbogado.especialidades" | translate }}</h2>
              <br />
              <div class="desktop">
                <mat-grid-list cols="3" rowHeight="2:1" [gutterSize]="'30px'">
                  <mat-grid-tile
                    *ngFor="let especialidad of especialidadesNombre"
                    class="especialidadBox"
                  >
                    <div class="textoEnGrid">{{ especialidad }}</div>
                    <mat-icon
                      svgIcon="{{ especialidad }}"
                      class="icono-derecha-abajo"
                    >
                    </mat-icon>
                  </mat-grid-tile>
                </mat-grid-list>
              </div>
              <div class="mobile">
                <mat-grid-list cols="2" rowHeight="1:1" [gutterSize]="'30px'">
                  <mat-grid-tile
                    *ngFor="let especialidad of especialidadesNombre"
                    class="especialidadBox"
                  >
                    <div class="textoEnGridPerfilAbogado">
                      {{ especialidad }}
                    </div>
                    <mat-icon
                      svgIcon="{{ especialidad }}"
                      class="icono-derecha-abajo"
                    >
                    </mat-icon>
                  </mat-grid-tile>
                </mat-grid-list>
              </div>
              <br /><br />
              <div class="container-boton">
                <button
                  class="boton-primario"
                  mat-raised-button
                  color="primary"
                  [disabled]="!suscripcionSel || suscripcionSel.estado != 'active'"
                  (click)="verificoPlan(2)"
                >
                  {{ "perfilAbogado.agregarEspe" | translate }}
                </button>
              </div>
            </mat-card>

            <mat-card class="cardPerfil2">
              <h2>{{ "perfilAbogado.datosPer" | translate }}</h2>
              <br />
              <div *ngIf="!editarDatos">
                <div class="mobile">
                  <div class="dosDatos">
                    <span>
                      <mat-card-subtitle>{{
                        "perfilAbogado.nombre" | translate
                      }}</mat-card-subtitle>
                      <p>{{ nombre }}</p>
                    </span>
                    <span class="izq">
                      <mat-card-subtitle>
                        {{
                          "perfilAbogado.apellido" | translate
                        }}</mat-card-subtitle
                      >
                      <p>{{ apellido }}</p>
                    </span>
                  </div>
                  <br />
                  <mat-card-subtitle>
                    {{ "perfilAbogado.correo" | translate }}</mat-card-subtitle
                  >
                  <p>{{ mail }}</p>
                </div>
                <div class="desktop">
                  <div class="tresDatos">
                    <span>
                      <mat-card-subtitle>{{
                        "perfilAbogado.nombre" | translate
                      }}</mat-card-subtitle>
                      <p>{{ nombre }}</p>
                    </span>
                    <span class="izq">
                      <mat-card-subtitle>
                        {{
                          "perfilAbogado.apellido" | translate
                        }}</mat-card-subtitle
                      >
                      <p>{{ apellido }}</p>
                    </span>
                    <span class="izq">
                      <mat-card-subtitle>
                        {{
                          "perfilAbogado.correo" | translate
                        }}</mat-card-subtitle
                      >
                      <p>{{ mail }}</p>
                    </span>
                  </div>
                </div>
                <br />
                <div class="dosDatos">
                  <span>
                    <mat-card-subtitle>{{
                      "perfilAbogado.celular" | translate
                    }}</mat-card-subtitle>
                    <p>{{ telefono }}</p>
                  </span>
                  <span class="izq">
                    <mat-card-subtitle>{{
                      perfil.datosProfesionales[0].tipoDni
                    }}</mat-card-subtitle>
                    <p>{{ numeroDni }}</p>
                  </span>
                </div>
                <span class="izq">
                  <mat-card-subtitle>{{
                    "perfilAbogado.provincia" | translate
                  }}</mat-card-subtitle>
                  <p>{{ perfil.provincia }}</p>
                </span>
                <span class="izq">
                  <mat-card-subtitle>
                    {{
                      "perfilAbogado.localidad" | translate
                    }}</mat-card-subtitle
                  >
                  <p>{{ perfil.ciudad }}</p>
                </span>

                <br />
                <div class="tresDatos">
                  <span>
                    <mat-card-subtitle>{{
                      "perfilAbogado.domicilio" | translate
                    }}</mat-card-subtitle>
                    <p>{{ direccion }}</p>
                  </span>
                  <span class="izq">
                    <mat-card-subtitle>{{
                      "perfilAbogado.codigo" | translate
                    }}</mat-card-subtitle>
                    <p>{{ codigoPostal }}</p>
                  </span>
                  <span class="izq">
                    <mat-card-subtitle>{{
                      paisConfigCuit?.sdesc
                    }}</mat-card-subtitle>
                    <p>{{ cuit }}</p>
                  </span>
                </div>
                
                <br />
                <div class="tresDatos">
                  <span>
                    <mat-card-subtitle>
                      Promocion
                    </mat-card-subtitle>
                    <p>{{ promocion }}</p>
                  </span>
                </div>

                <div class="tresDatos" *ngIf="otro">
                  <span>
                    <mat-card-subtitle>{{
                      "registroAbogado.otro" | translate
                    }}</mat-card-subtitle>
                    <p>{{ otro }}</p>
                  </span>
                  <span class="izq">
                    
                  </span>
                  <span class="izq">
                    
                  </span>
                </div>

                <div class="container-boton">
                  <button
                    class="boton-primario"
                    mat-raised-button
                    color="primary"
                    (click)="editar(44)"
                  >
                    {{ "perfilAbogado.editarDet" | translate }}
                  </button>
                </div>
              </div>

              <div *ngIf="editarDatos">
                <mat-form-field appearance="outline" class="inputs-registro">
                  <mat-label>{{
                    "perfilAbogado.nombre" | translate
                  }}</mat-label>
                  <input
                    required
                    matInput
                    type="text"
                    formControlName="nombre"
                  />
                </mat-form-field>
                <mat-form-field appearance="outline" class="inputs-registro">
                  <mat-label>{{
                    "perfilAbogado.apellido" | translate
                  }}</mat-label>
                  <input
                    required
                    matInput
                    type="text"
                    placeholder="{{ apellido }}"
                    formControlName="apellido"
                  />
                </mat-form-field>
                <mat-form-field appearance="outline" class="inputs-registro">
                  <mat-label>{{
                    "perfilAbogado.correo" | translate
                  }}</mat-label>
                  <input
                    required
                    matInput
                    type="mail"
                    placeholder="{{ mail }}"
                    formControlName="mail"
                    readonly
                  />
                </mat-form-field>
                <mat-form-field appearance="outline" class="inputs-registro">
                  <mat-label>{{
                    "perfilAbogado.celular" | translate
                  }}</mat-label>
                  <input
                    required
                    matInput
                    type="text"
                    formControlName="telefono"
                    value="telefono"
                  />
                </mat-form-field>

                <mat-form-field appearance="outline" class="inputs-registro">
                  <mat-label>{{ "registroAbogado.tipoIdenti" | translate }}</mat-label>
                  <mat-select
                    formControlName="tipoIdentificacion"
                    required
                    disableRipple
                  >
                    <mat-option
                      *ngFor="let identificacion of identificaciones"
                      [value]="identificacion"
                      >{{ identificacion.nombre }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>


                
                <mat-form-field appearance="outline" class="inputs-registro">
                  <mat-label>{{
                    "registroAbogado.nroDocumento" | translate
                  }}</mat-label>
                  <input
                    required
                    matInput
                    type="number"
                    placeholder="{{ dni }}"
                    formControlName="dni"
                  />
                </mat-form-field>

                <br />
                <mat-card-subtitle class="inputs-registro"
                  >Residencia</mat-card-subtitle
                >
                <mat-form-field appearance="outline" class="inputs-registro">
                  <mat-label>{{ perfil.provincia }}</mat-label>
                  <mat-select
                    formControlName="provinciaResidencia"
                    (selectionChange)="cargarLocalidadesResidencia()"
                  >
                    <mat-option
                      *ngFor="let provincia of provinciasResidencias"
                      [value]="provincia"
                    >
                      {{ provincia.nombre }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="inputs-registro">
                  <mat-label>{{ perfil.ciudad }}</mat-label>
                  <mat-select formControlName="localidadResidencia">
                    <mat-option
                      *ngFor="let localidad of localidadesResidencias"
                      [value]="localidad"
                      >{{ localidad.nombre }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="inputs-registro">
                  <mat-label>{{
                    "perfilAbogado.domicilio" | translate
                  }}</mat-label>
                  <input
                    required
                    matInput
                    type="text"
                    formControlName="direccion"
                  />
                </mat-form-field>

                <mat-form-field appearance="outline" class="inputs-registro">
                  <mat-label>{{
                    "perfilAbogado.codigo" | translate
                  }}</mat-label>
                  <input
                    type="number"
                    required
                    matInput
                    min="0"
                    type="number"
                    (input)="fixDigits(5, $event)"
                    placeholder="{{ codigoPostal }}"
                    formControlName="codigoPostal"
                    value="codigoPostal"
                  />
                </mat-form-field>

                <mat-form-field appearance="outline" class="inputs-registro">
                  <mat-label>{{
                    paisConfigCuit.sdesc
                  }}</mat-label>
                  <input
                    matInput
                    type="text"
                    placeholder="{{ paisConfigCuit.sdesc }}"
                    formControlName="cuit"
                    value="cuit"
                    (input)="doCuitFix($event)"
                  />
                </mat-form-field>
                <mat-form-field appearance="outline" class="inputs-registro">
                  <mat-label>
                    Promocion
                  </mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="promocion"
                  />
                </mat-form-field>

                <div class="container-boton">
                  <button
                    class="boton-primario"
                    mat-raised-button
                    color="primary"
                    (click)="editar(4)"
                  >
                    {{ "perfilAbogado.guardarDet" | translate }}
                  </button>
                </div>
              </div>
            </mat-card>

            <!-- <mat-card class="cardPerfil2">
                          <mat-card-title>Contraseña</mat-card-title>
                          <mat-form-field appearance="outline" class="inputs">
                              <mat-label>Contraseña</mat-label>
                              <input required matInput placeholder="Contraseña" [type]="hide ? 'password' : 'text'"
                                  formControlName="password">
                              <button mat-icon-button matSuffix (click)="hide = !hide"
                                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </button>
                          </mat-form-field>
                          <div class="container-boton">
                              <button class="boton-secundario" mat-button color="primary" (click)="editar(55)">
                                  CAMBIAR CONTRASEÑA
                              </button>
                          </div>
                      </mat-card> -->

            <mat-card class="cardPerfil2" *ngIf="!isSuscriptionActiveAndAgenda()" >
              <h2>{{ "perfilAbogado.agenda" | translate }}</h2>
              <br />
              <div style="color: red;">
                <div class="separadorCentrado">
                  <mat-icon class="iconoSinPlan">error_outlined</mat-icon>
                  <h1>Plan sin Agendas</h1>
                  <p class="subtitle">
                    Contrata un plan PREMIUM o CORPORATIVO<br />
                    Para habilitar esta caracteristica.
                  </p>
                </div>
              </div>        
            </mat-card>
            <mat-card class="cardPerfil3" *ngIf="isSuscriptionActiveAndAgenda()" >
              <h2>{{ "perfilAbogado.agenda" | translate }}</h2>
              <br />
              <div *ngFor="let dia of diasAgenda">
                <form
                  class="example-form"
                  [formGroup]="formGroup"
                  ngNativeValidate
                >
                  <br />
                  <div class="linea">
                    <mat-card-title>{{ dia.nombre }}</mat-card-title>
                    <button mat-button (click)="activar(dia)">
                      <mat-icon *ngIf="!dia.activado">expand_more</mat-icon>
                      <mat-icon *ngIf="dia.activado">expand_less</mat-icon>
                    </button>
                  </div>
                  <br />
                  <div *ngIf="dia.activado">
                    <div class="container">
                      <form
                        [formGroup]="
                          productFormArray[dia.rangeHour].productForm
                        "
                      >
                        <table
                          class="table table-bordered"
                          formArrayName="quantities"
                        >
                          <tr>
                            <th width="150px"></th>
                          </tr>
                          <tr
                            *ngFor="
                              let quantity of quantities(dia.rangeHour)
                                .controls;
                              let i = index
                            "
                            [formGroupName]="i"
                          >
                            <td class="inputs-registro">
                              <mat-form-field
                                appearance="outline"
                                class="inputs-horariosAgenda"
                              >
                                <mat-label>{{
                                  "perfilAbogado.horaIn" | translate
                                }}</mat-label>
                                <mat-select formControlName="init">
                                  <mat-option
                                    *ngFor="
                                      let range of getInitRanges(
                                        i,
                                        dia.rangeHour
                                      )
                                    "
                                    [value]="range.value"
                                    (click)="
                                      loadEndRangeHours(
                                        range.value,
                                        dia.rangeHour
                                      )
                                    "
                                  >
                                    {{ range.viewValue }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>

                              <mat-form-field
                                appearance="outline"
                                class="inputs-horariosAgenda"
                              >
                                <mat-label>{{
                                  "perfilAbogado.horaFin" | translate
                                }}</mat-label>
                                <mat-select
                                  [disabled]="dia.endHourButton"
                                  formControlName="end"
                                >
                                  <mat-option
                                    *ngFor="
                                      let range of getEndRanges(
                                        i,
                                        dia.rangeHour
                                      )
                                    "
                                    [value]="range.value"
                                  >
                                    {{ range.viewValue }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                              <button mat-icon-button>
                                <mat-icon
                                  svgIcon="borrar"
                                  (click)="removeQuantity(i, dia.rangeHour)"
                                >
                                </mat-icon>
                              </button>
                            </td>
                          </tr>
                        </table>
                        <button
                          class="boton-secundario"
                          mat-button
                          color="primary"
                          (click)="addQuantity(dia.rangeHour)"
                          [hidden]="dia.addRanges"
                          [disabled]="dia.addRange"
                        >
                          {{ "perfilAbogado.agregarRan" | translate }}
                        </button>
                        <button
                          class="boton-primario"
                          mat-raised-button
                          color="primary"
                          (click)="onFormSubmitMasHorarios(dia.rangeHour)"
                          [disabled]="!dia.addRange"
                        >
                          {{ "perfilAbogado.guardarHorario" | translate }}
                        </button>
                      </form>
                      <br />
                    </div>
                  </div>
                  <hr color="#D8D8D8" />
                </form>
              </div>
            </mat-card>

            <mat-card class="cardPerfil2" *ngIf="!isPlanExpensive()" >
              <h2>{{ "perfilAbogado.llamada" | translate }}</h2>
              <br />
              <div style="color: red;">
                <div class="separadorCentrado">
                  <mat-icon class="iconoSinPlan">error_outlined</mat-icon>
                  <h1>Plan sin llamadas</h1>
                  <p class="subtitle">
                    Contrata un plan PREMIUM o CORPORATIVO<br />
                    Para habilitar esta caracteristica.
                  </p>
                </div>
              </div>        
            </mat-card>
            <mat-card class="cardPerfil2" *ngIf="isPlanExpensive()" >
              <h2>{{ "perfilAbogado.llamada" | translate }}</h2>
              <br />
              <div *ngFor="let dia of diasLlamada">
                <form
                  class="example-form"
                  [formGroup]="formGroup"
                  ngNativeValidate
                >
                  <br />
                  <div class="linea">
                    <mat-card-title>{{ dia.nombre }}</mat-card-title>
                    <button mat-button (click)="activarLlamada(dia)">
                      <mat-icon *ngIf="!dia.activado">expand_more</mat-icon>
                      <mat-icon *ngIf="dia.activado">expand_less</mat-icon>
                    </button>
                  </div>
                  <br />
                  <div *ngIf="dia.activado">
                    <td class="inputs-registro">
                      <mat-form-field
                        (click)="modificar(dia, true)"
                        appearance="outline"
                        class="inputs-horariosAgenda"
                      >
                        <mat-label
                          >{{
                            dia.inicioString
                              ? dia.inicioString
                              : "No hay horario"
                          }}
                        </mat-label>
                        <mat-select [ngModel]="dia.inicioString"  formControlName="inicio">
                          <mat-option
                            *ngFor="let hour of initCallHours"
                            [value]="hour.value"
                          >
                            {{
                              dia.modificar ? hour.viewValue : dia.inicioString
                            }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field
                        (click)="modificar(dia, true)"
                        appearance="outline"
                        class="inputs-horariosAgenda"
                      >
                        <mat-label
                          >{{
                            dia.finString ? dia.finString : "No hay horario"
                          }}
                        </mat-label>
                        <mat-select [ngModel]="dia.finString"  formControlName="fin" >
                          <mat-option
                            *ngFor="let hour of endCallHours"
                            [value]="hour.value"
                          >
                            {{ dia.modificar ? hour.viewValue : dia.finString }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <button
                        [disabled]="!dia.borrado"
                        mat-icon-button
                        (click)="borrarHorarioLlamada(dia)"
                      >
                        <mat-icon svgIcon="borrar"> </mat-icon>
                      </button>
                    </td>
                    <br />
                    <button
                      class="boton-primario"
                      mat-raised-button
                      color="primary"
                      (click)="onFormSubmitLlamada(dia)"
                      [disabled]="!formGroup.valid"
                    >
                      Guardar este día
                    </button>
                  </div>
                  <hr color="#D8D8D8" />
                </form>
              </div>
            </mat-card>

            <mat-card class="cardPerfil3">
              <h2>{{ "perfilAbogado.plan" | translate }}</h2>
              <app-plan-en-perfil
                [cuenta]="cuenta"
                [plan]="planSel"
                [planPrecio]="suscripcionSel ? (suscripcionSel.monto ? suscripcionSel.monto : '0.00') : 0"
                [suscripcion]="suscripcionSel"
                [textoSubscripcion]="suscripcion ? suscripcionSel.planNombre : ''"
              ></app-plan-en-perfil>
            </mat-card>
          </div>
        </form>

        <app-opiniones
          [datosPerfil]="perfil"
          [opiniones]="opiniones"
        ></app-opiniones>

        <mat-card class="cardPerfil">
          <button
            class="boton-secundario"
            mat-button
            color="primary"
            (click)="cerrarSesion()"
          >
            {{ "perfilAbogado.cerrar" | translate }}
          </button>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab label="{{ 'perfilAbogado.vista' | translate }}">
      <div class="casoFondo">
        <div class="cuadradoLimitado">
          <app-vista-previa
            *ngIf="nombre"
            [mostrarBoton]="false"
            [abogado]="perfil"
            [avatarUrl]="avatarUrl"
            [tieneFoto]="tieneFoto"
            [certified]="certified"
          >
          </app-vista-previa>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
