<mat-toolbar class="header">
  <button mat-button [routerLink]="['/perfil-abogado']">
    <mat-icon class="icono">chevron_left</mat-icon>
  </button>
  <span class="example-spacer"></span>
  <span>Planes</span>
</mat-toolbar>

<div class="casoFondo">
  <div>
    <mat-tab-group dynamicHeight mat-align-tabs="center" class="tabs">
      <mat-tab class="mont" label="MENSUALES">
        <div class="casoFondo">
          <br />
          <div class="separadorCentrado">
            <br />
            <div class="casos-flexbox" *ngFor="let plan of cachePlanes">
              <ng-container *ngIf="suscripcionSel; else planElse" >
                <div>
                  <ng-container *ngIf="isPlanWithSuscription(plan, plan.planPlataformasMes, suscripcionSel); else suscripcionPlanElse">
                    <ng-container *ngIf="isSuscripcionActivo(plan, suscripcionSel);">
                      <div class="tiempoSubscripcion">
                        <span>{{ textoSubscripcion }}</span>
                        <br />
                        <span *ngIf="suscripcionSel.estadoRaw == 'pending_canceled'">{{ 'plan.textoFinalizacion' | translate }}</span>
                      </div>
                      <mat-card class="cardSeleccionada">
                        <br />
                        <app-detalle
                          [anual]="false"
                          [plan]="plan"
                          [planPrecio]="plan.costo"
                          [planNombre]="plan.nombre"
                          [planContratado]="suscripcionSel"
                          [mostrarAyuda]="true"
                        ></app-detalle>
                        <div class="container-boton">
                          <button
                            *ngIf="suscripcionSel.estadoRaw !== 'pending_canceled'"
                            class="boton-continuar"
                            mat-button
                            (click)="cancelar()"
                          >
                            Cancelar plan
                          </button>
                        </div>
                      </mat-card>
                    </ng-container>

                    <ng-container *ngIf="isSuscripcionInactivo(plan, suscripcionSel);">
                      <div class="tiempoSubscripcion" style="color: white; text-shadow: 1px 1px black;">
                        <span style="background-color: red
                        ;" *ngIf="suscripcionSel.estado == 'canceled'">INACTIVO</span>
                        <span style="background-color: red;" *ngIf="suscripcionSel.estado == 'pending'">PENDIENTE DE PAGO</span>
                      </div>
                      <mat-card class="cardSeleccionada">
                        <br />
                        <app-detalle
                          [anual]="false"
                          [plan]="plan"
                          [planNombre]="plan.nombre"
                          [planPrecio]="plan.costo"
                          [mostrarAyuda]="false"
                        ></app-detalle>

                        <ng-container *ngIf="!isSuscripcionActivoWithRaw()">
                          <div *ngFor="let planPlataforma of plan.planPlataformasMes;" >
                            <ng-container *ngIf="suscripcionSel.estado == 'canceled'; else elsePendingButton">
                              <button
                                *ngIf="suscripcionSel.estado == 'canceled'"
                                class="boton-continuar"
                                mat-button
                                (click)="elegirPlanPlataforma(planPlataforma)"
                              >
                                Renovar plan
                              </button>
                            </ng-container>
                            <ng-template #elsePendingButton>
                              <ng-container *ngIf="suscripcionSel.uuidPlan == planPlataforma.uuid && suscripcionSel.estado == 'pending'; else elseDefaultButton">
                                <button
                                  class="boton-continuar"
                                  mat-button
                                  (click)="contratar({checkoutLink: suscripcionSel.urlCheckout})"
                                >
                                  Contratar plan
                                </button>
                              </ng-container>
                            </ng-template>
                            <ng-template #elseDefaultButton>
                              <button
                                  mat-raised-button
                                  class="boton-primario"
                                  color="primary"
                                  (click)="elegirPlanPlataforma(planPlataforma)"
                                >
                                  {{ planPlataforma.plataformaNombre }} - {{planPlataforma.monto}}/{{ planPlataforma.monedaId }}
                                </button>
                            </ng-template>
                          </div>
                        </ng-container>
                      </mat-card>
                    </ng-container>
                  </ng-container>
                  <ng-template #suscripcionPlanElse>
                    <div class="tiempoSubscripcion2">
                      <span>{{ textoSubscripcion }}</span>
                    </div>
                    <mat-card class="casoscard">
                      <br />
                      <app-detalle
                        [anual]="false"
                        [plan]="plan"
                        [planNombre]="plan.nombre"
                        [planPrecio]="plan.costo"
                        [mostrarAyuda]="true"
                      ></app-detalle>
                      <ng-container *ngIf="!isSuscripcionActivoWithRaw()">
                        <div *ngFor="let planPlataforma of plan.planPlataformasMes;">
                          <button
                            class="boton-primario"
                            mat-raised-button
                            color="primary"
                            (click)="elegirPlanPlataforma(planPlataforma)"
                          >
                            {{ planPlataforma.plataformaNombre }} - {{planPlataforma.monto}}/{{ planPlataforma.monedaId }}
                          </button>
                        </div>
                      </ng-container>
                      </mat-card>
                  </ng-template>
                </div>
              </ng-container>

              <ng-template #planElse>
                <div>
                  <div class="tiempoSubscripcion2">
                    <span>{{ textoSubscripcion }}</span>
                  </div>
                  <mat-card class="casoscard">
                    <br />
                    <app-detalle
                      [anual]="false"
                      [plan]="plan"
                      [planNombre]="plan.nombre"
                      [planPrecio]="plan.costo"
                      [mostrarAyuda]="true"
                    ></app-detalle>

                    <ng-container *ngIf="!isSuscripcionActivoWithRaw()">
                      <div *ngFor="let planPlataforma of plan.planPlataformasMes;">
                        <button
                          class="boton-primario"
                          mat-raised-button
                          color="primary"
                          (click)="elegirPlanPlataforma(planPlataforma)"
                        >
                          {{ planPlataforma.plataformaNombre }} - {{planPlataforma.monto}}/{{ planPlataforma.monedaId }}
                        </button>
                      </div>
                    </ng-container>
                  </mat-card>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="ANUALES">
        <div class="casoFondo">
          <br />
          <div class="separadorCentrado">
            <br />
            <div class="casos-flexbox" *ngFor="let plan of cachePlanes">
              <ng-container *ngIf="suscripcionSel; else planElse" >
                <div>
                  <ng-container *ngIf="isPlanWithSuscription(plan, plan.planPlataformasMes, suscripcionSel); else suscripcionPlanElse">
                    <ng-container *ngIf="isSuscripcionActivo(plan, suscripcionSel);">
                      <div class="tiempoSubscripcion">
                        <span>{{ textoSubscripcion }}</span>
                        <br />
                        <span *ngIf="suscripcionSel.estadoRaw == 'pending_canceled'">{{ 'plan.textoFinalizacion' | translate }}</span>
                      </div>
                      <mat-card class="cardSeleccionada">
                        <br />
                        <app-detalle
                          [anual]="true"
                          [plan]="plan"
                          [planNombre]="plan.nombre"
                          [planPrecio]="plan.costoAnual"
                          [planContratado]="suscripcionSel"
                          [mostrarAyuda]="true"
                        ></app-detalle>
                        <div class="container-boton">
                          <button
                            *ngIf="suscripcionSel.estadoRaw !== 'pending_canceled'"
                            class="boton-continuar"
                            mat-button
                            (click)="cancelar()"
                          >
                            Cancelar plan
                          </button>
                        </div>
                      </mat-card>
                    </ng-container>

                    <ng-container *ngIf="isSuscripcionInactivo(plan, suscripcionSel);">
                      <div class="tiempoSubscripcion" style="color: red;">
                        <span *ngIf="suscripcionSel.estado == 'canceled'">INACTIVO</span>
                        <span *ngIf="suscripcionSel.estado == 'pending'">PENDIENTE DE PAGO</span>
                      </div>
                      <mat-card class="cardSeleccionada">
                        <br />
                        <app-detalle
                          [anual]="true"
                          [plan]="plan"
                          [planNombre]="plan.nombre"
                          [planPrecio]="plan.costoAnual"
                          [mostrarAyuda]="false"
                        ></app-detalle>

                        <ng-container *ngIf="!isSuscripcionActivoWithRaw()">
                          <div *ngFor="let planPlataforma of plan.planPlataformasAnio;" >
                            <ng-container *ngIf="suscripcionSel.estado == 'canceled'; else elsePendingButton">
                              <button
                                *ngIf="suscripcionSel.estado == 'canceled'"
                                class="boton-continuar"
                                mat-button
                                (click)="elegirPlanPlataforma(planPlataforma)"
                              >
                                Renovar plan
                              </button>
                            </ng-container>
                            <ng-template #elsePendingButton>
                              <ng-container *ngIf="suscripcionSel.uuidPlan == planPlataforma.uuid && suscripcionSel.estado == 'pending'; else elseDefaultButton">
                                <button
                                  class="boton-continuar"
                                  mat-button
                                  (click)="contratar({checkoutLink: suscripcionSel.urlCheckout})"
                                >
                                  Contratar plan
                                </button>
                              </ng-container>
                            </ng-template>
                            <ng-template #elseDefaultButton>
                              <button
                                  mat-raised-button
                                  class="boton-primario"
                                  color="primary"
                                  (click)="elegirPlanPlataforma(planPlataforma)"
                                >
                                  {{ planPlataforma.plataformaNombre }} - {{planPlataforma.monto}}/{{ planPlataforma.monedaId }}
                                </button>
                            </ng-template>
                            
                          </div>
                        </ng-container>
                      </mat-card>
                    </ng-container>
                  </ng-container>
                  <ng-template #suscripcionPlanElse>
                    <div class="tiempoSubscripcion2">
                      <span>{{ textoSubscripcion }}</span>
                    </div>
                    <mat-card class="casoscard">
                      <br />
                      <app-detalle
                        [anual]="true"
                        [plan]="plan"
                        [planNombre]="plan.nombre"
                        [planPrecio]="plan.costoAnual"
                        [mostrarAyuda]="true"
                      ></app-detalle>
                      <ng-container *ngIf="!isSuscripcionActivoWithRaw()">
                        <div *ngFor="let planPlataforma of plan.planPlataformasAnio;">
                          <button
                            class="boton-primario"
                            mat-raised-button
                            color="primary"
                            (click)="elegirPlanPlataforma(planPlataforma)"
                          >
                            {{ planPlataforma.plataformaNombre }} - {{planPlataforma.monto}}/{{ planPlataforma.monedaId }}
                          </button>
                        </div>
                      </ng-container>
                      </mat-card>
                  </ng-template>
                </div>
              </ng-container>

              <!--ELSE -->
              <ng-template #planElse>
                <div>
                  <div class="tiempoSubscripcion2">
                    SS153
                    <span>{{ textoSubscripcion }}</span>
                  </div>
                  <mat-card class="casoscard">
                    <br />
                    <app-detalle
                      [anual]="true"
                      [plan]="plan"
                      [planNombre]="plan.nombre"
                      [planPrecio]="plan.costoAnual"
                      [mostrarAyuda]="true"
                    ></app-detalle>
                    
                    <ng-container *ngIf="!isSuscripcionActivoWithRaw()">
                      <div *ngFor="let planPlataforma of plan.planPlataformasAnio;">
                        <button
                          class="boton-primario"
                          mat-raised-button
                          color="primary"
                          (click)="elegirPlanPlataforma(planPlataforma)"
                        >
                          {{ planPlataforma.plataformaNombre }} - {{planPlataforma.monto}}/{{ planPlataforma.monedaId }}
                        </button>
                      </div>
                    </ng-container>
                  </mat-card>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
