import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/api/api.service';
import { ReturnUser } from 'src/app/api/user';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  selector: 'app-language-nav',
  templateUrl: './language-nav.component.html',
  styleUrls: ['./language-nav.component.css']
})
export class LanguageNavComponent implements OnInit {
  language: String = "";
  imgSelected: String = "";
  constructor(
    private apiService: ApiService,
    public translate: TranslateService,private authenticationService: AuthenticationService,) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    if(sessionStorage.getItem('language')==null){
      this.language ='es-AR';
      translate.setDefaultLang('es-AR');
    }
    else{
      this.language = sessionStorage.getItem('language');
      translate.setDefaultLang(sessionStorage.getItem('language'));
    }
    translate.setDefaultLang(sessionStorage.getItem('language'));
    if(this.language == "es-AR")
      this.imgSelected = "../../../assets/españa.png"
    if(this.language == "it-IT")
      this.imgSelected = "../../../assets/italia.png"
    if(this.language == "en-US")
      this.imgSelected = "../../../assets/ingles.png"
    }

  ngOnInit(): void {
  }

  switchLang(lang: string) {
    console.log(lang)
    this.translate.use(lang);
    this.language = lang;
    sessionStorage.setItem('language', lang);
    sessionStorage.language = lang;
    let user:ReturnUser = JSON.parse(localStorage.getItem('user'));
    let leng="";
    
    if(lang == "es-AR"){
      this.imgSelected = "../../../assets/españa.png"
      leng="P";
    }
    if(lang == "it-IT"){
      this.imgSelected = "../../../assets/italia.png"
      leng="T"
    } 
    if(lang == "en-US"){
      this.imgSelected = "../../../assets/ingles.png"
      leng="N"
    }
    this.apiService
      .cambiarLenguaje({
        mail: user.mail,
        lenguage: leng
        
      } as any)
      .subscribe(
        (res) => {
          console.log("Cambios Langu");
        },
        (error) => {
          console.error(error);
          
        }
      );  
  }

}
