<div class="casoFondo">
    <mat-toolbar class="header">
      <span class="example-spacer"></span>
      <span>{{ "paises.admin" | translate }}</span>
  
      <br />
      <button class="cerrarSesionAdmin" mat-button (click)="cerrarSesion()">
          <mat-icon class="icono">logout</mat-icon>
      </button>
  </mat-toolbar>
  
<br />
<app-navbar-admin></app-navbar-admin>


<mat-card class="admin">
    <img class="logo" src="../../../assets/logo.jpg" />
    <br />

    <button mat-button  (click)="addFormulario()" *ngIf="formulario==undefined || formulario==null">
                                <mat-icon>add_box</mat-icon>Agregar Formulario
                            </button>

    <button mat-button (click)="addQuestion()" *ngIf="formulario!=undefined && formulario!=null">
                              <mat-icon>add_box</mat-icon>Agregar Pregunta
                          </button>                        

    <br />


      <div class="table-container" *ngIf="formulario!=undefined && formulario.preguntas!=undefined">
        <table mat-table [dataSource]="formulario.preguntas" style="width: 80%;margin: auto;">
          <ng-container matColumnDef="id_pregunta">
            <th mat-header-cell *matHeaderCellDef>
              ID
            </th>
            <td mat-cell *matCellDef="let pregunta">{{ pregunta.id_pregunta }}</td>
          </ng-container>
  
          <ng-container matColumnDef="pregunta">
            <th mat-header-cell *matHeaderCellDef>
              Pregunta
            </th>
            <td mat-cell *matCellDef="let pregunta">{{ pregunta.pregunta }}</td>
          </ng-container>
  
  
          <ng-container matColumnDef="respuesta">
            <th mat-header-cell *matHeaderCellDef>
              Respuesta
            </th>
            <td mat-cell *matCellDef="let pregunta">
              {{ pregunta.respuesta }}
            </td>
          </ng-container>

          <ng-container matColumnDef="peso">
            <th mat-header-cell *matHeaderCellDef>
              Peso
            </th>
            <td mat-cell *matCellDef="let pregunta">
              {{ pregunta.peso }}
            </td>
          </ng-container>
  
        
          <ng-container matColumnDef="action" >
            <th mat-header-cell *matHeaderCellDef>
              {{ "registroEntidad.acciones" | translate }}
            </th>
           
            <td mat-cell *matCellDef="let pregunta">
              <button mat-icon-button matSuffix (click)="edit(pregunta)" >
                <mat-icon>settings</mat-icon>
              </button>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
  </mat-card>
</div>
