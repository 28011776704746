import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../modules/material.module';
import { CertificacionComponent } from './certificacion.component';
import { CertificacionRoutingModule } from './certificacion-routing.module';
import { NavbarAdminmodule } from '../navbar-admin/navbar-admin.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CertificacionComponent],
  imports: [
    BrowserModule,
    MaterialModule,
    ReactiveFormsModule,
    CertificacionRoutingModule,
    NavbarAdminmodule,
    MatExpansionModule,
    TranslateModule,
  ],
  exports: [],
  providers: [],
  bootstrap: [],
})
export class CertificacionModule {}
