import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InicioComponent } from './inicio.component';
import { AuthGuard } from '../../../auth.guard';
import { Role } from '../../../enums/role';
import { PaisesComponent } from '../paises/paises.component';
import { EntidadesComponent } from '../entidades/entidades.component';
import { CertificacionComponent } from '../certificacion/certificacion.component';
import { AdminComponent } from '../admin.component';
import { PlanListComponent } from '../plan/plan-list.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    children: [{
      path: '',
      component: InicioComponent,
      canActivate: [AuthGuard],
      data: { roles: [Role.Admin] },
    }, {
      path: 'plan',
      component: PlanListComponent,
      canActivate: [AuthGuard],
      data: { roles: [Role.Admin] },
    }, {
      path: 'multipago',
      loadChildren: () => import('../multipago/modules/multipago.module').then(module => module.MultipagoModule),
      canLoad: [AuthGuard],
      data: { roles: [Role.Admin] }
    }]
  },

  { path: 'paises', component: PaisesComponent },
  { path: 'entidades', component: EntidadesComponent },
  { path: 'certificacion', component: CertificacionComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class InicioRoutingModule {}
