export const environment = {
  production: true,
  apiUrl : "https://login.legalify-app.com/service/legalify",
  angularUrl: 'https://login.legalify-app.com',
  measurement_id:'G-S8ZS180DR0',
  //measurement_id:'G-JCMXK9TH2P'
  mercadopago_token: 'APP_USR-06602490-3f74-40b1-beed-577bfc395c4c',
  paypal_clientid: 'ATUA-JWZMQ945_zHBddEkg4y0azoOYZ_GgKUY6vuqsphdWOsbQElEQa9rf_HsD686eMf3A637JAS-45V',
  paypal_partner_url: 'https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js'
};
