import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataObject } from 'src/app/api/reporte';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
    private url = environment.apiUrl + '/';
    headers = new HttpHeaders({
          'Content-Type': 'application/json',
        })
    constructor(  private http: HttpClient ) {}

    crearReporte(){
        return this.http.get<DataObject>(
          this.url +
            `api/reportes/general`,
          {headers: this.headers }
        );
      }
}
