import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RestablecerComponent } from './restablecer/restablecer.component';
import { NuevaFormularioComponent } from './nueva-formulario/nueva-formulario.component';
import { RedesComponent } from './redes/redes.component';
import { BienvenidaComponent } from './bienvenida/bienvenida.component';

const routes: Routes = [
  {
    path: 'restablecer',
    component: RestablecerComponent
  },
  {
    path: 'nueva/:id',
    component: NuevaFormularioComponent
  },
  {
    path: 'redes',
    component: RedesComponent
  },
  {
    path: 'bienvenida',
    component: BienvenidaComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }