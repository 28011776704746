import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api/api.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CasoDetallado, ListaArchivos } from '../../../../api/user';
import { StorageService } from 'src/app/utils/storage/storage.service';
import { FormatDateService } from 'src/app/utils/formatDate/formatDate.service.';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { StatusIncrease } from 'src/app/api/planes';
import { PlanService } from 'src/app/services/plan/plan.service';
import { Suscripcion } from 'src/app/components/admin/multipago/modules/multipago.model';
import { PlanMultipagoService } from 'src/app/services/plan/plan-multipago.service';

@Component({
  selector: 'app-oportunidades',
  templateUrl: './oportunidades.component.html',
  styleUrls: ['./oportunidades.component.css'],
})
export class OportunidadesComponent implements OnInit {
  constructor(
    private planService: PlanService,
    private apiService: ApiService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private storageService: StorageService,
    private servicePlanMP: PlanMultipagoService,
    private formatDateService: FormatDateService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
    this.matIconRegistry.addSvgIcon(
      `rechazar`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/rechazar.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `aceptar`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/aceptar.svg'
      )
    );
  }
  idOportunidadLinkeada: number;
  hayCaso: boolean = true;
  contadorCasos = 0;
  pagina = 0;
  CANTIDAD_CASOS_PARA_PEDIR = 1000;
  today: number;
  caso: CasoDetallado;
  casosDetallados: CasoDetallado[];
  showingCasos: CasoDetallado[]=[];
  listaArchivos: ListaArchivos[];
  casoNoDisponible: boolean;
  accionesHabilitadas: boolean = true;
  pages:number[]=[];
  actualpage:number=1;
  itemsperpage=3;

  suscripcionSel: Suscripcion;
  planStatus: boolean = false;

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.idOportunidadLinkeada = params.id;
      if (this.idOportunidadLinkeada) {
        console.log("OPORTUNIDAD LINKEADA ")
        this.apiService.getCasoById(this.idOportunidadLinkeada).subscribe(
          (res) => {
            this.caso = res;
            this.hayCaso = true;
          },
          (error) => {
            console.error('error', error);
            if (error.status == 409) {
              this.casoNoDisponible = true;
            }
          }
        );
      } else {
        console.log("OPORTUNIDAD LINKEADA no ")
        this.pidoCasos(this.pagina);
      }
    });

    this.servicePlanMP.getSubscripcionLast().subscribe({
      next: (suscripcion: Suscripcion) => {
        this.suscripcionSel = suscripcion;
        this.planStatus = this.servicePlanMP.isSuscriptionActive(suscripcion);
      }
    });
    /*this.planService.getClientStatus().subscribe( res => {
      if(res.mensaje == "OK"){
        let plan: StatusIncrease["suscripcion"] = res.suscripcion;
        this.planStatus = plan && (plan.status !== 'canceled' && plan.status !== 'pending')
      }
    });*/
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  aceptarCaso() {
    if (this.accionesHabilitadas) {
      this.accionesHabilitadas = false;
      this.apiService.aceptarCaso(this.caso.id_caso).subscribe(
        (respuesta) => {
          this.recargarLista();
          this.snackBar.open(
            'El caso fue enviado a la sección “Mis casos” para que un cliente pueda seleccionarte',
            '',
            { duration: 5000 }
          );
        },
        (error) => {
          if (error.status == 409) {
            this.snackBar.open(
              'Se alcanzó el numero maximo de abogados interesados en este caso.',
              '',
              { duration: 5000 }
            );
            this.rechazarCaso();
          }
        }
      );
    }
  }

  aceptarCaso1(caso) {
    if (this.accionesHabilitadas) {
      this.accionesHabilitadas = false;
      this.apiService.aceptarCaso(caso.id_caso).subscribe(
        (respuesta) => {
          this.recargarLista();
          this.snackBar.open(
            'El caso fue enviado a la sección “Mis casos” para que un cliente pueda seleccionarte',
            '',
            { duration: 5000 }
          );
        },
        (error) => {
          if (error.status == 409) {
            this.snackBar.open(
              'Se alcanzó el numero maximo de abogados interesados en este caso.',
              '',
              { duration: 5000 }
            );
            this.rechazarCaso();
          }
        }
      );
    }
  }

  rechazarCaso() {
    this.accionesHabilitadas = false;
    this.storageService.addItem('Casos', this.caso.id_caso.toString());
    this.recargarLista();
  }

  rechazarCaso1(caso) {
    this.accionesHabilitadas = false;
    this.storageService.addItem('Casos', caso.id_caso.toString());
    this.recargarLista();
  }

  pidoListaArchivos() {
    this.apiService.getFiles(this.caso.id_caso).subscribe(
      (lista) => {
        this.listaArchivos = lista;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  pidoCasos(desde: number) {
    this.accionesHabilitadas = false;
    this.casoNoDisponible = false;
    this.apiService
      .getOportunidades(desde, this.CANTIDAD_CASOS_PARA_PEDIR)
      .subscribe(
        (casos) => {
          const delimiter = ';';
          var oportunities = this.storageService.getItem('Casos');
          if (oportunities != null) {
            var lstOportunity = oportunities.split(delimiter);
            var casesWithFilter = casos.filter(
              (x) =>
                lstOportunity.find((y) => y == x.id_caso.toString()) == null
            );

            casos.forEach(function (cas,index) {
              lstOportunity.forEach(function (value) {
                if(cas.id_caso.toString()==value){
  
                  casos[index].isrejected=true;
                }
              }); 
            }); 

            
            // this.casosDetallados = this.formatDateService.convertDateToUTCByAttribute(casesWithFilter, "fechaCreacion");
         //   this.casosDetallados = casesWithFilter;
          } else {
         //   this.casosDetallados = casos;
          }


          this.casosDetallados = casos;//Cambio 24/02
          this.showingCasos = [];

          if(this.casosDetallados.length>this.itemsperpage){
            for(var j=this.actualpage;j<this.itemsperpage;j++){
              this.showingCasos.push(this.casosDetallados[j]);
            }
          }
          else{
            this.showingCasos=this.casosDetallados;
          }
          
          
          
          // this.casosDetallados = this.formatDateService.convertDateToUTCByAttribute(casos, "fechaCreacion");
          this.caso = this.casosDetallados[0];
          if (this.casosDetallados.length == 0) {
            this.hayCaso = false;
          } else {
            this.hayCaso = true;
            this.pidoListaArchivos();
          }
          this.accionesHabilitadas = true;
          this.pages = [];
          let cantidad = this.casosDetallados.length/3;
          for(var j=1;j<cantidad+1;j++){

            this.pages.push(j);
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  recargarLista() {
    this.contadorCasos++;
    const casoSiguienteExiste =
      this.casosDetallados && this.casosDetallados[this.contadorCasos];
    this.caso = casoSiguienteExiste
      ? this.casosDetallados[this.contadorCasos]
      : this.caso;
    this.accionesHabilitadas = true;
    if (!casoSiguienteExiste) {
      this.accionesHabilitadas = false;
      this.pagina++;
      this.contadorCasos = 0;
      this.pidoCasos(this.pagina);
    }
  }

  gotoPage(page:number){

      this.actualpage=page-1;
      this.showingCasos = [];
      for(var j=this.actualpage;j<this.itemsperpage;j++){
        this.showingCasos.push(this.casosDetallados[j]);
      }

  }
}
