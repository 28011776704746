import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ResetPassword } from '../../../api/user';
import { ApiService } from '../../../api/api.service';
import { MatSnackBar, 
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-restablecer',
  templateUrl: './restablecer.component.html',
  styleUrls: ['./restablecer.component.css'],
})
export class RestablecerComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  user: ResetPassword;
  constructor(
    private apiService: ApiService,
    private _snackBar: MatSnackBar,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }
  public usuario = { rol: 0 };
  ngOnInit() {}
  userForm = new UntypedFormGroup({
    email: new UntypedFormControl(),
  });

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  onSubmit() {
    if (this.userForm.value.email) {
      this.restablecer();
    }
  }

  restablecer() {
    this.apiService
      .requestChangePassword({
        mail: this.userForm.value.email,
        redirectUrl: environment.angularUrl + '/nueva',
      } as ResetPassword)
      .subscribe(
        (userId) => {
          this.getRequest(userId);
        },
        (error) => {
          console.error(error);
          if (error.status === 404) {
            this.openSnackBar(
              'Error, verifique que haya ingresado correctamente su email'
            );
          } else {
            this.openSnackBar(
              'Estamos trabajando para mejorar el servicio, por favor intente nuevamente más tarde'
            );
          }
        }
      );
  }

  getRequest(userId) {
    this.apiService.getRequest(userId).subscribe(
      (respuesta) => {
        this.openSnackBar(this.translate.instant("recuperar.contraseña"))
      },
      (error) => {
        console.error(error);
        if (error.status === 404) {
          this.openSnackBar(
            'Error, verifique que haya ingresado correctamente su email'
          );
        } else {
          this.openSnackBar(
            'Estamos trabajando para mejorar el servicio, por favor intente nuevamente más tarde'
          );
        }
      }
    );
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
