export interface NewUser {
  clave: string;
  mail: string;
  tutorialCompletado: boolean;
}

export interface ResetPassword {
  mail: string;
  redirectUrl: string;
}

export interface ResetPasswordRepeat {
  clave: string;
  idCambioClave: number;
}

export interface ReturnUser {
  mail: string;
  lastName: string;
  name: string;
  clave: string;
  rol: Rol;
  token: string;
  tutorialCompletado: boolean;
}

export interface RegisterClient {
  apellido: string;
  clave: string;
  datosUsuario: [
    {
      localidadId: number;
      numeroDni: string;
      tipoDni: string;
      direccion: string;
      codigoPostal: string;
      telefono: string;
    }
  ];
  lenguaje: string;
  mail: string;
  localidadId: number;
  nombre: string;
  pais: string;
  rol: number;
  mailConfirmUrl: string;
  redesSociales?: boolean;
  token?: string;
}

export interface RegisterAbogado {
  apellido: string;
  clave: string;
  datosProfesionales: [
    {
      colegio: string;
      especialidadesIds: [number];
      folio: string;
      otro: string;
      localidadId: number;
      numeroDni: string;
      tipoDni: string;
      tomo: string;
      telefono: string;
    }
  ];
  mail: string;
  localidadId: number;
  nombre: string;
  pais: string;
  rol: number;
  mailConfirmUrl: string;
  suscripcionPlataforma?: string;
  suscripcionUuid?: string;
  id_usuario?: number;
}

interface Rol {
  descripcion: string;
  estado: string;
  id: number;
}

export interface Identificacion {
  id_tipos_identificacion: number;
  nombre: string;
}

export interface PendienteAbogado {
  apellido: string;
  clave: string;
  datosProfesionales: [
    {
      colegio: string;
      especialidades: [
        {
          id_especialidad: 0;
          nombre: string;
        }
      ];
      folio: string;
      localidad: {
        id_localidad: 0;
        jurisdiccion: {
          id_jurisdiccion: 0;
          nombre: string;
        };
        nombre: string;
      };
      numeroDni: string;
      tipoDni: string;
      tomo: string;
      telefono: string;
      estado: string;
    }
  ];
  mail: string;
  nombre: string;
  pais: string;
  ciudad: string;
  provincia: string;
  rol: number;
  token: string;
  id_usuario: number;
  paisNombre:string;
}

export interface PendienteAbogadoModal {
  apellido: string;
  clave: string;
  datosProfesionales: [
    {
      colegio: string;
      especialidadesIds: [number];
      folio: string;
      localidadId: number;
      numeroDni: string;
      tipoDni: string;
      tomo: string;
    }
  ];
  mail: string;
  nombre: string;
  pais: string;
  rol: number;
  token: string;
  id_usuario: number;
}

export interface JurisdiccionEditarVer {
  jurisdiccionNombre: string;
  localidadNombre: string;
  tomo: string;
  folio: string;
  otro: string;
  id_datos_profesionales: number;
  colegio: string;
  aceptada: boolean;
}

export interface JurisdiccionEditarVer2 {
  localidadId: number;
  colegio: string;
  tomo: string;
  folio: string;
  otro: string;
  id_datos_profesionales: number;
  jurisdiccionNombre: string;
}

export interface JurisdiccionEditarVer3 {
  localidadId: number;
  colegio: string;
  tomo: string;
  folio: string;
  otro: string;
  datosProfesionalesId: number;
  jurisdiccionNombre: string;
}

export interface Perfil {
  apellido: string;
  clave: string;
  datosProfesionales: [
    {
      id_datos_profesionales: number;
      colegio: string;
      especialidadesAbogados: [
        {
          id_especialidad: 0;
          nombre: string;
          nombreCliente: string[];
        }
      ];
      direccion: string;
      codigoPostal: number;
      cuit: string;
      folio: string;
      localidad: {
        id_localidad: 0;
        jurisdiccion: {
          id_jurisdiccion: 0;
          nombre: string;
        };
        nombre: string;
      };
      numeroDni: string;
      tipoDni: string;
      tomo: string;
      telefono: string;
      estado: string;
      otro: string;
    }
  ];
  datosUsuario: [
    {
      localidadId:number;
      direccion: string;
      codigoPostal: number;
      localidad: {
        id_localidad: 0;
        jurisdiccion: {
          id_jurisdiccion: 0;
          nombre: string;
        };
        nombre: string;
      };
      numeroDni: string;
      tipoDniId: number;
      tiposIdentificacionId: number;
      tipoDni: string;
      tomo: string;
      telefono: string;
      estado: string;
    }
  ];
  mail: string;
  nombre: string;
  pais: string;
  rol: number;
  token: string;
  id_usuario: number;
  descripcion: string;
  telefono: string;
  avatarUrl: string;
  puntuacionFinal?: number;
  cantOpiniones?: number;
  promocion?: string;
}

export interface PerfilEditar {
  apellido: string;
  clave: string;
  datosProfesionales: [
    {
      colegio: string;
      especialidadesId: [number];
      folio: string;
      localidadId: number;
      numeroDni: string;
      tipoDni: string;
      tomo: string;
    }
  ];
  mail: string;
  mailConfirmUrl: string;
  nombre: string;
  pais: string;
  rol: number;
  token: string;
  id_usuario: number;
  descripcion: string;
  telefono: string;
}

export interface Especialidad {
  id_especialidad: number;
  nombre: string;
  nombreCliente: string[];
  selected?: boolean;
}

export interface MotivoBaja {
  id: String;
  descripcion: string;
  selected?: boolean;
}

export interface BajaInput {
  id: Number;
  estado: String;
  motivoBaja: String;
}

export interface Jurisdiccion {
  id_jurisdiccion: number;
  nombre: string;
  countryId?: number;
}

export interface JurisdiccionCheckBox {
  id_jurisdiccion: number;
  nombre: string;
  selected: boolean;
}

export interface DatosJurisdiccion {
  folio: string;
  tomo: string;
  colegio: string;
  localidadId: number;
  telefono: string;
  numeroDni: string;
  tipoDni: string;
  datosProfesionalesId: number;
}

export interface Categoria {
  descripcion: string;
  id_categoria: number;
  titulo: string;
}

export interface Localidad {
  id_localidad: number;
  jurisdiccion: {
    id_jurisdiccion: number;
    nombre: string;
  };
  nombre: string;
}

export interface Caso {
  titulo: string;
  descripcion: string;
  localidadId: number;
  categoriaId: number;
}

export interface CasoDetallado {
  categoria: {
    descripcion: string;
    id_categoria: number;
    titulo: string;
    tituloAlterado: string;
  };
  especialidad: {
    id_especialidad: number;
    nombre: string;
    nombreCliente: string;
  };
  descripcion: string;
  fechaCreacion: number;
  id_caso: number;
  localidad: {
    id_localidad: number;
    jurisdiccion: {
      id_jurisdiccion: number;
      nombre: string;
    };
    nombre: string;
  };
  profesionalAsignado: {
    colegio: string;
    especialidades: [
      {
        id_especialidad: number;
        nombre: string;
      }
    ];
    folio: string;
    localidad: {
      id_localidad: number;
      jurisdiccion: {
        id_jurisdiccion: number;
        nombre: string;
      };
      nombre: string;
    };
    numeroDni: string;
    tipoDni: string;
    tomo: string;
  };
  titulo: string;
  tituloAlterado: string;
  usuarioId: number;
  estado: string;
  isrejected: boolean;
  toggled:boolean;
}

export interface Caso {
  titulo: string;
  descripcion: string;
  localidadId: number;
  categoriaId: number;
}

export interface Opiniones {
  nombre: string;
  apellido: string;
  puntuacion: number;
  estrellas: string;
  comentario: string;
}

export interface ListaArchivos {
  fecha: {
    calendarType: string;
    firstDayOfWeek: number;
    lenient: true;
    minimalDaysInFirstWeek: number;
    time: string;
    timeInMillis: number;
    timeZone: {
      displayName: string;
      dstsavings: number;
      id: string;
      rawOffset: number;
    };
    weekDateSupported: true;
    weekYear: number;
    weeksInWeekYear: number;
  };
  id_adjunto_caso: number;
  nombre: string;
  nombreoriginal: string;
  tipo: string;
}

export interface Archivo {
  description: string;
  filename: string;
  inputStream: {};
  open: true;
  readable: true;
  uri: string;
  url: string;
}

export const TI_MP = {
  1: "DNI",
  2: "CI",
  3: "LC",
  4: "LE",
  5: "Otro"
}
