<div class="casoFondo">
  <mat-toolbar class="header">
    <button mat-button (click)="navegar(1)">
      <mat-icon class="icono">chevron_left</mat-icon>
    </button>
    <span class="example-spacer"></span>
    <span>{{ this.seleccion.titulo }}</span>
  </mat-toolbar>
  <div class="barraDeProgreso">
    <mat-toolbar class="barraDeProgreso">
      <span class="example-spacer"></span>
      <span>{{ "resumen.cita" | translate }}</span>
    </mat-toolbar>
    <mat-progress-bar mode="determinate" value="80"></mat-progress-bar>
  </div>

  <div class="servicio-responsivo">
    <mat-card class="cardColumna">
      <mat-card-header>
        <div class="imagenCuadrada2">
          <!-- <mat-icon *ngIf="!tieneFoto" color="primary" class="icono">account_circle</mat-icon> -->
          <mat-icon
            *ngIf="!tieneFoto"
            svgIcon="perfil"
            class="imagenDetalles"
          ></mat-icon>
          <img
            *ngIf="tieneFoto"
            class="rounded"
            [src]="avatarUrl | secure | async"
          />
        </div>
        <span class="elegiste">
          <mat-card-title>{{ nombre }} {{ apellido }}</mat-card-title>
          <mat-card-subtitle *ngIf="!mostrar">{{
            nombreEspecialidad
          }}</mat-card-subtitle>
          <mat-card-subtitle *ngIf="mostrar">{{
            especialidadParaMostrar
          }}</mat-card-subtitle>
        </span>
      </mat-card-header>
      <div *ngIf="mostrarFecha">
        <br />
        <hr color="#D8D8D8" />
        <br />
        <mat-card-title>{{ "resumen.datos" | translate }}</mat-card-title>
        <div class="fecha-hora">
          <mat-card-title class="desktop"
            >{{ "resumen.fecha" | translate }} {{ this.fecha }}</mat-card-title
          >
          <mat-card-title class="desktop"
            >{{ "resumen.hora" | translate }} {{ this.hora }}</mat-card-title
          >
          <h2 class="mobile">
            {{ "resumen.fecha" | translate }} {{ this.fecha }}
          </h2>
          <h2 class="mobile">
            {{ "resumen.hora" | translate }} {{ this.hora }}
          </h2>
        </div>
      </div>
      <mat-label class="especialista"
        >{{ nombre }} {{ apellido }}
        {{ "resumen.contacto" | translate }}</mat-label
      >
    </mat-card>

    <mat-card class="cardColumna">
      <mat-card-title>{{ "resumen.precio" | translate }}</mat-card-title>
      <br />
      <mat-card-title class="importe"> {{ importeMoneda ? importeMoneda : '$' }} {{ importe }}</mat-card-title>

      <button *ngFor="let planPlataforma of cacheAgendaPlataformas;"
        class="boton-primario"
        mat-raised-button
        color="primary"
        (click)="doMultipago(planPlataforma)"
      >
        {{ planPlataforma.plataformaNombre }}: {{ planPlataforma.monto }} {{ planPlataforma.monedaId }}
      </button>
      <router-outlet #outlet="outlet"></router-outlet>
    </mat-card>
  </div>
</div>
