import {
    Component,
    OnInit,
    Input,
    Output,
    ViewChild,
    ElementRef,
    Inject,
  } from '@angular/core';
  import { FormControl, FormGroup } from '@angular/forms';
  import { MatSnackBar } from '@angular/material/snack-bar';
  import { ApiService } from 'src/app/api/api.service';
  import { UpdateRangesService } from 'src/app/services/agenda/update-ranges.service';
  import { LogOutService } from 'src/app/services/logOut/log-out.service';
  import { MatAccordion } from '@angular/material/expansion';
  import { TranslateService } from '@ngx-translate/core';
  import { Company } from 'src/app/api/company';
  import { EntFormularioComponent } from 'src/app/components/company/formulario/formulario.component'
import { MatDialog } from '@angular/material/dialog';
import { LogoComponent } from '../../company/logo/logo.component';
import { environment } from 'src/environments/environment';
  @Component({
    selector: 'app-entidades',
    templateUrl: './entidades.component.html',
    styleUrls: ['./entidades.component.css'],
  })
  export class EntidadesComponent implements OnInit {

    sinEntidades = false;
  constructor(
    public translate: TranslateService,
    private logOut: LogOutService,
    private _snackBar: MatSnackBar,
    private apiService: ApiService,
    private dialog: MatDialog,
    private updateRangesService: UpdateRangesService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang('es-AR');
  }
  displayedColumns: string[] = [
    'nombre',
    'descripcion',
    'hash',
    'logo',
    'country',
    'idCompany'
  ];
  abogadosData: any;
  cargoLaLista = false;
  id: string;

    companies: Company[];
  
    ngOnInit(): void {
      this.getCompanies();
      //this.getProvincias(dato);
    }
    switchLang(lang: string) {
      this.translate.use(lang);
    }

    addEntity(){
          const dialogRef = this.dialog.open(EntFormularioComponent, {
            backdropClass: 'backdropBackground',
            data: {
              entity: null,
              edit:false
            }
          });

          dialogRef.afterClosed().subscribe(result => {
            this.getCompanies();
          });
    }
  
    onFileSelected(event) {}
  

    getCompanies() {
        this.apiService.getCompanies().subscribe((companies) => (this.companies = companies));
    }
  
    edit(company){
      const dialogRef = this.dialog.open(EntFormularioComponent, {
        backdropClass: 'backdropBackground',
        data: {
          entity: company,
          edit:true
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getCompanies();
      });
    }

    doLogoUpload(company) {
      const dialogRef = this.dialog.open(LogoComponent, {
        backdropClass: 'backdropBackground',
        data: {
          entity: company
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getCompanies();
      });
    }

    getLogoUrl(company: Company): string {
      let url: string;

      url = `${environment.apiUrl}/public/company/${company.idCompany}/logo`;
      return url;
    }
  
    cerrarSesion() {
      this.logOut.cerrarSesion();
    }
  
    
  }
  