import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../../../modules/material.module';
import { NavbarAdminmodule } from '../navbar-admin/navbar-admin.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { DataComponent } from './datos.component';

@NgModule({
  declarations: [DataComponent],
  imports: [
    BrowserModule,
    MaterialModule,
    ReactiveFormsModule,
    NavbarAdminmodule,
    MatExpansionModule,
    TranslateModule,
  ],
  exports: [],
  providers: [],
  bootstrap: [],
})
export class DatosModule {}
