import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-creacion-exitosa-abogado',
  templateUrl: './creacion-exitosa-abogado.component.html',
  styleUrls: ['./creacion-exitosa-abogado.component.css'],
})
export class CreacionExitosaAbogadoComponent implements OnInit {
  registroRedes: boolean = false;
  constructor(public translate: TranslateService) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }

  ngOnInit(): void {
    let aux = localStorage.getItem('redSocial');
    if (aux == 'true') {
      this.registroRedes = true;
    }
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }
}
