import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InicioComponent } from './inicio.component';
import { TranslateModule } from '@ngx-translate/core';
import { InicioRoutingModule } from './inicio-routing.module';
import { MaterialModule } from 'src/app/modules/material.module';
import { NavbarAdminmodule } from '../navbar-admin/navbar-admin.module';
import { AdminComponent } from '../admin.component';
import { PlanListComponent } from '../plan/plan-list.component';
import { CommonModule } from '@angular/common';
import { EspecialidadesComponent } from '../multipago/especialidades/especialidades.component';
import { ModalComponent } from '../multipago/especialidades/modal.component';

@NgModule({
  declarations: [ InicioComponent, AdminComponent, PlanListComponent, EspecialidadesComponent, ModalComponent ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    InicioRoutingModule,
    MaterialModule,
    NavbarAdminmodule,
  ],
  exports: [],
  providers: [],
  bootstrap: [],
})
export class InicioModule {}
