import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-creacion-exitosa-cliente',
  templateUrl: './creacion-exitosa-cliente.component.html',
  styleUrls: ['./creacion-exitosa-cliente.component.css']
})
export class CreacionExitosaClienteComponent implements OnInit {


  constructor( ) { }

  ngOnInit(): void {
  }
}



