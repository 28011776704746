import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../../api/api.service';
import { ListaArchivos } from '../../../../../api/user';
import { Location } from '@angular/common';
import { environment } from '../../../../../../environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { EstadoCaso } from 'src/app/enums/EstadoCaso';

import { FormatDateService } from '../../../../../utils/formatDate/formatDate.service.';
import { StorageService } from '../../../../../utils/storage/storage.service';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { Role } from '../../../../../enums/role';
import { TranslateService } from '@ngx-translate/core';
import { Provincia } from '../../../../../api/Provincia';

@Component({
  selector: 'app-detalles',
  templateUrl: './detalles.component.html',
  styleUrls: ['./detalles.component.css'],
})
export class DetallesComponent implements OnInit {
  fecha: any;
  hora: any;
  fechaCompletaString: string;
  fechaCompletaDate: Date;

  mailAbogado = '';
  mailCliente = '';
  mail = '';
  idProfesionalAsignado: number;

  caso = {
    zonaHoraria: '',
    titulo: '',
    descripcion: '',
    categoria: '',
    localidad: '',
    jurisdiccion: '',
    estado: '',
    fechaCreacion: '',
    id_caso: 0,
    clase: '',
    tipo: '',
    detalles: {
      fecha: new Date(),
    },
    profesionalAsignado: {
      id_usuario: 0,
    },
    especialidad: {
      id_especialidad: 0,
      nombre: '',
      nombreCliente: '',
    },
  };

  seleccionado = false;
  seleccion = {
    abogado: '',
  };

  noSoyAbogado:boolean;
  miRol: number;
  textoParaCalificar = '';

  datos = {
    titulo: 'Detalle del caso',
    id_caso: 0,
    tituloDelCaso: '',
    abogado: null,
    categoriaCaso: '',
    cliente: {
      nombre:'',
      mail:'',
      pais:''
    },
    rolActual: 0,
    abogados: [
      {
        nombre: 'perez',
        apellido: 'cruz',
      },
      {
        nombre: 'juana',
        apellido: 'de arco',
      },
      {
        nombre: 'pepe',
        apellido: 'williams',
      },
    ],
  };

  pais : any;
  estado = '';
  hayAbogadosParaElegir = false;
  finalizado = false;
  perdido = false;
  listaArchivos: ListaArchivos[];
  abogadosDisponibles: [];
  abogado: any;
  avatarUrl: string;
  tieneFoto = false;
  elegiste = false;
  esLlamada = false;
  esCita = false;
  telefono = '';
  nombre = 'aa';
  apellido = 'bb';
  idCliente;
  abogadosChequeados: boolean = false;
  usuarioActualId: number;

  formatCulture: string = 'full';
  culture: string = 'es-AR';
  formatDateBackend: string = 'yyyy-MM-ddTHH:mm:ss';

  constructor(
    private formatDateService: FormatDateService,
    private storageService: StorageService,
    private matIconRegistry: MatIconRegistry,
    private authenticationService: AuthenticationService,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private _location: Location,
    private ngZone: NgZone,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
    this.matIconRegistry.addSvgIcon(
      `alert`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/alert.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `whatsapp`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/whatsapp.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `perfil`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/perfil.svg'
      )
    );
    if (this.router.getCurrentNavigation().extras.state) {
      if (this.router.getCurrentNavigation().extras.state.estado == 's') {
        this.getAbogado();
      }
    }
  }

  hayCasosAbiertos = true;
  hayCasosCerrados = true;
  rolId = 0;
  crearOBuscar = '';
  async ngOnInit() {
    this.getRol();
    this.usuarioActualId = parseInt(localStorage.getItem('usuarioActualId'));
    if (history.state.navigationId != 1) {
      console.log("0310 HIS");
      this.caso.titulo = history.state.caso.titulo;
      this.caso.zonaHoraria = history.state.caso.zonaHoraria;
      this.caso.especialidad = history.state.caso.especialidad;
      if (history.state.caso.profesionalAsignado) {
        this.idProfesionalAsignado =
          history.state.caso.profesionalAsignado.id_usuario;
      }
      this.caso.profesionalAsignado.id_usuario = this.idProfesionalAsignado;
      this.datos.tituloDelCaso = this.caso.titulo;
      this.datos.categoriaCaso = history.state.caso.categoria.titulo;
      this.caso.descripcion = history.state.caso.descripcion;
      this.caso.localidad = history.state.caso.localidad.nombre;
      this.caso.categoria = history.state.caso.categoria.titulo;
      this.caso.jurisdiccion = history.state.caso.localidad.jurisdiccion.nombre;
      this.caso.estado = history.state.caso.estado;
      this.caso.clase = history.state.caso.clase;
      this.caso.tipo = history.state.caso.tipo;
      this.caso.fechaCreacion = history.state.caso.fechaCreacion;
      this.caso.id_caso = history.state.caso.id_caso;
      this.datos.id_caso = this.caso.id_caso;
      this.idCliente = history.state.caso.usuarioId;
      localStorage.setItem('usuarioId', this.idCliente);
      this.datos.rolActual = history.state.rol;
      localStorage.setItem('rolActual', this.datos.rolActual.toString());
      if (history.state.caso.detalles) {
        this.caso.detalles.fecha = history.state.caso.detalles.fecha;
      }
    } else {
      console.log("0310 HIS L");
      this.caso = JSON.parse(localStorage.getItem('caso'));
      if (this.caso.profesionalAsignado) {
        this.idProfesionalAsignado = this.caso.profesionalAsignado.id_usuario;
      }
      this.caso.id_caso = this.caso.id_caso;
      this.idCliente = localStorage.getItem('usuarioId');
      this.datos.rolActual = parseInt(localStorage.getItem('rolActual'), 10);
      this.pidoListaArchivos();
    }
    if (this.caso.estado === 'Finalizado' || this.caso.estado === 'Perdido') {
      this.finalizado = true;
    }
    if (this.caso.estado === 'Perdido') {
      this.perdido = true;
    }
    if (this.caso.detalles) {
      if (this.caso.detalles.fecha) {
        let aux = this.caso.detalles.fecha;
        this.fechaCompletaDate = new Date(aux);
        if (this.caso.tipo === 'Agenda') {
          this.esCita = true;
        } else {
          this.esCita = false;
        }
        this.fechaCompletaString =
          this.formatDateService.convertDateToFormatSpecifc(
            this.formatCulture,
            this.fechaCompletaDate,
            this.culture
          );
        this.hora = this.formatDateService.convertDateToFormatSpecifc(
          'HH:mm',
          this.fechaCompletaDate,
          this.culture
        );
        this.fecha =
          this.fechaCompletaDate.getDate() +
          '/' +
          (this.fechaCompletaDate.getMonth() + 1);
      }
    }
    await this.getPerfilCliente();
    await this.getAbogado();
    if (this.datos.abogado && this.datos.abogado.avatarUrl) {
      this.avatarUrl = environment.apiUrl + this.datos.abogado.avatarUrl;
      this.tieneFoto = true;
    }

    if (this.datos.abogado) {
      this.seleccionado = true;
    }

    if (this.datos.abogado) {
      this.hayAbogadosParaElegir = false;
    } else {
      this.ngZone.run(() => {
        if (this.authenticationService.getRole.toString() != Role.Abogado) {
          this.apiService.getAbogadosDisponibles(this.caso.id_caso).subscribe(
            (abogados) => {
              this.abogadosDisponibles = abogados;
              if (abogados.length == 0) {
                this.hayAbogadosParaElegir = false;
              } else {
                this.hayAbogadosParaElegir = true;
                this.seleccionado = false;
              }
              this.abogadosChequeados = true;
            },
            (error) => {
              error(error);
            }
          );
        }
      });
    }

    this.pidoListaArchivos();
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  getMiPerfil() {
    this.apiService.getPerfil().subscribe(
      (perfil) => {
        this.miRol = perfil.rol;
        if (this.datos.abogado.id_usuario === perfil.id_usuario) {
          this.seleccionado = true;
          this.noSoyAbogado = false;
        } else {
          this.textoParaCalificar =
            this.datos.abogado.nombre + ' ' + this.datos.abogado.apellido;
        }
        //Eres Abogado
        if(perfil.rol['id'] == 2){
          //Mostrar data del Cliente
          this.pais = parseInt(this.globalCliente.pais,10);
          this.apiService.getPais(this.pais)
            .subscribe(pais =>{
              console.log(pais);
              this.datos.cliente.pais = pais.name;
            })
          this.datos.cliente = this.globalCliente;
          this.telefono = this.globalCliente.datosUsuario[0].telefono; 
        }
        //Eres Cliente
        else if(perfil.rol['id'] == 3){
          //Mostrar data del Abogado
          console.log(this.globalAgobado);
          this.pais = parseInt(this.globalAgobado.pais,10);
              this.apiService.getPais(this.pais)
                .subscribe(data =>{
                  console.log(data);
                  this.datos.cliente.pais = data.name;
                })
              this.datos.cliente = this.globalAgobado;
              this.telefono = this.globalAgobado.datosProfesionales[0].telefono; 
        }

      },
      (error) => {
        console.error(error);
      }
    );
  }

  globalCliente:any;
  private getPerfilCliente() {
    return new Promise<void>((resolve, reject) => {
      this.apiService.getPerfilUsuario(this.idCliente).subscribe((res) => {
        this.globalCliente = res;
        this.textoParaCalificar = res.nombre + ' ' + res.apellido;
        this.mailCliente = res.mail;
        this.apiService.getPais(this.globalCliente.pais)
            .subscribe(pais =>{
              console.log(pais);
              this.datos.cliente.pais = pais.name;
            })
        localStorage.setItem('caso', JSON.stringify(this.caso));
        resolve();
      });
    });
  }




  userForm = new UntypedFormGroup({});

  llamar(telefono) {
    window.open(`tel:${telefono}`);
  }

  whatsapp(telefono) {
    window.open(`tel:${telefono}`);
  }

  rolActual:any;

  getRol(){
    this.apiService.getPerfil().subscribe(perfil => {
      this.rolActual = perfil.rol['id'];
    })
  }

  globalAgobado : any;
  getAbogado() {
    if (
      this.authenticationService.getRole.toString() != Role.Abogado ||
      this.usuarioActualId == this.idProfesionalAsignado
    ) {
      return new Promise<void>((resolve, reject) => {
        this.apiService.getAbogadoCaso(this.caso.id_caso).subscribe(
          (abogado) => {
            this.globalAgobado = abogado;
            this.datos.abogado = abogado;
            localStorage.setItem('abogado', JSON.stringify(this.abogado));
            localStorage.setItem('caso', JSON.stringify(this.caso));
            this.mailAbogado = this.datos.abogado.mail;

            if (
              this.caso.tipo === 'Llamada Express' ||
              this.caso.tipo === 'Llamada Express'
            ) {
              this.esLlamada = true;
              this.telefono = this.datos.abogado.datosProfesionales[0].telefono;
            }
            this.getMiPerfil();
            if (this.datos.abogado) {
              this.seleccionado = true;
              this.hayAbogadosParaElegir = false;
              if (this.datos.abogado && this.datos.abogado.avatarUrl) {
                this.avatarUrl =
                  environment.apiUrl + this.datos.abogado.avatarUrl;
                this.tieneFoto = true;
              }
            } else {
              this.seleccionado = false;
            }
            resolve();
          },
          (error) => {
            this.seleccionado = false;
            localStorage.setItem('abogado', JSON.stringify(this.abogado));
            localStorage.setItem('caso', JSON.stringify(this.caso));
            resolve();
          }
        );
      });
    }
  }
  pidoListaArchivos() {
    this.apiService.getFiles(this.caso.id_caso).subscribe(
      (lista) => {
        this.listaArchivos = lista;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  navegar(destino) {
    switch (destino) {
      case 1:
        this._location.back();
        break;
      case 2:
        localStorage.setItem('caso', JSON.stringify(this.caso));
        localStorage.setItem('datos', JSON.stringify(this.datos));
        this.router.navigateByUrl('/calificar');
        break;
      case 3:
        this.datos.abogados = this.abogadosDisponibles;
        localStorage.setItem('caso', JSON.stringify(this.caso));
        this.router.navigateByUrl('/seleccion/previa', { state: this.datos });
        break;
      default:
        break;
    }
  }

  sePuedeCalificar() {
    const key = EstadoCaso.getEstadoFromString(this.caso.estado);
    const estadoInvalido =
      EstadoCaso[key] == EstadoCaso.FINALIZADO ||
      EstadoCaso[key] == EstadoCaso.PENDIENTE_ACEPTACION ||
      EstadoCaso[key] == EstadoCaso.PENDIENTE_SELECCION ||
      EstadoCaso[key] == EstadoCaso.PERDIDO;
    const involucradoEnElCaso =
      this.datos.rolActual == 2 && this.datos.abogado
        ? this.usuarioActualId == this.datos.abogado.id_usuario
        : this.usuarioActualId == this.idCliente;
    const calificable =
      !this.hayAbogadosParaElegir &&
      this.seleccionado &&
      involucradoEnElCaso &&
      !estadoInvalido;
    return calificable;
  }
}
