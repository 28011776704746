import {
    Component,
    OnInit,
    Input,
    Output,
    ViewChild,
    ElementRef,
    Inject,
  } from '@angular/core';
  import { FormControl, FormGroup } from '@angular/forms';
  import { MatSnackBar } from '@angular/material/snack-bar';
  import { ApiService } from 'src/app/api/api.service';
  import { UpdateRangesService } from 'src/app/services/agenda/update-ranges.service';
  import { LogOutService } from 'src/app/services/logOut/log-out.service';
  import { MatAccordion } from '@angular/material/expansion';
  import { TranslateService } from '@ngx-translate/core';
  import { Company } from 'src/app/api/company';
  import { EntFormularioComponent } from 'src/app/components/company/formulario/formulario.component'
import { MatDialog } from '@angular/material/dialog';
import { Pregunta } from 'src/app/api/pregunta';
import { Formulario } from 'src/app/api/formulario';
import { CertFormularioComponent } from '../../certificacion/formulario/formulario.component';
  @Component({
    selector: 'app-certificacion',
    templateUrl: './certificacion.component.html',
    styleUrls: ['./certificacion.component.css'],
  })
  export class CertificacionComponent implements OnInit {

  sinPreguntas = false;
  constructor(
    public translate: TranslateService,
    private logOut: LogOutService,
    private _snackBar: MatSnackBar,
    private apiService: ApiService,
    private dialog: MatDialog
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang('es-AR');
  }
  displayedColumns: string[] = [
    'id_pregunta',
    'pregunta',
    'respuesta',
    'peso',
    'action'
  ];
  abogadosData: any;
  cargoLaLista = false;
  id: string;
  selectedPregunta:Pregunta;
  formulario:Formulario;
  
    ngOnInit(): void {
      this.getFormulario();
      //this.getProvincias(dato);
    }
    switchLang(lang: string) {
      this.translate.use(lang);
    }

    addQuestion(){
      const dialogRef = this.dialog.open(CertFormularioComponent, {
        backdropClass: 'backdropBackground',
        data: {
          edit: false,
          formulario:this.formulario
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getFormulario();
      });
    }
    addFormulario(){
      let form=new Formulario(1,1);
      this.apiService.postFormulario(form).subscribe((formulario) => (this.formulario = formulario));
    }

    getFormulario(){
      this.apiService.getFormulario(1).subscribe((formulario) => (this.formulario = formulario));
    }
  
    edit(pregunta:Pregunta){
      const dialogRef = this.dialog.open(CertFormularioComponent, {
        backdropClass: 'backdropBackground',
        data: {
          edit: true,
          formulario:this.formulario,
          pregunta:pregunta
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.getFormulario();
      });
    }
  
    cerrarSesion() {
      this.logOut.cerrarSesion();
    }
  
    
  }
  