<div>
    <mat-toolbar class="header">
        <span class="example-spacer"></span>
        <span>{{ "paises.admin" | translate }}</span>

        <br />
        <button class="cerrarSesionAdmin" mat-button (click)="cerrarSesion()">
            <mat-icon class="icono">logout</mat-icon>
        </button>
    </mat-toolbar>
    
    <br />

    <app-navbar-admin></app-navbar-admin>
    <br />
    <br />
    <mat-card class="admin dataBlock">
        <p class="tituloAdmin">
            {{ "data.title" | translate }}
        </p>
        <button class="generateButton" (click)="generateExcel()">{{ "data.generate" | translate }}</button>
    </mat-card>
</div>
