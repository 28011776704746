<div class="separadorCentrado">
  <h1>Vinculaciones</h1>
</div>

<div class="separadorCentrado toTable">
  <table mat-table [dataSource]="cacheVinculaciones" class="example-full-width" style="text-align: left;">
    <ng-container matColumnDef="plataformaNombre">
      <th mat-header-cell *matHeaderCellDef class="pl-5px">
        Plataforma Nombre
      </th>
      <td mat-cell *matCellDef="let vinculacion" class="pl-5px">
        {{ vinculacion.plataformaNombre }}
      </td>
    </ng-container>
    <ng-container matColumnDef="vincular">
      <th mat-header-cell *matHeaderCellDef class="pl-5px">
        Vinculacion
      </th>
      <td mat-cell *matCellDef="let vinculacion" class="tituloCentrado">
        <mat-checkbox color="primary" [checked]="vinculacion.needLinked" [disableRipple]="true" (click)="$event.preventDefault()" ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="vinculado">
      <th mat-header-cell *matHeaderCellDef class="pl-5px">
        Esta vinculado
      </th>
      <td mat-cell *matCellDef="let vinculacion" class="tituloCentrado">
        <mat-checkbox color="primary" [checked]="vinculacion.linked" [disableRipple]="true" (click)="$event.preventDefault()" ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef class="pl-5px pr-10px">
        Acciones
      </th>
      <td mat-cell *matCellDef="let vinculacion" class="pl-5px pr-10px">
        <ng-container *ngIf="vinculacion.plataformaNombre == 'Paypal'; else vinculacionDiv">
          <a mat-flat-button color="primary" class="btn-margin-table"
            *ngIf="vinculacion.needLinked && !vinculacion.linked"
            (click)="onPaypalButton(vinculacion)"
            target="_blank" data-paypal-button="true"
            data-paypal-onboard-complete="doPaypalVinculado"
            [href]="vinculacion.urlLink"
          >
            Vincular
          </a>
        </ng-container>
        <ng-template #vinculacionDiv>
          <button mat-flat-button color="primary" class="btn-margin-table"
            *ngIf="vinculacion.needLinked && !vinculacion.linked"
            (click)="openDialogVincular(vinculacion)"
          >
              Vincular
          </button>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
