<body>
  <!-- <label class="selectLenguage">
    {{ "admin.selectLenguage" | translate }}
  </label>
  <select
    class="lenguage"
    #selectedLang
    (change)="switchLang(selectedLang.value)"
  >
    <option
      *ngFor="let language of translate.getLangs()"
      [value]="language"
      [selected]="language === translate.currentLang"
    >
      {{ language }}
    </option>
  </select> -->
  <app-language-nav></app-language-nav>

  <div class="contenedor-cards">
    <mat-card
      class="login"
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <img class="logo" src="../../../assets/logo.jpg" />
      <mat-card class="opciones">
        <div class="separadorCentrado">
          <p class="Mont14">
            {{ "inicio.crear" | translate }}
          </p>
        </div>
        <button
          class="boton-secundario"
          mat-button
          color="primary"
          (click)="navegarRegistro(3)"
        >
          {{ "inicio.cliente" | translate }}</button
        ><br />
        <button
          class="boton-terciario"
          mat-button
          color="primary"
          (click)="navegarRegistro(2)"
        >
          {{ "inicio.abogado" | translate }}
        </button>
        <router-outlet #outlet="outlet"></router-outlet>
      </mat-card>
      <div class="iconos" style="width: 100%">
        <button mat-button (click)="redirectGoogle()">
          <mat-icon svgIcon="google" class="icono"></mat-icon>
          {{ "inicio.iniciarSesion" | translate }}</button
        ><br />
      </div>
      <div class="iconos" style="width: 100%">
        <button mat-button (click)="redirectFacebook()">
          <mat-icon svgIcon="facebook" class="icono"></mat-icon>
          {{ "inicio.iniciarSesion2" | translate }}</button
        ><br />
      </div>
      <div class="iconos" style="width: 100%">
        <button mat-button (click)="redirectLinkedin()">
          <mat-icon svgIcon="linkedin" class="icono"></mat-icon>
          {{ "inicio.iniciarSesion3" | translate }}</button
        ><br />
      </div>
      <div id="separadores" style="width: 100%">
        <div id="or-separator1" class="or-separator mt-12"></div>
        <span class="or-text">o</span>
        <div id="or-separator2" class="or-separator mt-12"></div>
      </div>
      <form [formGroup]="userForm">
        <mat-form-field appearance="outline" class="inputs inputLogin">
          <mat-label>
            {{ "inicio.correo" | translate }}
          </mat-label>
          <input
            required
            matInput
            type="email"
            ngModel
            [email]="true"
            placeholder="{{ 'inicio.correo' | translate }}"
            formControlName="email"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="inputs inputLogin">
          <mat-label>
            {{ "inicio.contraseña" | translate }}
          </mat-label>
          <input
            required
            matInput
            placeholder="{{ 'inicio.contraseña' | translate }}"
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
          />
          <button
            mat-icon-button
            matSuffix
            type="button"
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
        </mat-form-field>
        <button
          class="boton-cuaternario"
          mat-button
          type="button"
          color="primary"
          [routerLink]="['/restablecer']"
        >
          {{ "inicio.olvidar" | translate }}
        </button>
        <button
          type="button"
          class="boton-cuaternario"
          mat-button
          color="primary"
          (click)="openResend()"
        >
          {{ "inicio.reenvio" | translate }}
        </button>
        <button
          (click)="onSubmit()"
          class="boton-primario"
          mat-raised-button
          color="primary"
        >
          {{ "inicio.inicioSesion" | translate }}
        </button>
      </form>
    </mat-card>

    <div class="poweredBy">
      Powered by Legalify
      <img class="logoLegalify" src="../../../assets/poweredBy.png" />
    </div>
  </div>
</body>
