import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupPaypalPagoComponent } from '../dialogo/mercadopago/popup-paypal-pago/popup-paypal-pago.component';
import { DialogoConfirmarComponent } from '../dialogo/dialogo-confirmar/dialogo-confirmar.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { DialogoPrevisualizarComponent } from '../dialogo/dialogo-previsualizar/dialogo-previsualizar.component';



@NgModule({
  declarations: [
    PopupPaypalPagoComponent,
    DialogoConfirmarComponent,
    DialogoPrevisualizarComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule
  ],
  exports: [
    PopupPaypalPagoComponent
  ]
})
export class CommonsModule { }
