import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mensaje-validar',
  templateUrl: './mensaje-validar.component.html',
  styleUrls: ['./mensaje-validar.component.css']
})
export class MensajeValidarComponent implements OnInit {

  @Input('textoBoton') textoBoton: string;
  @Input('mostrarLogo') mostrarLogo: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
