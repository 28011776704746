import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class LogOutService {

  constructor(    
    private router: Router,
    private _snackBar: MatSnackBar) 
    { }

    public cerrarSesion() {
      this.limpiarStorage();
      this.openSnackBar("Su sesión se cerró exitosamente");
      this.router.navigate(['']);
    }

    public BajaCuenta() {
      this.limpiarStorage();
      this.openSnackBar("Su cuenta ha sido dado de Baja");
      this.router.navigate(['']);
    }

    public expirado() {
      this.openSnackBar("Tu sesión ha expirado. Por favor inicia sesión nuevamente.");
      this.limpiarStorage();
      this.router.navigate(['']);
    }

    openSnackBar(message: string) {
      this._snackBar.open(message, "", { duration: 5000 });
    }

    public limpiarStorage() {
      localStorage.removeItem('entro');
      localStorage.removeItem('usuarioActualId');
      localStorage.removeItem('user');
      localStorage.removeItem('option');
      localStorage.removeItem('token');
      localStorage.removeItem('rol');
    }
}
