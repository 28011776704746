<div class="contenidoModal">
    <!-- <div
      (mouseover)="changeClass(true)"
      (mouseout)="changeClass(false)"
      [class]="clase"
    > -->
    <button mat-button class="close" mat-dialog-close>X</button>

    <div class="contentDardebaja">
        <div class="linea">
          <h1>{{ "baja.title" | translate }}</h1>
          <p class="limites">
            <mat-icon class="errorIcon">error_outline</mat-icon>
            
          </p>
        </div>
  
    </div>

    <mat-selection-list [disableRipple]="true" [multiple]="false" #item>
      <mat-list-option
        *ngFor="let motivo of motivosBaja"
        [selected]="motivo.selected"
        (click)="cambiarMotivo(motivo)"
      >
        <p class="nombreEsp">{{ motivo.descripcion }}</p>
      </mat-list-option>
    </mat-selection-list>

    <!-- </div> -->

    <div>
      <button
        [disabled]="desactivarBoton"
        [mat-dialog-close]="true"
        class="boton-primario"
        mat-raised-button
        color="primary"
        (click)="onSubmitBaja()"
      >
        {{ "editarEsp.confirmar" | translate }}
      </button>
    </div>

</div>