import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../../../modules/material.module';

import { CrearComponent } from '../crear/crear.component';
import { CategoriaComponent } from '../categoria/categoria.component';
import { TipoServicioComponent } from '../tipo-servicio/tipo-servicio.component';
import { FinalizadaComponent } from '../finalizada/finalizada.component';
import { ClienteCasosNuevosRoutingModule } from './clienteCasosNuevos-routing.module';
import { EspecialidadComponent } from '../especialidad/especialidad.component';
import { DialogComponent } from '../especialidad/dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageNavComponent } from 'src/app/components/language-nav/language-nav.component';

@NgModule({
    declarations: [
        CrearComponent,
        CategoriaComponent,
        TipoServicioComponent,
        // RevisarComponent,
        FinalizadaComponent,
        EspecialidadComponent,
        DialogComponent,
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        ClienteCasosNuevosRoutingModule,
        MaterialModule,
        TranslateModule
    ],
    providers: [],
    bootstrap: []
})
export class ClienteCasosNuevosModule { }
