import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../modules/material.module';
import { LoginRoutingModule } from './login-routing.module';

import { AbogadoModule } from '../abogado/modules/abogado.modules';
import { ClienteModule } from '../cliente/modules/cliente.modules';
import { RestablecerComponent } from './restablecer/restablecer.component';
import { NuevaFormularioComponent } from './nueva-formulario/nueva-formulario.component';
import { RedesComponent } from './redes/redes.component';
import { BienvenidaComponent } from './bienvenida/bienvenida.component';
import { TranslateModule } from '@ngx-translate/core';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    RestablecerComponent,
    NuevaFormularioComponent,
    RedesComponent,
    BienvenidaComponent
  ],
  imports: [
    BrowserModule,
    LoginRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    ClienteModule,
    FormsModule,
    AbogadoModule,
    TranslateModule,
  ],
  providers: [ {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 5000}}],
  bootstrap: [],
})
export class LoginModule {}
