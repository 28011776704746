<div class="header-modal">
  <h2 mat-dialog-title class="titulo-modal">{{data.titulo}}</h2>
</div>

<mat-dialog-content>
  <h2>{{"planPreguntas.pregunta1" | translate}}</h2>
  <ol>
    <li>{{data.uno}}</li>
    <li>{{data.dos}}</li>
    <li>{{data.tres}}</li>
    <li>{{data.cuatro}}</li>
  </ol>
  <h2>{{"planPreguntas.pregunta2" | translate}}</h2>
  {{data.paga}}
  <a target="_blank" href="/terminos-y-condiciones/"
    >{{"planPreguntas.terminos" | translate}}</a
  >
  <br />
  <br />
  <button class="boton-secundario" mat-button mat-dialog-close>
    {{"planPreguntas.cerrar" | translate}}
  </button>
</mat-dialog-content>
