import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContadorJurisdiccionesService {
  constructor() {}

  contador(datosProfesionales) {
    let cantidadJurisdicciones: number = 0;
    datosProfesionales.forEach((dato) => {
      if (dato.estado != 'D' && dato.estado != 'B') {
        cantidadJurisdicciones++;
      }
    });
    return cantidadJurisdicciones;
  }

  contadorActivas(datosProfesionales) {
    let cantidadJurisdicciones: number = 0;
    datosProfesionales.forEach((dato) => {
      if (dato.estado != 'D' && dato.estado != 'B' && dato.estado != 'P') {
        cantidadJurisdicciones++;
      }
    });
    return cantidadJurisdicciones;
  }
}
