<div class="casoFondo">
  <mat-toolbar class="header">
    <span class="tituloHeader">{{ "Resumen del Caso" | translate }}</span>
  </mat-toolbar>

  <mat-card >
    <mat-card-header>
      <mat-card-title class="tituloCaso">{{ caso.titulo }}</mat-card-title>
    </mat-card-header>

    <mat-card-content class="contenidoCaso">
      <div class="detalleCaso">
        <strong>{{ "Detalles:" | translate }}</strong>
        <p>{{ caso.detalles }}</p>
      </div>
      <div class="detalleCaso">
        <strong>{{ "Jurisdicción:" | translate }}</strong>
        {{ caso.jurisdiccion.nombre }}
      </div>
      <div class="detalleCaso">
        <strong>{{ "Localidad:" | translate }}</strong>
        {{ caso.localidad.nombre }}
      </div>
      <div class="detalleCaso">
        <strong>{{ "Especialidad:" | translate }}</strong>
        {{ caso.especialidad.nombre }}
      </div>
      <div class="detalleCaso">
        <strong>{{ "Categoría:" | translate }}</strong>
        {{ caso.categoria.nombre }}
      </div>
    </mat-card-content>

    <mat-card class="archivosCard">
      <h2 class="tituloArchivos">{{ "revisarNue.archivos" | translate }}</h2>
      <div
        *ngIf="
          caso.archivosSeleccionados && caso.archivosSeleccionados.length > 0
        "
      >
        <div *ngFor="let archivo of caso.archivosSeleccionados">
          <span class="nombreArchivo">{{ archivo.nombre }}</span>
        </div>
      </div>
      <div
        class="sinAdjunto"
        *ngIf="
          !caso.archivosSeleccionados || caso.archivosSeleccionados.length === 0
        "
      >
        No se cargó adjunto
      </div>
    </mat-card>

    <div class="botones">
      <button mat-raised-button class="botonGuardar" (click)="onSubmit()">
        {{ "Guardar" | translate }}
      </button>
      <button mat-raised-button (click)="cerrar()">
        {{ "Cerrar" | translate }}
      </button>
    </div>
  </mat-card>
</div>
