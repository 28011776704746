<div class="menu-container">
  <input type="checkbox" class="toggler" ngModel #hambMenu/>
  <div class="hamburguer">
    <div></div>
  </div>
  <app-language-nav></app-language-nav>
  <div class="menu">
    <div>
      <div>
        <ul>
         <li>
          <div (click)="changeMenu(1, '/perfil-cliente',hambMenu)" class="iconosMenu">
            <button mat-button >
              <mat-icon>person</mat-icon>
            </button>
            <br />
            <button mat-button class="navbarText" >
              {{ "casosActivos.perfil" | translate }}
            </button>
          </div>
         </li>
         <li>
          <div (click)="changeMenu(2, '/casos-activos/3',hambMenu)" class="iconosMenu">
            <button mat-button >
              <mat-icon>work</mat-icon>
            </button>
            <br />
            <button mat-button class="navbarText">
              {{ "casosActivos.casos" | translate }}
            </button>
          </div>
         </li>
         <li>
          <div class="iconosMenu">
            <button
              class="boton-secundario logoutButton"
              mat-button
              color="primary"
              (click)="logout()"
            >
              {{ "perfilAbogado.cerrar" | translate }}
            </button>
          </div>
         </li>
        </ul>
      </div>
    </div>
  </div>
  <router-outlet #outlet="outlet"></router-outlet>
</div>
