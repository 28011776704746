<div class="casoFondo">
    <div class="casoFondo">
        <div class="header">
            <div class="tituloCentrado">
                <h1>Te damos la bienvenida</h1>
            </div>
        </div>


        <mat-card class="card-bienvenida">
            <mat-icon [svgIcon]="textoMuestra.logo" class="icono-bienvenida"></mat-icon>
            <h1>{{textoMuestra.titulo}}</h1>
            <mat-card-subtitle>{{textoMuestra.descripcion}}</mat-card-subtitle>

            <div class="botones-slider">
                <button class="boton-slider" mat-fab color="white" (click)="cambiar(1)" [disabled]="posicionInicial">
                    <mat-icon class="sliderArrow">arrow_back</mat-icon>
                </button>
                <p *ngIf="soyCliente">{{posicion+1}}/{{textoCliente.length}}</p>
                <p *ngIf="!soyCliente">{{posicion+1}}/{{textoAbogado.length}}</p>
                <button class="boton-sliderDerecha" mat-fab color="white" [disabled]="posicionFinal"
                    (click)="cambiar(2)">
                    <mat-icon class="sliderArrow">arrow_forward</mat-icon>
                </button>
            </div>
            <button class="boton-primario" mat-raised-button color="primary" (click)="navegar()">CONTINUAR</button>
        </mat-card>
    </div>
</div>