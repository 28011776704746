import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from './services/authentication/authentication.service';
import { LogOutService } from './services/logOut/log-out.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad {
    constructor(
        private _snackBar: MatSnackBar,
        private router: Router,
        private authenticationService: AuthenticationService,
        private logOut: LogOutService,
    ) { }

    openSnackBar(message: string) {
        this._snackBar.open(message, "", { duration: 5000 });
    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authenticationService.getRole;

        
        if (user) {
            if (route.data.roles != user) {
                this.openSnackBar("No es posible ingresar a esta página con tu rol");
                this.logOut.limpiarStorage();
                this.router.navigate(['/']);
                return false;
            }
            return true;
        }

        this.logOut.limpiarStorage();
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const user = this.authenticationService.getRole;

        if (user) {
            if (route.data.roles != user) {
                this.openSnackBar("No es posible ingresar a esta página con tu rol");
                this.logOut.limpiarStorage();
                this.router.navigate(['/']);
                return false;
            } else {
                return true;
            }
        }

        this.logOut.limpiarStorage();
        this.router.navigate(['/login']);
        return false;
    }

}