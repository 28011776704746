import { Component, OnInit} from '@angular/core';
import { Validators, UntypedFormControl, UntypedFormGroup, FormControl } from '@angular/forms';
import { RegisterClient, ResetPasswordRepeat } from '../../../api/user';
import { ApiService } from '../../../api/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CheckPasswordService } from 'src/app/services/register/checkPassword/check-password.service';
import { Company } from 'src/app/api/company';
import { MatDialog } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Pais } from 'src/app/api/pais';

@Component({
  selector: 'app-ent-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css']
})
export class EntFormularioComponent implements OnInit{
  language:String="";
  coinciden = true;
  imgSelected: String = "";
  blur = false;
  cacheCountries: Pais[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialog,private apiService: ApiService, private checkPasswordService: CheckPasswordService,private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute,public translate: TranslateService) { 
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    this.language = sessionStorage.getItem('language');
    if(!sessionStorage.getItem("language")){
      translate.setDefaultLang("es-AR");
      this.language = "es-AR"
    }else
      translate.setDefaultLang(sessionStorage.getItem('language'));
    if(this.language == "es-AR")
      this.imgSelected = "../../../assets/españa.png"
    if(this.language == "it-IT")
      this.imgSelected = "../../../assets/italia.png"
    if(this.language == "en-US")
      this.imgSelected = "../../../assets/ingles.png"
  }
  entForm = new UntypedFormGroup({
    nombre: new UntypedFormControl('', [
      Validators.required,
    ]),
    descripcion: new UntypedFormControl('', [
        Validators.required,
    ]),
    telefono: new UntypedFormControl('', [
        Validators.required,
    ]),
    url: new UntypedFormControl('', [
        Validators.required,
    ]),
    codigo: new UntypedFormControl('', [
        Validators.required,
    ]),
    countryId: new FormControl<number>(null, [])
  });
  idCambioClave: number;
  edit:Boolean=false;
  selectedEntity:Company;

  ngOnInit() {
    this.edit=this.data.edit;
    this.selectedEntity=this.data.entity;
    console.log(JSON.stringify(this.data));

    this.apiService.getPaisesClean().subscribe({
      next: (resp: Pais[]) => {
        this.cacheCountries = resp;
      }
    });

    if(this.edit){
      this.feedForm();
    }
  }

  hide = true;

  feedForm(){
    this.entForm.get('nombre').setValue(this.selectedEntity.nombre);
    this.entForm.get('descripcion').setValue(this.selectedEntity.descripcion);
    this.entForm.get('telefono').setValue(this.selectedEntity.telefono);
    this.entForm.get('url').setValue(this.selectedEntity.url);
    this.entForm.get('codigo').setValue(this.selectedEntity.codigo);
    this.entForm.get('countryId').setValue(this.selectedEntity.countryId);
  }
  
  switchLang(lang: string) {
    console.log(lang)
    this.translate.use(lang);
    this.language = lang;
    sessionStorage.setItem('language', lang);
    sessionStorage.language = lang;
    if(lang == "es-AR")
      this.imgSelected = "../../../assets/españa.png"
    if(lang == "it-IT")
      this.imgSelected = "../../../assets/italia.png"
    if(lang == "en-US")
      this.imgSelected = "../../../assets/ingles.png"
  }

  onSubmit() {

    console.log("Valid "+this.entForm.valid);
    if(this.entForm.valid){
        let codigo = this.entForm.value.codigo;
        let nombre = this.entForm.value.nombre;
        let descripcion = this.entForm.value.descripcion;
        let telefono = this.entForm.value.telefono;
        let url = this.entForm.value.url;
        let countryId = this.entForm.value.countryId;

        if(this.edit==true){
          let company:Company ={ idCompany:this.selectedEntity.idCompany,codigo:codigo,nombre:nombre,descripcion:descripcion,telefono:telefono,url:url,hash:null, countryId };
          this.apiService.putCompany(company)
            .subscribe( () => {     
              
              
              this.openSnackBar("Se actualizo compañia");
              this.dialog.closeAll();
            },
              error => {
                console.error(error);
                if(error.status === 409) {
                  this.openSnackBar("Error de registro");
                }else if(error.status === 406) {
                  this.openSnackBar("El codigo ya esta tomado");
                  this.dialog.closeAll();
                } else {
                  this.openSnackBar("Estamos trabajando para mejorar el servicio, por favor intente nuevamente más tarde");
                }
              });
        }
        else{
          let company:Company ={ idCompany:null,codigo:codigo,nombre:nombre,descripcion:descripcion,telefono:telefono,url:url,hash:null, countryId };
          this.apiService.postCompany(company)
            .subscribe( () => {      
              this.openSnackBar("Se creo compañia");
              this.dialog.closeAll();
            },
              error => {
                console.error(error);
                if(error.status === 409) {
                  this.openSnackBar("Error de registro");
                }else if(error.status === 406) {
                  this.openSnackBar("El codigo ya esta tomado");
                  this.dialog.closeAll();
                } else {
                  this.openSnackBar("Estamos trabajando para mejorar el servicio, por favor intente nuevamente más tarde");
                  this.dialog.closeAll();
                }
              });

        }

        
    }
    else{
        this.openSnackBar("Ingrese todos los campos");

    }

    
  }



  openSnackBar(message: string) {
    this._snackBar.open(message, "", { duration: 5000 });
  }

}