<div class="casoFondo">
  <mat-toolbar class="header">
    <button mat-button (click)="atras()">
      <mat-icon class="icono">chevron_left</mat-icon>
    </button>
    <span class="example-spacer"></span>
    <span>{{ "revisarNue.caso" | translate }}</span>
  </mat-toolbar>
  <div class="barraDeProgreso">
    <mat-toolbar class="barraDeProgreso">
      <span class="example-spacer"></span>
      <span>{{ "revisarNue.Descrip" | translate }}</span>
    </mat-toolbar>
    <mat-progress-bar mode="determinate" value="90"></mat-progress-bar>
  </div>

  <mat-card class="cardPerfil">
    <mat-card-header>
      <mat-card-title class="tituloCaso">{{ this.caso.titulo }}</mat-card-title>
      <mat-card-subtitle
        >Categoria del caso: {{ this.caso.categoria.titulo }}</mat-card-subtitle
      >
    </mat-card-header>
  </mat-card>
  <mat-card class="cardPerfil">
    <h2>{{ "revisarNue.jurisdiccion" | translate }}</h2>
    <mat-card-title>
      <mat-icon class="azul">place</mat-icon>{{ this.caso.jurisdiccion.nombre }}
    </mat-card-title>
    <br />
    <mat-card-subtitle> {{ this.caso.localidad.nombre }}</mat-card-subtitle>
    <br />
    <hr color="#D8D8D8" />
    <br />
    <h2>{{ "revisarNue.descripCaso" | translate }}</h2>
    <mat-card-subtitle>{{ this.caso.detalles }}</mat-card-subtitle>
    <br /><br />
    <hr color="#D8D8D8" />
    <br />
    <mat-card-subtitle>*{{ "revisarNue.msg2month" | translate }}</mat-card-subtitle>
    <hr color="#D8D8D8" />
    <br />
    <h2>{{ "revisarNue.archivos" | translate }}</h2>
    <app-archivos
      [archivos]="caso.archivosSeleccionados"
      [isDownloadable]="false"
    ></app-archivos>
  </mat-card>

  <mat-card class="cardPerfil">
    <button
      (click)="onSubmit()"
      class="boton-primario"
      *ngIf="edit==true"
      [disabled]="desactivarSubmit"
      mat-raised-button
      color="primary"
    >
      {{ "revisarNue.actualizar" | translate }}
    </button>
    <button
      (click)="onSubmit()"
      *ngIf="edit==false"
      class="boton-primario"
      [disabled]="desactivarSubmit"
      mat-raised-button
      color="primary"
    >
      {{ "revisarNue.crear" | translate }}
    </button>
    <router-outlet #outlet="outlet"></router-outlet>
  </mat-card>
  <br /><br />
</div>
