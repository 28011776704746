<div class="casoFondo">
  <mat-toolbar class="header">
    <button mat-button [routerLink]="['/perfil-abogado']">
      <mat-icon class="icono">chevron_left</mat-icon>
    </button>
    <span class="example-spacer"></span>
    <span>{{ titulo }}</span>
  </mat-toolbar>
  <app-language-nav></app-language-nav>
  <br />
  <mat-card class="cardPerfil">
    <div *ngIf="cargando">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!cargando">
      <div *ngIf="mostrarJurisdiccion">
        <form [formGroup]="agregarJurisdiccion">
          <div class="mobile">
            <mat-form-field appearance="outline" class="input-fullwidth">
              <mat-label>{{ "agregar.jurisdiccion" | translate }}</mat-label>
              <mat-select
                formControlName="jurisdiccion"
                (selectionChange)="cargarLocalidades()"
              >
                <mat-option
                  *ngFor="let jurisdiccion of jurisdicciones"
                  [value]="jurisdiccion"
                >
                  {{ jurisdiccion.nombre }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br />
            <mat-form-field appearance="outline" class="input-fullwidth" floatLabel="alaways">
              <mat-label>{{ "agregar.colegio" | translate }}</mat-label>
              <input
                [disabled]="!coinciden"
                matInput
                type="text"
                required
                placeholder="Colegio"
                formControlName="colegio"
              />
              <mat-hint *ngIf="!colegioValido" class="textoRojo"
                >{{ "agregar.letras" | translate }}
              </mat-hint>
            </mat-form-field>
            <br />
          </div>
          <div class="desktop">
            <mat-form-field appearance="outline" class="inputs-registro">
              <mat-label>{{ "agregar.jurisdiccion" | translate }}</mat-label>
              <mat-select
                formControlName="jurisdiccion"
                (selectionChange)="cargarLocalidades()"
              >
                <mat-option
                  *ngFor="let jurisdiccion of jurisdicciones"
                  [value]="jurisdiccion"
                >
                  {{ jurisdiccion.nombre }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="inputs-registro">
              <input
                [disabled]="!coinciden"
                matInput
                type="text"
                required
                placeholder="Colegio"
                formControlName="colegio"
              />
              <mat-hint *ngIf="!colegioValido" class="textoRojo"
                >{{ "agregar.letras" | translate }}
              </mat-hint>
            </mat-form-field>
            <br />
          </div>
          <mat-form-field appearance="outline" class="inputs-registro" *ngIf="showTomo==true">
            <mat-label>{{ "agregar.tomo" | translate }}</mat-label>
            <input
              (keyup)="onKeyUpTomo($event)"
              matInput
              type="number"
              required
              placeholder="{{ 'agregar.tomo' | translate }}"
              placeholder="Tomo"
              formControlName="tomo"
            />
            <mat-hint>{{ "agregar.validar" | translate }}</mat-hint>
            <br />
            <mat-hint *ngIf="!tomoValido" class="textoRojo"
              >{{ "agregar.tomoCar" | translate }}
            </mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline" class="inputs-registro" *ngIf="showFolio==true">
            <mat-label>{{ "agregar.folio" | translate }}</mat-label>
            <input
              (keyup)="onKeyUpFolio($event)"
              matInput
              type="number"
              required
              placeholder="{{ 'agregar.folio' | translate }}"
              max="9999"
              formControlName="folio"
            />
            <mat-hint *ngIf="!folioValido" class="textoRojo"
              >{{ "agregar.folioCar" | translate }}
            </mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline" class="inputs-registro" *ngIf="showMatricula==true">
            <mat-label>{{ "agregar.matricula" | translate }}</mat-label>
            <input
              matInput
              type="number"
              placeholder="{{ 'agregar.matricula' | translate }}"
              formControlName="otro"
            />
          </mat-form-field>
        </form>

        <div *ngIf="editarJurisdiccion==false">
          <button
          class="boton-primario"
          [disabled]="!agregarJurisdiccion.valid"
          mat-raised-button
          color="primary"
          (click)="intentoAgregar(1)"
        >
          {{ "agregar.continuar" | translate }}
        </button>
      </div>
        
        <div
        *ngIf="editarJurisdiccion==true">
        <button
        class="boton-primario"
        [disabled]="!agregarJurisdiccion.valid"
        mat-raised-button
        color="info"
        (click)="editar()"
        >
        {{ "agregar.continuar" | translate }}
      </button>
    </div>
        
        <button
          class="boton-terciario"
          mat-button
          color="primary"
          (click)="cancelar()"
        >
          {{ "agregar.cancelar" | translate }}
        </button>
      </div>

      <div *ngIf="mostrarEspecialidades">
        <div class="mobile">
          <mat-grid-list cols="2" rowHeight="1:1" [gutterSize]="'30px'">
            <mat-grid-tile
              *ngFor="let especialidad of todasEspecialidades"
              [ngClass]="{ selected: especialidad.selected }"
              class="casoImpar"
              [value]="especialidad"
              (click)="guardar(especialidad)"
            >
              <div class="textoEnGrid">{{ especialidad.nombre }}</div>
              <mat-icon
                svgIcon="{{ especialidad.nombre }}"
                class="icono-derecha-abajo"
              ></mat-icon>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
        <div class="desktop">
          <mat-grid-list cols="5" rowHeight="2:1" [gutterSize]="'30px'">
            <mat-grid-tile
              tabindex="0"
              *ngFor="let especialidad of todasEspecialidades"
              [ngClass]="{ selected: especialidad.selected }"
              class="casoImpar"
              [value]="especialidad"
              (click)="guardar(especialidad)"
            >
              <div class="textoEnGrid">{{ especialidad.nombre }}</div>
              <mat-icon
                svgIcon="{{ especialidad.nombre }}"
                class="icono-derecha-abajo"
              >
              </mat-icon>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
        <br />
        <button
          class="boton-primario"
          mat-raised-button
          color="primary"
          (click)="intentoAgregar(2)"
        >
          {{ "agregar.continuar" | translate }}
        </button>
        <button
          class="boton-terciario"
          mat-button
          color="primary"
          (click)="cancelar()"
        >
          {{ "agregar.cancelar" | translate }}
        </button>
      </div>
    </div>
  </mat-card>
</div>
