import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.css']
})
export class SignComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer) { }

  sign_url='assets/manifiesto.pdf';

  ngOnInit(): void {
  }

  signURL() {
    return this.sanitizer.bypassSecurityTrustUrl(this.sign_url);
  }

}