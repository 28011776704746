import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api/api.service';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {

  formLogo: FormGroup;
  selFile: File;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
      public dialog: MatDialog,
      private serviceApi: ApiService,
      private _snackBar: MatSnackBar
  ) {
    this.formLogo = new FormGroup({
      'display': new FormControl<string>(null)
    });
  }

  ngOnInit(): void {
  }

  doSelFile(files: FileList): void {
    let filename: string = '';
    if (!files || files.length < 1) {
      this.selFile = null;
      this.formLogo.patchValue({
        display: filename
      });
      return;
    }

    this.selFile = files[0];
    filename = files[0].name;
    this.formLogo.patchValue({
      display: files[0].name
    });
  }

  doSubirLogo(): void {
    if (!this.selFile)
      return;

    this.serviceApi.putCompanyLogo(this.data.entity, this.selFile).subscribe({
      next: (resp) => {
        this.openSnackBar("Se actualizo el logo de la compañia");
        this.dialog.closeAll();
      },
      error: (err) => {
        this.openSnackBar("Error al procesar la transaccion.");
      }
    });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "", { duration: 5000 });
  }

}
