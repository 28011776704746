import { Component, OnInit, Input } from '@angular/core';
import { Planes, PlanesIncrease, StatusIncrease } from 'src/app/api/planes';
import { PopupBajaComponent } from './../plan/popup-baja/popup-baja.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CuentaMercado } from 'src/app/api/mercado';
import { TranslateService } from '@ngx-translate/core';
import { Suscripcion } from '../../admin/multipago/modules/multipago.model';
@Component({
  selector: 'app-plan-en-perfil',
  templateUrl: './plan-en-perfil.component.html',
  styleUrls: ['./plan-en-perfil.component.css'],
})
export class PlanEnPerfilComponent implements OnInit {
  
  _plan: StatusIncrease["suscripcion"] | Planes;
  _suscripcion: Suscripcion;
  @Input('cuenta') cuenta:CuentaMercado;
  //@Input('plan') plan: StatusIncrease["suscripcion"] | Planes;
  @Input('textoSubscripcion') textoSubscripcion: string;
  //@Input('suscripcion') suscripcion: Suscripcion;

  language:String="";
  constructor(public dialog: MatDialog,public translate: TranslateService) {
    this.language=sessionStorage.getItem('language');
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }

  ngOnInit(): void {
  }

  get plan(): any {
    return this._plan;
  }

  @Input('plan')
  set plan(value: any) {
    this._plan = value;
    this.doUpdateCostos();
  }

  get suscripcion(): any {
    return this._suscripcion;
  }

  @Input('suscripcion')
  set suscripcion(value: any) {
    this._suscripcion = value;
    this.doUpdateCostos();
  }

  doUpdateCostos(): void {
    if (this.plan && this.suscripcion) {
      if (this.suscripcion.planPlataformaCiclo == 1) {
        this.plan.costo = this.suscripcion.monto;
        this.plan.moneda = this.suscripcion.monedaId;
      } else if (this.suscripcion.planPlataformaCiclo == 2) {
        this.plan.costoAnual = this.suscripcion.monto;
        this.plan.monedaAnual = this.suscripcion.monedaId;
      }
    }
  }
  
  switchLang(lang: string) {
    sessionStorage.setItem('language',lang);
    this.language = lang;
    this.translate.use(lang);
  }

  baja(){

    const dialogRef = this.dialog.open(PopupBajaComponent, {
      width: '400px',
      data: {
        plan: this.plan,
        downgrade: false,
      },
      backdropClass: 'backdropBackground',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        
      }
    });

  }
}
