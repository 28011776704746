import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-finalizada',
  templateUrl: './finalizada.component.html',
  styleUrls: ['./finalizada.component.css'],
})
export class FinalizadaComponent implements OnInit {
  constructor(public translate: TranslateService) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }

  ngOnInit(): void {}

  switchLang(lang: string) {
    this.translate.use(lang);
  }
}
