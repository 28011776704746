import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { EspecialidadDelCasoService } from '../../../services/especialidad/especialidad-del-caso.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vista-previa',
  templateUrl: './vista-previa.component.html',
  styleUrls: ['./vista-previa.component.css'],
})
export class VistaPreviaComponent implements OnInit {
  @Input('nombre') nombre: string;
  @Input('certified') certified: boolean;
  @Input('apellido') apellido: string;
  @Input('titulo') titulo: string;
  @Input() abogado: any;
  @Input('id_caso') id_caso: number;
  @Input('caso') caso: any;
  @Input('avatarUrl') avatarUrl: string;
  @Input('tieneFoto') tieneFoto: boolean;
  @Input('mostrarBoton') mostrarBoton: boolean;

  stars: string =
    'star_outline star_outline star_outline star_outline star_outline';

  hayAvatar = false;
  especialidadParaMostrar = '';
  mostrar = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private especialidadService: EspecialidadDelCasoService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
    this.matIconRegistry.addSvgIcon(
      `perfil`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/perfil.svg'
      )
    );
  }

  datos = {
    titulo: '',
    id_caso: 0,
    abogado: '',
    caso: '',
    tieneFoto: true,
  };

  countryId = 54;
  rolId = 3;

  ngOnInit(): void {
    if (this.abogado && this.abogado.puntuacionFinal) {
      this.generateStars();
    }
    if (
      this.abogado.avatarUrl != null &&
      !this.abogado.avatarUrl.includes(null)
    ) {
      this.tieneFoto = true;
    } else {
      this.tieneFoto = false;
    }
    this.datos.tieneFoto = this.tieneFoto;
    this.datos.titulo = this.titulo;
    this.nombre = this.abogado.nombre;
    this.apellido = this.abogado.apellido;
    if (this.caso && this.caso.especialidad && this.caso.especialidad.nombre) {
      this.especialidadParaMostrar = this.especialidadService.match(
        this.caso.especialidad.nombre,
        this.abogado
      );
      if (this.especialidadParaMostrar.length > 2) {
        this.mostrar = true;
      }
    } else {
      this.caso = JSON.parse(localStorage.getItem('caso'));
      if (this.caso && this.caso.especialidad) {
        this.especialidadParaMostrar = this.especialidadService.match(
          this.caso.especialidad.nombre,
          this.abogado
        );
      } else {
        let aux: string = '';
        console.log(this.abogado);
        this.abogado.datosProfesionales[0].especialidadesAbogados.map(
          (especialidad) => {
            aux = aux + especialidad.nombre + ', ';
          }
        );
        this.especialidadParaMostrar = aux.substring(0, aux.length - 2);
      }
      if (this.especialidadParaMostrar.length > 2) {
        this.mostrar = true;
      }
    }

    let userData = JSON.parse(localStorage.user);
    this.countryId = userData.pais;
    this.rolId = userData.rol?.id;
  }

  

  private generateStars() {
    let baseStar =
      'star_outline star_outline star_outline star_outline star_outline';
    for (let i = 1; i <= this.abogado.puntuacionFinal; i++) {
      baseStar = baseStar.replace('star_outline', 'star');
    }
    this.stars = baseStar;
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  navegar() {
    if (this.titulo != undefined) {
      this.datos.abogado = this.abogado;
      this.datos.id_caso = this.id_caso;
      this.datos.caso = this.caso;
      this.datos.tieneFoto = this.tieneFoto;
      this.router.navigateByUrl('/seleccion/datos', { state: this.datos });
    }
  }
}
