<body>
  <div class="contenedor-cards">
    <div class="language-container">
      <img [src]="imgSelected" alt="" class="language-img"/>
      <select [ngModel]="language" (ngModelChange)="switchLang($event)" class="language-select">
          <option value="es-AR" [selected] = '"es-AR" === language'>{{ "admin.Button.es" | translate }}</option>
          <option value="it-IT" [selected] = '"it-IT" === language'>{{ "admin.Button.it" | translate }}</option>
          <option value="en-US" [selected] = '"en-US" === language'>{{ "admin.Button.en" | translate }}</option>
      </select>  
  </div>
    <mat-card class="login"> 
      <form [formGroup]="userForm">
        <img class="logo" src="../../../assets/logo.jpg">
        <mat-form-field appearance="outline" class="inputs">
          <mat-label>{{ "registroCliente.contraseña" | translate }}</mat-label>
          <input required matInput placeholder="Contraseña" 
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            (keyup)="verificarPass()"
            >
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-hint>{{ "registroCliente.contraDescrip" | translate }}</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" class="inputs">
          <mat-label>{{ "registroCliente.repCon" | translate }}</mat-label>
          <input required matInput placeholder="{{ 'registroCliente.repCon' | translate }}"
          (blur)="verificarPassSalir()"
          (keyup)="verificarPass()"
          [type]="hide ? 'password' : 'text'"
            formControlName="repeatPassword">
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <button (click)="onSubmit()" class="boton-primario" mat-raised-button color="primary">{{ "reestablecer.reestablecer" | translate }}</button>
      </form>
    </mat-card>

    <mat-card class="login-redes">
      <h3>{{ "reestablecer.cuenta" | translate }}</h3>
      <button class="boton-secundario" mat-button color="primary" [routerLink]="['']">{{ "reestablecer.ingreso" | translate }}</button>
      <router-outlet #outlet="outlet"></router-outlet>
    </mat-card>
  </div>

</body>