<div class="casoFondo">
  <div class="header-iconos">
    <app-navbar-abogado></app-navbar-abogado>
    <app-language-nav></app-language-nav>
  </div>
  <div class="separadorCentrado">
    <h1>{{ "barraAbo.oportunidades" | translate }}</h1>
  </div>
  <div *ngIf="accionesHabilitadas">
    <div class="separadorCentrado" *ngIf="!hayCaso && !casoNoDisponible">
      <div class="desktop">
        <h1>{{ "oportunidades.no" | translate }}</h1>
        <h2>
          {{ "oportunidades.visualizar" | translate }}
        </h2>
      </div>
      <div class="mobile">
        <h2 class="weigth600">{{ "oportunidades.no" | translate }}</h2>
        <h3>
          {{ "oportunidades.visualizar" | translate }}
        </h3>
      </div>
    </div>

    <div class="separadorCentrado" *ngIf="!hayCaso && casoNoDisponible">
      <h2>{{ "oportunidades.disponible" | translate }}</h2>
    </div>

    <div *ngIf="hayCaso">

      <div *ngIf="!planStatus" style="color: red;">
        <div class="separadorCentrado">
          <mat-icon class="iconoSinPlan">error_outlined</mat-icon>
          <h1>No tienes un plan contratado</h1>
          <p class="subtitle">
            Contrata un plan para poder utilizar los <br />
            servicios disponibles
          </p>
        </div>
      </div>

      <div style="text-align: right;width: 50%;text-align: right;margin-left: auto;margin-right: auto;margin-top: 2%;">
        
        Paginas&nbsp;&nbsp;<button mat-button *ngFor="let page of pages" (click)="gotoPage(page)" [ngStyle]="{'color':page== actualpage ? 'blue' : 'white' }">{{page}}</button>

      </div>
      <div *ngFor="let caso1 of casosDetallados" class="separadorCentrado">
        <mat-card class="card" [ngStyle]="{'border':caso1.isrejected=== true ? '1px solid red' : '1px solid white' }">
          <mat-card-header>
            <br />
            <p class="fecha">{{ caso1.fechaCreacion | date: "dd/MM/yyy" }}</p>
            <br />
          </mat-card-header>
          <mat-card-content>
            <br /><br /><br />
            <mat-card-title class="tituloCaso">{{
              caso1.titulo
            }}</mat-card-title>
            <mat-card-subtitle>{{ caso1.categoria.titulo }}</mat-card-subtitle>
            <hr color="#D8D8D8" />
            <br />
            <h2>{{ "oportunidades.jurisdiccion" | translate }}</h2>
            <mat-card-title>
              <mat-icon class="azul">place</mat-icon
              >{{ caso1.localidad.jurisdiccion.nombre }}
            </mat-card-title>
            <br />
            <mat-card-subtitle> {{ caso1.localidad.nombre }}</mat-card-subtitle>
            <br />
            <hr color="#D8D8D8" />
            <br />
            <h2>{{ "oportunidades.descripcion" | translate }}</h2>
            <mat-card-subtitle>{{ caso1.descripcion }}</mat-card-subtitle>
            <br />
            <hr color="#D8D8D8" />
            <br />
            <h2>{{ "oportunidades.adjuntar" | translate }}</h2>
            <mat-card-subtitle>{{
              "oportunidades.viArchivos" | translate
            }}</mat-card-subtitle>
            <app-archivos
              [archivos]="this.listaArchivos"
              [casoId]="caso.id_caso"
            ></app-archivos>
            <hr color="#D8D8D8" />
            <br />
            
          </mat-card-content>
        </mat-card>
        <br />
        <div class="separadorCentradoChico">
          <button
            *ngIf="accionesHabilitadas && caso1.isrejected!=true"
            class="aceptarRechazarCaso"
            mat-fab
            color="white"
            (click)="rechazarCaso1(caso1)"
          >
            <mat-icon class="iconoRechazar">close</mat-icon>
          </button>
          <button
            *ngIf="accionesHabilitadas && planStatus"
            class="aceptarRechazarCaso"
            mat-fab
            color="white"
            (click)="aceptarCaso1(caso1)"
          >
            <mat-icon class="iconoAceptar">done</mat-icon>
          </button>
        </div>

      </div>
      
    </div>
  </div>
</div>
