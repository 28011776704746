import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { PerfilComponent } from './components/cliente/perfil/perfil.component';
import { PerfilAbogadoComponent } from './components/abogado/perfil-abogado/perfil-abogado.component';
import { CalificarComponent } from './components/calificar/calificar.component';
import { PlanComponent } from './components/abogado/plan/plan.component';
import { AgregarComponent } from './components/abogado/agregar/agregar.component';
import { VinculacionComponent } from './components/mercado/vinculacion/vinculacion.component';
import { PagoFalloComponent } from './components/mercado/pago-fallo/pago-fallo.component';
import { PagoExitosoComponent } from './components/mercado/pago-exitoso/pago-exitoso.component';
import { PagoPendienteComponent } from './components/mercado/pago-pendiente/pago-pendiente.component';

import { AuthGuard } from './auth.guard';
import { Role } from './enums/role';
import { NavbarAdminComponent } from './components/admin/navbar-admin/navbar-admin.component';
import { PaisesComponent } from './components/admin/paises/paises.component';
import { EntidadesComponent } from './components/admin/entidades/entidades.component';
import { ExitoComponent } from './components/abogado/plan/exito/exito.component';
import { TermsComponent } from './components/terms/terms.component';
import { CertificacionComponent } from './components/admin/certificacion/certificacion.component';
import { SignComponent } from './components/sign/sign.component';
import { PoliticasComponent } from './components/politicas/politicas.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'sign-signature',
    component: SignComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/:tipo',
    component: LoginComponent,
  },
  {
    path: 'pago/fallo',
    component: PagoFalloComponent,
  },
  {
    path: 'pago/pendiente',
    component: PagoPendienteComponent,
  },

  {
    path: 'pago/exito',
    component: PagoExitosoComponent,
  },
  {
    path: 'vinculacion',
    component: VinculacionComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Abogado] },
  },
  {
    path: 'agregar',
    component: AgregarComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Abogado] },
  },
  {
    path: 'perfil-cliente',
    component: PerfilComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Cliente] },
  },
  {
    path: 'perfil-abogado',
    component: PerfilAbogadoComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Abogado] },
  },
  {
    path: 'calificar',
    component: CalificarComponent,
  },
  {
    path: 'plan',
    component: PlanComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Abogado] },
  }, {
    path: 'plan/exito',
    component: ExitoComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Abogado] },
  },

  {
    path: 'navbarAdmin',
    component: NavbarAdminComponent,
  },
  {
    path: 'paises',
    component: PaisesComponent,
  },
  {
    path: 'terminos-y-condiciones',
    component: TermsComponent,
  },
  {
    path: 'politica-de-privacidad',
    component: PoliticasComponent,
  },
  {
    path: 'entidades',
    component: EntidadesComponent,
  },{
    path: 'certificacion',
    component: CertificacionComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
