import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    constructor() {}

    doPreparePageTranslate(translate: TranslateService): void {
        translate.addLangs(['es-AR', 'it-IT','en-US']);
        if (sessionStorage.getItem('language') == undefined) {
            var ln = window.navigator.language;
            sessionStorage.setItem('language','es-AR');
            console.log('1ro: ' + ln);
            if (ln != 'es-AR' && ln != 'it-IT') {
              sessionStorage.language = 'es-AR';
              translate.setDefaultLang('es-AR');
            } else {
              translate.setDefaultLang(ln);
            }
        } else { 
            translate.setDefaultLang(sessionStorage.language);
        }
    }

}