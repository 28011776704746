import { Component, OnInit } from '@angular/core';
import {
  Validators,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Location } from '@angular/common';
import {
  RegisterAbogado,
  Identificacion,
  Especialidad,
  Jurisdiccion,
  Localidad,
} from '../../../../api/user';
import { ApiService } from '../../../../api/api.service';
import { MatSnackBar, 
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition, } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/services/login.service';
import { RoleNumber } from '../../../../enums/role';
import { CheckPasswordService } from '../../../../services/register/checkPassword/check-password.service';
import { TranslateService } from '@ngx-translate/core';
import { Pais } from 'src/app/api/pais';
import { MatDialog } from '@angular/material/dialog';
import { Respuesta } from 'src/app/api/respuesta';
import { Certificacion } from 'src/app/api/certificacion';
import { MatriculaConfig } from 'src/app/api/matriculaConfig';
import { UtilService } from 'src/app/services/util.service';
import { RedesCredenciales } from 'src/app/interfaces/redes-credenciales';

declare var gtag;
@Component({
  selector: 'app-registro-abogado',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css'],
})
export class RegistroAbogadoComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  coinciden = true;
  blur = false;
  language:String="";
  imgSelected: String = "";
  showTomo:boolean=true;
  showFolio:boolean=true;
  showMatricula:boolean=false;
  matriculaConfig:MatriculaConfig;
  constructor(
    private apiService: ApiService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private loginSrv: LoginService,
    private checkPasswordService: CheckPasswordService,
    private serviceUtil: UtilService,
    public translate: TranslateService,
    public dialog: MatDialog,
  ) {
    this.serviceUtil.doPreparePageTranslate(translate);
    if(this.language == "es-AR")
      this.imgSelected = "../../../assets/españa.png"
    if(this.language == "it-IT")
      this.imgSelected = "../../../assets/italia.png"
    if(this.language == "en-US")
      this.imgSelected = "../../../assets/ingles.png"
  }
  
  especialidades: Especialidad[];
  jurisdicciones: Jurisdiccion[];
  identificaciones: Identificacion[];
  registroRedes: boolean = false;
  cacheCountries: Pais[];
  localidades: Localidad[];
  provinciasResidencias: Jurisdiccion[];
  localidadesResidencias: Localidad[];
  nombreIdentificacion = 'Seleccione una del campo anterior';
  colegioValido = true;
  tomoValido = true;
  folioValido = true;

  newUser = new UntypedFormGroup({
    nombre: new UntypedFormControl(),
    apellido: new UntypedFormControl(),
    email: new UntypedFormControl(),
    dni: new UntypedFormControl(),
    tipoIdentificacion: new UntypedFormControl(),
    password: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/),
    ]),
    repeatPassword: new UntypedFormControl('', [Validators.required]),
    tomo: new UntypedFormControl('', [Validators.max(999), Validators.min(0)]),
    folio: new UntypedFormControl('', [Validators.max(9999), Validators.min(0)]),
    colegio: new UntypedFormControl('', [
      Validators.maxLength(255),
    ]),
    otro: new UntypedFormControl(),
    pais: new UntypedFormControl(),
    jurisdiccion: new UntypedFormControl(),
    localidad: new UntypedFormControl(),
    provinciaResidencia: new UntypedFormControl(),
    localidadResidencia: new UntypedFormControl('', [Validators.required]),
    especialidad: new UntypedFormControl(),
    telefono: new UntypedFormControl(),
    certification : new UntypedFormControl()
  });

  suscripcionPlataforma: string;
  suscripcionUuid: string;

  redes:string="";
  ngOnInit(): void {
    this.registroRedes = false;
    this.route.paramMap.subscribe((params) => {
      let suscripcionUuid: string;
      const red = params['params'].tipo;
      this.redes = red;
      this.route.queryParams.subscribe((params) => {
        const code = params['code'];
        if (code) {
          this.registroRedes = true;
          this.loginSrv.getRedPerfil(code, 'A', red).subscribe((res) => {
            this.newUser.patchValue({
              nombre: res.nombre,
              apellido: res.apellido,
              email: res.mail,
            });
            this.newUser.controls['nombre'].disable();
            this.newUser.controls['apellido'].disable();
            this.newUser.controls['email'].disable();
            this.newUser.controls['password'].disable();
            this.newUser.controls['repeatPassword'].disable();
          });
        } else if (params?.plataforma) {
          if (params.plataforma == 'MercadoPago') { // https://legalify01.dwxdesign.com/registro-abogado?plataforma=MercadoPago&preapproval_id=8aef2d75867b449001868697ea6f086d
            if (params?.preapproval_id)
              suscripcionUuid = params.preapproval_id;
            else {
              this.openSnackBar("No se obtuvo el ID de la suscripcion, volviendo a la pagina anterior.");
              setTimeout(() => {
                this.location.back();
              }, 5000);
              return;
            }
          } else {
            suscripcionUuid = params.suscripcionUuid;
          }
          this.loginSrv.getPlataformaPerfil(params.plataforma, suscripcionUuid).subscribe({
            next: (resp: RedesCredenciales) => {
              this.newUser.patchValue({
                nombre: resp.nombre,
                apellido: resp.apellido,
                email: resp.mail,
              });
              if (resp.countryId) {
                this.cacheCountries.some(pais => {
                  if (pais.countryId == resp.countryId) {
                    this.newUser.patchValue({ pais });
                    return true;
                  }
                  return false;
                });
                this.cargarProvincias();
                console.log(`Seleccionando pais por defecto: ${resp.countryId}`);
                this.newUser.controls['pais'].disable();

                this.suscripcionPlataforma = params.plataforma;
                this.suscripcionUuid = suscripcionUuid;
              }
            }
          })
        }
      });
    });
    this.trackRegister();
    this.apiService.getEspecialidades(RoleNumber.Abogado).subscribe(
      (especialidades) => {
        this.especialidades = especialidades;
      },
      (error) => {
        console.error(error);
      }
    );

    this.apiService.getJurisdicciones().subscribe(
      (respuesta) => {
        this.jurisdicciones = respuesta;
        this.provinciasResidencias = respuesta;
      },
      (error) => {
        console.error(error);
      }
    );

    this.apiService.getPaisesClean().subscribe({
      next: (resp: Pais[]) => {
        this.cacheCountries = resp;
        this.jurisdicciones = this.provinciasResidencias = [];

        resp.some((pais: Pais) => {
          pais.name = pais.name ?? "";
          if (pais.name.toUpperCase().startsWith("ARGENT")) {
            this.newUser.controls['pais'].setValue(pais);
            this.cargarProvincias();
            return true;
          }
          return false;
        });
      },
      error: (err: any) => {
        console.error(err);
      }
    });

    this.getIdentificaciones();
  }

  keyPressAlphanumeric(event:any){
    // pattern of alphanumeric characters with maximum length of 9
    const pattern = /^[a-zA-Z0-9]{0,9}$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    } 
  }

  switchLang(lang: string) {
    console.log(lang)
    this.translate.use(lang);
    this.language = lang;
    sessionStorage.setItem('language', lang);
    sessionStorage.language = lang;
    if(lang == "es-AR")
      this.imgSelected = "../../../assets/españa.png"
    if(lang == "it-IT")
      this.imgSelected = "../../../assets/italia.png"
    if(lang == "en-US")
      this.imgSelected = "../../../assets/ingles.png"
  }

  verificarPassSalir() {
    this.blur = true;
    this.verificarPass();
  }

  verificarPass() {
    this.coinciden = this.checkPasswordService.checkPass(
      this.blur,
      this.newUser.value.password,
      this.newUser.value.repeatPassword
    );
  }

  trackRegister(){
    gtag('event', 'registro_abog_visita', {
      'send_to':environment.measurement_id,
      'event_name': 'registro_abog_visita'
    });
  }

  onSubmit() {
    if (this.newUser.value.password != this.newUser.value.repeatPassword) {
      this.openSnackBar(this.translate.instant("password.diferentes"));
    } else {
      this.guardarAbogado();
    }
  }
  hide = true;

  getIdentificaciones() {
    this.apiService.getIdentificacion().subscribe(
      (identificaciones) => {
        this.identificaciones = identificaciones;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  certificar(){


    let respuestas:Respuesta[] =[];

    let dto= new Certificacion(this.newUser.value.email,"Certificacion","",respuestas);
       this.apiService.certification(dto).subscribe(preg => {
        
        this.openSnackBar('Registro exitoso, revise su correo para la firma del manifiesto');
        localStorage.setItem('redSocial', this.registroRedes.toString());
        this.router.navigate(['/exito-abogado']);
        },
        error => {
            console.error(error);
            this.openSnackBar("Estamos trabajando para mejorar el servicio, por favor intente nuevamente más tarde");
        });



  }

  guardarAbogado() {

    const rawValue = this.newUser.getRawValue();
    const { nombre, apellido, email } = rawValue;
    const countryId = rawValue.pais.countryId;
    let lenguage=sessionStorage.getItem('language');
    let lchar="";
    if(lenguage == "es-AR")
      lchar="P";
    if(lenguage == "it-IT")
      lchar="T";
    if(lenguage == "en-US")
      lchar="N";
    this.apiService
      .registrarAbogado({
        apellido,
        clave: this.newUser.value.password,
        datosProfesionales: [
          {
            colegio: this.newUser.value.colegio.toUpperCase(),
            especialidadesIds: [
              this.newUser.value.especialidad.id_especialidad,
            ],
            folio: this.newUser.value.folio,
            otro: this.newUser.value.otro,
            localidadId: this.localidades[0].id_localidad,
            numeroDni: this.newUser.value.dni,
            tipoDni:
              this.newUser.value.tipoIdentificacion.id_tipos_identificacion,
            tomo: this.newUser.value.tomo,
            telefono: this.newUser.value.telefono.toString(),
          },
        ],
        mail: email,
        lenguaje:lchar,
        localidadId: this.newUser.value.localidadResidencia.id_localidad,
        nombre,
        pais: countryId,
        redesSociales: this.registroRedes,
        rol: 2,
        mailConfirmUrl: '/validada-abogado',
        suscripcionPlataforma: this.suscripcionPlataforma,
        suscripcionUuid: this.suscripcionUuid
      } as RegisterAbogado)
      .subscribe(
        (res) => {
          if (sessionStorage["companyId"]) {
            this.loginSrv.doUpdateUsuarioCompany(res.id_usuario, sessionStorage["companyId"]).subscribe({
              next: () => {
                sessionStorage.removeItem("companyId");
              }
            });
          }

          if(this.registroRedes==true){
            if(this.redes=="L"){
              gtag('event', 'registro_abog_exitoso_linkedin', {
                'send_to':environment.measurement_id,
                'method':'registro_abog_exitoso_linkedin'
              });
            }
            else if(this.redes=="G"){
              gtag('event', 'registro_abog_exitoso_gmail', {
                'send_to':environment.measurement_id,
                'method':'registro_abog_exitoso_gmail'
              });

            }
            else if(this.redes=="F"){
              gtag('event', 'registro_abog_exitoso_facebook', {
                'send_to':environment.measurement_id,
                'method':'registro_abog_exitoso_facebook'
              });
            }
            
          }
          else{
            gtag('event', 'registro_abog_exitoso_mail', {
              'send_to':environment.measurement_id,
              'method':'registro_abog_exitoso_mail'
            });
          }

          

          if(this.newUser.value.certification=='true' || this.newUser.value.certification==true ){
            this.certificar();
          }
          else{
            this.openSnackBar('Registro exitoso');
            localStorage.setItem('redSocial', this.registroRedes.toString());
            this.router.navigate(['/exito-abogado']);
          }

          
        },
        (error) => {
          if (error.status === 409) {
            this.openSnackBar(
              this.translate.instant("email.registered")
            );
          } else {
            this.openSnackBar('Error');
          }
        }
      );
  }

  cargarLocalidades() {
    this.apiService
      .getLocalidades(this.newUser.value.jurisdiccion.id_jurisdiccion)
      .subscribe(
        (localidad) => {
          this.localidades = localidad;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  cargarProvincias(): void {
    this.jurisdicciones = this.provinciasResidencias = [];
    this.localidadesResidencias = [];
    let paraguay=0;
    this.cacheCountries.some((pais: Pais) => {
      pais.name = pais.name ?? "";
      if(pais.countryId==this.newUser.value.pais.countryId){
        if (pais.name.toUpperCase().startsWith("PARA")) {
           paraguay=1;
        }
      }
    });
    if(paraguay==1){
      this.showFolio=false;
      this.showTomo=false;
      this.newUser.get('tomo').clearValidators();
      this.newUser.get('folio').clearValidators();
      this.newUser.get('tomo').updateValueAndValidity();
      this.newUser.get('folio').updateValueAndValidity();
      this.showMatricula=true;
      this.apiService.getMatriculaConfig(this.newUser.value.pais.countryId).subscribe({
        next: (resp: MatriculaConfig) => {
          this.matriculaConfig=resp;
          if(paraguay==1){
            if(this.matriculaConfig.visible==true){
              if(this.matriculaConfig.solicitarCampo){
                this.newUser.get('otro').setValidators([Validators.required, 
                  Validators.maxLength(this.matriculaConfig.tamanio)]);
              }
              else{
                this.newUser.get('otro').setValidators([
                  Validators.minLength(this.matriculaConfig.tamanio),Validators.maxLength(this.matriculaConfig.tamanio)]);
              }
            }
            else{
              this.newUser.get('otro').clearValidators();
              this.showMatricula=false;
            }
            this.newUser.get('otro').updateValueAndValidity();
          }
        }
      });
    }
    else{
      this.showFolio=true;
      this.showTomo=true;
      this.showMatricula=false;
      this.newUser.get('otro').clearValidators();
      this.newUser.get('otro').updateValueAndValidity();
    }
    
    this.apiService.getEspecialidadesByPais(RoleNumber.Abogado, this.newUser.value.pais.countryId).subscribe({
      next: (resp: Especialidad[]) => {
        this.especialidades = resp;
      },
      error: err => {
        console.log(err);
      }
    });
    this.apiService.getJurisdiccionPaisesBy(this.newUser.value.pais.countryId).subscribe({
      next: (resp: Jurisdiccion[]) => {
        this.jurisdicciones = this.provinciasResidencias = resp;
        this.localidadesResidencias = [];
      }
    });
  }

  cargarLocalidadesResidencia() {
    this.apiService
      .getLocalidades(this.newUser.value.provinciaResidencia.id_jurisdiccion)
      .subscribe(
        (localidad) => {
          this.localidadesResidencias = localidad;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  onKey(event) {
    event.target.value = event.target.value.toUpperCase();
  }

  onKeyUp(event) {
    if (!this.newUser.value.colegio.match(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ.\s]+$/)) {
      this.colegioValido = false;
    } else {
      this.colegioValido = true;
    }
  }

  onKeyUpFolio(event) {
    let aux = event.target.value;
    if (aux > 9999) {
      this.folioValido = false;
    } else {
      this.folioValido = true;
    }
  }

  onKeyUpTomo(event) {
    let aux = event.target.value;
    if (aux > 999) {
      this.tomoValido = false;
    } else {
      this.tomoValido = true;
    }
  }

  definirIdentificacion() {
    this.nombreIdentificacion = this.newUser.value.tipoIdentificacion.nombre;
  }
}
