<body>
  <div class="desktop">
    <div class="contenedor-cards-mensaje">
      <mat-card class="mensaje">
        <img class="logo" src="../../../../assets/logo.jpg" />
        <div *ngIf="!registroRedes">
          <img class="logo" src="../../../../assets/confirmacion.jpg" />
          <div class="separadorCentrado">
            <h1>{{ "creaExitosaAbo.creo" | translate }}</h1>
            <mat-card-subtitle color="primary">{{
              "creaExitosaAbo.mail" | translate
            }}</mat-card-subtitle
            ><br />
          </div>
        </div>
        <div *ngIf="registroRedes">
          <img class="logo" src="../../../../assets/verificando.jpg" />
          <div class="separadorCentrado">
            <h1>{{ "creaExitosaAbo.matricula" | translate }}</h1>
            <mat-label>{{ "creaExitosaAbo.proceso" | translate }} </mat-label>
            <mat-label class="azul">{{
              "creaExitosaAbo.noDeseado" | translate
            }}</mat-label>
          </div>
        </div>
        <button
          class="boton-footer"
          mat-button
          color="primary"
          [routerLink]="['']"
        >
          {{ "creaExitosaAbo.volver" | translate }}
        </button>
        <router-outlet #outlet="outlet"></router-outlet>
      </mat-card>
    </div>
  </div>

  <div class="mobile">
    <mat-card class="mensaje">
      <img class="logo" src="../../../../assets/logo.jpg" />
      <div *ngIf="!registroRedes">
        <img class="logo" src="../../../../assets/confirmacion.jpg" />
        <div class="separadorCentrado">
          <h1>{{ "creaExitosaAbo.creo" | translate }}</h1>
          <mat-card-subtitle color="primary">{{
            "creaExitosaAbo.mail" | translate
          }}</mat-card-subtitle
          ><br />
        </div>
      </div>
      <div *ngIf="registroRedes">
        <img class="logo" src="../../../../assets/verificando.jpg" />
        <div class="separadorCentrado">
          <h1>{{ "creaExitosaAbo.matricula" | translate }}</h1>
          <mat-label>{{ "creaExitosaAbo.proceso" | translate }} </mat-label>
          <mat-label class="azul">{{
            "creaExitosaAbo.noDeseado" | translate
          }}</mat-label
          ><br />
        </div>
      </div>
      <button
        class="boton-footer"
        mat-button
        color="primary"
        [routerLink]="['']"
      >
        {{ "creaExitosaAbo.volver" | translate }}
      </button>
      <router-outlet #outlet="outlet"></router-outlet>
    </mat-card>
  </div>
</body>
