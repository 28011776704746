import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  NewUser,
  RegisterClient,
  ReturnUser,
  Identificacion,
  ResetPassword,
  ResetPasswordRepeat,
  PendienteAbogado,
  Especialidad,
  Jurisdiccion,
  Localidad,
  Categoria,
  Perfil,
  CasoDetallado,
  ListaArchivos,
  PerfilEditar,
  DatosJurisdiccion,
  JurisdiccionEditarVer2,
  MotivoBaja,
  BajaInput,
  RegisterAbogado,
  JurisdiccionEditarVer3,
} from './user';
import { Rango, RangoConId } from './horarios';
import { Planes, PlanesIncrease } from './planes';
import { environment } from '../../environments/environment';
import {
  CuentaMercado,
  Link,
  ContratarAbogado,
  ContratarPlan,
  ContratarPlanYAbogado,
} from './mercado';
import { UsuariosHora, CrearCitaDatos, RangoAgenda } from './agenda';
import { NuevaPuntuacion } from '../interfaces/NuevaPuntuacion';
import { Puntuacion } from '../interfaces/Puntuacion';
import { Pais, PaisConfig } from './pais';
import { Provincia } from './Provincia';
import { JurisdiccionPaises, JurisdiccionPaisesInput } from './jurisdiccion';
import { LocalidadPaises } from './localidad';
import { Company } from './company';
import { map } from 'rxjs/operators';
import { Formulario } from './formulario';
import { Pregunta } from './pregunta';
import { Certificacion } from './certificacion';
import { MatriculaConfig } from './matriculaConfig';
import {
  EspecialidadesCliente,
  EspecialidadesAbogado,
  CategoriasAll,
  EspecialidadesLista,
  IPlanAmount,
  ICicloPlanAmount,
  IPlanCicloPlanAmount,
} from '../components/admin/multipago/modules/multipago.model';

@Injectable({ providedIn: 'root' })
export class ApiService {
  especialidades: any[] = [];
  private url = environment.apiUrl + '/';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  multipartOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    }),
  };
  constructor(private http: HttpClient) {}

  getAbogados(modo: string): Observable<PendienteAbogado[]> {
    return this.http.get<PendienteAbogado[]>(
      this.url + `api/usuario?estado=${modo}`,
      this.httpOptions
    );
  }

  getPlanes(): Observable<Planes[]> {
    return this.http.get<Planes[]>(this.url + 'api/planes', this.httpOptions);
  }

  getPlanesAll(): Observable<Planes[]> {
    return this.http.get<Planes[]>(
      this.url + 'api/planes/all',
      this.httpOptions
    );
  }

  doPlanesSave(plan: Planes): Observable<Planes> {
    let url: string = `${this.url}api/planes`;
    return this.http.post<Planes>(url, plan, this.httpOptions);
  }

  doPlanesDelete(planId: number): Observable<Planes> {
    let url: string = `${this.url}api/planes/${planId}`;
    return this.http.delete<Planes>(url, this.httpOptions);
  }

  aceptarBajar(estado: string, id: number) {
    return this.http.patch(
      this.url + `api/usuario/${id}`,
      {
        estado: `${estado}`,
        loginUrl: environment.angularUrl,
      },
      this.httpOptions
    );
  }

  baja(baja: BajaInput) {
    const formData = new FormData();
    formData.append('id', baja.id.toString());
    formData.append('estado', baja.estado.toString());
    formData.append('motivoBaja', baja.motivoBaja.toString());
    //return this.http.post<any>(this.url + 'api/caso', formData);

    return this.http.put<any>(this.url + `api/usuario/baja`, formData);
  }

  solicitarReenvioConfirmacion(mail: string) {
    const formData = new FormData();
    formData.append('mail', mail);

    return this.http.post<any>(
      this.url + `cuenta/requestResendConfirmation`,
      formData
    );
  }

  registrarCliente(registerClient: RegisterClient): Observable<RegisterClient> {
    return this.http.post<RegisterClient>(
      this.url + 'cuenta',
      registerClient,
      this.httpOptions
    );
  }

  registrarClienteWithFiles(registerClient: RegisterClient, files: File[]): Observable<RegisterClient> {
    let data = new FormData();

    data.append("data", JSON.stringify(registerClient));
    if (files) {
      files.forEach(file => {
        data.append("files", file);
      });
    }
    return this.http.post<RegisterClient>(
      this.url + 'cuenta/files',
      data
    );
  }

  registrarAbogado(
    registerClient: RegisterAbogado
  ): Observable<RegisterAbogado> {
    return this.http.post<RegisterAbogado>(
      this.url + 'cuenta',
      registerClient,
      this.httpOptions
    );
  }

  certification(registerClient: Certificacion): Observable<any> {
    return this.http.post<any>(
      this.url + 'signature',
      registerClient,
      this.httpOptions
    );
  }

  getcertification(mail): Observable<any> {
    const formData = new FormData();
    formData.append('mail', mail);
    return this.http.post<any>(this.url + 'signature/certification', formData);
  }

  postUser(newUser: NewUser): Observable<ReturnUser> {
    return this.http.post<ReturnUser>(
      this.url + 'cuenta/login',
      newUser,
      this.httpOptions
    );
  }

  LoginUserNippy(dato: any) {
    const httpOpt = {
      headers: new HttpHeaders({ 'content-type': 'application/json' }),
    };
    return this.http.post<any>(this.url + 'cuenta/login/entity', dato, httpOpt);
  }

  postUserNippy(dato: any) {
    const httpOpt = {
      headers: new HttpHeaders({ 'content-type': 'application/json' }),
    };
    return this.http.post<any>(this.url + 'cuenta/user/entity', dato, httpOpt);
  }

  getIdentificacion(): Observable<Identificacion[]> {
    return this.http.get<Identificacion[]>(
      this.url + 'tiposidentificacion',
      this.httpOptions
    );
  }

  confirmarUsuario(token: string): Observable<ReturnUser> {
    return this.http.put<ReturnUser>(
      this.url + `cuenta?token=${token}`,
      token,
      this.httpOptions
    );
  }

  tutorialCompleto(): Observable<any> {
    return this.http.put<any>(
      this.url + 'api/usuario/tutorialCompletado',
      this.httpOptions
    );
  }

  requestChangePassword(
    resetPassword: ResetPassword
  ): Observable<ResetPassword> {
    return this.http.patch<ResetPassword>(
      this.url + 'cuenta',
      resetPassword,
      this.httpOptions
    );
  }

  getRequest(id: number): Observable<ReturnUser> {
    return this.http.get<ReturnUser>(
      this.url + `cuenta/requestChangePassword/${id}`,
      this.httpOptions
    );
  }

  setNewPass(resetPasswordRepeat: ResetPasswordRepeat): Observable<ReturnUser> {
    return this.http.patch<ReturnUser>(
      this.url + 'cuenta/requestChangePassword',
      resetPasswordRepeat,
      this.httpOptions
    );
  }

  /* Casos y datos */

  getPlanInfos(jurisdiccionId: number, planIds: number[]): Observable<IPlanCicloPlanAmount> {
    return new Observable<IPlanCicloPlanAmount>(observer => {
      let url = `${this.url}multipago/cliente/plan/defaultamounts`;
      let params = {
        ciclo: -1,
        jurisdiccionId,
        planIds
      }
      let options = Object.assign({params}, this.httpOptions);
      let data: IPlanCicloPlanAmount = {};
      let ciclo: ICicloPlanAmount;

      this.http.get<IPlanAmount[]>(url, options).subscribe({
        next: (planAmounts: IPlanAmount[]) => {
          planAmounts.forEach((planAmount: IPlanAmount) => {
            if (!data[planAmount.planId])
              data[planAmount.planId] = {};

            ciclo = data[planAmount.planId];
            ciclo[planAmount.ciclo] = planAmount;
          });
          observer.next(data);
        },
        complete: () => {
          observer.complete();
        }
      });
    });
  }

  getEspecialidades(rol: number): Observable<Especialidad[]> {
    return this.http.get<Especialidad[]>(
      this.url + `especialidad/${rol}`,
      this.httpOptions
    );
  }

  getEspecialidadesByPais(rol: number, countryId: number): Observable<Especialidad[]> {
    let url = `${this.url}especialidad/${rol}`;
    let params = {
      countryId
    };
    let options = Object.assign({ params }, this.httpOptions);

    return this.http.get<Especialidad[]>(url, options);
  }

  getMotivosBaja(): Observable<MotivoBaja[]> {
    return this.http.get<MotivoBaja[]>(
      this.url + `api/usuario/motivosbaja`,
      this.httpOptions
    );
  }

  getJurisdicciones(): Observable<Jurisdiccion[]> {
    return this.http.get<Jurisdiccion[]>(
      this.url + 'jurisdiccion',
      this.httpOptions
    );
  }

  getJurisdiccionByLocalidadId(localidadId: number): Observable<Jurisdiccion> {
    let url = `${this.url}jurisdiccion/byLocalidad/${localidadId}`;
    return this.http.get<Jurisdiccion>(url, this.httpOptions);
  }

  getLocalidades(id: number): Observable<Localidad[]> {
    return this.http.get<Localidad[]>(
      this.url + `jurisdiccion/${id}/localidad`,
      this.httpOptions
    );
  }

  getCategorias(id: number): Observable<Categoria[]> {
    return this.http.get<Categoria[]>(
      this.url + `especialidad/${id}/categorias`,
      this.httpOptions
    );
  }

  getCategoriasByPais(id: number, countryId: number): Observable<Categoria[]> {
    let url = `${this.url}especialidad/${id}/categorias`;
    let params = {
      countryId
    };
    let options = Object.assign({ params }, this.httpOptions);

    return this.http.get<Categoria[]>(url, options);
  }

  postCaso(
    categoriaId,
    descripcion,
    files,
    localidadId,
    titulo,
    profesionalAsignadoId,
    tipo,
    timeStamp,
    timeZone
  ) {
    let data = {
      categoriaId: categoriaId,
      descripcion: descripcion,
      localidadId: localidadId,
      tipo: tipo,
      titulo: titulo,
      profesionalAsignadoId: profesionalAsignadoId,
      fechaCreacion: timeStamp,
      zonaHoraria: timeZone,
      files: files,
    };

    return this.http.post<any>(this.url + 'api/caso', data);
  }

  getPaises(): Observable<Pais[]> {
    console.log(this.httpOptions);
    return this.http.get<Pais[]>(this.url + 'pais', this.httpOptions);
  }

  getPaisesClean(): Observable<Pais[]> {
    console.log('getPaisesClean()');
    return this.getPaises().pipe(
      map((resp: Pais[]) => {
        let result: Pais[] = [];

        resp = resp ?? [];
        resp.forEach((pais: Pais) => {
          if (pais && pais.countryId > 0) result.push(pais);
        });
        return result;
      })
    );
  }

  getFormulario(id): Observable<Formulario> {
    console.log(this.httpOptions);
    return this.http.get<Formulario>(
      this.url + 'formulario/' + id,
      this.httpOptions
    );
  }

  postFormulario(form: Formulario) {
    const httpOpt = {
      headers: new HttpHeaders({ 'content-type': 'application/json' }),
    };
    return this.http.post<any>(
      this.url + 'api/formulario',
      JSON.stringify(form),
      httpOpt
    );
  }

  putPregunta(preg: Pregunta) {
    const httpOpt = {
      headers: new HttpHeaders({ 'content-type': 'application/json' }),
    };

    const formData = new FormData();
    formData.append('pregunta', preg.pregunta);
    formData.append('peso', preg.peso.toString());
    formData.append('respuesta', preg.respuesta.toString());
    formData.append('idformulario', preg.formulario.id_formulario.toString());

    return this.http.post<any>(this.url + 'formulario/addpregunta', formData);
  }

  updPregunta(preg: Pregunta) {
    const httpOpt = {
      headers: new HttpHeaders({ 'content-type': 'application/json' }),
    };
    return this.http.put<any>(
      this.url + 'formulario/updpregunta',
      JSON.stringify(preg),
      httpOpt
    );
  }

  postCompany(company: Company) {
    const formData = new FormData();
    const httpOpt = {
      headers: new HttpHeaders({ 'content-type': 'application/json' }),
    };
    return this.http.post<any>(
      this.url + 'api/company/create',
      JSON.stringify(company),
      httpOpt
    );
  }

  putCompany(company: Company) {
    const formData = new FormData();
    const httpOpt = {
      headers: new HttpHeaders({ 'content-type': 'application/json' }),
    };
    return this.http.put<any>(
      this.url + 'api/company/edit',
      JSON.stringify(company),
      httpOpt
    );
  }

  putCompanyLogo(company: Company, file): Observable<void> {
    let url: string = `${this.url}api/company/${company.idCompany}/logo`;
    const formData = new FormData();
    formData.append('file', file);

    return this.http.put<void>(url, formData);
  }

  getCompanies(): Observable<Company[]> {
    console.log(this.httpOptions);
    return this.http.get<Company[]>(
      this.url + 'api/company/all',
      this.httpOptions
    );
  }

  getPais(countryId: number): Observable<Pais> {
    return this.http.get<Pais>(
      this.url + `pais/${countryId}`,
      this.httpOptions
    );
  }

  postPais(pais: Pais) {
    const formData = new FormData();
    const httpOpt = {
      headers: new HttpHeaders({ 'content-type': 'application/json' }),
    };
    return this.http.post<any>(
      this.url + 'pais',
      JSON.stringify(pais),
      httpOpt
    );
  }

  postJurisdicciones(jurisdicciones: JurisdiccionPaises[]) {
    const httpOpt = {
      headers: new HttpHeaders({ 'content-type': 'application/json' }),
    };
    return this.http.post<any>(
      this.url + 'jurisdiccion/bulk',
      '[' + jurisdicciones.toString() + ']',
      httpOpt
    );
  }

  postLocalidades(localidades: LocalidadPaises[]) {
    const httpOpt = {
      headers: new HttpHeaders({ 'content-type': 'application/json' }),
    };
    return this.http.post<any>(
      this.url + 'jurisdiccion/localidad/bulk',
      '[' + localidades.toString() + ']',
      httpOpt
    );
  }

  getJurisdiccionPaises(
    countryId: number
  ): Observable<JurisdiccionPaisesInput[]> {
    return this.http.get<JurisdiccionPaisesInput[]>(
      this.url + `jurisdiccion/country/${countryId}`,
      this.httpOptions
    );
  }

  getJurisdiccionPaisesBy(countryId: number): Observable<Jurisdiccion[]> {
    return this.getJurisdiccionPaises(countryId).pipe(
      map((input: JurisdiccionPaisesInput[]) => {
        let result: Jurisdiccion[] = [];

        input = input ?? [];
        input.forEach((row: JurisdiccionPaisesInput) => {
          result.push(row);
        });
        return result;
      })
    );
  }

  getJurisdiccionPaisesAuto(): Observable<Jurisdiccion[]> {
    let userRet = JSON.parse(localStorage.getItem('user'));
    let paisId = userRet.pais;
    if (paisId == 'ARG') paisId = '54';

    return this.getJurisdiccionPaisesBy(0 + paisId);
  }

  getSessionCountryIdByLocalStorage(): number {
    let userRet = JSON.parse(localStorage.getItem('user'));
    let paisId: string = userRet.pais;
    if (paisId == 'ARG') paisId = '54';

    return Number.parseInt(paisId);
  }

  // postJurisdiccionPais(jurisdiccionId, descripcion, name, countryId) {
  //   const formData = new FormData();
  //   formData.append('jurisdiccionId', jurisdiccionId),
  //     formData.append('descripcion', descripcion),
  //     formData.append('name', name),
  //     formData.append('countryId', countryId);

  //   return this.http.post<any>(this.url + 'jurisdiccion', formData);
  // }

  postJurisdiccionPais(jurisdiccion: JurisdiccionPaises) {
    const httpOpt = {
      headers: new HttpHeaders({ 'content-type': 'application/json' }),
    };
    return this.http.post<any>(
      this.url + 'jurisdiccion',
      JSON.stringify(jurisdiccion),
      httpOpt
    );
  }

  getJurisdiccionPaisAdmin(paisId: number): Observable<boolean> {
    let url = `${this.url}jurisdiccion/country/admin/${paisId}`;
    return this.http.get<boolean>(url, this.httpOptions);
  }

  getMatriculaConfig(paisId: number): Observable<MatriculaConfig> {
    let url = `${this.url}pais/getMatriculaConfig/${paisId}`;
    return this.http.get<MatriculaConfig>(url, this.httpOptions);
  }

  postMatriculaConfig(matriculaConfig: MatriculaConfig) {
    const httpOpt = {
      headers: new HttpHeaders({ 'content-type': 'application/json' }),
    };
    return this.http.post<any>(
      this.url + 'pais/addMatriculaConfig',
      JSON.stringify(matriculaConfig),
      httpOpt
    );
  }

  putMatriculaConfig(matriculaConfig: MatriculaConfig) {
    const httpOpt = {
      headers: new HttpHeaders({ 'content-type': 'application/json' }),
    };
    return this.http.put<any>(
      this.url + 'pais/updMatriculaConfig',
      JSON.stringify(matriculaConfig),
      httpOpt
    );
  }

  getPaisConfig(countryId: number, clave: string): Observable<PaisConfig> {
    let url: string = `${this.url}pais/${countryId}/config/${clave}`;

    return this.http.get<PaisConfig>(url);
  }

  doPaisConfigSave(
    countryId: number,
    paisConfig: PaisConfig
  ): Observable<PaisConfig> {
    let url: string = `${this.url}pais/${countryId}/config`;

    return this.http.post<PaisConfig>(url, paisConfig);
  }

  setJurisdiccionPaisAdminPut(paisId: number): Observable<string> {
    let url = `${this.url}jurisdiccion/country/admin/${paisId}`;
    return this.http.put<string>(url, this.httpOptions);
  }

  setJurisdiccionPaisAdminDel(paisId: number): Observable<string> {
    let url = `${this.url}jurisdiccion/country/admin/${paisId}`;
    return this.http.delete<string>(url, this.httpOptions);
  }

  getLocalidadPaises(jurisdiccionId: number): Observable<LocalidadPaises[]> {
    return this.http.get<LocalidadPaises[]>(
      this.url + `jurisdiccion/${jurisdiccionId}/localidad`,
      this.httpOptions
    );
  }

  postLocalidadPais(localidad: LocalidadPaises, jurisdiccionId: number) {
    const httpOpt = {
      headers: new HttpHeaders({ 'content-type': 'application/json' }),
    };
    return this.http.post<any>(
      this.url + `jurisdiccion/${jurisdiccionId}/localidad`,
      JSON.stringify(localidad),
      httpOpt
    );
  }

  getProvincias(countryId: number): Observable<Provincia[]> {
    return this.http.get<Provincia[]>(
      this.url + `provincia/${countryId}`,
      this.httpOptions
    );
  }

  postProvincia(stateId, name, countryId) {
    const formData = new FormData();
    formData.append('stateId', stateId);
    formData.append('name', name);
    formData.append('countryId', countryId);

    return this.http.post<any>(this.url + 'provincia', formData);
  }

  postCasoTmpUpload(files) {
    const formData = new FormData();
    if (files && files.length > 0) {
      files.forEach((file) => {
        formData.append('files', file);
      });
    }

    const multipartOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
      }),
    };

    return this.http.post<any>(this.url + 'api/caso/tempUpload', formData);
  }

  postCasoAgenda(
    categoriaId,
    descripcion,
    files,
    localidadId,
    titulo,
    profesionalAsignadoId,
    tipo,
    citaId,
    timeStamp,
    timeZone
  ) {
    let data = {
      categoriaId: categoriaId,
      descripcion: descripcion,
      localidadId: localidadId,
      titulo: titulo,
      profesionalAsignadoId: profesionalAsignadoId,
      tipo: tipo,
      citaId: citaId,
      fechaCreacion: timeStamp,
      zonaHoraria: timeZone,
      files: files,
    };

    return this.http.post<any>(this.url + 'api/caso', data);
  }

  putCasoAgenda(
    casoId,
    categoriaId,
    descripcion,
    files,
    localidadId,
    titulo,
    profesionalAsignadoId,
    tipo,
    citaId,
    timeStamp,
    timeZone
  ) {
    let data = {
      id_caso: casoId,
      categoriaId: categoriaId,
      descripcion: descripcion,
      localidadId: localidadId,
      titulo: titulo,
      profesionalAsignadoId: profesionalAsignadoId,
      tipo: tipo,
      citaId: citaId,
      fechaCreacion: timeStamp,
      zonaHoraria: timeZone,
      files: files,
    };

    return this.http.put<any>(this.url + 'api/caso', data);
  }

  postCasoMail(
    categoriaId,
    descripcion,
    files,
    localidadId,
    titulo,
    tipo,
    timeStamp,
    timeZone
  ) {
    let data = {
      categoriaId: categoriaId,
      descripcion: descripcion,
      localidadId: localidadId,
      tipo: tipo,
      titulo: titulo,
      fechaCreacion: timeStamp,
      zonaHoraria: timeZone,
      files: files,
    };

    return this.http.post<any>(this.url + 'api/caso', data);
  }

  putCasoMail(
    casoId,
    categoriaId,
    descripcion,
    files,
    localidadId,
    titulo,
    tipo,
    timeStamp,
    timeZone
  ) {
    let data = {
      id_caso: casoId,
      categoriaId: categoriaId,
      descripcion: descripcion,
      localidadId: localidadId,
      tipo: tipo,
      titulo: titulo,
      fechaCreacion: timeStamp,
      zonaHoraria: timeZone,
      files: files,
    };

    return this.http.put<any>(this.url + 'api/caso', data);
  }

  getPerfil(): Observable<Perfil> {
    return this.http.get<Perfil>(
      this.url + 'api/usuario/miPerfil',
      this.httpOptions
    );
  }
  getPerfilUsuario(id): Observable<Perfil> {
    return this.http.get<Perfil>(
      this.url + `api/usuario/perfilUsuario?idUsuario=${id}`,
      this.httpOptions
    );
  }

  getCasos(
    pagina: number,
    estado: string,
    maximoPorPagina: number
  ): Observable<CasoDetallado[]> {
    return this.http.get<CasoDetallado[]>(
      this.url +
        `api/caso/${estado}/MisCasos?pagina=${pagina}&maximoPorPagina=${maximoPorPagina}`,
      this.httpOptions
    );
  }

  getAbogadosDisponibles(idCaso: number): Observable<any> {
    return this.http.get<any>(
      this.url + `api/caso/getAbogadosCaso?idCaso=${idCaso}`,
      this.httpOptions
    );
  }

  getOportunidades(
    pagina: number,
    maximoPorPagina: number
  ): Observable<CasoDetallado[]> {
    return this.http.get<CasoDetallado[]>(
      this.url +
        `api/caso/misOportunidades?pagina=${pagina}&maximoPorPagina=${maximoPorPagina}`,
      this.httpOptions
    );
  }

  editarPerfil(usuarioInput: any): Observable<PerfilEditar> {
    return this.http.patch<PerfilEditar>(
      this.url + 'api/usuario/edit',
      usuarioInput,
      this.httpOptions
    );
  }

  cambiarLenguaje(usuarioInput: any): Observable<any> {
    const formData = new FormData();
    formData.append('mail', usuarioInput.mail.toString());
    formData.append('lenguaje', usuarioInput.lenguage.toString());
    return this.http.put<any>(this.url + 'api/usuario/lenguaje', formData);
  }

  getAbogadoCaso(idCaso: number): Observable<any> {
    return this.http.get<any>(
      this.url + `api/caso/getAbogadoAsignado?idCaso=${idCaso}`,
      this.httpOptions
    );
  }

  aceptarAbogado(idAbogado: number, idCaso: number): Observable<any> {
    return this.http.put<any>(
      this.url +
        `api/caso/aceptarAbogado?idAbogado=${idAbogado}&idCaso=${idCaso}`,
      this.httpOptions
    );
  }

  aceptarCaso(id_caso: number): Observable<any> {
    return this.http.put<any>(
      this.url + `api/caso/aceptarOportunidad?idCaso=${id_caso}`,
      this.httpOptions
    );
  }

  borrarCaso(id_caso: number): Observable<any> {
    return this.http.delete<any>(
      this.url + `api/caso/deletecaso/${id_caso}`,
      this.httpOptions
    );
  }

  postFiles(formData): Observable<any> {
    return this.http.post<any>(this.url + '', formData, this.httpOptions);
  }

  getFiles(id_caso: number): Observable<ListaArchivos[]> {
    return this.http.get<ListaArchivos[]>(
      this.url + `api/caso/${id_caso}/documentos`
    );
  }

  patchAvatar(files): Observable<any> {
    const formData = new FormData();
    if (files && files.length > 0) {
      files.forEach((file) => {
        formData.append('file', file);
      });
    }
    const multipartOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
      }),
    };
    return this.http.patch<any>(this.url + 'api/usuario/avatar', formData);
  }

  getAvatar(fileId: number): Observable<any> {
    return this.http.get<any>(this.url + `'api/usuario/avatar'/${fileId}`);
  }

  downloadDoc(fileId: number, casoId: number): Observable<any> {
    return this.http.get<any>(
      this.url + `api/caso/documento?casoId=${casoId}&fileId=${fileId}`,
      { responseType: 'blob' as 'json' }
    );
  }

  //llamada express

  getHorariosLlamada(): Observable<RangoConId[]> {
    return this.http.get<RangoConId[]>(
      this.url + 'api/horariollamada',
      this.httpOptions
    );
  }

  postHorario(horario: Rango): Observable<ReturnUser> {
    return this.http.post<ReturnUser>(
      this.url + 'api/horariollamada',
      horario,
      this.httpOptions
    );
  }

  modificarHorario(horario: Rango, rangoHorarioId: number): Observable<any> {
    return this.http.patch<any>(
      this.url + `api/horariollamada/${rangoHorarioId}`,
      horario,
      this.httpOptions
    );
  }

  createPuntuacion(puntuacion: NuevaPuntuacion): Observable<void> {
    return this.http.post<void>(
      this.url + `api/puntuacion`,
      puntuacion,
      this.httpOptions
    );
  }
  getPuntuacion(usuarioId?: number): Observable<Array<Puntuacion>> {
    return this.http.get<Array<Puntuacion>>(
      this.url +
        `api/puntuacion/getPuntuacion${
          usuarioId ? `?idAbogado=${usuarioId}` : ''
        }`,
      this.httpOptions
    );
  }

  getAbogadosDisponiblesLlamada(
    especialidadId: number,
    horaActual: number,
    localidadId: number
  ) {
    return this.http.get<any>(
      this.url +
        `api/usuario/abogado/llamadaexpress?especialidadId=${especialidadId}&horaActual=${horaActual}&localidadId=${localidadId}`,
      this.httpOptions
    );
  }

  deleteHorario(rangoHorarioId: number) {
    return this.http.delete<any>(
      this.url + `api/horariollamada/${rangoHorarioId}`,
      this.httpOptions
    );
  }

  //Mercado pago
  getCuenta(): Observable<CuentaMercado> {
    return this.http.get<CuentaMercado>(
      this.url + 'api/cuentas',
      this.httpOptions
    );
  }

  doCuentaNotificacionSuscribir(data): Observable<void> {
    let url: string = `${this.url}api/cuentas/notificacion/suscribir`;

    return this.http.post<void>(url, data);
  }

  postPago(linkPagoInput: ContratarPlanYAbogado): Observable<Link> {
    linkPagoInput.mpCallbackFailure
      ? linkPagoInput.mpCallbackFailure
      : 'pago/fallo';
    linkPagoInput.mpCallbackSuccess
      ? linkPagoInput.mpCallbackSuccess
      : 'pago/exito';
    linkPagoInput.mpCallbackPending
      ? linkPagoInput.mpCallbackPending
      : 'pago/pendiente';
    return this.http.post<Link>(
      this.url + 'api/cuentas/linkDePago',
      linkPagoInput,
      this.httpOptions
    );
  }

  postPagoContratarPlan(linkPagoInput: ContratarPlan): Observable<Link> {
    linkPagoInput.mpCallbackFailure
      ? linkPagoInput.mpCallbackFailure
      : 'pago/fallo';
    linkPagoInput.mpCallbackSuccess
      ? linkPagoInput.mpCallbackSuccess
      : 'pago/exito';
    linkPagoInput.mpCallbackPending
      ? linkPagoInput.mpCallbackPending
      : 'pago/pendiente';
    return this.http.post<Link>(
      this.url + 'api/cuentas/linkDePago',
      linkPagoInput,
      this.httpOptions
    );
  }

  postPagoContratarAbogado(
    linkPagoInput: ContratarAbogado
  ): Observable<string> {
    linkPagoInput.mpCallbackFailure
      ? linkPagoInput.mpCallbackFailure
      : 'pago/fallo';
    linkPagoInput.mpCallbackSuccess
      ? linkPagoInput.mpCallbackSuccess
      : 'pago/exito';
    linkPagoInput.mpCallbackPending
      ? linkPagoInput.mpCallbackPending
      : 'pago/pendiente';
    return this.http.post<string>(
      this.url + 'api/cuentas/linkDePago',
      linkPagoInput,
      this.httpOptions
    );
  }

  doSuscripcionNewCheck(plataformaNombre: string, suscripcionUuid: string) {
    let url: string = `${this.url}api/multipago/abogado/suscripcion/${plataformaNombre}/${suscripcionUuid}`;

    return this.http.post<void>(url, null, this.httpOptions);
  }

  getResultado(externalReference: string): Observable<string> {
    return this.http.get<string>(
      this.url + `api/cuentas/validarPagoStatus?idUsuario=${externalReference}`,
      this.httpOptions
    );
  }

  editarDatosProfesionales(especialidadId: number[]): Observable<Perfil> {
    return this.http.patch<Perfil>(
      this.url + 'api/usuario/edit/datosProfesionales',
      { especialidadesIds: especialidadId },
      this.httpOptions
    );
  }

  editarJurisdiccion(
    nuevaJurisdiccion: JurisdiccionEditarVer2
  ): Observable<any> {
    return this.http.patch<any>(
      this.url + 'api/usuario/edit/jurisdiccion',
      nuevaJurisdiccion,
      this.httpOptions
    );
  }

  editarJurisdiccionv2(
    nuevaJurisdiccion: JurisdiccionEditarVer3
  ): Observable<any> {
    return this.http.patch<any>(
      this.url + 'api/usuario/edit/jurisdiccion',
      nuevaJurisdiccion,
      this.httpOptions
    );
  }

  agregarJurisdiccion(nuevaJurisdiccion: DatosJurisdiccion): Observable<any> {
    return this.http.patch<any>(
      this.url + 'api/usuario/edit/jurisdiccion',
      nuevaJurisdiccion,
      this.httpOptions
    );
  }

  putLinkAsociacion(code: string): Observable<any> {
    return this.http.put<any>(
      this.url + `api/cuentas/asociarAbogadoMercadoPago?code=${code}`,
      this.httpOptions
    );
  }

  putLinkAsociacionPaypal(code: string): Observable<any> {
    return this.http.put<any>(
      this.url + `api/cuentas/asociarPaypalMerchantId?merchantId=${code}`,
      this.httpOptions
    );
  }

  //Agenda

  getAgendaDisponibles(
    fechaInicio: string,
    fechaFin: string,
    tagEspecialidad: string,
    tagLocalidad: string
  ): Observable<UsuariosHora[]> {
    return this.http.get<UsuariosHora[]>(
      this.url +
        `api/agenda/dia?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&tags=${tagLocalidad}&tags=${tagEspecialidad}`,
      this.httpOptions
    );
  }

  getAgendaDias(
    fechaInicio: string,
    fechaFin: string,
    idEspecialidad: number,
    tagLocalidad: string
  ): Observable<string[]> {
    return this.http.get<string[]>(
      this.url +
        `api/agenda?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&tags=${tagLocalidad}`,
      this.httpOptions
    );
  }

  /*29/03
  getAgendaDias(
    fechaInicio: string,
    fechaFin: string,
    tagEspecialidad: string,
    tagLocalidad: string
  ): Observable<string[]> {
    return this.http.get<string[]>(
      this.url +
        `api/agenda?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&tags=${tagLocalidad}&tags=${tagEspecialidad}`,
      this.httpOptions
    );
  }

  */

  agendarCita(datos: CrearCitaDatos): Observable<any> {
    return this.http.post<any>(
      this.url + 'api/agenda',
      datos,
      this.httpOptions
    );
  }

  guardarRangoCitas(datos: RangoAgenda): Observable<any> {
    return this.http.post<any>(
      this.url + 'api/agenda/rango',
      datos,
      this.httpOptions
    );
  }

  removeRange(rangeId: number): Observable<any> {
    return this.http.delete<any>(
      this.url + `api/agenda/rangos/${rangeId}`,
      this.httpOptions
    );
  }

  getRanges(usuarioId: number): Observable<any> {
    return this.http.get<any>(
      this.url + `api/agenda/rangos?usuarioId=${usuarioId}&applicationId=1`,
      this.httpOptions
    );
  }

  getAbogadosDisponiblesAgenda(listaIds: string) {
    return this.http.get<any>(
      this.url + `api/usuario/bulk?${listaIds}`,
      this.httpOptions
    );
  }

  getTags(usuarioId: number): Observable<any> {
    return this.http.get<any>(
      this.url + `api/agenda/usuario/${usuarioId}/tags`,
      this.httpOptions
    );
  }

  patchRangos(usuarioId: number, tags: string[]): Observable<any> {
    return this.http.patch<any>(
      this.url + `api/agenda/rangos/?usuarioId=${usuarioId}&${tags}`,
      this.httpOptions
    );
  }

  getEspecialidadesCliente(): Observable<EspecialidadesCliente[]> {
    return this.http.get<EspecialidadesCliente[]>(
      this.url + 'api/admin/especialidades/clientes',
      this.httpOptions
    );
  }

  getEspecialidadesAbogados(): Observable<EspecialidadesAbogado[]> {
    return this.http.get<EspecialidadesCliente[]>(
      this.url + 'api/admin/especialidades/abogados',
      this.httpOptions
    );
  }

  getCategoriasAll(): Observable<CategoriasAll[]> {
    return this.http.get<[]>(
      this.url + 'api/admin/especialidades/categorias',
      this.httpOptions
    );
  }

  getEspecialidadesLista(): Observable<EspecialidadesLista[]> {
    return this.http.get<EspecialidadesLista[]>(
      this.url + 'api/admin/especialidades/',
      this.httpOptions
    );
  }

  getEspecialidadesListaById(planillaId: number): Observable<EspecialidadesLista> {
    return this.http.get<EspecialidadesLista>(
      `${this.url}api/admin/especialidades/${planillaId}`,
      this.httpOptions
    );
  }

  delRegistroTabla(id: number): Observable<any> {
    const url = `${this.url}api/admin/especialidades/${id}`;
    return this.http.delete(url, this.httpOptions);
  }

  postEspecialidades(data: any): Observable<any> {
    const url = `${this.url}api/admin/especialidades`;
    return this.http.post(url, data);
  }

  addEspecialidad(especialidad: any): void {
    this.especialidades.push(especialidad);
  }

  getLinkAsociacion(): Observable<Link> {
    return this.http.get<Link>(
      this.url + 'api/cuentas/mercadoPagoAsociacionUrl',
      this.httpOptions
    );
  }

  getCasoById(id): Observable<CasoDetallado> {
    return this.http.get<CasoDetallado>(
      `${this.url}api/caso/${id}`,
      this.httpOptions
    );
  }

  getCountry(): Observable<any> {
    return this.http.get<any>('http://ip-api.com/json', this.httpOptions);
  }

  private getHttpOptions() {
    var tk = '';
    if (window.localStorage['token'] != undefined) {
      tk = window.localStorage['token'];
    }
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: tk,
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      }),
    };
    console.log('httpOptions :' + httpOptions);
    return httpOptions;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }
}
