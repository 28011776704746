import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from '../../../api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { abogado } from 'src/app/interfaces/abogado';

@Component({
  selector: 'app-pago-exitoso',
  templateUrl: './pago-exitoso.component.html',
  styleUrls: ['./pago-exitoso.component.css'],
})
export class PagoExitosoComponent implements OnInit {
  external_reference: string;
  exito = false;
  texto = 'Se está procesando tu pago';
  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }

  ngOnInit(): void {
    let user:abogado = JSON.parse(localStorage.getItem('user'));

    this.route.queryParams.subscribe((params) => {
      if (params.typePlatform == 'MercadoPago') {
        if (!params.preapproval_id) {
          this.location.back();
          return;
        }
        this.apiService.doSuscripcionNewCheck(params.typePlatform, params.preapproval_id).subscribe({
          next: () => {
            this.texto = `${params.typePlatform} - Tu pago se procesó exitosamente.`;
            this.exito = true;
            console.log("Suscripcion exitosa.");
          }
        });
      } else {
        this.external_reference = params.externalref;
  
        this.apiService.getResultado(user.id_usuario.toString()).subscribe(
          (respuesta) => {
            this.texto = 'Tu pago se procesó exitosamente.';
            this.exito = true;
          },
          (error) => {
            console.error(error);
          }
        );
      }
    });
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  navegar() {
    let soyCliente: boolean;
    this.apiService.getPerfil().subscribe(
      (perfil) => {
        if (perfil.datosProfesionales.length > 0) {
          soyCliente = false;
        } else {
          soyCliente = true;
        }
        if (soyCliente) {
          this.router.navigate(['/casos-activos/3']);
        } else {
          this.router.navigate(['/perfil-abogado']);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
