import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LogOutService } from 'src/app/services/logOut/log-out.service';

@Component({
  selector: 'app-navbar-cliente',
  templateUrl: './navbar-cliente.component.html',
  styleUrls: ['./navbar-cliente.component.css'],
})
export class NavbarClienteComponent implements OnInit {
  constructor(private router: Router, public translate: TranslateService, private logOut: LogOutService) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }

  btn1Style;
  btn2Style;

  ngOnInit(): void {
    var option = localStorage.getItem('option');
    if (option == null) {
      this.btn1Style = 'shortOpacity';
      this.btn2Style = 'completeOpacity';
    } else {
      this.activeOpacity(option);
    }
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  changeMenu(option: number, link: string, menu){
    menu.checked = false;
    localStorage.setItem('option', option.toString());
    this.router.navigateByUrl(link);
  }

  logout(){
    this.logOut.cerrarSesion();
  }

  activeOpacity(option: string) {
    this.btn1Style = option == '1' ? 'completeOpacity' : 'shortOpacity';
    this.btn2Style = option == '2' ? 'completeOpacity' : 'shortOpacity';
  }
}
