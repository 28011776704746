import { Component, OnInit } from '@angular/core';
import { Especialidad, Identificacion, JurisdiccionEditarVer, Perfil } from '../../../api/user';
import { Rango, RangoConId } from '../../../api/horarios';
import { ApiService } from '../../../api/api.service';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from './dialog.component';
import { DialogVincularComponent } from './dialogvincular.component';
import { ActivatedRoute, Router } from '@angular/router';
import { JurisdiccionesAEliminar, Planes, StatusIncrease } from '../../../api/planes';
import { Puntuacion } from '../../../interfaces/Puntuacion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlanService } from '../../../services/plan/plan.service';
import { PuntuacionesService } from '../../../services/puntuaciones.service';
import { LogOutService } from '../../../services/logOut/log-out.service';
import { TiempoSubscripcionService } from '../../../services/tiempoSubscripcion/tiempo-subscripcion.service';
import { Jurisdiccion, Localidad } from '../../../api/user';
import { PopupDowngradeComponent } from '../plan/popup-downgrade/popup-downgrade.component';
import { UpdateRangesService } from '../../../services/agenda/update-ranges.service';
import { ContadorJurisdiccionesService } from '../../../services/contadorJurisdicciones/contador-jurisdicciones.service';
import { CancelarComponent } from '../plan/cancelar/cancelar/cancelar.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CuentaMercado } from 'src/app/api/mercado';
import { PopupFacturacionComponent } from '../plan/popup-facturacion/popup-facturacion.component';
import { Certificacion } from 'src/app/api/certificacion';
import { Respuesta } from 'src/app/api/respuesta';
import { MPVinculacion, Suscripcion } from '../../admin/multipago/modules/multipago.model';
import { PlanMultipagoService } from 'src/app/services/plan/plan-multipago.service';
import { PaisConfig, PAISCONFIG_KEY_CUIT } from 'src/app/api/pais';

class RangeHour {
  value: string;
  viewValue: string;
  

  constructor(value: string, viewValue: string) {
    this.value = value;
    this.viewValue = viewValue;
  }
}

class ProductFormArray {
  productForm: UntypedFormGroup;
  dia: number;

  constructor(dia: number, productForm: UntypedFormGroup) {
    this.dia = dia;
    this.productForm = productForm;
  }
}

@Component({
  selector: 'app-perfil-abogado',
  templateUrl: './perfil-abogado.component.html',
  styleUrls: ['./perfil-abogado.component.css'],
})
export class PerfilAbogadoComponent implements OnInit {
  language:String="";
  planI: StatusIncrease["suscripcion"];
  diasAgenda=[];
  diasLlamada = [];
  suscripcionSel: Suscripcion;
  planSel: Planes;

  isChecked = true;
  hide = true;
  formGroup: UntypedFormGroup;
  cuenta:CuentaMercado;
  planApto = false;
  cuentaVinculada = false;
  planNombre: string;
  tags = ['', ''];
  
  identificaciones: Identificacion[];

  name = 'Angular';

  productForm: UntypedFormGroup;
  masHorarios: UntypedFormGroup;

  productFormArray: ProductFormArray[];

  duraciones: number[] = [30, 60, 90];
  duracionLlamada: number;
  duracionAgenda: number;

  initRangeHours: RangeHour[] = [];
  endRangeHours: RangeHour[] = [];

  lst: number[];

  initRangeHoursTemp: { [id: string]: RangeHour[] } = {};
  endtRangeHoursTemp: { [id: string]: RangeHour[] } = {};

  userId: number;
  textoSubscripcion: string;

  provinciasResidencias: Jurisdiccion[];
  localidadesResidencias: Localidad[];
  cacheVinculaciones: MPVinculacion[] = [];
  certified;
  cert_requested=false;
  otro:string='';

  paisConfigCuit: PaisConfig;
  cuitOldVal: string = "";

  constructor(
    private logOut: LogOutService,
    private planService: PlanService,
    private puntuacionesService: PuntuacionesService,
    private _snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private apiService: ApiService,
    formBuilder: UntypedFormBuilder,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public tiempoSubscripcionService: TiempoSubscripcionService,
    private updateRangesService: UpdateRangesService,
    private contadorJurisdiccionesService: ContadorJurisdiccionesService,
    private servicePlanMP: PlanMultipagoService,
    public translate: TranslateService
  ) {
    this.language=sessionStorage.getItem('language');
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    if(sessionStorage.getItem('language')==null){
      translate.setDefaultLang('es-AR');
      sessionStorage.setItem('language','es-AR');
    }
    else{
      translate.setDefaultLang(sessionStorage.getItem('language'));
    }
    this.matIconRegistry.addSvgIcon(
      `borrar`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/garbage.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `basico`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/basico.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `premium`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/premium.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `corporativo`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/corporativo.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Derecho Laboral`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/Laboral.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Derecho de Familia y Sucesiones`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/individuos.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Derecho Comercial`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/comercial.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Contratos`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/contratos.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Derecho del Consumidor`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/defensa.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Derecho Tributario`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/impuestos.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Inmobiliario y Propiedad Horizontal`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/inmobiliaria.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Otros`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/otros.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Derecho Penal`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/penal.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Sucesiones y herencias`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/sucesiones.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Derecho Previsional`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/previsional.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Derecho Digital Internet y Tecnología`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/digital.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Daños y Perjuicios`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/perjuicios.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Derecho de Sociedades y Corporativo`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/societario.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Accidentes de tránsito`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/accidentes.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Propiedad Industrial e Intelectual`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/propiedad.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Mala Praxis y Salud`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/mala.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Discriminación y violencia de género`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/discriminacion.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `ayuda`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/ayuda.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      `Derecho Administrativo y Regulación Económica`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/administrativo.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Derecho Migratorio`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/derecho-migratorio.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Derecho Civil`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/derecho-civil.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Derecho Ambiental`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/derecho ambiental.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Concursos y Quiebras`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/quiebras.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      `Derecho Aduanero y Comercio Exterior`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/aduanero.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      `perfil`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/perfil.svg'
      )
    );

    this.formGroup = formBuilder.group({
      activado: false,
      inicio: new UntypedFormControl('', [Validators.max(24), Validators.min(0)]),
      fin: new UntypedFormControl('', [Validators.max(24), Validators.min(0)]),
      duracionLlamada: Number,
      duracionAgenda: Number,
    });

    this.masHorarios = this.fb.group({
      name: '',
      horariosLista: this.fb.array([]),
    });

    this.productForm = this.fb.group({
      name: '',
      quantities: this.fb.array([]),
    });

    this.productFormArray = [];

    for (let i = 0; i < 7; i++) {
      var a = new ProductFormArray(
        i,
        this.fb.group({
          name: '',
          quantities: this.fb.array([]),
        })
      );

      this.productFormArray.push(a);
    }

    for (let i = 0; i < 24; i++) {
      var strHour: String;
      strHour = i < 10 ? '0' + i + ':' : i + ':';
      this.initRangeHours.push(new RangeHour(strHour + '00', strHour + '00'));
      this.initRangeHours.push(new RangeHour(strHour + '30', strHour + '30'));
    }

    

    
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params?.jurisdiccion == 1) {
        this.actualizarJurisdicciones = true;
      }
    });

    this.getSuscripcion();
    this.planService.verificoDatosFacturacion();
    localStorage.removeItem('caso');
    this.crearHorarios();
    this.datosJurisdiccion = [];
    this.tags = [];
    this.obtenerHorariosLlamada();
    this.getMiPerfil();
    this.getPuntuaciones();
    this.datosAux.jurisdiccionNombre = '';
    this.datosAux.localidadNombre = '';
    this.datosAux.pais = '';
    this.datosAux.tomo = '';
    this.datosAux.folio = '';
    this.getProvinciaResidencia();
    this.getCuenta();
    this.getIdentificaciones();
    this.initTranslateComponent();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.initTranslateComponent();
    });

    this.servicePlanMP.getAbogadoVinculaciones().subscribe({
      next: (vinculaciones: MPVinculacion[]) => {
        this.cacheVinculaciones = vinculaciones;
      }
    });

    this.doPaisConfigCuitLoad();
  }

  doPaisConfigCuitLoad(): void {
    let countryId = JSON.parse(localStorage.getItem('user')).pais;

    this.apiService.getPaisConfig(countryId, PAISCONFIG_KEY_CUIT).subscribe({
      next: (paisConfigCuit: PaisConfig) => {
        console.log(`CUIT configuracion: ${countryId} - ${PAISCONFIG_KEY_CUIT}`);
        console.log(paisConfigCuit);
        this.setPaisConfigCuit(paisConfigCuit);
      }
    });
  }

  setPaisConfigCuit(cuit: PaisConfig): void {
    let validators = [];
    let controlCuit: AbstractControl;

    if (cuit && cuit.bvisible) {
      if (cuit.bobligatorio)
        validators.push(Validators.required);
      if (cuit.svalor01)
        validators.push(Validators.pattern(cuit.svalor01));
    }

    controlCuit = this.cambiarDatos.get("cuit");
    controlCuit.setValidators(validators);
    controlCuit.updateValueAndValidity();
    this.paisConfigCuit = cuit;
    this.cuitOldVal = this.cambiarDatos.getRawValue().cuit;
  }

  certify(){

    let respuestas:Respuesta[] =[];

    let dto= new Certificacion(this.mail,"Certificacion","",respuestas);
       this.apiService.certification(dto).subscribe(preg => {
        this.openSnackBar("Revise su bandeja de correo para la firma del manifiesto");
          this.getMiPerfil();
        },
        error => {
            console.error(error);
            this.openSnackBar("Estamos trabajando para mejorar el servicio, por favor intente nuevamente más tarde");
        });


  }

  initTranslateComponent(){

    this.translate.get(['']).subscribe(translations => {
      this.diasAgenda = [
        {
          dia: 2,
          nombre:this.translate.instant('perfilAbogado.lunes'),
          activado: false,
          inicio: 0,
          fin: 0,
          id_rango_horario: 0,
          endHourButton: true,
          rangeHour: 0,
          addRange: false,
          addRanges: false,
        },
        {
          dia: 3,
          nombre: this.translate.instant('perfilAbogado.martes'),
          activado: false,
          inicio: 0,
          fin: 0,
          id_rango_horario: 0,
          endHourButton: true,
          rangeHour: 1,
          addRange: false,
          addRanges: false,
        },
        {
          dia: 4,
          nombre: this.translate.instant('perfilAbogado.miercoles'),
          activado: false,
          inicio: 0,
          fin: 0,
          id_rango_horario: 0,
          endHourButton: true,
          rangeHour: 2,
          addRange: false,
          addRanges: false,
        },
        {
          dia: 5,
          nombre: this.translate.instant('perfilAbogado.jueves'),
          activado: false,
          inicio: 0,
          fin: 0,
          id_rango_horario: 0,
          endHourButton: true,
          rangeHour: 3,
          addRange: false,
          addRanges: false,
        },
        {
          dia: 6,
          nombre: this.translate.instant('perfilAbogado.viernes'),
          activado: false,
          inicio: 0,
          fin: 0,
          id_rango_horario: 0,
          endHourButton: true,
          rangeHour: 4,
          addRange: false,
          addRanges: false,
        },
        {
          dia: 7,
          nombre: this.translate.instant('perfilAbogado.sabado'),
          activado: false,
          inicio: 0,
          fin: 0,
          id_rango_horario: 0,
          endHourButton: true,
          rangeHour: 5,
          addRange: false,
          addRanges: false,
        },
        {
          dia: 1,
          nombre: this.translate.instant('perfilAbogado.domingo'),
          activado: false,
          inicio: 0,
          fin: 0,
          id_rango_horario: 0,
          endHourButton: true,
          rangeHour: 6,
          addRange: false,
          addRanges: false,
        },
      ];

      this.diasLlamada = [
        {
          dia: 1,
          nombre: this.translate.instant('perfilAbogado.lunes'),
          activado: false,
          inicio: 0,
          fin: 0,
          cargado: false,
          id_rango_horario: 0,
          inicioString: '',
          finString: '',
          modificar: false,
          borrado: false,
        },
        {
          dia: 2,
          nombre: this.translate.instant('perfilAbogado.martes'),
          activado: false,
          inicio: 0,
          fin: 0,
          cargado: false,
          id_rango_horario: 0,
          inicioString: '',
          finString: '',
          modificar: false,
          borrado: false,
        },
        {
          dia: 3,
          nombre: this.translate.instant('perfilAbogado.miercoles'),
          activado: false,
          inicio: 0,
          fin: 0,
          cargado: false,
          id_rango_horario: 0,
          inicioString: '',
          finString: '',
          modificar: false,
          borrado: false,
        },
        {
          dia: 4,
          nombre: this.translate.instant('perfilAbogado.jueves'),
          activado: false,
          inicio: 0,
          fin: 0,
          cargado: false,
          id_rango_horario: 0,
          inicioString: '',
          finString: '',
          modificar: false,
          borrado: false,
        },
        {
          dia: 5,
          nombre: this.translate.instant('perfilAbogado.viernes'),
          activado: false,
          inicio: 0,
          fin: 0,
          cargado: false,
          id_rango_horario: 0,
          inicioString: '',
          finString: '',
          modificar: false,
          borrado: false,
        },
        {
          dia: 6,
          nombre: this.translate.instant('perfilAbogado.sabado'),
          activado: false,
          inicio: 0,
          fin: 0,
          cargado: false,
          id_rango_horario: 0,
          inicioString: '',
          finString: '',
          modificar: false,
          borrado: false,
        },
        {
          dia: 7,
          nombre: this.translate.instant('perfilAbogado.domingo'),
          activado: false,
          inicio: 0,
          fin: 0,
          cargado: false,
          id_rango_horario: 0,
          inicioString: '',
          finString: '',
          modificar: false,
          borrado: false,
        },
      ];
  });

    
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 5000 });
  }

  quantities(index): UntypedFormArray {
    return this.productFormArray
      .find((x) => x.dia == index)
      .productForm.get('quantities') as UntypedFormArray;
  }

  newQuantity(): UntypedFormGroup {
    return this.fb.group({
      init: new UntypedFormControl('', [Validators.max(24), Validators.min(0)]),
      end: new UntypedFormControl('', [Validators.max(24), Validators.min(0)]),
      id: new String(),
    });
  }

  getIdentificaciones() {
    this.apiService.getIdentificacion().subscribe(
      (identificaciones) => {
        this.identificaciones = identificaciones;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  horariosLista(): UntypedFormArray {
    return this.masHorarios.get('horariosLista') as UntypedFormArray;
  }

  nuevosHorarios(): UntypedFormGroup {
    return this.fb.group({
      inicio: new UntypedFormControl('', [Validators.max(24), Validators.min(0)]),
      fin: new UntypedFormControl('', [Validators.max(24), Validators.min(0)]),
    });
  }

  agregarInput() {
    this.horariosLista().push(this.nuevosHorarios());
  }

  addQuantity(index) {
    this.diasAgenda.find((elemento) => elemento.rangeHour === index).addRange =
      true;

    this.enableRanges(index, true, false);

    var initRangeHours = this.calculateNewRange(index);

    var indexInitRange = 0;

    if (Object.keys(this.initRangeHoursTemp).length > 0) {
      indexInitRange = Object.entries(this.initRangeHoursTemp).filter(
        (x) => x[0].substring(0, 1) == index
      ).length;
    }

    this.initRangeHoursTemp[index.toString() + indexInitRange] = initRangeHours;

    this.enableRanges(index, false, true);

    this.quantities(index).push(this.newQuantity());
  }

  switchLang(lang: string) {
    sessionStorage.setItem('language',lang);
    this.language = lang;
    this.translate.use(lang);
    console.log("Switch lang");
    this.initTranslateComponent();
  }

  loadNewInitRange(index) {
    this.diasAgenda.find((elemento) => elemento.rangeHour === index).addRange =
      true;

    this.enableRanges(index, true, false);

    var initRangeHours = this.calculateNewRange(index);

    var indexInitRange = 0;

    if (Object.keys(this.initRangeHoursTemp).length > 0) {
      indexInitRange =
        Object.entries(this.initRangeHoursTemp).filter(
          (x) => x[0].substring(0, 1) == index
        ).length - 1;
    }

    this.initRangeHoursTemp[index.toString() + indexInitRange] = initRangeHours;

    this.enableRanges(index, false, true);

    this.quantities(index).push(this.newQuantity());
  }

  removeQuantity(i, index) {
    var controlsScheduler = this.diasAgenda.find(
      (elemento) => elemento.rangeHour === index
    );
    controlsScheduler.addRange = false;

    var aux: boolean = false;

    var lastRemoveWithoutData: boolean = false;

    var quantities = this.productFormArray[index].productForm.controls
      .quantities as any;
    var control = quantities.controls[i].controls;
    if (control.id.value != '') {
      var idRange = control.id.value;

      this.apiService.removeRange(idRange).subscribe(
        (respuesta) => {},
        (error) => {
          this.openSnackBar('No fue posible guardar este rango');
        }
      );
    }

    this.quantities(index).removeAt(i);

    for (let it = 0; it < Object.keys(this.initRangeHoursTemp).length; it++) {
      if (it == i) {
        if (it + 1 == Object.keys(this.initRangeHoursTemp).length) {
          delete this.initRangeHoursTemp[index.toString() + it];
          delete this.endtRangeHoursTemp[index.toString() + it];
          lastRemoveWithoutData = true;
          break;
        } else {
          aux = true;
          this.initRangeHoursTemp[index.toString() + it] =
            this.initRangeHoursTemp[index.toString() + (it + 1)];
          this.endtRangeHoursTemp[index.toString() + it] =
            this.endtRangeHoursTemp[index.toString() + (it + 1)];
        }
      } else {
        if (
          it + 1 == Object.keys(this.initRangeHoursTemp).length ||
          this.initRangeHoursTemp[index.toString() + (it + 1)] == null
        ) {
          if (this.initRangeHoursTemp[index.toString() + it] == null) {
            delete this.initRangeHoursTemp[index.toString() + (it - 1)];
            delete this.endtRangeHoursTemp[index.toString() + (it - 1)];
          } else {
            delete this.initRangeHoursTemp[index.toString() + it];
            delete this.endtRangeHoursTemp[index.toString() + it];
          }
          break;
        }
        if (
          aux &&
          this.initRangeHoursTemp[index.toString() + (it + 1)] != null
        ) {
          this.initRangeHoursTemp[index.toString() + it] =
            this.initRangeHoursTemp[index.toString() + (it + 1)];
          this.endtRangeHoursTemp[index.toString() + it] =
            this.endtRangeHoursTemp[index.toString() + (it + 1)];
        }
      }
    }

    this.activeAddRanges(index);

    if (!lastRemoveWithoutData) {
      var indexLast = parseInt(
        Object.keys(this.initRangeHoursTemp)
          .map((x) => x)
          .reverse()[0]
      );

      this.enableRanges(index, true, false);

      var time = this.initRangeHoursTemp[
        index.toString() + indexLast
      ][0] as any;

      this.enableRanges(index, false, true);
    }
  }

  calculateNewRange(index) {
    var range = this.productFormArray[index].productForm.value.quantities;

    this.initRangeHours = [];
    for (let i = 0; i < 24; i++) {
      var strHour: String;
      strHour = i < 10 ? '0' + i + ':' : i + ':';

      if (range != null && range.length > 0) {
        var existIntervalZero = range.filter(
          (x) =>
            i * 60 >=
              parseInt(x.init.split(':')[0]) * 60 +
                parseInt(x.init.split(':')[1]) &&
            i * 60 <
              parseInt(x.end.split(':')[0]) * 60 + parseInt(x.end.split(':')[1])
        );

        var existIntervalMid = range.filter(
          (x) =>
            i * 60 + 30 >=
              parseInt(x.init.split(':')[0]) * 60 +
                parseInt(x.init.split(':')[1]) &&
            i * 60 + 30 <
              parseInt(x.end.split(':')[0]) * 60 + parseInt(x.end.split(':')[1])
        );

        if (existIntervalZero.length == 0)
          this.initRangeHours.push(
            new RangeHour(strHour + '00', strHour + '00')
          );
        if (existIntervalMid.length == 0)
          this.initRangeHours.push(
            new RangeHour(strHour + '30', strHour + '30')
          );
      } else {
        this.initRangeHours.push(new RangeHour(strHour + '00', strHour + '00'));
        this.initRangeHours.push(new RangeHour(strHour + '30', strHour + '30'));
      }
    }

    return this.initRangeHours;
  }

  onFormSubmitMasHorarios(index) {
    var controlsScheduler = this.diasAgenda.find(
      (elemento) => elemento.rangeHour === index
    );

    let diaSeleccionado = this.diasAgenda.find(
      (elemento) => elemento.rangeHour === index
    );
    var indexLast = parseInt(
      Object.keys(this.initRangeHoursTemp)
        .map((x) => x)
        .reverse()[0]
    );

    var quantities = this.productFormArray[index].productForm.controls
      .quantities as any;
    var time = quantities.controls[quantities.controls.length - 1].controls;
    diaSeleccionado.inicio =
      parseInt(time.init.value.split(':')[0]) * 60 +
      parseInt(time.init.value.split(':')[1]);
    diaSeleccionado.fin =
      parseInt(time.end.value.split(':')[0]) * 60 +
      parseInt(time.end.value.split(':')[1]);
    if (isNaN(diaSeleccionado.inicio) || isNaN(diaSeleccionado.fin)) {
      let text = '';
      if (isNaN(diaSeleccionado.inicio)) {
        text = text + ' hora inicial';
        if (isNaN(diaSeleccionado.fin)) {
          text = text + ' y';
        }
      }
      if (isNaN(diaSeleccionado.fin)) {
        text = text + ' hora final';
      }
      this.openSnackBar(`Por favor, seleccioná ${text}`);
    } else {
      controlsScheduler.addRange = false;
      this.cargarHorariosAgenda(index, diaSeleccionado);
      this.activeAddRanges(index);
    }
    diaSeleccionado.endHourButton = false;
  }

  activeAddRanges(index) {
    var controlsScheduler = this.diasAgenda.find(
      (elemento) => elemento.rangeHour === index
    );
    this.enableRanges(index, true, false);

    var ranges = this.calculateNewRange(index);

    if (ranges != null && ranges.length == 0) {
      controlsScheduler.addRanges = true;
    } else {
      controlsScheduler.addRanges = false;
    }

    this.enableRanges(index, false, true);
  }

  abogado = {
    nombre: '',
    apellido: '',
  };

  horariosLlamadaConId: RangoConId[];
  horarioLlamada: Rango[];
  nuevoHorario = {
    desde: 0,
    dia: 0,
    hasta: 0,
  };

  cambiarDatos = new UntypedFormGroup({
    nombre: new UntypedFormControl(),
    apellido: new UntypedFormControl(),
    mail: new UntypedFormControl(),
    dni: new UntypedFormControl(),
    tipoIdentificacion: new UntypedFormControl(),
    // password: new FormControl('', [
    //   Validators.required,
    //   Validators.pattern(/^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/),
    // ]),
    // repeatPassword: new FormControl('', [
    //   Validators.required
    // ]),
    tomo: new UntypedFormControl(),
    folio: new UntypedFormControl(),
    colegio: new UntypedFormControl(),
    pais: new UntypedFormControl(),
    jurisdiccion: new UntypedFormControl(),
    localidad: new UntypedFormControl(),
    especialidad: new UntypedFormControl(),
    descripcion: new UntypedFormControl(),
    plan: new UntypedFormControl(),
    telefono: new UntypedFormControl(),
    provinciaResidencia: new UntypedFormControl(),
    localidadResidencia: new UntypedFormControl(),
    direccion: new UntypedFormControl('', [Validators.pattern(/^[a-z\d\_\s]+$/i)]),
    codigoPostal: new UntypedFormControl('', [Validators.max(99999999)]),
    cuit: new UntypedFormControl('', [Validators.required]),
    promocion: new FormControl('', [])
  });

  archivosForm = new UntypedFormGroup({
    archivosSeleccionados: new UntypedFormControl(),
  });

  perfil: Perfil;
  nuevosDatos: any;
  datosGuardados: boolean = false;
  actualizarJurisdicciones: boolean = false;
  terminoCarga: boolean = false;
  modificarEspecialidades: boolean = false;
  agregoEspecialidades: boolean = false;

  direccion: string;
  codigoPostal: number;
  cuit: string;
  promocion: string;
  nombre: string;
  apellido: string;
  bio: string;
  mail: string;
  localidad: string[] = [];
  jurisdiccion: string[] = [];
  colegio: string[] = [];
  especialidadesAbogados: [
    {
      id_especialidad: '';
      nombre: '';
    }
  ];
  especialidadesNombre: string[] = [];
  especialidadesIds: number[] = [];
  folio: string[] = [];
  numeroDni: string;
  tomo: string[] = [];
  pais: string;
  telefono: string;
  descripcion: string;
  avatarUrl: string;
  datosJurisdiccion: JurisdiccionEditarVer[] = [];

  datosAux = {
    jurisdiccionNombre: '',
    localidadNombre: '',
    pais: '',
    tomo: '',
    folio: '',
  };

  archivos= [];

  plan: Planes;

  editarBio = false;
  editarDatos = false;
  tieneFoto = false;
  opiniones: Array<Puntuacion> = [];

  activar(dia) {
    if (this.hasLinkedPayment() && this.planApto /*&& this.plan.agenda*/) {
      let diaSeleccionado = this.diasAgenda.find(
        (elemento) => elemento.nombre === dia.nombre
      );
      diaSeleccionado.activado = !diaSeleccionado.activado;
      dia.activado == true;
    } else {
      if (!this.planApto || !this.suscripcionSel.planAgenda) {
        this.openSnackBar(
          'Por favor cambia tu plan para poder recibir citas agendadas'
        );
        this.router.navigate(['/plan']);
      }
      if (!this.hasLinkedPayment() && this.servicePlanMP.isSuscriptionActive(this.suscripcionSel)) {
        console.log('ENTRO ACA');
        this.router.navigate(["/abogado/vinculaciones"]);
      }
    }
  }

  activartemp(dia) {
    console.log('ENTRO ACA');
      let diaSeleccionado = this.diasAgenda.find(
        (elemento) => elemento.nombre === dia.nombre
      );
      diaSeleccionado.activado = !diaSeleccionado.activado;
      dia.activado == true;
   
  }

  onFormSubmitHorarioAgenda() {
    this.duracionAgenda = this.formGroup.value.duracionAgenda;
  }

  activarLlamada(dia) {
    if (this.hasLinkedPayment() && this.planApto /*&& this.plan.llamada*/) {
      let diaSeleccionado = this.diasLlamada.find(
        (elemento) => elemento.nombre === dia.nombre
      );
      diaSeleccionado.activado = !diaSeleccionado.activado;
      dia.activado == true;
    } else {
      if (!this.planApto || !this.suscripcionSel.planLlamada) {
        this.openSnackBar(
          'Por favor cambia tu plan para poder recibir llamadas express'
        );
        this.router.navigate(['/plan']);
      }
      if (!this.hasLinkedPayment() && this.servicePlanMP.isSuscriptionActive(this.suscripcionSel)) {
        this.router.navigate(["/abogado/vinculaciones"]);
      }
    }
  }

  onFormSubmitLlamada(dia) {
    let diaSeleccionado = this.diasLlamada.find(
      (elemento) => elemento.nombre === dia.nombre
    );

    diaSeleccionado.inicio = this.formGroup.value.inicio;
    diaSeleccionado.fin = this.formGroup.value.fin;

    if(diaSeleccionado.inicio === '' || diaSeleccionado.fin === ''){
      this.openSnackBar('Debe seleccionar un horario.');
    }else{
      this.cargarHorarios(dia);
      //(diaSeleccionado.inicio = 0),
        //(diaSeleccionado.fin = 0),
        this.diasLlamada.map((dia) => {
          dia.modificar = true;
        });
      this.obtenerHorariosLlamada();
    }
    
    
  }

  borrarHorarioLlamada(dia) {
    let posicion = dia.dia - 1;
    this.apiService.deleteHorario(dia.id_rango_horario).subscribe(
      (response) => {
        this.diasLlamada[posicion].inicioString = '';
        this.diasLlamada[posicion].finString = '';
        this.diasLlamada[posicion].inicio = 0;
        this.diasLlamada[posicion].fin = 0;
        this.diasLlamada[posicion].borrado = false;
        this.diasLlamada[posicion].cargado = false;
        this.openSnackBar('Se borró el horario exitosamente.');
        this.obtenerHorariosLlamada();
      },
      (error) => {
        this.openSnackBar(
          'No se pudo borrar el horario, por favor intente nuevamente más tarde.'
        );
      }
    );
  }

  cargarHorariosAgenda(index, dia) {
    this.nuevoHorario.dia = dia.dia;
    this.nuevoHorario.desde = dia.inicio;
    this.nuevoHorario.hasta = dia.fin;
    let datos = {
      dia: this.nuevoHorario.dia,
      estado: 1,
      horaFin: this.nuevoHorario.hasta,
      horaInicio: this.nuevoHorario.desde,
      tags: this.tags,
      usuarioId: this.perfil.id_usuario,
    };

    var quantities = this.productFormArray[index].productForm.controls
      .quantities as any;
    var control = quantities.controls[quantities.controls.length - 1].controls;

    console.log("GUARDANDO rango")
    this.apiService.guardarRangoCitas(datos).subscribe(
      (response) => {
        
        //0904control.id.value = response.id;

        control.id.value = response.id
        this.openSnackBar('Se guardó el horario exitosamente');
      },
      (error) => {
        console.log("Error "+JSON.stringify(error));
        this.openSnackBar('No fue posible guardar este rango');
      }
    );
  }

  cargarHorarios(dia) {
    this.nuevoHorario.desde =
      parseInt(dia.inicio.split(':')[0]) * 60 +
      parseInt(dia.inicio.split(':')[1]);
    this.nuevoHorario.hasta =
      parseInt(dia.fin.split(':')[0]) * 60 + parseInt(dia.fin.split(':')[1]);
    this.nuevoHorario.dia = parseInt(dia.dia, 10);
    if (
      this.nuevoHorario.desde > this.nuevoHorario.hasta ||
      this.nuevoHorario.desde == this.nuevoHorario.hasta
    ) {
      this.openSnackBar(
        'La hora final no puede ser antes que la hora inicial ni pueden ser iguales'
      );
    } else {
      if (dia.cargado) {
        this.apiService
          .modificarHorario(this.nuevoHorario, dia.id_rango_horario)
          .subscribe(
            (respuesta) => {
              this.openSnackBar('Se guardó el horario exitosamente');
              this.obtenerHorariosLlamada();
            },
            (error) => {
              this.openSnackBar(
                'No fue posible guardar el horario, por favor intente nuevamente más tarde'
              );
            }
          );
      } else {
        this.apiService.postHorario(this.nuevoHorario).subscribe(
          (respuesta) => {
            this.openSnackBar('Se guardó el horario exitosamente');
            this.obtenerHorariosLlamada();
          },
          (error) => {}
        );
      }
    }
  }

  obtenerHorariosLlamada() {
    this.apiService.getHorariosLlamada().subscribe(
      (horarios) => {
        this.horariosLlamadaConId = horarios;
        this.convertirAHorasYMinutos();
        this.cargarExistentes();
      },
      (error) => {}
    );
  }

  convertirAHorasYMinutos() {
    this.horariosLlamadaConId.map((horario) => {
      horario.desde = horario.desde / 60;
      horario.hasta = horario.hasta / 60;
    });
  }

  convertirString(horario) {
    let hora = Math.trunc(horario).toString();
    if (hora.length == 1) {
      hora = '0' + hora;
    }
    let minutosNumero = horario % 1;
    let minutos;
    switch (minutosNumero) {
      case 0.5:
        minutos = '30';
        break;
      case 0:
        minutos = '00';
        break;
    }
    return hora + ':' + minutos;
  }

  modificar(dia, modo) {
    if (modo === true) {
      dia.modificar = true;
    } else {
      dia.modificar = false;
    }
  }

  cargarExistentes() {
    this.horariosLlamadaConId.map((element) => {
      this.diasLlamada.find((encontrado) => {
        if (encontrado.dia === element.dia) {
          encontrado.inicio = element.desde;
          encontrado.inicioString = this.convertirString(element.desde);
          encontrado.fin = element.hasta;
          encontrado.finString = this.convertirString(element.hasta);
          encontrado.cargado = true;
          encontrado.id_rango_horario = element.id_rango_horario;
          encontrado.borrado = true;
        }
      });
    });
  }

  initCallHours: any;
  endCallHours: any;
  crearHorarios() {
    this.initCallHours = [];
    this.endCallHours = [];
    for (let i = 0; i < 24; i++) {
      var strHour: String;
      strHour = i < 10 ? '0' + i + ':' : i + ':';
      this.initCallHours.push(new RangeHour(strHour + '00', strHour + '00'));
      this.initCallHours.push(new RangeHour(strHour + '30', strHour + '30'));
      if (i != 0) {
        this.endCallHours.push(new RangeHour(strHour + '00', strHour + '00'));
      }
      this.endCallHours.push(new RangeHour(strHour + '30', strHour + '30'));
    }
    this.endCallHours.push(new RangeHour('24:00', '24:00'));
  }


  selectFiles(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      //this.archivos.push(event.target.files[i]);
    }
    this.archivos=[];
    console.log("1402 FILE "+event.target.files[0].name);
    this.archivos.push(event.target.files[0]);
   // this.archivosForm.value.archivosSeleccionados = this.archivos;

    this.apiService.patchAvatar(this.archivos).subscribe(
      (respuesta) => {
        event.target.value = '';
        this.getMiPerfil();
        this.terminoCarga = true;
      },
      (error) => {
        this.openSnackBar('No fue posible actualizar la foto de perfil');
      }
    );
  }

  openDialog() {
    this.dialog.open(DialogComponent, {
      backdropClass: 'backdropBackground',
    });
  }

  openDialogVincular() {
    this.dialog.open(DialogVincularComponent, {
      backdropClass: 'backdropBackground',
    });
  }

  editar(campo) {
    const BIO = 1;
    const EDITAR_BIO = 11;
    const JURISDICCION = 2;
    const DATOS = 4;
    const EDITAR_DATOS = 44;

    this.nuevosDatos = this.perfil;

    switch (campo) {
      case BIO: {
        this.nuevosDatos.nombre = this.cambiarDatos.value.nombre
          ? this.cambiarDatos.value.nombre
          : this.nombre;
        this.nuevosDatos.apellido = this.cambiarDatos.value.apellido
          ? this.cambiarDatos.value.apellido
          : this.apellido;
        this.nuevosDatos.mail = this.cambiarDatos.value.mail
          ? this.cambiarDatos.value.mail
          : this.mail;
        this.nuevosDatos.telefono = this.cambiarDatos.value.telefono
          ? this.cambiarDatos.value.telefono
          : this.telefono;
        this.nuevosDatos.datosProfesionales[0].numeroDni = this.cambiarDatos
          .value.dni
          ? this.cambiarDatos.value.dni
          : this.numeroDni;

        this.nuevosDatos.direccion = this.cambiarDatos.value.direccion
          ? this.cambiarDatos.value.direccion
          : this.direccion;
        this.nuevosDatos.codigoPostal = this.cambiarDatos.value.codigoPostal
          ? this.cambiarDatos.value.codigoPostal
          : this.codigoPostal;
        if (this.cambiarDatos.value.localidadResidencia) {
          this.nuevosDatos.localidadId =
            this.cambiarDatos.value.localidadResidencia.id_localidad;
        }

        let TiposIdentificacion = {id_tipos_identificacion:this.cambiarDatos.value.tipoIdentificacion};

        this.nuevosDatos.datosProfesionales[0].especialidadesAbogados = null;
        this.nuevosDatos.datosProfesionales[0].TiposIdentificacion = TiposIdentificacion;
        this.nuevosDatos.descripcion = this.cambiarDatos.value.descripcion;
        this.descripcion = this.cambiarDatos.value.descripcion;
        this.editarBio = false;
        this.editarPerfil();
        break;
      }
      case JURISDICCION: {
      }
      case DATOS: {
        if (this.cambiarDatos.status !== "VALID")
          return;

        this.nuevosDatos.nombre = this.cambiarDatos.value.nombre
          ? this.cambiarDatos.value.nombre
          : this.nombre;
        this.nuevosDatos.apellido = this.cambiarDatos.value.apellido
          ? this.cambiarDatos.value.apellido
          : this.apellido;
        this.nuevosDatos.mail = this.cambiarDatos.value.mail
          ? this.cambiarDatos.value.mail
          : this.mail;
        this.nuevosDatos.telefono = this.cambiarDatos.value.telefono
          ? this.cambiarDatos.value.telefono
          : this.telefono;
        this.nuevosDatos.datosProfesionales[0].numeroDni = this.cambiarDatos
          .value.dni
          ? this.cambiarDatos.value.dni
          : this.numeroDni;

        this.nuevosDatos.direccion = this.cambiarDatos.value.direccion
          ? this.cambiarDatos.value.direccion
          : this.direccion;
        this.nuevosDatos.codigoPostal = this.cambiarDatos.value.codigoPostal
          ? this.cambiarDatos.value.codigoPostal
          : this.codigoPostal;
        this.nuevosDatos.cuit = this.cambiarDatos.value.cuit 
          ? this.cambiarDatos.value.cuit 
          : this.cuit;
        this.nuevosDatos.promocion = this.cambiarDatos.value.promocion ? this.cambiarDatos.value.promocion : this.promocion;
        if (this.cambiarDatos.value.localidadResidencia) {
          this.nuevosDatos.localidadId =
            this.cambiarDatos.value.localidadResidencia.id_localidad;
        }

        let TiposIdentificacion = {id_tipos_identificacion:this.cambiarDatos.value.tipoIdentificacion};

        this.nuevosDatos.datosProfesionales[0].especialidadesAbogados = null;
        this.nuevosDatos.datosProfesionales[0].TiposIdentificacion = TiposIdentificacion;
        this.editarPerfil();
        this.editarDatos = false;
        break;
      }

      case EDITAR_BIO: {

        this.nuevosDatos.nombre = this.cambiarDatos.value.nombre
          ? this.cambiarDatos.value.nombre
          : this.nombre;
        this.nuevosDatos.apellido = this.cambiarDatos.value.apellido
          ? this.cambiarDatos.value.apellido
          : this.apellido;
        this.nuevosDatos.mail = this.cambiarDatos.value.mail
          ? this.cambiarDatos.value.mail
          : this.mail;
        this.nuevosDatos.telefono = this.cambiarDatos.value.telefono
          ? this.cambiarDatos.value.telefono
          : this.telefono;
        this.nuevosDatos.datosProfesionales[0].numeroDni = this.cambiarDatos
          .value.dni
          ? this.cambiarDatos.value.dni
          : this.numeroDni;

        this.nuevosDatos.direccion = this.cambiarDatos.value.direccion
          ? this.cambiarDatos.value.direccion
          : this.direccion;
        this.nuevosDatos.codigoPostal = this.cambiarDatos.value.codigoPostal
          ? this.cambiarDatos.value.codigoPostal
          : this.codigoPostal;
        if (this.cambiarDatos.value.localidadResidencia) {
          this.nuevosDatos.localidadId =
            this.cambiarDatos.value.localidadResidencia.id_localidad;
        }

        let TiposIdentificacion = {id_tipos_identificacion:this.cambiarDatos.value.tipoIdentificacion};

        this.nuevosDatos.datosProfesionales[0].especialidadesAbogados = null;
        this.nuevosDatos.datosProfesionales[0].TiposIdentificacion = TiposIdentificacion;
        this.descripcion = this.cambiarDatos.value.descripcion;
        this.editarBio = true;
        this.editarPerfil();
        

        this.editarBio = true;
        break;
      }
      case EDITAR_DATOS: {
        this.editarDatos = true;
        break;
      }
      // case CAMBIAR_PASSWORD: {
      //   break;
      // }
    }
  }

  verificoPlan(campo: number) {
    const JURISDICCION = 1;
    const ESPECIALIDAD = 2;
    localStorage.removeItem('jurisdiccion');
    if (!this.planApto) {
      this.openDialog();
      this.router.navigate(['/plan']);
    } else {
      let mostrar = {
        jurisdiccion: false,
        especialidades: false,
      };
      let cantidadEspecialides =
        this.perfil.datosProfesionales[0].especialidadesAbogados.length;
      let cantidadJurisdicciones: number =
        this.contadorJurisdiccionesService.contador(
          this.perfil.datosProfesionales
        );
      switch (campo) {
        case JURISDICCION: {
          if (
            this.contadorJurisdiccionesService.contadorActivas(
              this.perfil.datosProfesionales
            ) > 0
          ) {
            console.log('cantidad1:' + cantidadJurisdicciones);
            console.log('cantidad2:' + this.suscripcionSel.planJurisdicciones);
            if (cantidadJurisdicciones < this.suscripcionSel.planJurisdicciones) {
              mostrar.jurisdiccion = true;
              localStorage.setItem(
                'jurisdiccionesExistentes',
                JSON.stringify(this.datosJurisdiccion)
              );
              localStorage.setItem('mode', 'jurisdiccion');
              this.router.navigateByUrl('/agregar');
            } else {
              this.planApto = false;
              this.openSnackBar(
                'Por favor cambia tu plan para poder agregar más jurisdicciones'
              );
              this.router.navigate(['/plan']);
            }
          } else {
            this.openSnackBar(
              'No podrás agregar jurisdicciones hasta que tengas una activa'
            );
          }
          break;
        }
        case ESPECIALIDAD: {
          // if (cantidadEspecialides < this.plan.especialidades) {
          mostrar.especialidades = true;
          localStorage.setItem(
            'especialidades',
            JSON.stringify(this.especialidadesNombre)
            );
            localStorage.setItem('mode', 'especialidades');
          const dialogRef = this.dialog.open(PopupDowngradeComponent, {
            data: {
              plan: this.planSel,
              downgrade: false,
            },
            backdropClass: 'backdropBackground',
          });
          console.log("LLEGAMOOOOS ACAA PAAA")
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.actualizarEspecialidades();
            }
          });
          // this.router.navigateByUrl("/agregar");
          // } else {
          //   this.planApto = false;
          //   this.openSnackBar("Por favor cambia tu plan para poder agregar más especialidades");
          //   this.router.navigate(['/plan']);
          // }
          break;
        }
      }
    }
  }

  editarJurisdiccion(dato) {
    localStorage.setItem('jurisdiccion', JSON.stringify(dato));
    localStorage.setItem('mode', 'editar');
    this.router.navigateByUrl('/agregar');
  }

  borrarJurisdiccion(dato) {
    let aux = this.perfil.datosProfesionales.filter(
      (dato) => dato.estado != 'D'
    );
    if (aux.length > 1) {
      const dialogRef = this.dialog.open(CancelarComponent, {
        data: {
          titulo: '¿Deseas borrar esta jurisdicción?',
          texto:
            'Este proceso es irreversible, si deseas volver a tenerla deberás cargar los datos nuevamente.',
          textoBotonCerrar: 'NO',
          textoBotonConfirmar: 'BORRAR',
          mostrarCancelar: true,
        },
        backdropClass: 'backdropBackground',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          let jurisdiccionesAEliminar: number[] = [];
          jurisdiccionesAEliminar.push(dato.id_datos_profesionales);
          let data: JurisdiccionesAEliminar = {
            jurisdiccionesAEliminar,
          };
          this.planService.bajarJurisdicciones(data).subscribe(
            (respuesta) => {
              this.openSnackBar('Jurisdicción eliminada exitosamente');
              this.datosGuardados = false;
              this.actualizarJurisdicciones = true;
              this.tags = [];
              this.datosJurisdiccion = [];
              this.especialidadesNombre = [];
              this.getMiPerfil();
            },
            (error) => {
              console.error(error);
              this.openSnackBar(
                'No fue posible eliminar la jurisdicción, por favor intente nuevamente más tarde.'
              );
            }
          );
        }
      });
    } else {
      this.openSnackBar('No se puede eliminar tu única jurisdicción');
    }
  }

  actualizarEspecialidades() {
    let especialidadesSeleccionadas: Especialidad[] = JSON.parse(
      sessionStorage.getItem('especialidadesSeleccionadas')
    );
    let nuevasEspecialidades: number[] = especialidadesSeleccionadas.map(
      (e) => e.id_especialidad
    );
    this.agregoEspecialidades = true;
    this.agregarEspecialidades(
      especialidadesSeleccionadas,
      nuevasEspecialidades
    );
  }

  agregarEspecialidades(
    especialidadesSeleccionadas: Especialidad[],
    nuevasEspecialidades: number[]
  ) {
    this.apiService.editarDatosProfesionales(nuevasEspecialidades).subscribe(
      (respuesta) => {
        let texto: string = '';
        especialidadesSeleccionadas.length > 1
          ? (texto = 'Especialidades agregadas exitosamente')
          : (texto = 'Especialidad agregada exitosamente');
        this.openSnackBar(texto);
        this.especialidadesNombre = [];
        this.modificarEspecialidades = true;
        this.getMiPerfil();
      },
      (error) => {
        console.error(error);
        this.openSnackBar(
          'Falló agregar nueva especialidad, por favor intente nuevamente más tarde.'
        );
      }
    );
  }

  editarPerfil() {
    this.apiService.editarPerfil(this.nuevosDatos).subscribe(
      (respuesta) => {
        this.getMiPerfil();
      },
      (error) => {
        this.openSnackBar('No es posible editar el perfil');
        this.nombre = 'Nombre';
      }
    );
  }

  getSuscripcion(){
    this.servicePlanMP.getSubscripcionLast().subscribe({
      next: (suscripcion: Suscripcion) => {
        this.suscripcionSel = suscripcion;
        if (this.suscripcionSel) {
          switch(this.suscripcionSel.planNombre){
            case "Plan Basico":
                this.suscripcionSel.planEspecialidades = 1;
                this.suscripcionSel.planJurisdicciones = 1;
                break;
            case "Plan Premium":
                this.suscripcionSel.planLlamada = true
                this.suscripcionSel.planAgenda = true
                this.suscripcionSel.planEspecialidades = 3;
                this.suscripcionSel.planJurisdicciones = 2;
                break;
            case "Plan Corporativo":
                this.suscripcionSel.planLlamada = true
                this.suscripcionSel.planAgenda = true
                this.suscripcionSel.planEspecialidades = 9999;
                this.suscripcionSel.planJurisdicciones = 9999;
                break;
          }
        }

        this.apiService.getPlanes().subscribe({
          next: (planes: Planes[]) => {
            let selPlan: Planes;

            planes.some((plan: Planes) => {
              if (suscripcion && plan.id_plan == suscripcion.planId) {
                selPlan = plan;
                return true;
              }
            });
            this.planSel = selPlan;
          }
        });
      }
    });
    this.planService.getClientStatus().subscribe( res => {
      if(res.mensaje == "OK"){
        this.planI = res.suscripcion;
        this.textoSubscripcion = this.tiempoSubscripcionService.getTiempo(res.suscripcion);
        if(res.tieneSuscripcion)
          switch(this.planI.nombrePlan){
              case "Plan Basico":
                  this.planI.especialidades = 1;
                  this.planI.jurisdicciones = 1;
                  break;
              case "Plan Premium":
                  this.planI.llamada = true
                  this.planI.agenda = true
                  this.planI.especialidades = 3;
                  this.planI.jurisdicciones = 2;
                  break;
              case "Plan Corporativo":
                  this.planI.llamada = true
                  this.planI.agenda = true
                  this.planI.especialidades = 9999;
                  this.planI.jurisdicciones = 9999;
                  break;
            }
        this.planI.planVencido = true
        if(res.cuit == null || res.cuit.length < 1 || res.codigoPostal == null || res.direccion == null){
          const dialogRef = this.dialog.open(PopupFacturacionComponent, {
            backdropClass: 'backdropBackground',
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
              this.planService.verificoDatosFacturacion();
              window.location.reload();
            }
          });
        }
      }

    }, err => {
      console.log(err)
    })
  }

  getCuenta() {
    this.planService.getCuenta().subscribe(
      (respuesta) => {
        this.planApto = true;
        this.cuenta = respuesta;

        console.log("GET CUENTA "+JSON.stringify(respuesta));
        //this.plan = respuesta.plan;
        console.log(respuesta);
        if (respuesta.estaAsociado) {
          this.cuentaVinculada = true;
        } else {
          this.cuentaVinculada = false;
        }
        console.log(this.planApto);
        
      },
      (error) => {
        if (error.status === 404) {
          this.cuentaVinculada = false;
          this.planApto = false;
        }
      }
    );
  }

  definirIdentificacion(){

  }

  private getProvinciaResidencia() {
    this.apiService.getJurisdiccionPaisesAuto().subscribe(
      (respuesta) => {
        this.provinciasResidencias = respuesta;
      },
      (error) => {}
    );
  }

  cargarLocalidadesResidencia() {
    this.apiService
      .getLocalidades(
        this.cambiarDatos.value.provinciaResidencia.id_jurisdiccion
      )
      .subscribe(
        (localidad) => {
          this.localidadesResidencias = localidad;
        },
        (error) => {}
      );
  }

  private getPuntuaciones() {
    this.apiService.getPuntuacion().subscribe((res) => {
      this.opiniones = res;
      this.puntuacionesService.crearEstrellasMapeando(this.opiniones);
    });
  }

  loadScheduler(userId: number) {
    this.apiService.getRanges(userId).subscribe(
      (response) => {
        this.initRangeHours = [];
        this.endRangeHours = [];
        for (let i = 0; i < 24; i++) {
          var strHour: String;
          strHour = i < 10 ? '0' + i + ':' : i + ':';
          this.initRangeHours.push(
            new RangeHour(strHour + '00', strHour + '00')
          );
          this.initRangeHours.push(
            new RangeHour(strHour + '30', strHour + '30')
          );
          if (i != 0) {
            this.endRangeHours.push(
              new RangeHour(strHour + '00', strHour + '00')
            );
          }
          this.endRangeHours.push(
            new RangeHour(strHour + '30', strHour + '30')
          );
        }

        this.endRangeHours.push(new RangeHour('24:00', '24:00'));

        for (let i = 0; i < response.length; i++) {
          var scheduler = this.diasAgenda.filter(
            (x) => x.dia === response[i].dia
          );
          var indexEndRange: number = 0;

          if (Object.keys(this.initRangeHoursTemp).length > 0) {
            var indexLast = Object.keys(this.initRangeHoursTemp).filter(
              (x) => parseInt(x.substring(0, 1)) == scheduler[0].rangeHour
            );
            indexEndRange =
              indexLast.length > 0
                ? parseInt(
                    indexLast.map((x) => x.substring(1, 2)).reverse()[0]
                  ) + 1
                : 0;
          }

          this.initRangeHoursTemp[
            scheduler[0].rangeHour.toString() + indexEndRange
          ] = this.initRangeHours;
          this.endtRangeHoursTemp[
            scheduler[0].rangeHour.toString() + indexEndRange
          ] = this.endRangeHours;

          this.quantities(scheduler[0].rangeHour).push(this.newQuantity());
          var quantities = this.productFormArray[scheduler[0].rangeHour]
            .productForm.controls.quantities as any;
          var control =
            quantities.controls[quantities.controls.length - 1].controls;
          control.id.value = response[i].id;
          var hourInit = parseInt(response[i].horaInicio.toString()) / 60;
          var minuteInit = parseInt(response[i].horaInicio) % 60;
          var hourEnd = parseInt(response[i].horaFin) / 60;
          var minuteEnd = parseInt(response[i].horaFin) % 60;
          control.init.value =
            parseInt(hourInit.toString()) > 9
              ? parseInt(hourInit.toString())
              : '0' + parseInt(hourInit.toString());
          control.init.value +=
            parseInt(minuteInit.toString()) > 9
              ? ':' + parseInt(minuteInit.toString())
              : ':' + '0' + parseInt(minuteInit.toString());
          control.end.value =
            parseInt(hourEnd.toString()) > 9
              ? parseInt(hourEnd.toString())
              : '0' + parseInt(hourEnd.toString());
          control.end.value +=
            parseInt(minuteEnd.toString()) > 9
              ? ':' + parseInt(minuteEnd.toString())
              : ':' + '0' + parseInt(minuteEnd.toString());
          this.enableRanges(scheduler[0].rangeHour, false, true);
        }
      },
      (error) => {}
    );
  }

  getCertification(){
    this.apiService.getcertification(this.mail).subscribe(
      (certification) => {
        console.log("CERT "+JSON.stringify(certification));
        if(certification!=null){
          this.cert_requested=true;
          if(certification.status_doc=='CO'){
            this.certified=true;
          }
          else{
            this.certified=false;
          }
        }
        else{
          this.certified=false;
          this.cert_requested=false;
        }
        
      },
      (error) => {
        this.openSnackBar(
          'No es posible recuperar la información del perfil. Por favor vuelva a intentarlo más tarde.'
        );
      }
    );
  }

  getMiPerfil() {
    this.apiService.getPerfil().subscribe(
      (perfil) => {
        this.perfil = perfil;
        this.userId = this.perfil.id_usuario;
        this.loadScheduler(this.userId);
        this.nombre = this.perfil.nombre;
        this.apellido = this.perfil.apellido;

        this.direccion = this.perfil.datosProfesionales[0].direccion;
        this.codigoPostal = this.perfil.datosProfesionales[0].codigoPostal;
        this.cuit = this.perfil.datosProfesionales[0].cuit;
        this.promocion = this.perfil.promocion;
        this.abogado.nombre = this.nombre;
        this.abogado.apellido = this.apellido;
        this.mail = this.perfil.mail;
        this.numeroDni = this.perfil.datosProfesionales[0].numeroDni;
        this.otro = this.perfil.datosProfesionales[0].otro==null ? '':this.perfil.datosProfesionales[0].otro;
        this.descripcion = this.perfil.descripcion
          ? this.perfil.descripcion
          : ' ';
        this.telefono = this.perfil.datosProfesionales[0].telefono;
        this.getCertification();

        if (!this.datosGuardados) {
          this.perfil.datosProfesionales.forEach((dato) => {
            let datosAux: JurisdiccionEditarVer = {
              jurisdiccionNombre: '',
              localidadNombre: '',
              tomo: '',
              folio: '',
              colegio: '',
              aceptada: true,
              otro:'',
              id_datos_profesionales: 0,
            };
            this.datosGuardados = true;
            if (
              dato.especialidadesAbogados[0] &&
              dato.especialidadesAbogados[0].id_especialidad
            ) {
              this.especialidadesIds.push(
                dato.especialidadesAbogados[0].id_especialidad
              );
            }
            if (dato.estado != 'D') {
              this.tags.push(dato.localidad.jurisdiccion.nombre);
            }
            if (dato.estado == 'P') {
              datosAux.aceptada = false;
            }
            datosAux.jurisdiccionNombre = dato.localidad.jurisdiccion.nombre;
            datosAux.localidadNombre = dato.localidad.nombre;
            datosAux.tomo = dato.tomo;
            datosAux.folio = dato.folio;
            datosAux.id_datos_profesionales = dato.id_datos_profesionales;
            datosAux.colegio = dato.colegio;
            datosAux.otro = dato.otro;
            if (dato.estado != 'B' && dato.estado != 'D') {
              this.datosJurisdiccion.push(datosAux);
            }
          });

          this.perfil.datosProfesionales[0].especialidadesAbogados.forEach(
            (especialidad) => {
              let especialidadAlterada = especialidad.nombre.replace('/', ' ');
              this.especialidadesNombre.push(especialidadAlterada);
              this.tags = this.tags.concat(especialidad.nombreCliente);
              this.tags = [
                ...new Set([...this.tags, ...especialidad.nombreCliente]),
              ];
            }
          );
        }
        this.cambiarDatos.patchValue({ nombre: perfil.nombre });
        this.cambiarDatos.patchValue({ apellido: perfil.apellido });
        this.cambiarDatos.patchValue({ descripcion: perfil.descripcion });
        this.cambiarDatos.patchValue({
          telefono: perfil.datosProfesionales[0].telefono,
        });
        this.cambiarDatos.patchValue({
          dni: perfil.datosProfesionales[0].numeroDni,
        });
        this.cambiarDatos.patchValue({ mail: perfil.mail });
        this.cambiarDatos.patchValue({
          direccion: perfil.datosProfesionales[0].direccion,
        });
        this.cambiarDatos.patchValue({
          codigoPostal: perfil.datosProfesionales[0].codigoPostal,
        });
        this.cambiarDatos.patchValue({
          cuit: perfil.datosProfesionales[0].cuit,
          promocion: perfil.promocion
        });

        if (this.perfil.avatarUrl != null) {
          this.tieneFoto = true;
        } else {
          this.tieneFoto = false;
        }
        this.avatarUrl = environment.apiUrl + this.perfil.avatarUrl;

        if (this.perfil.pais === 'ARG') {
          this.pais = 'Argentina';
        } else {
          this.pais = this.perfil.pais;
        }
        if (this.modificarEspecialidades) {
          this.modificarEspecialidades = false;
          this.actualizarAgenda();
          this.redibujarEspecialidades();
        }
        if (this.actualizarJurisdicciones) {
          this.actualizarJurisdicciones = false;
          this.actualizarAgenda();
        }
        this.terminoCarga = true;
      },
      (error) => {
        this.openSnackBar(
          'No es posible recuperar la información del perfil. Por favor vuelva a intentarlo más tarde.'
        );
      }
    );
  }

  async actualizarAgenda() {
    try {
      this.tags = await this.updateRangesService.updateWithEmptyTags();
    } catch (error) {
      this.openSnackBar('Falló la actualización de agenda');
    }
  }

  redibujarEspecialidades() {
    this.perfil.datosProfesionales[0].especialidadesAbogados.forEach(
      (especialidad) => {
        let especialidadAlterada = especialidad.nombre.replace('/', ' ');
        this.especialidadesNombre.push(especialidadAlterada);
      }
    );
  }

  loadEndRangeHours(value, index) {
    this.endRangeHours = [];
    var hour = parseInt(value.split(':')[0]);
    var minute = parseInt(value.split(':')[1]);

    this.enableRanges(index, true, false);

    var range = this.productFormArray[index].productForm.value.quantities;
    var existIntervalZeroTwo: any[] = [];
    var existIntervalMidTwo: any[] = [];

    for (let i = hour; i <= 24; i++) {
      var existIntervalZero = range.filter(
        (x) =>
          i * 60 >
            parseInt(x.init.split(':')[0]) * 60 +
              parseInt(x.init.split(':')[1]) &&
          i * 60 <
            parseInt(x.end.split(':')[0]) * 60 + parseInt(x.end.split(':')[1])
      );

      var intervalToRemoveHour = range.filter(
        (x) =>
          i * 60 >=
            parseInt(x.init.split(':')[0]) * 60 +
              parseInt(x.init.split(':')[1]) &&
          i * 60 <
            parseInt(x.end.split(':')[0]) * 60 + parseInt(x.end.split(':')[1])
      );

      if (intervalToRemoveHour.length > 0)
        intervalToRemoveHour.forEach(function (item) {
          existIntervalZeroTwo.push(item);
        });

      var existIntervalMid = range.filter(
        (x) =>
          i * 60 + 30 >
            parseInt(x.init.split(':')[0]) * 60 +
              parseInt(x.init.split(':')[1]) &&
          i * 60 + 30 <
            parseInt(x.end.split(':')[0]) * 60 + parseInt(x.end.split(':')[1])
      );

      var intervalToRemoveMid = (intervalToRemoveMid = range.filter(
        (x) =>
          i * 60 + 30 >=
            parseInt(x.init.split(':')[0]) * 60 +
              parseInt(x.init.split(':')[1]) &&
          i * 60 + 30 <
            parseInt(x.end.split(':')[0]) * 60 + parseInt(x.end.split(':')[1])
      ));

      if (intervalToRemoveMid.length > 0)
        intervalToRemoveMid.forEach(function (item) {
          existIntervalMidTwo.push(item);
        });

      var strHour: String;
      strHour = i < 10 ? '0' + i + ':' : i + ':';

      if (i == hour && minute == 0 && existIntervalMid == 0) {
        this.endRangeHours.push(new RangeHour(strHour + '30', strHour + '30'));
        continue;
      } else if (i == hour && minute == 30) {
        continue;
      }

      if (existIntervalZero.length == 0)
        this.endRangeHours.push(new RangeHour(strHour + '00', strHour + '00'));
      if (existIntervalMid.length == 0 && i != 24)
        this.endRangeHours.push(new RangeHour(strHour + '30', strHour + '30'));
    }

    if (existIntervalZeroTwo.length > 0) {
      var itemsDuplicatedHour = this.endRangeHours.filter(
        (z) =>
          existIntervalZeroTwo.map((x) => x.end).filter((x) => x == z.value)
            .length > 0
      );

      var hour: number;
      itemsDuplicatedHour.forEach(function (item, index) {
        var itemHour =
          parseInt(item.value.split(':')[0]) * 60 +
          parseInt(item.value.split(':')[1]);
        if (index == 0) hour = itemHour;
        else {
          if (itemHour - hour >= 60) {
            itemsDuplicatedHour.splice(index, itemsDuplicatedHour.length);
          }
        }
      });

      if (itemsDuplicatedHour.length > 0) {
        this.endRangeHours = this.endRangeHours.filter(
          (x) =>
            parseInt(x.value.split(':')[0]) * 60 +
              parseInt(x.value.split(':')[1]) <
            parseInt(itemsDuplicatedHour[0].value.split(':')[0]) * 60 +
              parseInt(itemsDuplicatedHour[0].value.split(':')[1])
        );
      }
    }
    if (existIntervalMidTwo.length > 0) {
      var itemsDuplicatedMid = this.endRangeHours.filter(
        (z) =>
          existIntervalMidTwo.map((x) => x.end).filter((x) => x == z.value)
            .length > 0
      );

      var hour: number;
      itemsDuplicatedMid.forEach(function (item, index) {
        var itemHour =
          parseInt(item.value.split(':')[0]) * 60 +
          parseInt(item.value.split(':')[1]);
        if (index == 0) hour = itemHour;
        else {
          if (itemHour - hour >= 60) {
            itemsDuplicatedMid.splice(index, itemsDuplicatedMid.length);
          }
        }
      });

      if (itemsDuplicatedMid.length > 0) {
        this.endRangeHours = this.endRangeHours.filter(
          (x) =>
            parseInt(x.value.split(':')[0]) * 60 +
              parseInt(x.value.split(':')[1]) <
            parseInt(itemsDuplicatedMid[0].value.split(':')[0]) * 60 +
              parseInt(itemsDuplicatedMid[0].value.split(':')[1])
        );
      }
    }

    if (range.length > 1) {
      this.enableRanges(index, false, false);
    }

    var indexEndRange = 0;

    if (Object.keys(this.endtRangeHoursTemp).length > 0) {
      var indexLast = parseInt(
        Object.keys(this.initRangeHoursTemp)
          .filter((x) => parseInt(x.substring(0, 1)) == index)
          .map((x) => x.substring(1, 2))
          .reverse()[0]
      );
      indexEndRange = indexLast;
    }

    this.endtRangeHoursTemp[index.toString() + indexEndRange] =
      this.endRangeHours;
  }

  getInitRanges(i, index) {
    return this.initRangeHoursTemp[index.toString() + i];
  }

  getEndRanges(i, index) {
    return this.endtRangeHoursTemp[index.toString() + i];
  }

  enableRanges(index, active, indexStart) {
    var cont = this.productFormArray[index].productForm.get(
      'quantities'
    ) as UntypedFormGroup;

    var lstControls = cont.controls as any;

    if (lstControls.length > 0) {
      lstControls.forEach(function (item, i) {
        if (active) {
          item.get('init').enable();
          item.get('end').enable();
        } else {
          if (i != lstControls.length - 1) {
            item.get('init').disable();
            item.get('end').disable();
          } else {
            if (indexStart) {
              item.get('init').disable();
              item.get('end').disable();
            }
          }
        }
      });
    }
  }

  loadInitRange(index) {
    var initRangeHours = this.calculateNewRange(index);

    var indexInitRange = 0;

    if (Object.keys(this.initRangeHoursTemp).length > 0) {
      indexInitRange = parseInt(
        Object.keys(this.initRangeHoursTemp)
          .map((x) => x)
          .reverse()[0]
      );
    }

    this.initRangeHoursTemp[index.toString() + indexInitRange] = initRangeHours;

    this.enableRanges(index, false, false);
  }

  cerrarSesion() {
    this.logOut.cerrarSesion();
  }

  isPlanExpensive(): boolean {
    return this.servicePlanMP.isPlanCorpOrPrem(this.suscripcionSel);
  }

  isSuscriptionActive(): boolean {
    // result = plan.nombrePlan.toLocaleUpperCase().indexOf("PREMIUM") >= 0 || plan.nombrePlan.toLocaleUpperCase().indexOf("CORPORATIVO") >= 0;
    return this.servicePlanMP.isSuscriptionActive(this.suscripcionSel);
  }

  isSuscriptionActiveAndAgenda(): boolean {
    let result: boolean = this.isSuscriptionActive() && this.suscripcionSel.planAgenda;
    return result;
  }

  isSuscriptionActiveAndLlamada(): boolean {
    let result: boolean = this.isSuscriptionActive() && this.suscripcionSel.planLlamada;
    return result;
  }

  hasLinkedPayment(): boolean {
    let result: boolean = false;

    if (this.cacheVinculaciones) {
      result = this.cacheVinculaciones.some((vinculacion: MPVinculacion) => {
        if (!vinculacion.needLinked)
          return true;
        if (vinculacion.needLinked && vinculacion.linked)
          return true;
        return false;
      });
    }

    return result;
  }

  fixDigits(digits: number, event): void {
    if (event.target.value.length > digits)
      this.cambiarDatos.get("codigoPostal").setValue(event.target.value.slice(0, digits));
  }

  doCuitFix(event): void {
    if (this.paisConfigCuit) {
      let cuitValue = this.cambiarDatos.getRawValue().cuit;
      if (cuitValue.match(this.paisConfigCuit.svalor02)) {
        this.cuitOldVal = cuitValue;
      } else {
        this.cambiarDatos.patchValue({
          cuit: this.cuitOldVal
        });
      }
    }
  }

}
