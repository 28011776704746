import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TiempoSubscripcionService {
  constructor() {}

  getTiempo(respuesta) {
    let separado = respuesta.vencimientoPeriodoPrueba.toString().split('T');
    let aux = separado[0].split('-');
    let mesFinal = parseInt(aux[1], 10);
    let today = new Date();
    let duracion = mesFinal - (today.getMonth() + 1);
    let tiempo = '';
    if (duracion > 1) {
      tiempo = 'MESES';
    } else {
      if (duracion == 1) {
        tiempo = 'MES';
      } else {
        if (duracion >= 0) {
          let diaFinal = parseInt(aux[2], 10);
          duracion = diaFinal - today.getDate();
          tiempo = 'DIAS';
        } else {
          duracion = 12 - (today.getMonth() + 1) + mesFinal;
          tiempo = 'MESES';
        }
      }
    }
    let retornarString: string = respuesta.status == "trial"
      ? 'SUBSCRIPCIÓN GRATIS POR ' + duracion + ' ' + tiempo
      : 'SUBSCRIPCIÓN DURANTE ' + duracion + ' ' + tiempo;
    return retornarString;
  }
}
