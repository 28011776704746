import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  isDevMode,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { NewUser, RegisterClient } from 'src/app/api/user';
import { LoginService } from 'src/app/services/login.service';
import { ApiService } from 'src/app/api/api.service';
import { ChangeDetectorRef } from '@angular/core';
import { SenderService } from 'src/app/services/sender.service';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var gtag;
@Component({
  selector: 'app-external',
  templateUrl: './external.component.html',
  styleUrls: ['./external.component.css'],
})
export class ExternalComponent implements OnInit {
  public usuario = { rol: 0 };
  public nippy: boolean;
  constructor(private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private http: HttpClient,
    private loginSrv: LoginService,
    private apiService: ApiService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private service: SenderService
  ) {}
  // key: string = "4573746F4573556E614B6579616161614573746F4573556E614B657961616161";
  // iv : string  = "3DA59479589FB1A4FE21487D7C07045C";
  // key: string = '4573746F4573556E614B657961616161';
  // iv: string = '3DA59479589FB1A4';

  key: string = 'JDG4R1WHPME850CW9NXCMQTVRCF6OY2Y';
  iv: string = 'CLQ0QAW64LGOHIIA';
  data: string;
  json: string;
  decKey: string = 'piorjfnkfnlaskfslf';
  desc = null;
  registerClients: RegisterClient[] = [];
  private url = environment.apiUrl + '/';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {

      try {
        this.data = decodeURI(params['data']);
      } catch (ex) {
        this.trackLoginNippyError();
        this.openSnackBar("Se encontro un error al validar su identidad");
      }
      
    });

    this.decrypt(this.data);
    this.cdRef.detectChanges();
    this.inciarSesion(this.json);

    // this.decrypt(this.data);

    // 1. consultar al backend si el usuario existe y esta activo
    // 2. si no existe aprovisionarlo y redirigir a la pagina para la apertura de caso
    // 3. si es existe, loguear y redireccionar a la pagina de apertura de caso
  }

  trackLoginNippySuccess(){
    gtag('event', 'login_nippy_success', {
      'send_to':environment.measurement_id,
      'event_name': 'login_nippy_success'
    });
    gtag('event', 'login', {
      'send_to':environment.measurement_id,
      'method': 'login_nippy_success'
    });
  }

  trackLoginNippyError(){
    gtag('event', 'login', {
      'send_to':environment.measurement_id,
      'method': 'login_nippy_error'
    });
  }

  decrypt(cypherText: string) {
    let keyBase64: any;
    let ivBase64: any;
    let cypherTextArray: any;
    keyBase64 = CryptoJS.enc.Utf8.parse(this.key);
    ivBase64 = CryptoJS.enc.Utf8.parse(this.iv);

    //console.log("JSON 1"+cypherText);
    //console.log("utf8 "+utf8);
    // cypherTextArray = this.stringToByteArray(cypherText);
    //cypherTextArray = CryptoJS.enc.Base64.parse(cypherText);

    try {
      this.json = CryptoJS.AES.decrypt(cypherText, keyBase64, {
        iv: ivBase64,
        padding: CryptoJS.pad.ZeroPadding,
        mode: CryptoJS.mode.CBC,
      }).toString(CryptoJS.enc.Utf8);
    } catch (ex) {
      this.openSnackBar("Se encontro un error AES");
    }
    
    


  }
  //if()
  send(json: string): Observable<any> {
    return this.http.post<any>(this.url + 'cuenta', json, this.httpOptions);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "", { duration: 5000 });
  }

  byteArrayToString(hex: Uint16Array): string {
    var hexString = '';
    var nextHexByte;
    for (var i = 0; i < hex.byteLength; i++) {
      nextHexByte = hex[i].toString(16); // Integer to base 16
      if (nextHexByte.length < 2) {
        nextHexByte = '0' + nextHexByte; // Otherwise 10 becomes just a instead of 0a
      } //  w w w.java  2 s  . c  om
      hexString += nextHexByte;
    }
    return hexString;
  }

  stringToByteArray(hex: string): Uint16Array {
    var numBytes = hex.length / 2;
    var byteArray = new Uint16Array(numBytes);
    for (var i = 0; i < numBytes; i++) {
      byteArray[i] = parseInt(hex.substr(i * 2, 2), 16);
    }
    return byteArray;
  }

  guardarUsuario() {
    console.log('Create una cuenta');
  }

  inciarSesion(dato: any) {
    this.nippy = true;
    //this.service.nippy = this.nippy;
    sessionStorage.nippy = '';
    if (isDevMode())
      console.log('Iniciar sesion: ' + dato);
    else
      console.log('Iniciar sesion.');
    //this.apiService.postUserNippy(dato).subscribe((dato) => (dato = dato));
    this.apiService.postUserNippy(dato).subscribe(
      (dato) => {
        sessionStorage.companyLogo = `${environment.apiUrl}/public/company/${dato.companyId}/logo`;
        this.trackLoginNippySuccess();
        this.loginSrv.handleUserData(dato);
      },
      (error) => {
        if (error.status == 409) {
          console.log('Error pa');
          this.apiService.LoginUserNippy(dato).subscribe((dato) => {
            sessionStorage.companyLogo = `${environment.apiUrl}/public/company/${dato.companyId}/logo`;
            this.loginSrv.handleUserData(dato);
          });
        }
      }
    );
  }

  navegarRegistro(rol: number) {
    this.usuario.rol = rol;
    localStorage.setItem('rol', this.usuario.rol.toString());
    this.router.navigateByUrl('/redes');
  }
}
// http://legalify.app/account?data=sndlñksandfaskldfniasdniosa
