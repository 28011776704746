import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Identificacion, Jurisdiccion, Localidad, Perfil } from '../../../api/user';
import { ApiService } from '../../../api/api.service';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogOutService } from '../../../services/logOut/log-out.service';
import { TranslateService } from '@ngx-translate/core';
import { SenderService } from 'src/app/services/sender.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css'],
})
export class PerfilComponent implements OnInit {
  editarPerfil = false;
  tieneFoto = false;
  nuevosDatos: any;
  nippy: boolean;
  language:String="";
  provinciasResidencias: Jurisdiccion[];
  localidadesResidencias: Localidad[];

  identificaciones: Identificacion[];

  sessionUser: any;

  constructor(
    private logOut: LogOutService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _snackBar: MatSnackBar,
    private apiService: ApiService,
    public translate: TranslateService,
    private service: SenderService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    this.language=sessionStorage.getItem('language');
    translate.setDefaultLang(sessionStorage.getItem('language'));

    this.sessionUser = JSON.parse(localStorage.user);

    this.matIconRegistry.addSvgIcon(
      `perfil`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/perfil.svg'
      )
    );
  }

  archivosForm = new UntypedFormGroup({
    archivosSeleccionados: new UntypedFormControl(),
  });

  cambiarDatos = new UntypedFormGroup({
    nombre: new UntypedFormControl(),
    apellido: new UntypedFormControl(),
    mail: new UntypedFormControl(),
    tipoDni: new FormControl<number | null>(null),
    dni: new UntypedFormControl(),
    pais: new UntypedFormControl(),
    telefono: new UntypedFormControl(),
    localidad: new UntypedFormControl(),
    provinciaResidencia: new UntypedFormControl(),
    localidadResidencia: new UntypedFormControl(),
    direccion: new UntypedFormControl('', [Validators.pattern(/^[a-z\d\_\s]+$/i)]),
    codigoPostal: new UntypedFormControl('', [Validators.max(99999999)]),
    promocion: new FormControl('', [])
  });

  perfil: Perfil;
  nombre: string;
  apellido: string;
  bio: string;
  mail: string;
  tipoDni: number;
  numeroDni: string;
  pais: string;
  telefono: string;
  promocion: string;
  avatarUrl: string;

  archivos = [];

  activarEditar() {
    this.editarPerfil = true;
  }

  cancelar(){
    this.editarPerfil = false
  }

  switchLang(lang: string) {
    sessionStorage.setItem('language',lang);
    this.language = lang;
    this.translate.use(lang);
  }

  editar() {
    this.editarPerfil = !this.editarPerfil;
    this.nuevosDatos = this.perfil;

    this.nuevosDatos.nombre = this.cambiarDatos.value.nombre
      ? this.cambiarDatos.value.nombre
      : this.nombre;
    this.nuevosDatos.apellido = this.cambiarDatos.value.apellido
      ? this.cambiarDatos.value.apellido
      : this.apellido;
    this.nuevosDatos.mail = this.cambiarDatos.value.mail
      ? this.cambiarDatos.value.mail
      : this.mail;
    this.nuevosDatos.telefono = this.cambiarDatos.value.telefono
      ? this.cambiarDatos.value.telefono
      : this.telefono;



      this.nuevosDatos.nombre = this.cambiarDatos.value.nombre
          ? this.cambiarDatos.value.nombre
          : this.nombre;
      this.nuevosDatos.apellido = this.cambiarDatos.value.apellido
          ? this.cambiarDatos.value.apellido
          : this.apellido;
        this.nuevosDatos.mail = this.cambiarDatos.value.mail
          ? this.cambiarDatos.value.mail
          : this.mail;
        this.nuevosDatos.telefono = this.cambiarDatos.value.telefono
          ? this.cambiarDatos.value.telefono
          : this.telefono;
        this.nuevosDatos.datosUsuario[0].tiposIdentificacionId = this.cambiarDatos.value.tipoDni 
          ? this.cambiarDatos.value.tipoDni : this.tipoDni;
        this.nuevosDatos.datosUsuario[0].numeroDni = this.cambiarDatos
          .value.dni
          ? this.cambiarDatos.value.dni
          : this.numeroDni;

          this.nuevosDatos.datosUsuario[0].telefono = this.cambiarDatos
          .value.telefono
          ? this.cambiarDatos.value.telefono
          : this.telefono;

        this.nuevosDatos.promocion = this.cambiarDatos.value.promocion ?
          this.cambiarDatos.value.promocion : this.promocion;
        
        this.nuevosDatos.codigoPostal = this.cambiarDatos.value.codigoPostal;
        this.nuevosDatos.datosUsuario[0].codigoPostal = this.cambiarDatos.value.codigoPostal;
        if (this.cambiarDatos.value.localidadResidencia) {
          this.nuevosDatos.localidadId =
            this.cambiarDatos.value.localidadResidencia.id_localidad;
            this.nuevosDatos.datosUsuario[0].id_localidad=this.cambiarDatos.value.localidadResidencia.id_localidad;
        }

      
    //this.nuevosDatos.datosUsuario.push();
    this.editarCambiosPerfil();
  }

  private getProvinciaResidencia() {
    this.apiService.getJurisdiccionPaisesAuto().subscribe(
      (respuesta) => {
        this.provinciasResidencias = respuesta;
      },
      (error) => {}
    );
  }

  editarCambiosPerfil() {
    this.apiService.editarPerfil(this.nuevosDatos).subscribe(
      (respuesta) => {
        this.getMiPerfil();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  cargarLocalidadesResidencia() {
    this.apiService
      .getLocalidades(
        this.cambiarDatos.value.provinciaResidencia.id_jurisdiccion
      )
      .subscribe(
        (localidad) => {
          this.localidadesResidencias = localidad;
        },
        (error) => {}
      );
  }

  selectFiles(event) {
    for (var i = 0; i < event.target.files.length; i++) {
     // this.archivos.push(event.target.files[i]);
    }
    this.archivos.push(event.target.files[0]);

    console.log("1402 FILE "+JSON.stringify(event.target.files[0]));
  //  this.archivosForm.value.archivosSeleccionados = this.archivos;

    this.apiService.patchAvatar(this.archivos).subscribe(
      (respuesta) => {
        this.getMiPerfil();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  getIdentificaciones() {
    this.apiService.getIdentificacion().subscribe(
      (identificaciones) => {
        this.identificaciones = identificaciones;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  ngOnInit(): void {
    this.getMiPerfil();
    //this.nippy = this.service.nippy;
    this.nippy = sessionStorage.nippy;
    console.log('NIPPY ' + this.nippy);
    this.getProvinciaResidencia();
    this.getIdentificaciones();
  }

  cerrarSesion() {
    sessionStorage.removeItem('nippy');
    sessionStorage.clear()
    this.logOut.cerrarSesion();
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 5000 });
  }

  getMiPerfil() {
    this.apiService.getPerfil().subscribe(
      (perfil) => {
        this.perfil = perfil;
        this.nombre = this.perfil.nombre;
        this.apellido = this.perfil.apellido;
        this.mail = this.perfil.mail;
        this.tipoDni = this.perfil.datosUsuario[0].tipoDniId;
        this.numeroDni = this.perfil.datosUsuario[0].numeroDni;
        this.telefono = this.perfil.datosUsuario[0].telefono;
        this.promocion = this.perfil.promocion;
        this.cambiarDatos.get('codigoPostal').setValue(this.perfil.datosUsuario[0].codigoPostal);

        if (this.perfil.avatarUrl != null) {
          this.tieneFoto = true;
        } else {
          this.tieneFoto = false;
        }
        this.avatarUrl = environment.apiUrl + this.perfil.avatarUrl;

        //01/03
        this.cambiarDatos.get('nombre').setValue(this.nombre);
        this.cambiarDatos.get('apellido').setValue(this.apellido);
        this.cambiarDatos.get('mail').setValue(this.mail);
        this.cambiarDatos.get('tipoDni').setValue(this.tipoDni);
        this.cambiarDatos.get('dni').setValue(this.numeroDni);
        this.cambiarDatos.patchValue({
          telefono: this.telefono,
          promocion: this.promocion 
        });

        if (this.perfil.pais === 'ARG') {
          this.pais = 'Argentina';
        } else {
          this.pais = this.perfil.pais;
        }
      },
      (error) => {
        console.error(error);
        this.openSnackBar(
          'No es posible recuperar la información del perfil. Por favor vuelva a intentarlo más tarde.'
        );
      }
    );
  }
}
