import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../api/api.service';
import { Perfil } from '../../../../api/user';
import { environment } from '../../../../../environments/environment';
import { Puntuacion } from 'src/app/interfaces/Puntuacion';
import { TiposCasoCode } from 'src/app/enums/TiposCasoCode';
import { PuntuacionesService } from '../../../../services/puntuaciones.service';
import { FilesService } from '../../../../utils/files.service';
import { EspecialidadDelCasoService } from '../../../../services/especialidad/especialidad-del-caso.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { CurrentTimeService } from '../../../../utils/currentTime/current-time.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-datos',
  templateUrl: './datos.component.html',
  styleUrls: ['./datos.component.css'],
})
export class DatosComponent implements OnInit {
  perfil: Perfil;
  nombre: string;
  apellido: string;
  bio: string;
  mail: string;
  localidad: string;
  jurisdiccion: string;
  colegio: string;
  especialidades: [
    {
      id_especialidad: number;
      nombre: string;
    }
  ];
  folio: string;
  numeroDni: string;
  tomo: string;
  pais: string;
  telefono: string;

  titulo: string;
  url = '';
  caso: any;
  nombreEspecialidad: string;
  tieneFoto = false;
  avatarUrl = '';

  abogado: any;
  seleccion = {
    nombre: '',
    apellido: '',
    estado: 's',
    titulo: '',
    id_caso: 0,
    caso: '',
    id_usuario: 0,
    abogado: '',
    archivosSeleccionados: '',
  };
  mostrarTelefono = false;
  mostrarCorreo = false;
  mostrarDatos = false;
  mostrarVolver = false;
  mostrarExplicacionLlamada = false;
  seleccionAbogado = false;

  especialidadParaMostrar = '';
  mostrar = false;
  opiniones: Array<Puntuacion> = [];

  countryId = 54;
  rolId = 3;

  constructor(
    private puntuacionesService: PuntuacionesService,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private _location: Location,
    private fileSrv: FilesService,
    private especialidadService: EspecialidadDelCasoService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private currentTimeSrv: CurrentTimeService,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
    this.matIconRegistry.addSvgIcon(
      `perfil`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../assets/perfil.svg'
      )
    );

    this.url =
      this.router.getCurrentNavigation().extractedUrl.root.children.primary.segments[0].path;

    if (this.url === 'seleccion') {
      if (this.router.getCurrentNavigation().extras.state.caso != undefined) {
        this.seleccion.archivosSeleccionados =
          this.router.getCurrentNavigation().extras.state.caso.archivosSeleccionados;
      }
      this.titulo = this.router.getCurrentNavigation().extras.state.titulo;
      switch (this.titulo) {
        case 'Llamada express':
          this.mostrarCorreo = false;
          this.mostrarExplicacionLlamada = true;
          this.mostrarDatos = true;
          break;
        case 'Detalle del caso':
          this.mostrarCorreo = true;
          this.mostrarDatos = true;
          break;
        case 'Agendar cita':
          this.mostrarCorreo = false;
          this.mostrarDatos = false;
          break;
        default:
          break;
      }
      this.caso = this.router.getCurrentNavigation().extras.state.caso;
      this.abogado = this.router.getCurrentNavigation().extras.state.abogado;

      if (
        this.caso &&
        this.caso.especialidad &&
        this.caso.especialidad.nombre
      ) {
        this.especialidadParaMostrar = this.especialidadService.match(
          this.caso.especialidad.nombre,
          this.abogado
        );
        if (this.especialidadParaMostrar.length > 2) {
          this.mostrar = true;
        }
      }

      this.seleccion.abogado = this.abogado;
      this.mail = this.abogado.mail;
      this.apiService.getPais(this.abogado.pais)
            .subscribe(pais =>{
              console.log(pais);
              this.pais = pais.name;
            })
      this.tieneFoto =
        this.router.getCurrentNavigation().extras.state.tieneFoto;
      this.seleccion.id_usuario = this.abogado.id_usuario;

      this.seleccionAbogado = true;
      this.seleccion.id_caso =
        this.router.getCurrentNavigation().extras.state.id_caso;
      this.nombre =
        this.router.getCurrentNavigation().extras.state.abogado.nombre;
      this.apellido =
        this.router.getCurrentNavigation().extras.state.abogado.apellido;
      this.nombreEspecialidad =
        this.router.getCurrentNavigation().extras.state.abogado.datosProfesionales[0].especialidadesAbogados[0].nombre;
    }

    if (this.url === 'llamada') {
      this.abogado = JSON.parse(localStorage.getItem('abogado'));
      this.nombre = this.abogado.nombre;
      this.apellido = this.abogado.apellido;
      this.nombreEspecialidad =
        this.abogado.datosProfesionales[0].especialidadesAbogados[0].nombre;
      this.titulo = 'Llamada express';
      this.mostrarDatos = true;
      this.mostrarCorreo = false;
      this.telefono = this.abogado.datosProfesionales[0].telefono;
      this.mostrarTelefono = true;
      this.mostrarVolver = true;
      this.seleccionAbogado = false;
      this.crearCaso();
    }
  }

  ngOnInit(): void {
    if (this.url === 'llamada') {
      this.abogado = JSON.parse(localStorage.getItem('abogado'));
      this.seleccion.abogado = this.abogado;
      this.caso = JSON.parse(localStorage.getItem('caso'));
    }
    this.getOpiniones();

    let aux: string;
    aux = this.abogado.avatarUrl.toString();
    let contiene = aux.includes(environment.apiUrl);
    if (
      this.abogado &&
      this.abogado.avatarUrl &&
      !this.abogado.avatarUrl.includes(null)
    ) {
      this.tieneFoto = true;
      this.avatarUrl = this.abogado.avatarUrl;
    } else {
      this.tieneFoto = false;
    }

    let userData = JSON.parse(localStorage.user);
    this.countryId = userData.pais;
    this.rolId = userData.rol?.id;
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  llamar(telefono) {
    window.open(`tel:${telefono}`);
  }

  atras() {
    window.history.back();
  }

  private getOpiniones() {
    this.apiService.getPuntuacion(this.abogado.id_usuario).subscribe((res) => {
      this.opiniones = res;
      this.puntuacionesService.crearEstrellasMapeando(this.opiniones);
    });
  }

  aceptarAbogado() {
    this.apiService
      .aceptarAbogado(this.abogado.id_usuario, this.seleccion.id_caso)
      .subscribe(
        (respuesta) => {},
        (error) => {
          console.error(error);
        }
      );
  }

  crearCaso() {
    this.abogado = JSON.parse(localStorage.getItem('abogado'));
    this.caso = JSON.parse(localStorage.getItem('caso'));
    let archivos = JSON.parse(localStorage.getItem('archivos'));
    let timeStamp = this.currentTimeSrv.getTimeStamp();
    let timeZone = '';
    // if (archivos) {
    //   for (let a of archivos) {
    //     const { file, name } = a;
    //     const archivoFinal = this.fileSrv.fromBase64ToFile(file, name);
    //     this.caso.archivosSeleccionados.push(archivoFinal);
    //   }
    // }
    this.caso.archivosSeleccionados = archivos;
    this.caso.profesionalAsignadoId = this.abogado.id_usuario;
    this.apiService.getCountry().subscribe(
      (res) => {
        timeZone = res.countryCode;
        this.postCaso(timeStamp, timeZone);
      },
      (error) => {
        console.error(error);
        timeZone = this.currentTimeSrv.getTimezoneHours();
        this.postCaso(timeStamp, timeZone);
      }
    );
  }

  postCaso(timeStamp, timeZone) {
    this.caso.profesionalAsignadoId = this.abogado.id_usuario;
    this.apiService
      .postCaso(
        this.caso.categoria.id_categoria,
        this.caso.detalles,
        this.caso.archivosSeleccionados,
        this.caso.localidad.id_localidad,
        this.caso.titulo,
        this.caso.profesionalAsignadoId,
        TiposCasoCode.LLAMADA,
        timeStamp,
        timeZone
      )
      .subscribe(
        (respuesta) => {
          this.seleccion.id_caso = respuesta.id_caso;
          this.aceptarAbogado;
        },
        (error) => {
          console.error(error);
          if (error.status === 409) {
            alert('Error de registro');
          } else {
            alert('Error');
          }
        }
      );
  }

  navegar(destino) {
    switch (destino) {
      case 1:
        this._location.back();
        break;
      case 2:
        this.seleccion.nombre = this.nombre;
        this.seleccion.apellido = this.apellido;
        this.seleccion.titulo = this.titulo;
        this.seleccion.caso = this.caso;
        if (this.titulo == 'Agendar cita') {
          this.router.navigateByUrl('/resumen', { state: this.seleccion });
        }
        if (this.titulo == 'Llamada express') {
          this.router.navigateByUrl('/resumen', { state: this.seleccion });
        }

        if (this.titulo == 'Detalle del caso') {
          this.router.navigateByUrl('/seleccionado', { state: this.seleccion });
        }

        break;
      case 3:
        this.router.navigate(['/casos-activos/3']);
        break;
      default:
        break;
    }
  }
}
