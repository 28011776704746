import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  Inject,
} from '@angular/core';
import { FormControl, FormGroup, Validators, FormControlStatus } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api/api.service';
import { IFormPaisConfig, Pais, PaisConfig, PAISCONFIG_KEY_CUIT, REGEX_PLANTILLA, REGEX_TEMPLATE } from 'src/app/api/pais';
import { Provincia } from 'src/app/api/Provincia';
import { UpdateRangesService } from 'src/app/services/agenda/update-ranges.service';
import { LogOutService } from 'src/app/services/logOut/log-out.service';
import { MatAccordion } from '@angular/material/expansion';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import {
  JurisdiccionPaises,
  JurisdiccionPaisesInput,
} from 'src/app/api/jurisdiccion';
import { LocalidadPaises } from 'src/app/api/localidad';
import { MatCheckbox } from '@angular/material/checkbox';
import { Observable } from 'rxjs';
import { MatriculaConfig } from 'src/app/api/matriculaConfig';
@Component({
  selector: 'app-paises',
  templateUrl: './paises.component.html',
  styleUrls: ['./paises.component.css'],
})
export class PaisesComponent implements OnInit {
  @ViewChild('codPais') codPais: ElementRef;
  @ViewChild('nomPais') nomPais: ElementRef;
  @ViewChild('adminPais') adminPais: MatCheckbox;
  @ViewChild('nomJur') nomJur: ElementRef;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('fileImportInput', { static: false }) fileImportInput: any;
  @ViewChild('fileImportInputLoc', { static: false }) fileImportInputLoc: any;
  @ViewChild('nomLoc') nomLoc: ElementRef;
  constructor(
    private logOut: LogOutService,
    private _snackBar: MatSnackBar,
    private apiService: ApiService,
    private updateRangesService: UpdateRangesService,
    public translate: TranslateService,
    private http: HttpClient,
    private ngxCsvParser: NgxCsvParser
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }
  displayedColumns: string[] = ['countryId', 'name'];
  PaisesData: any;
  paisConfig: PaisConfig = null;
  cargarLista = true;
  paises: Pais[];
  pais: Pais;
  provincias: Provincia[];
  jurisdiccionInput: JurisdiccionPaisesInput;
  jurisdiccionesInput: JurisdiccionPaisesInput[];
  jurisdiccion: JurisdiccionPaises;
  jurisdicciones: JurisdiccionPaises[];
  localidad: LocalidadPaises;
  localidades: LocalidadPaises[];
  matriculaConfig:MatriculaConfig;
  data: string;
  isDisabled = true;
  fileName: string;
  csvRecords: any[] = [];
  header = false;
  numPaisSeleccionado: number;
  numJurSeleccionado: number;

  formCuit: FormGroup;

  templateRegExs =  REGEX_PLANTILLA;

  ngOnInit(): void {
    this.getPaises();
    //this.getProvincias(dato);

    this.formCuit = new FormGroup<IFormPaisConfig>({
      countryConfigId: new FormControl<number>(0),
      countryId: new FormControl<number>(0),
      countryName: new FormControl(''),
      bobligatorio: new FormControl<boolean>(false),
	    bvisible: new FormControl<boolean>(false),
      sclave: new FormControl(''),
      sdesc: new FormControl('', [Validators.required]),
      svalor01: new FormControl('', [Validators.required]), // Digitos
      svalor02: new FormControl('', [Validators.required]), // RegEx
      svalordesc: new FormControl('', ), // RegEx
    });
  }
  switchLang(lang: string) {
    this.translate.use(lang);
  }

  onFileSelected(event) {}

  fileChangeListener($event: any): void {
    const files = $event.srcElement.files;
    const actualBtn = document.getElementById('file-upload');

    const fileChosen = document.getElementById('file-chosen');
    actualBtn.addEventListener('change', function () {
      fileChosen.textContent = files[0].name;
    });

    // Parse the file you want to select for the operation along with the configuration
    this.ngxCsvParser
      .parse(files[0], { header: this.header, delimiter: ';' })
      .pipe()
      .subscribe(
        (result: Array<any>) => {
          console.log('Result', result);
          this.csvRecords = result;
          let jsonObject = [];
          result.forEach(
            (item) =>
              jsonObject.push(
                JSON.stringify({
                  countryId: Number.parseInt(item[0]),
                  nombre: item[1],
                })
              )
            // jsonObject.push(
            //   '{"countryId":' + item[0] + ' , "name":"' + item[1] + '"}'
            // )
          );
          console.log(jsonObject);

          let json = jsonObject;
          console.log(result);
          console.log(json);
          this.apiService
            .postJurisdicciones(json)
            .subscribe((json) => (json = json));
        },
        (error: NgxCSVParserError) => {
          console.log('Error', error);
          actualBtn.addEventListener('change', function () {
            fileChosen.textContent = 'Se debe ingresar un .CSV';
          });
        }
      );
  }

  fileChangeListenerLoc($event: any): void {
    const files = $event.srcElement.files;
    const actualBtn = document.getElementById('file-uploadLoc');

    const fileChosen = document.getElementById('file-chosenLoc');
    actualBtn.addEventListener('change', function () {
      fileChosen.textContent = files[0].name;
    });

    // Parse the file you want to select for the operation along with the configuration
    this.ngxCsvParser
      .parse(files[0], { header: this.header, delimiter: ';' })
      .pipe()
      .subscribe(
        (result: Array<any>) => {
          console.log('Result', result);
          this.csvRecords = result;
          let jsonObject = [];
          result.forEach(
            (item) =>
              jsonObject.push(
                JSON.stringify({
                  jurisdiccionId: Number.parseInt(item[0]),
                  nombre: item[1],
                })
              )
            // jsonObject.push(
            //   '{"countryId":' + item[0] + ' , "name":"' + item[1] + '"}'
            // )
          );
          console.log(jsonObject);

          let json = jsonObject;
          console.log(result);
          console.log(json);
          this.apiService
            .postLocalidades(json)
            .subscribe((json) => (json = json));
        },
        (error: NgxCSVParserError) => {
          console.log('Error', error);
          actualBtn.addEventListener('change', function () {
            fileChosen.textContent = 'Se debe ingresar un .CSV';
          });
        }
      );
  }

  getPaises() {
    this.apiService.getPaises().subscribe((paises) => (this.paises = paises));
  }



  onSelected(SelectedItem: number) {
    console.log('item seleccionado : ' + SelectedItem);
    this.apiService
      .getJurisdiccionPaises(SelectedItem)
      .subscribe(
        (jurisdiccionesInput) =>
          (this.jurisdiccionesInput = jurisdiccionesInput)
      );
    
    this.apiService.getJurisdiccionPaisAdmin(SelectedItem).subscribe({
      next: (result: boolean) => {
        this.adminPais.checked = result;
        console.log(result);
      }
    });

    this.apiService
      .getMatriculaConfig(SelectedItem)
      .subscribe(
        (matriculaConfig) =>{
          if(matriculaConfig==null){
            this.matriculaConfig = new MatriculaConfig();
            this.matriculaConfig.countryId=SelectedItem;
          }
          else{
            this.matriculaConfig = matriculaConfig;
          }
          
        }
          
      );
    
    this.apiService.getPaisConfig(SelectedItem, PAISCONFIG_KEY_CUIT).subscribe({
      next: (paisConfig: PaisConfig) => {
        this.doPaisConfigLoad(paisConfig);
      },
      error: (err) => {
        this.doPaisConfigLoad({
          countryConfigId: 0,
          countryId: 0,
          countryName: '',
          bobligatorio: false,
          bvisible: false,
          sclave: '',
          sdesc: '',
          svalor01: '',
          svalor02: '',
          svalordesc: ''
        });
        console.error(err);
      }
    });
    // .getProvincias(SelectedItem)
    // .subscribe((provincias) => (this.provincias = provincias));
    this.isDisabled = false;
  }

  doPaisConfigLoad(paisConfig: PaisConfig): void {
    this.paisConfig = paisConfig;
    this.formCuit.setValue(paisConfig);
  }

  doPaisConfigVisibleOnChange(): void {
    if (!this.formCuit.getRawValue().bvisible) {
      this.formCuit.patchValue({
        bobligatorio: false
      });
    }
  }

  doPaisConfigRegEx(code: string): void {
    this.templateRegExs.some((regEx: REGEX_TEMPLATE) => {
      if (regEx.desc == code) {
        this.formCuit.patchValue({
          svalor01: regEx.regexVal,
          svalor02: regEx.regexInput,
          svalordesc: regEx.desc
        });
        return true;
      }
    });
  }

  doPaisConfigSave(): void {
    if (!this.paisConfig)
      return;
    
    if (this.formCuit.status !== "VALID")
      return;

    this.apiService.doPaisConfigSave(this.paisConfig.countryId, this.formCuit.getRawValue()).subscribe({
      next: (paisConfig: PaisConfig) => {
        this.doPaisConfigLoad(paisConfig);
      },
      error: (err) => {
        this.openSnackBar(
          'No fue posible obtener la información del perfil. Por favor, intente nuevamente más tarde.'
        );
      }
    });
  }

  saveMatriculaConfig(){

    if(this.matriculaConfig!=null && this.matriculaConfig.id_matriculaconfig!=null && this.matriculaConfig.id_matriculaconfig>0){
      this.apiService
      .putMatriculaConfig(this.matriculaConfig)
      .subscribe(
        (matriculaConfig) =>
          (this.matriculaConfig = matriculaConfig)
      );
    }
    else{
      this.apiService
      .postMatriculaConfig(this.matriculaConfig)
      .subscribe(
        (matriculaConfig) =>
          (this.matriculaConfig = matriculaConfig)
      );
    }

    
  }

  onChangeAdminPais(): void {
    let result: Observable<string>;
    if (this.adminPais.checked) {
      result = this.apiService.setJurisdiccionPaisAdminPut(this.numPaisSeleccionado);
    } else {
      result = this.apiService.setJurisdiccionPaisAdminDel(this.numPaisSeleccionado);
    }

    result.subscribe({
      next: (resp: string) => {
        console.log(resp);
      }
    })
    console.log(this.adminPais.checked);
  }

  isPaisConfig(): boolean {
    let pc: PaisConfig = this.paisConfig;
    return pc && pc.countryId && pc.countryId > 0;
  }

  onSelectedJur(SelectedItem: number) {
    console.log('donde estoy: onselectedJur');
    console.log('item seleccionadoJur : ' + SelectedItem);
    this.apiService
      .getLocalidadPaises(SelectedItem)
      .subscribe((localidades) => (this.localidades = localidades));
    // .getProvincias(SelectedItem)
    // .subscribe((provincias) => (this.provincias = provincias));

    this.isDisabled = false;
  }

  // onSelectedLoc(SelectedItem: number){
  //   console.log('item seleccionado: ' + SelectedItem);
  //   this.apiService.getLocalidadPaises(SelectedItem).subscribe((loc))
  // }

  cerrarSesion() {
    this.logOut.cerrarSesion();
  }

  onClick() {
    let pais: Pais;
    pais = {
      countryId: this.codPais.nativeElement.value,
      name: this.nomPais.nativeElement.value,
    };
    // pais.countryId = this.codPais.nativeElement.value;
    // pais.name = this.nomPais.nativeElement.value;
    this.apiService.postPais(pais).subscribe((pais) => (pais = pais));
    console.log(this.codPais.nativeElement.value);
    console.log(this.nomPais.nativeElement.value);
    console.log('NOMBRE:  ', pais.name);
    console.log('CODIGO:  ', pais.countryId);
  }

  onClickJur() {
    let jurisdiccion: JurisdiccionPaises;
    jurisdiccion = {
      nombre: this.nomJur.nativeElement.value,
      countryId: this.selectCod(this.numPaisSeleccionado),
    };
    this.apiService
      .postJurisdiccionPais(jurisdiccion)
      .subscribe((jurisdiccion) => (jurisdiccion = jurisdiccion));
  }

  selectCod(SelectedItem: number) {
    this.numPaisSeleccionado = SelectedItem;
    return this.numPaisSeleccionado;
  }

  onClickLoc() {
    let localidad: LocalidadPaises;
    localidad = {
      nombre: this.nomLoc.nativeElement.value,
      jurisdiccionId: this.selectCodJur(this.numJurSeleccionado),
    };
    this.apiService
      .postLocalidadPais(localidad, this.selectCodJur(this.numJurSeleccionado))
      .subscribe((localidad) => (localidad = localidad));
  }

  selectCodJur(SelectedItem: number) {
    this.numJurSeleccionado = SelectedItem;
    return this.numJurSeleccionado;
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 5000 });
  }

}
