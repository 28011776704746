import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InicioComponent } from '../inicio/inicio.component';
import { AuthGuard } from '../../../auth.guard';
import { Role } from '../../../enums/role';
import { PaisesComponent } from '../paises/paises.component';
import { NavbarAdminComponent } from '../navbar-admin/navbar-admin.component';
import { DataComponent } from '../datos/datos.component';
import { CertificacionComponent } from '../certificacion/certificacion.component';

const routes: Routes = [
  {
    path: 'admin',
    component: InicioComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },

  {
    path: 'paises',
    component: PaisesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'data-admin',
    component: DataComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
  {
    path: 'certificacion',
    component: CertificacionComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin] },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class navbarAdminRouting {}
