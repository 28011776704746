import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../../modules/material.module';

import { ClienteCasosNuevosModule } from '../nuevo/modules/clienteCasosNuevos.modules';
import { TranslateModule } from '@ngx-translate/core';
//import { ActivosComponent } from '../misCasos/activos/activos.component';
// import { DialogComponent } from '../nuevo/especialidad/dialog.component';
import { DetallesComponent } from '../misCasos/detalles/detalles.component';


@NgModule({
  declarations: [
    //ActivosComponent,
    // DialogComponent,
    //DetallesComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    ClienteCasosNuevosModule,
    MaterialModule,
    TranslateModule
  ],
  // entryComponents:[DialogComponent],
  providers: [],
  bootstrap: []
})
export class ClienteCasosModule { }
