import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../../../modules/material.module';
import { EntidadesComponent } from './entidades.component';
import { NavbarAdminComponent } from '../navbar-admin/navbar-admin.component';
import { EntidadesRoutingModule } from './entidades-routing.module';
import { InicioComponent } from '../inicio/inicio.component';
import { InicioModule } from '../inicio/inicio.module';
import { NavbarAdminmodule } from '../navbar-admin/navbar-admin.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [EntidadesComponent],
  imports: [
    BrowserModule,
    MaterialModule,
    ReactiveFormsModule,
    EntidadesRoutingModule,
    NavbarAdminmodule,
    MatExpansionModule,
    TranslateModule,
  ],
  exports: [],
  providers: [],
  bootstrap: [],
})
export class EntidadesModule {}
