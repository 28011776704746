import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { ApiService } from '../../../api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vinculacion',
  templateUrl: './vinculacion.component.html',
  styleUrls: ['./vinculacion.component.css'],
})
export class VinculacionComponent implements OnInit {
  code: string;
  exito = false;
  texto = 'Tu cuenta se está vinculando con Mercado Pago';
  constructor(
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params?.merchantIdInPayPal) {
        this.code = params.merchantIdInPayPal;
  
        this.apiService.putLinkAsociacionPaypal(this.code).subscribe(
          (respuesta) => {
            this.texto = 'Tu cuenta se vinculó con éxito a Paypal';
            this.exito = true;
          },
          (error) => {
            console.error(error);
            this.exito = false;
            this.texto = 'Falló la vinculación de tu cuenta con Paypal';
          }
        );
      } else {
        this.code = params.code;
  
        this.apiService.putLinkAsociacion(this.code).subscribe(
          (respuesta) => {
            this.texto = 'Tu cuenta se vinculó con éxito a Mercado Pago';
            this.exito = true;
          },
          (error) => {
            console.error(error);
            this.exito = false;
            this.texto = 'Falló la vinculación de tu cuenta con Mercado Pago';
          }
        );
      }

    });
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }
}
