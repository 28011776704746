<!-- <div class="casoFondo"> -->
<div class="separadorTop">
  <mat-card class="cardPerfil">
    <div class="separador50"></div>
    <div class="imagenCuadrada">
      <img class="sello_sign" src="assets/sello_sign.png" *ngIf="abogado.datosProfesionales[0].certificacion!=null && abogado.datosProfesionales[0].certificacion.status_sign=='CO'">
      
      <img
        *ngIf="tieneFoto"
        class="rounded"
        [src]="avatarUrl | secure | async"
      />
      <mat-icon
        *ngIf="!tieneFoto"
        svgIcon="perfil"
        class="imagenDetalles"
      ></mat-icon>
    </div>
    <div class="vistaPreviaFoto">
      <mat-card-title class="izq"
        >{{ abogado.nombre }} {{ abogado.apellido }}</mat-card-title>
      <br />
      <ng-template [ngIf]="countryId != 52 || rolId != 3">
        <p class="izq">
          <mat-icon class="blanco">place</mat-icon> {{ abogado.provincia }},
          {{ abogado.ciudad }} ds
        </p>
        <p class="izq" *ngIf="!mostrar">
          {{ abogado.datosProfesionales[0].especialidadesAbogados[0].nombre }}
        </p>
      </ng-template>
      
      <p class="izq" *ngIf="mostrar">{{ especialidadParaMostrar }}</p>
      <p class="izq">
        <mat-icon>{{ stars }}</mat-icon>
      </p>
      <p class="izq">
        {{ abogado.cantOpiniones }} {{ "vistaPrevia.opiniones" | translate }}
      </p>
      <br />
      <button
        *ngIf="mostrarBoton"
        mat-button
        class="verMas2"
        (click)="navegar()"
      >
        {{ "vistaPrevia.info" | translate }}
      </button>
    </div>
  </mat-card>
</div>
<!-- </div> -->
