import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TiposCasoCode } from 'src/app/enums/TiposCasoCode';
import { FilesService } from 'src/app/utils/files.service';

import { ApiService } from '../../../../../api/api.service';
import { Caso } from '../../../../../api/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrentTimeService } from '../../../../../utils/currentTime/current-time.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-revisar',
  templateUrl: './revisar.component.html',
  styleUrls: ['./revisar.component.css'],
})
export class RevisarComponent implements OnInit {
  caso = {
    titulo: '',
    detalles: '',
    jurisdiccion: {
      nombre: '',
      id_jurisdiccion: 0,
    },
    localidad: {
      nombre: '',
      id_localidad: 0,
    },
    especialidad: {
      nombre: '',
      id_especialidad: 0,
    },
    categoria: {
      titulo: '',
      id_categoria: 0,
      descripcion: '',
    },
    archivosSeleccionados: [],
  };

  edit:boolean=false
  desactivarSubmit = false;
  constructor(
    private _snackBar: MatSnackBar,
    private apiService: ApiService,
    private router: Router,
    private fileSrv: FilesService,
    private currentTimeSrv: CurrentTimeService,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }

  ngOnInit(): void {
    let caso = JSON.parse(localStorage.getItem('caso-edit'));
    if(caso!=null){this.edit=true}


    this.caso.titulo = history.state.titulo
      ? history.state.titulo
      : this.caso.titulo;
    this.caso.detalles = history.state.detalles
      ? history.state.detalles
      : this.caso.detalles;
    this.caso.jurisdiccion = history.state.jurisdiccion
      ? history.state.jurisdiccion
      : this.caso.jurisdiccion;
    this.caso.localidad = history.state.localidad
      ? history.state.localidad
      : this.caso.localidad;
    this.caso.especialidad = history.state.especialidad
      ? history.state.especialidad
      : this.caso.especialidad;
    this.caso.categoria = history.state.categoria
      ? history.state.categoria
      : this.caso.categoria;
    this.caso.archivosSeleccionados = history.state.archivosSeleccionados;
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  atras() {
    window.history.back();
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 5000 });
  }

  onSubmit() {
    this.desactivarSubmit = true;
    let archivos = JSON.parse(localStorage.getItem('archivos'));
    this.caso.archivosSeleccionados = archivos;
    let timeStamp = this.currentTimeSrv.getTimeStamp();
    let timeZone = '';
    this.apiService.getCountry().subscribe(
      (res) => {
        timeZone = res.countryCode;
        this.postCaso(timeStamp, timeZone);
      },
      (error) => {
        console.error(error);
        timeZone = this.currentTimeSrv.getTimezoneHours();
        this.postCaso(timeStamp, timeZone);
      }
    );
  }

  postCaso(timeStamp, timeZone) {
    let caso = JSON.parse(localStorage.getItem('caso-edit'));
    if(caso!=null){
      this.apiService
      .putCasoMail(
        caso.id_caso,
        this.caso.categoria.id_categoria,
        this.caso.detalles,
        this.caso.archivosSeleccionados,
        this.caso.localidad.id_localidad,
        this.caso.titulo,
        TiposCasoCode.EMAIL,
        timeStamp,
        timeZone
      )
      .subscribe(
        () => {
          this.router.navigate(['/finalizada-nuevo']);
          this.desactivarSubmit = false;
          localStorage.removeItem("caso-edit");
        },
        (error) => {
          this.desactivarSubmit = false;
          console.error(error);
          this.openSnackBar('No fue posible publicar tu caso');
        }
      );

    }
    else{

      this.apiService
      .postCasoMail(
        this.caso.categoria.id_categoria,
        this.caso.detalles,
        this.caso.archivosSeleccionados,
        this.caso.localidad.id_localidad,
        this.caso.titulo,
        TiposCasoCode.EMAIL,
        timeStamp,
        timeZone
      )
      .subscribe(
        () => {
          this.router.navigate(['/finalizada-nuevo']);
          this.desactivarSubmit = false;
        },
        (error) => {
          this.desactivarSubmit = false;
          console.error(error);
          this.openSnackBar('No fue posible publicar tu caso');
        }
      );

    }
    
  }
}
