import { Component, OnInit, Inject } from '@angular/core';
import { Planes } from '../../../../api/planes';
import {
  Especialidad,
  JurisdiccionCheckBox,
  Perfil,
} from '../../../../api/user';
import { PlanService } from '../../../../services/plan/plan.service';
import {
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ApiService } from '../../../../api/api.service';
import { RoleNumber } from '../../../../enums/role';

@Component({
  selector: 'app-popup-downgrade',
  templateUrl: './popup-downgrade.component.html',
  styleUrls: ['./popup-downgrade.component.css'],
})
export class PopupDowngradeComponent implements OnInit {
  perfil: Perfil;
  plan: Planes;
  labelPosition = 'before';
  desactivarBoton: boolean = true;
  desactivarBotonEspecialidades: boolean = true;
  cantidadJurisdiccionesSeleccionadas: number = 0;
  cantidadEspecialidadesSeleccionadas: number = 0;
  jurisdicciones: JurisdiccionCheckBox[] = [];
  especialidades: Especialidad[] = [];
  clase: string = 'scrooleableHidden';
  especialidadesExistentes: Especialidad[] = [];

  constructor(
    private planService: PlanService,
    public dialog: MatDialog,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.getMiPerfil();
    this.plan = this.data.plan;
    console.log(this.plan)
  }

  changeClass(change: boolean) {
    if (change) {
      this.clase = 'scrooleable';
    } else {
      this.clase = 'scrooleableHidden';
    }
  }

  getMiPerfil() {
    this.apiService.getPerfil().subscribe(
      (perfil) => {
        this.perfil = perfil;
        if (this.data.downgrade) {
          this.crearEspecialidades();
          this.crearJurisdicciones();
        } else {
          this.getEspecialidades();
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  getEspecialidades() {
    this.apiService.getEspecialidadesByPais(RoleNumber.Abogado, +this.perfil.pais).subscribe(
      (especialidades) => {
        this.especialidades = especialidades.map((especialidad) => {
          const nombreReemplazado = especialidad.nombre.replace('/', ' ');
          let selected: boolean = false;
          this.perfil.datosProfesionales[0].especialidadesAbogados.forEach(
            (especialidadSeleccionada) => {
              if (especialidad.nombre == especialidadSeleccionada.nombre) {
                selected = true;
              }
            }
          );
          return {
            ...especialidad,
            nombre: nombreReemplazado,
            selected: selected,
          };
        });
        this.crearEspecialidades();
        this.cambiarEspecialidad(null);
      },
      (error) => {}
    );
  }

  crearEspecialidades() {
    this.perfil.datosProfesionales[0].especialidadesAbogados.forEach(
      (especialidad) => {
        let selected: boolean = false;
        if (!this.data.downgrade) {
          selected = true;
        }
        let aux = {
          nombre: especialidad.nombre,
          id_especialidad: especialidad.id_especialidad,
          selected: selected,
          nombreCliente: especialidad.nombreCliente,
        };
        if (this.data.downgrade) {
          this.especialidades.push(aux);
        } else {
          this.especialidadesExistentes.push(aux);
        }
      }
    );
  }

  cambiarEspecialidad(especialidad: Especialidad) {
    this.especialidades.forEach((element) => {
      if (especialidad && element.id_especialidad === especialidad.id_especialidad) {
        element.selected = !element.selected;
      }
    });
    this.cantidadEspecialidadesSeleccionadas = 0;
    this.especialidades.forEach((element) => {
      if (element.selected == true) {
        this.cantidadEspecialidadesSeleccionadas++;
      }
    });
    this.botonDisabled();
  }

  crearJurisdicciones() {
    let contador = 0;
    this.perfil.datosProfesionales.forEach((dato) => {
      let selected: boolean = false;
      // if(contador == 0) {
      //   selected = true;
      // }
      contador++;
      let aux = {
        nombre: dato.localidad.jurisdiccion.nombre,
        id_jurisdiccion: dato.id_datos_profesionales,
        selected: selected,
        tomo: dato.tomo,
        folio: dato.folio,
      };
      if (dato.estado != 'D') {
        this.jurisdicciones.push(aux);
      }
    });
  }

  cambiarJurisdicciones(jurisdiccion: JurisdiccionCheckBox) {
    this.jurisdicciones.forEach((element) => {
      if (element.id_jurisdiccion === jurisdiccion.id_jurisdiccion) {
        element.selected = !element.selected;
      }
    });
    this.cantidadJurisdiccionesSeleccionadas = 0;
    this.jurisdicciones.forEach((element) => {
      if (element.selected == true) {
        this.cantidadJurisdiccionesSeleccionadas++;
      }
    });
    this.botonDisabled();
  }

  botonDisabled() {
    let jurisdiccionesValidas: boolean =
      this.cantidadJurisdiccionesSeleccionadas >= 1 &&
      this.cantidadJurisdiccionesSeleccionadas <= this.plan.jurisdicciones;
    let especialidadesValidas: boolean =
      this.cantidadEspecialidadesSeleccionadas >= 1 &&
      this.cantidadEspecialidadesSeleccionadas <= this.plan.especialidades;

    // if(this.plan.jurisdicciones >1) {
    this.desactivarBoton = !(jurisdiccionesValidas && especialidadesValidas);
    // } else {
    // this.desactivarBoton = !especialidadesValidas;
    // }
    this.desactivarBotonEspecialidades = !especialidadesValidas;
  }

  onSubmit() {
    let especialidadesAEliminar: number[] = [];
    let jurisdiccionesAEliminar: number[] = [];
    this.especialidades.map((especialidad) => {
      if (especialidad.selected) {
        especialidadesAEliminar.push(especialidad.id_especialidad);
      }
    });
    this.jurisdicciones.map((jurisdiccion) => {
      if (!jurisdiccion.selected) {
        jurisdiccionesAEliminar.push(jurisdiccion.id_jurisdiccion);
      }
    });
    sessionStorage.setItem(
      'especialidadesAEliminar',
      JSON.stringify(especialidadesAEliminar)
    );
    sessionStorage.setItem(
      'jurisdiccionesAEliminar',
      JSON.stringify(jurisdiccionesAEliminar)
    );
  }

  onSubmitEspecialidades() {
    let especialidadesAEliminar: number[] = [];
    let especialidadesSeleccionadas: Especialidad[] = [];

    this.especialidades.forEach((especialidad) => {
      if (!especialidad.selected) {
        this.especialidadesExistentes.forEach((seleccionada) => {
          if (seleccionada.nombre == especialidad.nombre) {
            seleccionada.selected = false;
            especialidadesAEliminar.push(especialidad.id_especialidad);
          }
        });
      }

      if (especialidad.selected) {
        let encontrada: boolean = false;
        this.especialidadesExistentes.forEach((seleccionada) => {
          if (seleccionada.nombre == especialidad.nombre) {
            seleccionada.selected = false;
            encontrada = true;
          }
        });
        especialidadesSeleccionadas.push(especialidad);
      }
    });
    sessionStorage.setItem(
      'especialidadesSeleccionadas',
      JSON.stringify(especialidadesSeleccionadas)
    );
    sessionStorage.setItem(
      'especialidadesAEliminar',
      JSON.stringify(especialidadesAEliminar)
    );
  }
}
