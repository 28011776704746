import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { Categoria } from '../../../../../api/user';
import { ApiService } from '../../../../../api/api.service';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IphoneScrollService } from 'src/app/services/iphone-scroll/iphone-scroll.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.css'],
})
export class CategoriaComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private iPhoneService: IphoneScrollService,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
    this.matIconRegistry.addSvgIcon(
      `Laboral`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/Laboral.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Familia`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/individuos.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Comercial y negocios`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/comercial.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Contratos`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/contratos.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Defensa del Consumidor`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/defensa.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Impuestos`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/impuestos.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Inmobiliaria y Propiedad Horizontal`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/inmobiliaria.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Otros`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/otros.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Penal`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/penal.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Sucesiones`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/sucesiones.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Jubilaciones y pensiones`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/previsional.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Digital`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/digital.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Daños y Perjuicios`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/perjuicios.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Sociedades`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/societario.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Accidentes de tránsito`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/accidentes.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Marcas y patentes`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/propiedad.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Mala Praxis y Salud`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/mala.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Violencia de género`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/discriminacion.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `ayuda`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/ayuda.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Reclamos al Estado o por Servicios Públicos`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/reclamos.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Derecho Aduanero y Comercio Exterior`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/aduanero.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Concursos y quiebras`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/quiebras.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Reclamos Temas Ambientales`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/derecho ambiental.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Trámites y Problemas Migratorios`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/derecho-migratorio.svg'
      )
    );
  }

  categoriaUnico = 'categoriaUnico';
  textoEnGridUnica = 'textoEnGridUnica';
  permitirContinuar = false;
  categorias: Categoria[];
  categoriaAnterior: any;
  masDeUno = true;
  caso = {
    titulo: '',
    detalles: '',
    jurisdiccion: {
      nombre: '',
      id_jurisdiccion: '',
      countryId: 0
    },
    localidad: {
      nombre: '',
      id_localidad: 0,
    },
    especialidad: {
      nombre: '',
      id_especialidad: 0,
    },
    categoria: {
      titulo: '',
      id_categoria: 0,
      descripcion: '',
    },
    archivosSeleccionados: [],
  };

  class = 'casoImpar';
  disableScroll() {
    this.class = this.iPhoneService.changeClass();
  }

  ngOnInit(): void {
    this.caso.titulo = history.state.titulo;
    if (this.caso.titulo == undefined) {
      this.caso = JSON.parse(localStorage.getItem('caso'));
      this.caso.detalles = this.caso.detalles;
      this.caso.jurisdiccion = this.caso.jurisdiccion;
      this.caso.localidad = this.caso.localidad;
      this.caso.especialidad = this.caso.especialidad;
    } else {
      this.caso.detalles = history.state.detalles;
      this.caso.jurisdiccion = history.state.jurisdiccion;
      this.caso.localidad = history.state.localidad;
      this.caso.especialidad = history.state.especialidad
        ? history.state.especialidad
        : 1;
    }

    this.caso.archivosSeleccionados = history.state.archivosSeleccionados;
    this.apiService
      .getCategoriasByPais(this.caso.especialidad.id_especialidad, this.caso.jurisdiccion.countryId)
      .subscribe(
        (categorias) => {
          this.categorias = categorias;
          if (categorias.length == 1) {
            this.permitirContinuar = true;
            this.caso.categoria = categorias[0];
            this.masDeUno = false;
            this.isOtros();
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
  casoForm = new UntypedFormGroup({});

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  atras() {
    localStorage.setItem('caso', JSON.stringify(this.caso));
    this.router.navigate(['/especialidad-nuevo']);
  }

  guardar(categoria) {
    if (!this.iPhoneService.isIos) {
      categoria.selected = !categoria.selected;
    }
    this.caso.categoria = categoria;
    this.permitirContinuar = true;
    if (!this.masDeUno) {
      this.categoriaUnico = 'categoriaUnicoClickeada';
      this.textoEnGridUnica = 'textoEnGridSeleccionadaUnica';
    }
  }

  onSubmit() {
    let caso_edit=JSON.parse(localStorage.getItem("caso-edit"));
    if(caso_edit!=null && caso_edit!=undefined){
      localStorage.setItem('caso', JSON.stringify(this.caso));
      this.router.navigateByUrl('/agendadamod', { });
    }
    else{
      localStorage.setItem('caso', JSON.stringify(this.caso));
      this.router.navigateByUrl('/tipo-servicio-nuevo', { state: this.caso });
    }
    
  }

  isOtros() {
    if (this.caso.especialidad.nombre == 'Otros') {
      this.guardar(this.caso.categoria);
      this.onSubmit();
    }
  }

  down(categoria) {
    this.class = 'casoImpar';
    categoria.selected = !categoria.selected;
    if (this.categoriaAnterior) {
      this.categoriaAnterior.selected = false;
    }
    this.categoriaAnterior = categoria;
  }
}
