<form [formGroup]="casoForm" class="casoFondo">
  <mat-toolbar class="header">
    <button mat-button (click)="atras()">
      <mat-icon class="icono">chevron_left</mat-icon>
    </button>
    <span class="example-spacer"></span>
    <span>{{ "servicio.crear" | translate }}</span>
  </mat-toolbar>

  <div class="barraDeProgreso">
    <mat-toolbar class="barraDeProgreso">
      <span class="example-spacer"></span>
      <span class="desktop">{{ "servicio.descripcion" | translate }}</span>
      <span class="mobile">Elige como contactarte con <br />tu Abogado</span>
    </mat-toolbar>
    <mat-progress-bar mode="determinate" value="80"></mat-progress-bar>
  </div>

  <div class="servicio-responsivo">
    <mat-card class="correo">
      <mat-card-content>
        <img src="../../../../../../assets/message.svg" />
        <mat-card-title>{{ "servicio.correo" | translate }}</mat-card-title>
        <p>
          {{ "servicio.correoDescripcion"| translate }}<br />
          {{ "servicio.correoDescripcion1"| translate }}<br />
          {{ "servicio.correoDescripcion2"| translate}} <br />
          {{ "servicio.correoDescripcion3"| translate}}
        </p>
       <br>
        <h2>{{ "servicio.gratis" | translate }}</h2> 
        <h6 style=" margin-top: -27px; margin-bottom: -9px;">{{ "servicio.probono" | translate }}</h6>
      </mat-card-content>
      <mat-card-actions>
        <button class="boton-secundario" mat-button (click)="navegar(1)">
          {{ "servicio.revisar" | translate }}
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card class="cita">
      <mat-card-content>
        <img src="../../../../../../assets/send.svg" />
        <mat-card-title>{{ "servicio.cita" | translate }}</mat-card-title>
        <p>
          <br>
          {{ "servicio.citaDescripcion1" | translate }}<br />{{
            "servicio.citaDescripcion2" | translate
          }} 
        </p>
        <br>
        <br>
        
        <h2>
          {{ "servicio.precioCita1" | translate }} 
          {{ costPlan.ScheduleMoneda ? costPlan.ScheduleMoneda : '$' }}
          {{ costPlan.Schedule }}
          {{ "servicio.precioCita2" | translate }}
        </h2>
      </mat-card-content>
      <mat-card-actions>
        <button class="boton-secundario" mat-button (click)="navegar(2)">
          {{ "servicio.citaAgen" | translate }}
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-card class="llamada">
      <mat-card-content>
        <img src="../../../../../../assets/calling.svg" />
        <mat-card-title>{{ "servicio.llamada" | translate }}</mat-card-title>
        <br>
        <p>
          {{ "servicio.llamadaDescripcion1" | translate }} <br />
          {{ "servicio.llamadaDescripcion2" | translate }}
        </p>
        <br>
        <br>
        <h2>
          {{ "servicio.precioLlamada1" | translate }}
          {{ costPlan.CallExpressMoneda ? costPlan.CallExpressMoneda : '$' }}
          {{ costPlan.CallExpress }} {{ "servicio.precioLlamada2" | translate }}
        </h2>
      </mat-card-content>
      <mat-card-actions>
        <button class="boton-secundario" mat-button (click)="navegar(3)">
          {{ "servicio.llamadaAho" | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
  <br /><br /><br />
</form>
