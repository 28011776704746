<div class="casoFondo">
  <div class="contenedor-cards-mensaje">
    <mat-card class="mensaje">
      <img class="logo" src="../../../../assets/logo.jpg" />
      <div class="separadorCentrado">
        <h3>{{ "mercado.fallo" | translate }}</h3>
      </div>
      <button
        class="boton-footer"
        mat-button
        color="primary"
        (click)="navegar()"
      >
        {{ "mercado.volver" | translate }}
      </button>
      <router-outlet #outlet="outlet"></router-outlet>
    </mat-card>
  </div>
</div>
