<div class="casoFondo">
  <mat-toolbar class="header">
    <button mat-button (click)="navegar()">
      <mat-icon class="icono">chevron_left</mat-icon>
    </button>
    <span class="example-spacer"></span>
    <span>{{ "calificar.cali" | translate }} {{ clienteAbogado }}</span>
  </mat-toolbar>

  <div *ngIf="calificado">
    <mat-card class="mensaje">
      <div class="separadorCentrado">
        <br />
        <img class="logo" src="../../../assets/confirmar-azul.jpg" />
        <h1>{{ "calificar.registrar" | translate }}</h1>
        <button
          class="boton-continuar"
          mat-button
          color="primary"
          (click)="navegar()"
        >
          {{ "calificar.volver" | translate }}
        </button>
      </div>
    </mat-card>
  </div>

  <div class="separadorCentradoFondo">
    <div *ngIf="!calificado">
      <mat-card class="card">
        <h3 *ngIf="!calificado">{{ "calificar.estas" | translate }}</h3>
        <h3 *ngIf="calificado">{{ "calificar.calificaste" | translate }}</h3>
        <div class="imagenCuadrada">
          <mat-icon
            *ngIf="!avatarUrl"
            svgIcon="perfil"
            class="imagenDetalles"
          ></mat-icon>
          <img
            *ngIf="avatarUrl"
            class="rounded"
            [src]="avatarUrl | secure | async"
          />
        </div>
        <br />
        <h1>{{ calificando.nombre }} {{ calificando.apellido }}</h1>
        <mat-card-subtitle *ngIf="calificando.categoriaAbogado"
          >{{ especialidadCaso }}
        </mat-card-subtitle>
        <h3>{{ "calificar.caso" | translate }}</h3>
        <h1>{{ caso.titulo }}</h1>
        <mat-card-subtitle>{{ caso.categoria }}</mat-card-subtitle>
      </mat-card>
      <form [formGroup]="opinionForm">
        <mat-card class="card">
          <h3>{{ "calificar.tuCali" | translate }}</h3>

          <div class="separadorCentrado">
            <mat-card-title class="estrellas">{{
              opinion.puntuacion
            }}</mat-card-title>
          </div>
          <div class="estrellasDispersas">
            <mat-icon class="estrella" (click)="puntuar(1)">{{
              estrella[1]
            }}</mat-icon>
            <mat-icon class="estrella" (click)="puntuar(2)">{{
              estrella[2]
            }}</mat-icon>
            <mat-icon class="estrella" (click)="puntuar(3)">{{
              estrella[3]
            }}</mat-icon>
            <mat-icon class="estrella" (click)="puntuar(4)">{{
              estrella[4]
            }}</mat-icon>
            <mat-icon class="estrella" (click)="puntuar(5)">{{
              estrella[5]
            }}</mat-icon>
          </div>
          <mat-label class="textoAyuda">{{ ayuda }}</mat-label>
          <mat-form-field appearance="outline" class="input-fullwidth">
            <mat-label>{{ "calificar.tuOpi" | translate }}</mat-label>
            <textarea
              style="overflow: auto; resize: none"
              matInput
              placeholder="¿Cómo fue tu experiencia con el abogado que seleccionaste?"
              formControlName="comentario"
            ></textarea>
          </mat-form-field>
        </mat-card>
        <mat-card class="card">
          <button
            *ngIf="!clickeada"
            class="boton-continuar"
            [disabled]="!clickeada"
            mat-stroked-button
            color="primary"
            (click)="onSubmit()"
          >
            {{ "calificar.confirmar" | translate }}
          </button>
          <button
            *ngIf="clickeada"
            class="boton-primario"
            mat-raised-button
            color="primary"
            (click)="onSubmit()"
          >
            {{ "calificar.confirmar" | translate }}
          </button>
        </mat-card>
      </form>
    </div>
  </div>
</div>
