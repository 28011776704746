import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FormatDateService {

  constructor(private datePipe : DatePipe) { }

  converDateToUTC(timestamp: number) {
    var dateLocal = new Date(timestamp);
    var dateUTC = new Date(dateLocal.getUTCFullYear(), dateLocal.getUTCMonth(), dateLocal.getUTCDate(), (dateLocal.getUTCHours()-3), dateLocal.getUTCMinutes()).getTime();
    return dateUTC;
  }

  convertDateToUTCByAttribute(element: any[], attribute: string) {
    element.forEach(function (item) {
      item[attribute] = this.converDateToUTC(item[attribute]);
    }, this);
    return element;
  }

  converDateToLocal(timestamp: number) {
    var dateLocal = new Date(timestamp);
    dateLocal.setHours(24);
    var dateLocalConverted = new Date(dateLocal.getFullYear(), dateLocal.getMonth(), dateLocal.getDate()).getTime();
    return dateLocalConverted;
  }

  convertDateToLocalByAttribute(element: any[], attribute: string) {
    element.forEach(function (item) {
      item[attribute] = this.converDateToLocal(item[attribute]);
    }, this);
    return element;
  }

  convertDateToFormatSpecifc(format : string, date: Date, country : string)
  {
    return this.datePipe.transform(date, format, country);
  }
}
