import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginService } from 'src/app/services/login.service';
import { Source } from '../../../enums/redesSociales';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/util.service';
declare var gtag;
@Component({
  selector: 'app-redes',
  templateUrl: './redes.component.html',
  styleUrls: ['./redes.component.css'],
})
export class RedesComponent implements OnInit {
  rol;
  titulo = '';
  mostrarTerminos = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private loginSrv: LoginService,
    private serviceUtil: UtilService,
    public translate: TranslateService
  ) {
    this.matIconRegistry.addSvgIcon(
      `google`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/google.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `facebook`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/facebook.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `linkedin`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/linkedin.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `correo`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/correo.svg'
      )
    );

    this.serviceUtil.doPreparePageTranslate(translate);

    let rolSelected: number = 0;
    route.queryParams.subscribe(params => {
      if (params["rol"]) {
        rolSelected = params["rol"] * 1;
      }
      if (params["company"]) {
        this.loginSrv.getCompanyId(params["company"]).subscribe({
          next: (response) => {
            sessionStorage.companyId = response;
          }
        });
      } else
        sessionStorage.removeItem("companyId")
    });

    if (rolSelected > 0)
      this.rol = rolSelected
    else
      this.rol = parseInt(localStorage.getItem('rol'), 10);
    if (this.rol === 3) {
      this.mostrarTerminos = false;
      this.translate.get('redes.encuentra').subscribe((translate: string) => {
        this.titulo = this.translate.instant('redes.encuentra');
      });
      //this.titulo = 'Encuentra a tu abogado ideal';
    } else {
      this.translate.get('redes.abogado').subscribe((translate: string) => {
        this.titulo = this.translate.instant('redes.abogado');
      });
      //this.titulo = 'Encuentra nuevos clientes';
    }
  }

  ngOnInit(): void {}

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  trackNetworkRegisterFacebook(){
    gtag('event', 'registro', {
      'send_to':environment.measurement_id,
      'method': 'register_facebook'
    });
  }

  trackNetworkRegisterGoogle(){
    gtag('event', 'registro', {
      'send_to':environment.measurement_id,
      'method': 'register_google'
    });
  }

  trackNetworkRegisterLinkedin(){
    gtag('event', 'registro', {
      'send_to':environment.measurement_id,
      'method': 'register_linkedin'
    });
    
  }

  navegarRegistro() {
    if (this.rol == 3) {
      this.router.navigate(['/registro-cliente']);
    } else {
      this.router.navigate(['/registro-abogado']);
    }
  }

  obtenerCredencialesLinkedin() {
    this.loginSrv
      .getRedesCredentialsUrl('L', this.rol == 3 ? 'C' : 'A')
      .subscribe(
        (res) => {
          this.trackNetworkRegisterLinkedin();
          window.location.href = res.url;
        },
        (error) => {
          this.loginSrv.handleError(error, Source.Register);
        }
      );
  }

  obtenerCredencialesGoogle() {
    this.loginSrv
      .getRedesCredentialsUrl('G', this.rol == 3 ? 'C' : 'A')
      .subscribe(
        (res) => {
          this.trackNetworkRegisterGoogle();
          window.location.href = res.url;
        },
        (error) => {
          this.loginSrv.handleError(error, Source.Register);
        }
      );
  }

  obtenerCredencialesFacebook() {
    this.loginSrv
      .getRedesCredentialsUrl('F', this.rol == 3 ? 'C' : 'A')
      .subscribe(
        (res) => {
          this.trackNetworkRegisterFacebook();
          window.location.href = res.url;
        },
        (error) => {
          this.loginSrv.handleError(error, Source.Register);
        }
      );
  }
}
