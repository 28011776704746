import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CrearComponent } from '../crear/crear.component';
import { CategoriaComponent } from '../categoria/categoria.component';
import { TipoServicioComponent } from '../tipo-servicio/tipo-servicio.component';
import { RevisarComponent } from '../revisar/revisar.component';
import { FinalizadaComponent } from '../finalizada/finalizada.component';
import { ActivosComponent } from '../../misCasos/activos/activos.component';
import { DetallesComponent } from '../../misCasos/detalles/detalles.component';
import { EspecialidadComponent } from '../especialidad/especialidad.component';
import { RangoComponent } from '../agenda/rango/rango.component';
import { ResumenComponent} from '../resumen/resumen.component';
import { AgendadaComponent } from '../agenda/agendada/agendada.component';

import { AuthGuard } from '../../../../../auth.guard'
import { Role } from '../../../../../enums/role';

const routes: Routes = [
  {
    path: 'crear-nuevo',
    component: CrearComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Cliente] }
  },
  {
    path: 'edit-caso',
    component: CrearComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Cliente] }
  },
  {
    path: 'casos-activos/:rolId',
    component: ActivosComponent
  },
  {
    path: 'detalles',
    component: DetallesComponent
  },
  {
    path: 'especialidad-nuevo',
    component: EspecialidadComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Cliente] }
  },
  {
    path: 'categoria-nuevo',
    component: CategoriaComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Cliente] }
  },
  {
    path: 'tipo-servicio-nuevo',
    component: TipoServicioComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Cliente] }
  },
  {
    path: 'revisar-nuevo',
    component: RevisarComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Cliente] }
  },
  {
    path: 'finalizada-nuevo',
    component: FinalizadaComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Cliente] }
  },
  {
    path: 'rango',
    component: RangoComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Cliente] }
  },
  {
    path: 'resumen',
    component: ResumenComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Cliente] }
  },
  {
    path: 'agendada',
    component: AgendadaComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Cliente] }
  },
  {
    path: 'agendadamod',
    component: AgendadaComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Cliente] }
  },
  {
    path: 'seleccionado',
    component: AgendadaComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Cliente] }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ClienteCasosNuevosRoutingModule { }