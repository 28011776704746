import { Component, OnInit, Inject, Input } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'dialogvalidar',
    templateUrl: 'dialogvalidar.html',
    styleUrls: ['./dialogvalidar.component.css']
  })

export class DialogValidarComponent {


  constructor(public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer) {
      this.matIconRegistry.addSvgIcon(
        `mercadopago`,
        this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/mercadopago.svg')
      );
  }

  }