import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreacionExitosaAbogadoComponent} from '../creacion-exitosa-abogado/creacion-exitosa-abogado.component';
import { CuentaValidadaAbogadoComponent } from '../cuenta-validada-abogado/cuenta-validada-abogado.component';
import { RegistroAbogadoComponent } from '../registro/registro.component';

const routes: Routes = [
  {
    path: 'exito-abogado',
    component: CreacionExitosaAbogadoComponent,
  },
  {
    path: 'validada-abogado/:id/:lang',
    component: CuentaValidadaAbogadoComponent
  },
  {
    path: 'registro-abogado',
    component: RegistroAbogadoComponent,
  },
  {
    path: 'registro-abogado/:tipo',
    component: RegistroAbogadoComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AbogadoRegistroRoutingModule { }