import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NewUser } from '../../api/user';
import { ApiService } from '../../api/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginService } from 'src/app/services/login.service';
import { Source } from '../../enums/redesSociales';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogValidarComponent } from './dialog-vincular/dialogvalidar.component';
import { TranslateService } from '@ngx-translate/core';
import { MensajeReenvioComponent } from './mensaje-reenvio/mensaje-reenvio.component';
import { environment } from 'src/environments/environment.prod'
declare var gtag;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public usuario = { rol: 0 };
  user: NewUser;
  flag: boolean = false;
  language:String="";
  constructor(
    private apiService: ApiService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private loginSrv: LoginService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);

    //localStorage.language = 'es';
    if (sessionStorage.getItem('language') == undefined) {
      var ln = window.navigator.language;
      sessionStorage.setItem('language','es-AR');
      console.log('1ro: ' + ln);
      if (ln != 'es-AR' && ln != 'it-IT') {
        sessionStorage.language = 'es-AR';
        translate.setDefaultLang('es-AR');
      } else {
        translate.setDefaultLang(ln);
      }
    } else {
      
      translate.setDefaultLang(sessionStorage.language);
    }
    this.language=sessionStorage.getItem('language');
    translate.setDefaultLang(sessionStorage.getItem('language'));
    console.log(ln);
    console.log(sessionStorage.language);
    //sessionStorage.clear();
    this.matIconRegistry.addSvgIcon(
      `google`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/google.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `facebook`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/facebook.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `linkedin`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/linkedin.svg'
      )
    );
  }
  ngOnInit() {
    this.loginSrv.verificoSesion();
    this.route.paramMap.subscribe((params) => {
      const red = params['params'].tipo;

      this.route.queryParams.subscribe((params) => {
        const code = params['code'];
        if (code) {
          this.loginSrv.loginRedes(red, code).subscribe(
            (user) => {
              if(user.rol.id==1){
                this.trackLoginRedesCliente(red);
              }
              else if(user.rol.id==2){
                this.trackLoginRedesAbogado(red);
              }
              this.loginSrv.handleUserData(user);
            },
            (error) => {
              if (error.status === 401) {
                this.openSnackBar(
                  'No se encontró una cuenta de Legalify vinculada a esta red social'
                );
              } else if (error.status == 409) {
                this.openDialog();
              } else if (error.status == 406) {
                this.openSnackBar(
                  this.translate.instant("register.notfound"),
                  8000
                );
              } else {
                this.openSnackBar(
                  this.translate.instant("service.error")
                );
              }
            }
          );
        }
      });
    });
  }

  trackLoginRedesAbogado(red){
    if(red=="L"){
      gtag('event', 'login_abog_exitoso_linkedin', {
        'send_to':environment.measurement_id,
        'method':'registro_abog_exitoso_linkedin'
      });
    }
    else if(red=="G"){
      gtag('event', 'registro_abog_exitoso_gmail', {
        'send_to':environment.measurement_id,
        'method':'registro_abog_exitoso_gmail'
      });

    }
    else if(red=="F"){
      gtag('event', 'registro_abog_exitoso_facebook', {
        'send_to':environment.measurement_id,
        'method':'registro_abog_exitoso_facebook'
      });
    }
  }

  trackLoginRedesCliente(red){
    if(red=="L"){
      gtag('event', 'login_clie_exitoso_linkedin', {
        'send_to':environment.measurement_id,
        'method':'registro_clie_exitoso_linkedin'
      });
    }
    else if(red=="G"){
      gtag('event', 'registro_clie_exitoso_gmail', {
        'send_to':environment.measurement_id,
        'method':'registro_clie_exitoso_gmail'
      });

    }
    else if(red=="F"){
      gtag('event', 'registro_clie_exitoso_facebook', {
        'send_to':environment.measurement_id,
        'method':'registro_clie_exitoso_facebook'
      });
    }
  }


  trackLogin(){
    gtag('event', 'login_intento', {
      'send_to':environment.measurement_id,
      'method': 'login_intento'
    });
  }

  trackLoginSuccess(){
    gtag('event', 'login_exitoso_mail', {
      'send_to':environment.measurement_id,
      'method': 'login_exitoso_mail'
    });
  }

  userForm = new UntypedFormGroup({
    email: new UntypedFormControl(),
    password: new UntypedFormControl(),
  });

  switchLang(lang: string) {
    this.translate.use(lang);
    this.language = lang;
    sessionStorage.setItem('language', lang);
    sessionStorage.language = lang;
    this.flag = true;
  }

  redirectLinkedin() {
    this.loginSrv.getRedesCredentialsUrl('L').subscribe(
      (res) => {
        gtag('event', 'login_intento_linkedin', {
          'send_to':environment.measurement_id,
          'method': 'login_intento_linkedin',
        });
        window.location.href = res.url;
      },
      (error) => {
        this.loginSrv.handleError(error, Source.Login);
      }
    );
  }

  redirectGoogle() {
    
    this.loginSrv.getRedesCredentialsUrl('G').subscribe(
      (res) => {
        gtag('event', 'login_intento_google', {
          'send_to':environment.measurement_id,
          'method': 'login_intento_google',
          
        });
        window.location.href = res.url;
      },
      (error) => {
        this.loginSrv.handleError(error, Source.Login);
      }
    );
  }

  redirectFacebook() {
    this.loginSrv.getRedesCredentialsUrl('F').subscribe(
      (res) => {
        gtag('event', 'login_intento_facebook', {
          'send_to':environment.measurement_id,
          'method': 'login_intento_facebook',
        });
        window.location.href = res.url;
      },
      (error) => {
        this.loginSrv.handleError(error, Source.Login);
      }
    );
  }

  onSubmit() {
    if (this.userForm.value.email && this.userForm.value.password) {
      this.iniciarSesion();
    }
  }

  hide = true;

  iniciarSesion() {
    this.trackLogin();
    this.apiService
      .postUser({
        clave: this.userForm.value.password,
        mail: this.userForm.value.email,
      } as NewUser)
      .subscribe(
        (user) => {
          this.trackLoginSuccess();
          if (user.rol.id == 2 || user.rol.id == 3)
            this.loginSrv.doLoadWebPush();
          sessionStorage.companyLogo = '';
          this.loginSrv.handleUserData(user);
        },
        (error) => {
          if (error.status === 401) {
            this.openSnackBar(this.translate.instant("login.error"))
          } else if (error.status == 409) {
            // this.openSnackBar("Tu matrícula se encuentra en proceso de validación");
            this.openDialog();
          } else if (error.status == 406) {
            this.openSnackBar(
              this.translate.instant("register.notfound"),
              8000
            );
          } else if (error.status == 423) {
            this.openErrorSnackBar(
              'Tu usuario se encuentra pendiente de confirmacion.',
              8000
            );
          }
          
          else {
            this.openSnackBar(
              this.translate.instant("service.error")
            );
          }
        }
      );
  }

  openDialog() {
    this.dialog.open(DialogValidarComponent, {
      backdropClass: 'backdropBackground',
    });
  }

  openDialogResendMail(){
    this.dialog.open(DialogValidarComponent, {
      backdropClass: 'backdropBackground',
    });
  }

  openSnackBar(message: string, setDuration?) {
    this._snackBar.open(message, '', {
      duration: setDuration ? setDuration : 5000,
    });
  }

  openErrorSnackBar(message: string, setDuration?) {
    this._snackBar.open(message, '', {
      duration: setDuration ? setDuration : 5000,
      panelClass: ['red-snackbar']
    });
  }

  openResend(){
    this.dialog.open(MensajeReenvioComponent, {
      backdropClass: 'backdropBackground',
    });
  }

  navegarRegistro(rol: number) {
    this.usuario.rol = rol;
    localStorage.setItem('rol', this.usuario.rol.toString());
    this.router.navigateByUrl('/redes');
  }
}
