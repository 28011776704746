import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Especialidad } from '../../../../../api/user';
import { ApiService } from '../../../../../api/api.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../especialidad/dialog.component';
import { RoleNumber } from '../../../../../enums/role';
import { delay } from 'rxjs/operators';
import { IphoneScrollService } from 'src/app/services/iphone-scroll/iphone-scroll.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-especialidad',
  templateUrl: './especialidad.component.html',
  styleUrls: ['./especialidad.component.css'],
})
export class EspecialidadComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private iPhoneService: IphoneScrollService,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));

    this.matIconRegistry.addSvgIcon(
      `Laboral`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/Laboral.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Familia`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/individuos.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Comercial y negocios`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/comercial.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Contratos`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/contratos.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Defensa del Consumidor`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/defensa.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Impuestos`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/impuestos.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Inmobiliaria y Propiedad Horizontal`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/inmobiliaria.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Otros`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/otros.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Penal`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/penal.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Sucesiones`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/sucesiones.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Jubilaciones y pensiones`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/previsional.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Digital`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/digital.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Daños y Perjuicios`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/perjuicios.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Sociedades`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/societario.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Accidentes de tránsito`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/accidentes.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Marcas y patentes`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/propiedad.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Mala Praxis y Salud`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/mala.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Violencia de género`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/discriminacion.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `ayuda`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/ayuda.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Reclamos al Estado o por Servicios Públicos`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/reclamos.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Derecho Aduanero y Comercio Exterior`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/aduanero.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Concursos y quiebras`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/quiebras.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Reclamos Temas Ambientales`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/derecho ambiental.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `Trámites y Problemas Migratorios`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../../../../assets/derecho-migratorio.svg'
      )
    );
  }

  openDialog(especialidad) {
    this.dialog.open(DialogComponent, {
      width: '600px',
      data: {
        nombre: especialidad.nombre,
        id_especialidad: especialidad.id_especialidad,
      },
      backdropClass: 'backdropBackground',
    });
  }

  permitirContinuar = false;
  caso = {
    titulo: '',
    detalles: '',
    jurisdiccion: {
      nombre: '',
      id_jurisdiccion: '',
      countryId: 0
    },
    localidad: {
      nombre: '',
      id_localidad: '',
    },
    especialidad: {
      nombre: '',
      id_especialidad: 'number',
    },
    archivosSeleccionados: [],
  };

  especialidades: Especialidad[];
  especialidadAnterior: any;
  casoForm = new UntypedFormGroup({
    especialidad: new UntypedFormControl(),
  });

  class = 'casoSinHover';
  disableScroll() {
    this.class = this.iPhoneService.changeClass();
  }

  ngOnInit(): void {
    this.caso.titulo = history.state.titulo;
    if (this.caso.titulo == undefined) {
      this.caso = JSON.parse(localStorage.getItem('caso'));
      this.caso.detalles = this.caso.detalles;
      this.caso.jurisdiccion = this.caso.jurisdiccion;
      this.caso.localidad = this.caso.localidad;
    } else {
      this.caso.detalles = history.state.detalles;
      this.caso.jurisdiccion = history.state.jurisdiccion;
      this.caso.localidad = history.state.localidad;
      this.caso.archivosSeleccionados = history.state.archivosSeleccionados;
    }

    this.apiService.getEspecialidadesByPais(RoleNumber.Cliente, this.caso.jurisdiccion.countryId).subscribe(
      (especialidades) => {
        this.especialidades = especialidades.map((especialidad) => {
          const nombreReemplazado = especialidad.nombre.replace('/', ' ');
          return { ...especialidad, nombre: nombreReemplazado };
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  atras() {
    localStorage.setItem('caso', JSON.stringify(this.caso));
    this.router.navigate(['/crear-nuevo']);
  }

  guardar(especialidad) {
    if (!this.iPhoneService.isIos) {
      especialidad.selected = !especialidad.selected;
    }
    this.caso.especialidad = especialidad;
    this.permitirContinuar = true;
  }

  onSubmit() {
    this.router.navigateByUrl('/categoria-nuevo', { state: this.caso });
  }

  down(especialidad) {
    this.class = 'casoImpar';
    especialidad.selected = !especialidad.selected;
    if (this.especialidadAnterior) {
      this.especialidadAnterior.selected = false;
    }
    this.especialidadAnterior = especialidad;
  }
}
