<body>
  <div class="language-container">
    <button class="button-idioma">
      <img [src]="imgSelected" alt="" class="language-img" />
      <select
        [ngModel]="language"
        (ngModelChange)="switchLang($event)"
        class="language-select"
      >
        <option value="es-AR" [selected]="'es-AR' === language">
          {{ "admin.Button.es" | translate }}
        </option>
        <option value="it-IT" [selected]="'it-IT' === language">
          {{ "admin.Button.it" | translate }}
        </option>
        <option value="en-US" [selected]="'en-US' === language">
          {{ "admin.Button.en" | translate }}
        </option>
      </select>
    </button>
  </div>
  <div class="contenedor-cards">
    <mat-card class="registro">
      <form [formGroup]="newUser">
        <img class="logo" src="../../../assets/logo.jpg" />
        <div class="separador">
          <button mat-button color="primary" [routerLink]="['/redes']">
            <mat-icon class="icono">chevron_left</mat-icon>
            {{ "registroAbogado.volver" | translate }}
          </button>
          <h5>{{ "registroAbogado.ingresar" | translate }}</h5>
          <h4>{{ "registroAbogado.datos" | translate }}</h4>
        </div>

        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroAbogado.nombre" | translate }}</mat-label>
          <input
            required
            matInput
            type="text"
            placeholder="{{ 'registroAbogado.nombre' | translate }}"
            formControlName="nombre"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroAbogado.apellido" | translate }}</mat-label>
          <input
            required
            matInput
            type="text"
            placeholder="{{ 'registroAbogado.apellido' | translate }}"
            formControlName="apellido"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroAbogado.correo" | translate }}</mat-label>
          <input
            required
            matInput
            type="email"
            ngModel
            email
            placeholder="{{ 'registroAbogado.correo' | translate }}"
            formControlName="email"
          />
          <mat-hint>{{ "registroAbogado.correoDescrip" | translate }}</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroAbogado.contraseña" | translate }}</mat-label>
          <input
            required
            matInput
            placeholder="Contraseña"
            (keyup)="verificarPass()"
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-hint>{{ "registroAbogado.contraDescrip" | translate }}</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroAbogado.repCon" | translate }}</mat-label>
          <input
            (blur)="verificarPassSalir()"
            (keyup)="verificarPass()"
            required
            matInput
            placeholder="{{ 'registroAbogado.repCon' | translate }}"
            [type]="hide ? 'password' : 'text'"
            formControlName="repeatPassword"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-hint *ngIf="!coinciden" class="textoRojo">{{
            "registroAbogado.contraCoin" | translate
          }}</mat-hint>
        </mat-form-field>

        <!-- <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>País</mat-label>
          <mat-select required formControlName="pais">
            <mat-option value="1">ARGENTINA</mat-option>
          </mat-select>
        </mat-form-field> -->

        <!-- *ngFor="let jurisdiccion of jurisdicciones" [value]="jurisdiccion">{{jurisdiccion.nombre}} -->
        <br />
        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroAbogado.tipoIdenti" | translate }}</mat-label>
          <mat-select
            (selectionChange)="definirIdentificacion()"
            formControlName="tipoIdentificacion"
            required
            disableRipple
          >
            <mat-option
              *ngFor="let identificacion of identificaciones"
              [value]="identificacion"
              >{{ identificacion.nombre }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ nombreIdentificacion }}</mat-label>
          <input
            [disabled]="!coinciden"
            required
            matInput
            type="text"
            placeholder="{{ nombreIdentificacion }}"
            formControlName="dni"
            #document
            [attr.maxlength]="9"
            (keypress)="keyPressAlphanumeric($event)"
          />
          <mat-hint>{{ "registroAbogado.datosDesc" | translate }}</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroAbogado.celular" | translate }}</mat-label>
          <input
            [disabled]="!coinciden"
            required
            matInput
            type="number"
            formControlName="telefono"
          />
        </mat-form-field>

        <br />

        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroAbogado.pais" | translate }}</mat-label>
          <mat-select
            [disabled]="!coinciden"
            required
            formControlName="pais"
            (selectionChange)="cargarProvincias()"
          >
            <mat-option *ngFor="let pais of cacheCountries" [value]="pais"
              >{{ pais.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroAbogado.provincia" | translate }}</mat-label>
          <mat-select
            [disabled]="!coinciden"
            required
            formControlName="provinciaResidencia"
            (selectionChange)="cargarLocalidadesResidencia()"
          >
            <mat-option
              *ngFor="let provincia of provinciasResidencias"
              [value]="provincia"
              >{{ provincia.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{ "registroAbogado.localidad" | translate }}</mat-label>
          <mat-select required formControlName="localidadResidencia">
            <mat-option
              *ngFor="let localidad of localidadesResidencias"
              [value]="localidad"
              >{{ localidad.nombre }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <div class="separador">
          <hr color="#D8D8D8" />
          <h4>{{ "registroAbogado.datosPro" | translate }}</h4>
          <mat-hint>{{ "registroAbogado.datosDesc" | translate }}</mat-hint>
        </div>

        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{
            "registroAbogado.jurisdiccion" | translate
          }}</mat-label>
          <mat-select
            [disabled]="!coinciden"
            required
            formControlName="jurisdiccion"
            (selectionChange)="cargarLocalidades()"
          >
            <mat-option
              *ngFor="let jurisdiccion of jurisdicciones"
              [value]="jurisdiccion"
              >{{ jurisdiccion.nombre }}
            </mat-option>
          </mat-select>
          <mat-hint class="azul">{{
            "registroAbogado.jurisdiccionDesc" | translate
          }}</mat-hint>
        </mat-form-field>
        <!-- <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>Localidad</mat-label>
          <mat-select required formControlName="localidad">
            <mat-option *ngFor="let localidad of localidades" [value]="localidad">{{localidad.nombre}}</mat-option>
          </mat-select>
        </mat-form-field> -->

        <mat-form-field appearance="outline" class="inputs-registro">
          <input
            [disabled]="!coinciden"
            matInput
            type="text"
            placeholder="Colegio"
            formControlName="colegio"
          />
          <mat-hint *ngIf="!colegioValido" class="textoRojo">{{
            "registroAbogado.colegioDesc" | translate
          }}</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" class="inputs-registro" *ngIf="showTomo==true">
          <mat-label>{{ "registroAbogado.tomo" | translate }}</mat-label>
          <input
            (keyup)="onKeyUpTomo($event)"
            [disabled]="!coinciden"
            matInput
            type="number"
            required
            placeholder="{{ 'registroAbogado.tomo' | translate }}"
            max="999"
            formControlName="tomo"
          />
          <mat-hint *ngIf="!tomoValido" class="textoRojo">{{
            "registroAbogado.tomoDesc" | translate
          }}</mat-hint>
        </mat-form-field>
        <mat-form-field appearance="outline" class="inputs-registro" *ngIf="showFolio==true">
          <mat-label>{{ "registroAbogado.folio" | translate }}</mat-label>
          <input
            (keyup)="onKeyUpFolio($event)"
            [disabled]="!coinciden"
            matInput
            type="number"
            max="9999"
            required
            placeholder="{{ 'registroAbogado.folio' | translate }}"
            formControlName="folio"
          />
          <mat-hint *ngIf="!folioValido" class="textoRojo">{{
            "registroAbogado.folioDesc" | translate
          }}</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="inputs-registro" *ngIf="showMatricula==true">
          <mat-label>{{ "registroAbogado.matriculaAbogado" | translate }}</mat-label>
          <input
            matInput
            type="text"
            placeholder="{{ 'registroAbogado.matriculaAbogado' | translate }}"
            formControlName="otro"
          />
          <mat-hint class="azul">{{
            "registroAbogado.mensajeOtro" | translate
          }}</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="inputs-registro">
          <mat-label>{{
            "registroAbogado.especialidad" | translate
          }}</mat-label>
          <mat-select
            [disabled]="!coinciden"
            required
            formControlName="especialidad"
          >
            <mat-option
              *ngFor="let especialidad of especialidades"
              [value]="especialidad"
              >{{ especialidad.nombre }}
            </mat-option>
          </mat-select>
          <mat-hint class="azul">{{
            "registroAbogado.especialidadDesc" | translate
          }}</mat-hint>
        </mat-form-field>


        

        <div class="separadorCentrado">
          <br />
          <hr color="#D8D8D8" />
          <br />
          <mat-label color="primary"
            >{{ "registroAbogado.final1" | translate }}
            <a target="_blank" href="/terminos-y-condiciones/"
              >{{ "registroAbogado.final2" | translate }}s</a
            >
            {{ "registroAbogado.final3" | translate }}
            <a target="_blank" href="/politica-de-privacidad/">{{
              "registroAbogado.final4" | translate
            }}</a></mat-label
          >
        </div>

        <div class="separadorCentrado">
          <div>
            <input type="checkbox" formControlName="certification" />
            <a target="_blank" href="/sign-signature/"
              >{{ "registroAbogado.finalsign" | translate }} </a
            >&nbsp;&nbsp;&nbsp;<mat-icon
              style="position: absolute"
              matTooltip="{{ 'registroAbogado.finalsignm' | translate }}"
              >info</mat-icon
            >
          </div>

          <div></div>
        </div>

        <button
          class="boton-primario"
          mat-raised-button
          color="primary"
          (click)="onSubmit()"
          [disabled]="!newUser.valid"
        >
          {{ "registroAbogado.continuar" | translate }}
        </button>
      </form>
    </mat-card>
  </div>
</body>
