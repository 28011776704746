import { Injectable } from '@angular/core';
import {
  Planes,
  GetLinkData,
  // ActualizarJurisdiccionesEspecialidades,
  JurisdiccionesAEliminar,
  PlanesIncrease,
  NewClientIncrease,
  StatusIncrease,
  DeleteClient
} from '../../api/planes';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CuentaMercado, Link } from '../../api/mercado';
import { Perfil } from '../../api/user';
import { ApiService } from '../../api/api.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PopupFacturacionComponent } from '../../components/abogado/plan/popup-facturacion/popup-facturacion.component';
import { RoleNumber } from '../../enums/role';
import { ContadorJurisdiccionesService } from '../contadorJurisdicciones/contador-jurisdicciones.service';

@Injectable({
  providedIn: 'root',
})
export class PlanService {
  private url = environment.apiUrl + '/';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private contadorJurisdiccionesService: ContadorJurisdiccionesService
  ) {}

  crearSubscripcion(data: GetLinkData): Observable<Link> {
    return this.http.get<Link>(
      this.url +
        `api/increase/createsubscription?anual=${data.anual}&planId=${data.planId}`,
      this.httpOptions
    );
  }

  createNewClientOld(datos: any): Observable<any>{
    return this.http.put<any>(
      this.url + "api/increase/nuevo",
      datos,
      this.httpOptions
    )
  }

  getPlanesIncrease(): Observable<PlanesIncrease>{
    return this.http.get<PlanesIncrease>(this.url + 'api/increase/plans', this.httpOptions);
  }


  setIncreaseClient(newClientObj):Observable<NewClientIncrease>{
    return this.http.post<NewClientIncrease>(this.url + "api/increase/nuevaSuscripcion", newClientObj ,this.httpOptions)
  }

  getClientStatus():Observable<StatusIncrease>{
    return this.http.get<StatusIncrease>(this.url + "api/increase/cliente",this.httpOptions)
  }

  deleteClientPlan(obj):Observable<DeleteClient>{
    return this.http.patch<DeleteClient>(this.url + "api/increase/cancelacion",obj,this.httpOptions)
  }

  getCuenta(): Observable<CuentaMercado> {
    return this.http.get<CuentaMercado>(
      this.url + 'api/cuentas',
      this.httpOptions
    );
  }

  getSubscriptionStatus(subscripcionId: string): Observable<any> {
    let url = this.url + `api/increase/confirmsubscription/${subscripcionId}`;

    return this.http.get(url, {responseType: 'text'});
  }

  removePlan(): Observable<any> {
    return this.http.delete<any>(
      this.url + 'api/increase/cancelsubscription',
      this.httpOptions
    );
  }

  bajarJurisdicciones(
    jurisdiccionesAEliminar: JurisdiccionesAEliminar
  ): Observable<any> {
    return this.http.post<any>(
      this.url + 'api/usuario/datosprofesionales',
      jurisdiccionesAEliminar,
      this.httpOptions
    );
  }

  getPlanesAbogado(): Observable<Planes[]> {
    return this.http.get<Planes[]>(this.url + 'api/planes', this.httpOptions);
  }

  abrirModal(plan: Planes, perfil: Perfil): boolean {
    let cantidadEspecialidades: number =
      perfil.datosProfesionales[0].especialidadesAbogados.length;
    let cantidadJurisdicciones: number =
      this.contadorJurisdiccionesService.contador(perfil.datosProfesionales);
    let result: boolean = false;
    if (
      plan.especialidades < cantidadEspecialidades ||
      plan.jurisdicciones < cantidadJurisdicciones
    ) {
      result = true;
    }
    return result;
  }

  public verificoDatosFacturacion() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.rol.id == RoleNumber.Abogado) {
      let tieneDatosDeFacturacion: boolean = false;
      this.apiService.getPerfil().subscribe(
        (respuesta) => {
          if (
            !respuesta.datosProfesionales[0].direccion ||
            !respuesta.datosProfesionales[0].codigoPostal
          ) {
            const dialogRef = this.dialog.open(PopupFacturacionComponent, {
              backdropClass: 'backdropBackground',
            });

            dialogRef.afterClosed().subscribe((result) => {
              if (!result) {
                this.verificoDatosFacturacion();
                window.location.reload();
              }
            });
          }
        },
        (error) => {
          console.error(error);
          this.openSnackBar(
            'No fue posible obtener la información del perfil. Por favor, intente nuevamente más tarde.'
          );
        }
      );
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 5000 });
  }

  isPlanActive(plan: StatusIncrease["suscripcion"]): boolean {
    let result: boolean;

    result = plan && (plan.status !== 'canceled' && plan.status !== 'pending')
    return result;
  }

  isPlanCorpOrPrem(plan: StatusIncrease["suscripcion"]): boolean {
    let result: boolean;
    let planExp;

    result = this.isPlanActive(plan);
    if (result) {
      result = plan.nombrePlan.toLocaleUpperCase().indexOf("PREMIUM") >= 0 || plan.nombrePlan.toLocaleUpperCase().indexOf("CORPORATIVO") >= 0;
    }
    return result;
  }

}
