<body>
  <div class="contenedor-cards">
    <mat-card class="login-redes">
      <img class="logo" src="../../../../assets/logo.jpg" />
      <div class="registrarse">
        <div class="linea">
          <button
            class="atras-redes"
            mat-button
            color="basic"
            [routerLink]="['']"
          >
            <mat-icon class="icono">chevron_left</mat-icon>
          </button>
          <p class="Mont14">{{ titulo }}</p>
        </div>
        <div class="separadorCentrado" *ngIf="mostrarTerminos">
          <mat-label color="primary">{{ "redes.term1" | translate }}</mat-label
          ><br />
          <mat-label color="primary"
            >{{ "redes.estaras" | translate }}
            <a target="_blank" href="/terminos-y-condiciones/">{{
              "redes.term2" | translate
            }}</a></mat-label
          ><br />
          <mat-label color="primary"
            >{{ "redes.term3" | translate }}
            <a
              target="_blank"
              href="/politica-de-privacidad/"
              >{{ "redes.term4" | translate }}</a
            ></mat-label
          >
        </div>
        <div class="iconos">
          <button mat-button (click)="obtenerCredencialesGoogle()">
            <mat-icon svgIcon="google" class="icono"></mat-icon>
            {{ "redes.google" | translate }}</button
          ><br />
        </div>
        <div class="iconos">
          <button mat-button (click)="obtenerCredencialesFacebook()">
            <mat-icon svgIcon="facebook" class="icono"></mat-icon>
            {{ "redes.facebook" | translate }}</button
          ><br />
        </div>
        <div class="iconos">
          <button mat-button (click)="obtenerCredencialesLinkedin()">
            <mat-icon svgIcon="linkedin" class="icono"></mat-icon>
            {{ "redes.linkedin" | translate }}</button
          ><br />
        </div>
        <div class="iconos">
          <button mat-button (click)="navegarRegistro()">
            <mat-icon svgIcon="correo" class="icono"></mat-icon>
            {{ "redes.correo" | translate }}</button
          ><br />
        </div>

        <div class="mobile">
          <button
            class="boton-secundario"
            mat-button
            color="primary"
            (click)="navegarRegistro()"
          >
            <mat-icon svgIcon="correo" class="icono"></mat-icon>
            <div class="texto">
              <div class="upper">REGISTRARSE CON</div>
              <div class="bottom">CORREO ELECTRONICO</div>
            </div>
          </button>
        </div>
      </div>

      <div class="separadorCentrado">
        <p class="Mont14">{{ "redes.preg" | translate }}</p>
      </div>
      <button
        class="ingresar-redes"
        mat-button
        color="primary"
        [routerLink]="['']"
      >
        {{ "redes.ingreso" | translate }}
      </button>
      <router-outlet #outlet="outlet"></router-outlet>
    </mat-card>
  </div>
</body>
