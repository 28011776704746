import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerServiceService {

  private showSpinner : BehaviorSubject<boolean>;
  public showSpinner$ : Observable<boolean>;

  constructor() {
    this.showSpinner = new BehaviorSubject<boolean> (false);
    this.showSpinner$ = this.showSpinner.asObservable();
   }

  updateShowSpinner(display: boolean) {
    this.showSpinner.next(display);
  }
}
