<div class="casoFondo">
  <mat-toolbar class="header">
    <button mat-button (click)="navegar(1)">
      <mat-icon class="icono">chevron_left</mat-icon>
    </button>
    <span class="example-spacer"></span>
    <span>{{ "detalles.detalles" | translate }}</span>
  </mat-toolbar>

  <div class="separadorCards" *ngIf="!perdido">
    <div *ngIf="seleccionado && noSoyAbogado">
      <mat-card class="cardElegiste">
        <div>
          <div class="imagenCuadrada">
            <mat-icon
              *ngIf="!tieneFoto"
              svgIcon="perfil"
              class="imagenDetalles"
            ></mat-icon>
            <img
              *ngIf="tieneFoto"
              class="rounded"
              [src]="avatarUrl | secure | async"
            />
          </div>
          <br />
          <div class="elegiste">
            <mat-card-title class="derecha"
              >{{ datos.abogado.nombre }} {{ datos.abogado.apellido }}
            </mat-card-title>
            <mat-card-subtitle class="derecha">{{
              "detalles.abogado" | translate
            }}</mat-card-subtitle>
            <mat-card-subtitle *ngIf="noSoyAbogado">
              <a href="mailto:{{ mailAbogado }}">{{ mailAbogado }}</a>
            </mat-card-subtitle>
            <div class="linea" *ngIf="esLlamada">
              <mat-card-subtitle>{{ telefono }}</mat-card-subtitle>
              <button
                class="boton-llamar"
                mat-button
                color="primary"
                (click)="llamar(telefono)"
              >
                {{ "detalles.llamar" | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="separadorCards">
    <div *ngIf="!finalizado">
      <mat-card *ngIf="hayAbogadosParaElegir || seleccionado" class="card">
        <div *ngIf="hayAbogadosParaElegir">
          <div class="informacion">
            <mat-icon svgIcon="alert"></mat-icon>
            <mat-card-title class="textoInformativo">{{
              "detalles.disponibles" | translate
            }}</mat-card-title>
          </div>
          <button
            class="boton-llamar"
            mat-button
            color="primary"
            (click)="navegar(3)"
          >
            {{ "detalles.seleccionar" | translate }}
          </button>
        </div>
        <div *ngIf="sePuedeCalificar()">
          <div class="separadorCentrado">
            <mat-icon class="iconoCalificar">star</mat-icon>
            <h1>{{ "detalles.preg" | translate }} {{ textoParaCalificar }}?</h1>
            <mat-card-subtitle>{{
              "detalles.calificar" | translate
            }}</mat-card-subtitle>
            <button
              class="boton-primario"
              mat-raised-button
              color="primary"
              (click)="navegar(2)"
            >
              {{ "detalles.calificarBtn" | translate }}
            </button>
          </div>
        </div>
      </mat-card>
    </div>
    <mat-card class="card">
      <mat-card-header>
        <p *ngIf="caso!=null" [class]="caso.clase">{{ caso.estado }}</p>
        <p *ngIf="caso!=null" class="fecha">{{ caso.fechaCreacion | date: "dd/MM/yyy" }}</p>
        <br />
      </mat-card-header>
      <mat-card-content>
        <br /><br />
         <mat-card-title class="tituloCaso" *ngIf="rolActual==3">{{datos.cliente.nombre}} </mat-card-title>
         <mat-card-subtitle *ngIf="rolActual==3">{{datos.cliente.pais}}</mat-card-subtitle>
         <mat-card-subtitle *ngIf="rolActual==3">{{datos.cliente.mail}}</mat-card-subtitle>
         <mat-card-subtitle *ngIf="rolActual==3">{{telefono}}</mat-card-subtitle>     

         
         <mat-card-title class="tituloCaso" *ngIf="rolActual==2 && globalCliente!=null && globalCliente.nombre!=null">{{globalCliente.nombre}} </mat-card-title>
         <mat-card-subtitle *ngIf="rolActual==2 && globalCliente!=null && globalCliente.nombre!=null">{{datos.cliente.pais}} </mat-card-subtitle>
         <mat-card-subtitle *ngIf="rolActual==2 && globalCliente!=null && globalCliente.mail!=null">{{globalCliente.mail}}</mat-card-subtitle>
         <mat-card-subtitle *ngIf="rolActual==2 && globalCliente!=null && globalCliente.datosUsuario!=null"> 
          {{globalCliente.datosUsuario[0].telefono}} &nbsp;&nbsp;
         <a href="https://wa.me/+{{pais}}{{globalCliente.datosUsuario[0].telefono}}">
          <mat-icon svgIcon="whatsapp" class="whatsapp"></mat-icon>
         </a></mat-card-subtitle>     
         <hr color="#D8D8D8" />
        <br /><br /><br />
        <mat-card-title class="tituloCaso">{{ caso.titulo }}</mat-card-title>
        <mat-card-subtitle>{{ caso.categoria }}</mat-card-subtitle>
        <br />
        <hr color="#D8D8D8" />
        <br />
        <h2>{{ "detalles.jurisdiccion" | translate }}</h2>
        <mat-card-title>
          <mat-icon class="azul">place</mat-icon>{{ caso.jurisdiccion }}
        </mat-card-title>
        <br />
        <mat-card-subtitle> {{ caso.localidad }}</mat-card-subtitle>
        <br />
        <hr color="#D8D8D8" />
        <br />
        <h2>{{ "detalles.descripcion" | translate }}</h2>
        <mat-card-subtitle>{{ caso.descripcion }}</mat-card-subtitle>
        <br /><br />
        <hr color="#D8D8D8" />
        <br />
        <h2>{{ "detalles.archivos" | translate }}</h2>
        <app-archivos
          [archivos]="this.listaArchivos"
          [isDownloadable]="true"
          [casoId]="caso.id_caso"
        ></app-archivos>
        <div *ngIf="esCita">
          <hr color="#D8D8D8" />
          <br />
          <h2>{{ "detalles.cita" | translate }}</h2>
          <mat-card-subtitle
            >{{ "detalles.fecha" | translate }} {{ fecha }}
            {{ "detalles.hora" | translate }} {{ hora }}</mat-card-subtitle
          >
          <mat-card-subtitle *ngIf="noSoyAbogado"
            >{{ "detalles.correoAbo" | translate }}
            <a href="mailto:{{ mailAbogado }}">{{ mailAbogado }}</a>
          </mat-card-subtitle>
          <mat-card-subtitle *ngIf="!noSoyAbogado"
            >{{ "detalles.correoCli" | translate }}
            <a href="mailto:{{ mailCliente }}">{{ mailCliente }}</a>
          </mat-card-subtitle>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <br />
</div>
