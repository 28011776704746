import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { AppComponent } from './app.component';
import { MaterialModule } from './modules/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { LoginComponent } from './components/login/login.component';
import { LoginModule } from './components/login/login.module';

import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

import { CuentaValidadaClienteComponent } from './components/cliente/registroYMensajesAsociados/cuenta-validada-cliente/cuenta-validada-cliente.component';
import { PerfilComponent } from './components/cliente/perfil/perfil.component';
import { PerfilAbogadoComponent } from './components/abogado/perfil-abogado/perfil-abogado.component';
import { DialogComponent } from './components/abogado/perfil-abogado/dialog.component';

import { NavbarAbogadoComponent } from './components/abogado/navbar-abogado/navbar-abogado.component';

import { OportunidadesComponent } from './components/abogado/casos/oportunidades/oportunidades.component';
import { ActivosComponent } from './components/cliente/casos/misCasos/activos/activos.component';
import { NavbarClienteComponent } from './components/cliente/navbar-cliente/navbar-cliente.component';
import { VistaPreviaComponent } from './components/abogado/vista-previa/vista-previa.component';
import { RangoComponent } from './components/cliente/casos/nuevo/agenda/rango/rango.component';
import { PreviaComponent } from './components/abogado/seleccion/previa/previa.component';
import { DatosComponent } from './components/abogado/seleccion/datos/datos.component';
import { ResumenComponent } from './components/cliente/casos/nuevo/resumen/resumen.component';
import { AgendadaComponent } from './components/cliente/casos/nuevo/agenda/agendada/agendada.component';
import { OpinionesComponent } from './components/abogado/opiniones/opiniones.component';
import { CalificarComponent } from './components/calificar/calificar.component';
import { ArchivosComponent } from './components/archivos/archivos.component';
import { RevisarComponent } from './components/cliente/casos/nuevo/revisar/revisar.component';
import { DetallesComponent } from './components/cliente/casos/misCasos/detalles/detalles.component';
import { SecurePipe } from './pipes/secure.pipe';
import { PlanComponent } from './components/abogado/plan/plan.component';
import { AgregarComponent } from './components/abogado/agregar/agregar.component';
import { VinculacionComponent } from './components/mercado/vinculacion/vinculacion.component';
import { PagoExitosoComponent } from './components/mercado/pago-exitoso/pago-exitoso.component';
import { PagoFalloComponent } from './components/mercado/pago-fallo/pago-fallo.component';
import { PagoPendienteComponent } from './components/mercado/pago-pendiente/pago-pendiente.component';
import { DatePipe } from '@angular/common';
import { DialogVincularComponent } from './components/abogado/perfil-abogado/dialogvincular.component';
import { EntFormularioComponent } from './components/company/formulario/formulario.component';
import { CertFormularioComponent } from './components/certificacion/formulario/formulario.component';
import { CertAvisoComponent } from './components/certificacion/aviso/aviso.component';
import { DetalleComponent } from './components/abogado/plan/detalle/detalle.component';
import { MensajeValidarComponent } from './components/login/mensaje-validar/mensaje-validar.component';
import { MensajeEsperaComponent } from './components/abogado/agregar/mensaje-espera/mensaje-espera.component';
import { CuentaValidadaAbogadoComponent } from './components/abogado/registroYMensajesAsociados/cuenta-validada-abogado/cuenta-validada-abogado.component';
import { DialogValidarComponent } from './components/login/dialog-vincular/dialogvalidar.component';
import { MensajeReenvioComponent } from './components/login/mensaje-reenvio/mensaje-reenvio.component';
import { DialogCalificarComponent } from './components/calificar/dialog/dialog.component';
import { SpinnerInterceptor } from './interceptors/spinner/spinner.interceptor';
import { InicioModule } from './components/admin/inicio/inicio.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PaisesModule } from './components/admin/paises/paises.module';
import { EntidadesModule } from './components/admin/entidades/entidades.module';
import { CertificacionModule } from './components/admin/certificacion/certificacion.module';
import { CancelarComponent } from './components/abogado/plan/cancelar/cancelar/cancelar.component';
import { ExitoComponent } from './components/abogado/plan/exito/exito.component';
import { PopupDowngradeComponent } from './components/abogado/plan/popup-downgrade/popup-downgrade.component';
import { PopupFacturacionComponent } from './components/abogado/plan/popup-facturacion/popup-facturacion.component';
import { PlanEnPerfilComponent } from './components/abogado/plan-en-perfil/plan-en-perfil.component';
import { ExternalComponent } from './components/cliente/registroYMensajesAsociados/external/external.component';
import { PopupBajaComponent } from './components/abogado/plan/popup-baja/popup-baja.component';
import { LanguageNavComponent } from './components/language-nav/language-nav.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TermsComponent } from './components/terms/terms.component';
import { DatosModule } from './components/admin/datos/datos.module';
import { MultipagoModule } from './components/admin/multipago/modules/multipago.module';
import { VinculacionesComponent } from './components/abogado/vinculaciones/vinculaciones.component';
import { AbogadoComponent } from './components/abogado/abogado.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { PopupDialogComponent } from './components/cliente/casos/nuevo/crear/popup-dialog/popup-dialog.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LogoComponent } from './components/company/logo/logo.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CuentaValidadaClienteComponent,
    PerfilComponent,
    PerfilAbogadoComponent,
    NavbarAbogadoComponent,
    OportunidadesComponent,
    ActivosComponent,
    NavbarClienteComponent,
    VistaPreviaComponent,
    RangoComponent,
    PreviaComponent,
    DatosComponent,
    ResumenComponent,
    AgendadaComponent,
    OpinionesComponent,
    CalificarComponent,
    ArchivosComponent,
    RevisarComponent,
    DetallesComponent,
    DialogComponent,
    SecurePipe,
    PlanComponent,
    AgregarComponent,
    VinculacionComponent,
    AbogadoComponent,
    VinculacionesComponent,
    PagoExitosoComponent,
    PagoFalloComponent,
    PagoPendienteComponent,
    DialogVincularComponent,
    DetalleComponent,
    MensajeValidarComponent,
    MensajeEsperaComponent,
    CuentaValidadaAbogadoComponent,
    DialogValidarComponent,
    EntFormularioComponent,
    CertFormularioComponent,
    CertAvisoComponent,
    MensajeReenvioComponent,
    DialogCalificarComponent,
    CancelarComponent,
    ExitoComponent,
    PopupDowngradeComponent,
    PopupBajaComponent,
    PopupFacturacionComponent,
    PlanEnPerfilComponent,
    ExternalComponent,
    LanguageNavComponent,
    TermsComponent,
    PopupDialogComponent,
    LogoComponent,
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    LoginModule,
    MatTooltipModule,
    HttpClientModule,
    FormsModule,
    MatListModule,
    MatFormFieldModule,
    InicioModule,
    PaisesModule,
    DatosModule,
    EntidadesModule,
    CertificacionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  exports: [NavbarAbogadoComponent, ArchivosComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
