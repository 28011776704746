import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Planes } from '../../../../../api/planes';
import { ApiService } from '../../../../../api/api.service';
// import { FilesService } from 'src/app/utils/files.service';
import { FilesService } from '../../../../../utils/files.service';
import { TranslateService } from '@ngx-translate/core';
import { PlanMultipagoService } from 'src/app/services/plan/plan-multipago.service';
import { PlanDefaultAmount } from 'src/app/components/admin/multipago/modules/multipago.model';

enum PlanType {
  Mail = 'Mail',
  Schedule = 'Agenda',
  CallExpress = 'Llamada Express',
}

class CostPlan {
  Mail: number;
  Schedule: number;
  CallExpress: number;
  ScheduleMoneda: string;
  CallExpressMoneda: string;
}

@Component({
  selector: 'app-tipo-servicio',
  templateUrl: './tipo-servicio.component.html',
  styleUrls: ['./tipo-servicio.component.css'],
})
export class TipoServicioComponent implements OnInit {
  caso = {
    titulo: '',
    detalles: '',
    jurisdiccion: {
      nombre: '',
      id_jurisdiccion: 0,
    },
    localidad: {
      nombre: '',
      id_localidad: 0,
    },
    especialidad: {
      nombre: '',
      id_especialidad: 0,
    },
    categoria: {
      nombre: '',
      id_categoria: 0,
      descripcion: '',
    },
    archivosSeleccionados: [],
  };
  datos = {
    titulo: 'Llamada express',
    abogados: [],
    caso: this.caso,
  };
  costPlan: CostPlan;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private servicePlanMultipago: PlanMultipagoService,
    private fileSrv: FilesService,
    public translate: TranslateService
  ) {
    translate.addLangs(['es-AR', 'it-IT','en-US']);
    translate.setDefaultLang(sessionStorage.getItem('language'));
  }
  casoForm = new UntypedFormGroup({});

  async ngOnInit() {
    this.caso.titulo = history.state.titulo;
    if (this.caso.titulo == undefined) {
      this.caso = JSON.parse(localStorage.getItem('caso'));
      this.caso.detalles = this.caso.detalles;
      this.caso.jurisdiccion = this.caso.jurisdiccion;
      this.caso.localidad = this.caso.localidad;
      this.caso.especialidad = this.caso.especialidad;
      this.caso.categoria = this.caso.categoria;
      this.caso.archivosSeleccionados = this.caso.archivosSeleccionados;

      let archivos = JSON.parse(localStorage.getItem('archivos'));
      if (archivos) {
        for (let a of archivos) {
          const { file, name } = a;
          const archivoFinal = this.fileSrv.fromBase64ToFile(file, name);
          this.caso.archivosSeleccionados.push(archivoFinal);
        }
      }
    } else {
      this.caso.detalles = history.state.detalles;
      this.caso.jurisdiccion = history.state.jurisdiccion;
      this.caso.localidad = history.state.localidad;
      this.caso.especialidad = history.state.especialidad;
      this.caso.categoria = history.state.categoria;
      this.caso.archivosSeleccionados = history.state.archivosSeleccionados;
    }
    // let base64Files = [];

    // if (this.caso.archivosSeleccionados) {
    //   for (let a of this.caso.archivosSeleccionados) {
    //     const convertedFile = await this.fileSrv.fromFileToBase64(a);
    //     base64Files.push({ file: convertedFile, name: a.name });
    //   }
    //   localStorage.setItem('archivos', JSON.stringify(base64Files));
    // } else {
    //   localStorage.removeItem('archivos');
    // }

    var plans: Planes[];
    this.costPlan = new CostPlan();

    this.apiService.getPlanes().subscribe(
      (planes) => {
        plans = planes;
        this.setCost(plans);

        this.servicePlanMultipago.getPlanDefaultAmounts(0, this.caso.jurisdiccion.id_jurisdiccion, [2, 3]).subscribe({
          next: (result: PlanDefaultAmount[]) => {
            this.setDefaultCost(result);
            console.log(result.length);
          }
        });
      },
      (error) => {
        console.error(error);
      }
    );
    this.traerAbogados();
    console.log(this.caso);
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  atras() {
    if (this.caso.especialidad.nombre == 'Otros') {
      this.router.navigate(['/especialidad-nuevo']);
    } else {
      this.router.navigate(['/categoria-nuevo']);
    }
  }

  traerAbogados() {
    let hora = Date.now();
    this.apiService
      .getAbogadosDisponiblesLlamada(
        this.caso.especialidad.id_especialidad,
        hora,
        this.caso.localidad.id_localidad
      )
      .subscribe(
        (respuesta) => {
          this.datos.abogados = respuesta;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  navegar(destino) {
    switch (destino) {
      case 1:
        this.router.navigateByUrl('/revisar-nuevo', { state: this.caso });
        break;
      case 2:
        this.router.navigateByUrl('/rango', { state: this.datos });
        break;
      case 3:
        this.router.navigateByUrl('/seleccion/previa', { state: this.datos });
      default:
        break;
    }
  }

  setDefaultCost(planAmounts: PlanDefaultAmount[]): void {
    planAmounts.forEach((planAmount: PlanDefaultAmount) => {
      switch (planAmount.planId) {
        case 2:
          this.costPlan.Schedule = planAmount.monto;
          this.costPlan.ScheduleMoneda = planAmount.monedaId;
          break;
        case 3:
          this.costPlan.CallExpress = planAmount.monto;
          this.costPlan.CallExpressMoneda = planAmount.monedaId;
          break;
      }
    });
  }

  setCost(plans: Planes[]) {
    this.costPlan.Mail = plans.find((x) => x.nombre == PlanType.Mail).costo;
    this.costPlan.Schedule = plans.find(
      (x) => x.nombre == PlanType.Schedule
    ).costo;
    this.costPlan.CallExpress = plans.find(
      (x) => x.nombre == PlanType.CallExpress
    ).costo;
  }
}
